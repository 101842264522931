import { PageContainer } from 'components';
import React from 'react';
import { ROUTE_CAPACITY_ACTION_PLAN } from 'types/routes';
import { CapacityActionPlan } from 'views';

const CapacityActionPlanRoute = () => (
    <PageContainer pageAnalyticsId={ROUTE_CAPACITY_ACTION_PLAN} isFullWidth align="horizontal">
        <CapacityActionPlan />
    </PageContainer>
);

export default CapacityActionPlanRoute;
