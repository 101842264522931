import React from 'react';
import { AuthContext } from 'context';
import { AuthContextValues } from 'types/authContext';

const useAuth = (): AuthContextValues => {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error('AuthContext was used outside of its provider');
    }

    return context;
};

export default useAuth;
