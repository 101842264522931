import { Coworker } from './coworker';
import { DummyCoworker } from './scenario';

export enum RuleOperator {
    AND = 'AND',
    OR = 'OR'
}
export enum ClauseOperator {
    EQUALS = '=',
    IN = 'in',
    NOT_EQUALS = '!=',
    RANGE = 'RANGE'
}

export type RuleClauseRangeValue = {
    min: number,
    max: number
};

export type ModulationRange = {
    maxLimit: {
        hours: number,
        minutes: number
    },
    minLimit: {
        hours: number,
        minutes: number
    }
};

export type RuleClause = {
    key: keyof (Coworker | DummyCoworker),
    operator: ClauseOperator,
    value: string | RuleClauseRangeValue | Array<string>
};

export type Rule = {
    id: string,
    operator: RuleOperator, // move this next to id?
    clauses: Array<RuleClause>,
    modulationRange: ModulationRange,
};
export enum ModulationType { CONSECUTIVE = 'CONSECUTIVE', ROLLING = 'ROLLING' }

export type SharedModulationData = {
    id: string;
    rules: Array<Rule>;
    modulation: {
        type: ModulationType;
        period: number;
        startDate: string; // ISO date format
    };
    isModulated: true;
};
