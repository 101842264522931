import Button from '@ingka/button';
import ListBox, { ListBoxFooter } from '@ingka/list-box';
import ListView, { ListViewItem } from '@ingka/list-view';
import SSRIcon from '@ingka/ssr-icon';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';
import chevronUp from '@ingka/ssr-icon/paths/chevron-up';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Pill from '@ingka/pill';
import { ListBoxWithMultiSelect } from 'types/appContext';
import classes from './ListBoxWithMultipleSelect.module.scss';

const ListBoxWithMultipleSelect = ({ showCount, placeHolder, data, isOpen, handleSetIsOpen, handleSelect, handleSave }:
{
    showCount: boolean,
    placeHolder: string,
    data: ListBoxWithMultiSelect[] | undefined,
    isOpen: boolean,
    handleSetIsOpen: (e: React.SetStateAction<boolean>) => void,
    handleSelect: (e: React.FormEvent<Element>) => void,
    handleSave: () => void,
}) => {
    const { t } = useTranslation();
    const selectedCount = useMemo(() => data?.filter(({ isChecked }) => isChecked)?.length ?? 0, [data]);

    return (
        <div className={classes['list-box__container']}>
            <div
                data-testid="list-box-dropdown"
                className={classes['list-box__dropdown']}
                onClick={() => handleSetIsOpen(!isOpen)}
            >
                <div
                    id="list-box-place-holder"
                    data-testid="list-box-place-holder"
                    className={classes['list-box__placeholder']}
                >
                    {showCount
                    && (
                    <Pill
                        data-testid="list-box-pill"
                        label={`${selectedCount ?? 0}`}
                        size="small"
                        iconPosition="trailing"
                        className={classes['list-box__pill']}
                    />
                    )}
                    {placeHolder}
                </div>
                <div>
                    <SSRIcon
                        id="list-box-dropdown-arrow-icon"
                        data-testid="list-box-dropdown-arrow-icon"
                        paths={isOpen ? chevronUp : chevronDown}
                    />
                </div>
            </div>
            {isOpen && data && (
                <div className={classes['list-box__wrapper']}>
                    <ListBox
                        footer={(
                            <ListBoxFooter>
                                <div className={classes['list-box__btns']}>
                                    <div>
                                        <Button
                                            id="list-box-cancel-button"
                                            data-testid="list-box-cancel-button"
                                            size="small"
                                            fluid
                                            className={classes['list-box__cancel-btn']}
                                            type="secondary"
                                            text={t('CANCEL')}
                                            onClick={() => handleSetIsOpen(false)}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            id="list-box-save-button"
                                            data-testid="list-box-save-button"
                                            fluid
                                            size="small"
                                            className={classes['list-box__save-btn']}
                                            type="primary"
                                            text={t('SAVE')}
                                            onClick={() => { handleSave(); handleSetIsOpen(false); }}
                                        />
                                    </div>
                                </div>
                            </ListBoxFooter>
                        )}
                    >
                        <ListView
                            id="list-view"
                            size="small"
                            showDivider={false}
                            className={classes['list-box__view']}
                        >
                            {data?.map((unit: ListBoxWithMultiSelect) => (
                                <ListViewItem
                                    id={`list-view-item-${unit.id}`}
                                    data-testid={`list-view-item-${unit.value}`}
                                    className={classes['list-box__item']}
                                    style={{ padding: '0' }}
                                    size="small"
                                    showDivider={false}
                                    inset
                                    key={`list-view-${unit.id}`}
                                    name={unit.value}
                                    title={unit.label}
                                    onChange={handleSelect}
                                    control="checkbox"
                                    controlProps={{ checked: unit.isChecked, value: unit.value }}
                                />
                            ))}
                        </ListView>
                    </ListBox>
                </div>
            )}
        </div>
    );
};
export default ListBoxWithMultipleSelect;
