import React from 'react';
import { LoadingContext } from 'context';

const useLoading = () => {
    const context = React.useContext(LoadingContext);
    if (!context) {
        throw new Error('No data in DataContext?');
    }

    return context;
};

export default useLoading;
