import InlineMessage from '@ingka/inline-message';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUploadFailed } from 'types/dataContext';
import Button from '@ingka/button';
import classes from './FileUpload.module.scss';

export const ErrorMessage = ({ uploadFailedProps }: { uploadFailedProps: FileUploadFailed }) => {
    const { t } = useTranslation();
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
    const { category, fileName, errorText, errors } = uploadFailedProps;

    return (
        <>
            <InlineMessage
                data-testid="fileupload-failed-inline-message"
                {...(!!errors && {
                    actions: [{
                        text: t('VIEW_ERROR'),
                        onClick: () => { setErrorModalOpen(true); },
                        loading: false,
                    }]
                })}
                title={t('UPLOAD_FAILED')}
                body={t(errorText)}
                variant="negative"
            />
            <Modal
                visible={errorModalOpen}
                escapable
                handleCloseBtn={() => setErrorModalOpen(false)}
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={(<ModalHeader title={t(category)} backBtnClick={() => setErrorModalOpen(false)} />)}
                    footer={(
                        <ModalFooter>
                            <Button
                                data-testid="modal-cancel-button"
                                size="small"
                                text={t('CLOSE')}
                                type="secondary"
                                onClick={() => { setErrorModalOpen(false); }}
                            />
                        </ModalFooter>
                    )}
                >
                    <ModalBody>
                        <div className={classes['upload-failed']}>
                            <div data-testid="fileupload-failed-category" className={classes.headings}>
                                {t(category)}
                            </div>
                            <div data-testid="fileupload-failed-file-name" className={classes.headings}>
                                {t(fileName)}
                            </div>
                            <div data-testid="fileupload-failed-error-details" className={classes.headings}>
                                {t('ERROR_DETAILS')}
                            </div>
                            <div>
                                {errors
                                    && errors.map(({ sheetName, row, col, error }, index) => (
                                        <Fragment key={`errorMessage-${index + 0}`}>
                                            <div data-testid="fileupload-failed-detailed-error">
                                                <div>{`${t('SHEET_NAME')}: ${sheetName}`}</div>
                                                <div>{`${t('ROW')}: ${row}`}</div>
                                                <div>{`${t('COLUMN')}: ${col}`}</div>
                                                <span>{`${t('ERROR')}: ${t(error)}`}</span>
                                            </div>
                                            <br />
                                        </Fragment>
                                    ))}
                            </div>
                        </div>
                    </ModalBody>
                </Sheets>
            </Modal>
        </>
    );
};

export default ErrorMessage;
