import React from 'react';
import { PageContainer } from 'components';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import classes from './Configuration.module.scss';
import CountrySelector from './CountrySelector';
import ConfigTabs from './ConfigTabs';

const Configuration = () => {
    const { t } = useTranslation();

    return (
        <PageContainer data-testid="configuration-page" className={classes.config__container}>
            <>
                <div id="config-page-header" className={classes['config__header-container']}>
                    <Text tagName="h1" headingSize="l" id="config-page-header-title" data-testid="config-page-header-title">{t('COUNTRY_CONFIG')}</Text>
                </div>
                <div id="config-page-card" className={classes.config__card}>
                    <div id="config-page-country-div" className={classes.config__country}>
                        <CountrySelector />
                    </div>
                    <div className={classes['config__general-setting']}>
                        <ConfigTabs />
                    </div>
                </div>
            </>
        </PageContainer>
    );
};

export default Configuration;
