import { useTranslation } from 'react-i18next';
import classes from './GreyedOutCell.module.scss';

export const GreyedOutCell = ({ colSpan, position, text }:{ colSpan: number, position: 'start' | 'end', text: string }) => {
    const { t } = useTranslation();

    return (
        <td
            className={`${classes['greyed-out-cell']} ${position === 'start'
                ? classes['greyed-out-cell--start']
                : classes['greyed-out-cell--end']}`}
            colSpan={colSpan}
            data-testid="greyed-out-cell"
        >
            {t(text)}
        </td>
    );
};
