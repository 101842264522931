import Button from '@ingka/button';
import chevronDownSmall from '@ingka/ssr-icon/paths/chevron-down-small';
import chevronUpSmall from '@ingka/ssr-icon/paths/chevron-up-small';
import useData from 'hooks/useData';
import { getCostCentreDescription } from 'hooks/useGrid/gridFunctions';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR } from 'utils/date';
import { TypeCoworkerActionPlan } from 'types/actionPlan';
import { getShortCC } from 'utils/text';
import Text from '@ingka/text';
import classes from './ActionTable.module.scss';
import ActionTableCoworkerDetails from './ActionTableCoworkerDetails';

export const CapacityActionTableRow = ({
    coworkerActionPlan,
    totalCoworkers,
}: {
    coworkerActionPlan: TypeCoworkerActionPlan
    totalCoworkers: number
}) => {
    const { unfilteredCountryOMData } = useData();
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState<boolean>(totalCoworkers === 1);
    const { name, isDummy, costCentres, lastChanged, actionGroups, personId } = coworkerActionPlan;
    const totalActions = actionGroups.reduce((accumulator, currentValue) => accumulator + currentValue.actions.length, 0);

    const handleClick = () => {
        setShowDetails(!showDetails);
    };

    return (
        <>
            <tr>
                <td>
                    <div
                        className={classes['table__name-cell']}
                    >
                        <Button
                            data-testid="capacity-action-plan-button"
                            iconPosition="trailing"
                            iconOnly
                            size="small"
                            ssrIcon={showDetails ? chevronUpSmall : chevronDownSmall}
                            type="tertiary"
                            onClick={handleClick}
                        />
                        <div className={classes.table__coworker}>
                            <Text
                                headingSize="xs"
                                data-testid="action-plan-row-name"
                            >
                                {name}
                                {' '}
                                {isDummy && `(${t('NEW').toUpperCase()})`}
                            </Text>
                            {!isDummy ? (
                                <Text>
                                    {personId}
                                </Text>
                            )
                                : ''}
                        </div>
                    </div>
                </td>
                <td>
                    <Text
                        data-testid="action-plan-row-num-changes"

                    >
                        { totalActions === 1 ? `${totalActions} ${t('CHANGE')}` : `${totalActions} ${t('CHANGES')}`}
                    </Text>
                </td>
                <td>
                    <Text
                        data-testid="action-plan-row-change-date"
                    >
                        {moment(lastChanged).format(DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR)}
                    </Text>
                </td>
                <td>
                    <Text
                        data-testid="action-plan-row-all-cost-centres"
                    >
                        {costCentres.map((cc, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`costDistributions-${index}`}>
                                <Text
                                    headingSize="xs"
                                    data-testid="action-plan-row-cost-centre-desc"
                                >
                                    {getCostCentreDescription(cc, unfilteredCountryOMData)}
                                </Text>
                                <Text
                                    data-testid="action-plan-row-cost-centre-id"
                                >
                                    {`CC${getShortCC(cc)}`}
                                </Text>
                            </div>
                        ))}
                    </Text>
                </td>
            </tr>
            {showDetails && (
            <tr>
                <td colSpan={100}>
                    <ActionTableCoworkerDetails coworkerActionPlan={coworkerActionPlan} />
                </td>
            </tr>
            )}
        </>
    );
};

export default CapacityActionTableRow;
