import { useContext } from 'react';
import { ActionPlanContext } from 'context/ActionPlanContext';

const useActionPlanContext = () => {
    const context = useContext(ActionPlanContext);

    if (context === undefined) {
        throw new Error('ActionPlanContext was used outside of its provider');
    }

    return context;
};

export default useActionPlanContext;
