import { buildUrlConfigApi } from 'routes/api';
import { ConfigurationData } from 'types/api';
import { useReader } from '../useReader';
import useData from '../useData';

export const useConfigApi = () => {
    const { currentCountry, currentUnit, currentUnitType } = useData();

    return useReader<ConfigurationData>(
        {
            url: buildUrlConfigApi(currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
            isSuspended: !currentCountry || !currentUnit || !currentUnitType
        }
    );
};
