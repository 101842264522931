import SSRIcon from '@ingka/ssr-icon';
import React from 'react';
import exclamationMark from '@ingka/ssr-icon/paths/notice-small';
import { Noop } from 'react-hook-form';
import classes from './DatePickerDisplay.module.scss';

const DatePickerDisplay = (
    {
        header,
        inputType,
        inputValue,
        handleChange,
        handleBlur,
        disabled,
        invalid,
        invalidMessage,
        minValue,
        maxValue,
    }: {
        header: string,
        inputType: 'week' | 'month' | 'date',
        inputValue: string | null,
        handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
        handleBlur: Noop | ((e: React.FocusEvent<HTMLInputElement>) => void),
        disabled?: boolean,
        invalid?: boolean,
        invalidMessage?: string,
        minValue?: string | null,
        maxValue?: string | null,
    }
) => (
    <div
        className={`${classes.datepicker} ${disabled ? classes.disabled : ''} ${invalid ? classes.invalid : ''}`}
        data-testid="datepicker-div"
    >
        <label htmlFor="datepicker" data-testid="datepicker-header">{header}</label>
        <input
            type={inputType}
            value={inputValue ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="datepicker-input"
            id="datepicker"
            disabled={disabled}
            min={minValue || ''}
            max={maxValue || ''}
            placeholder="yyyy-mm-dd"
        />
        {invalid && invalidMessage && (
            // TODO - FIX THIS!
            <span>
                <span className={classes['error-icon']}>
                    <SSRIcon paths={exclamationMark} />
                </span>
                <span data-testid="datepicker-error-message">{invalidMessage}</span>
            </span>
        )}
    </div>
);

DatePickerDisplay.defaultProps = {
    disabled: false,
    invalid: false,
    invalidMessage: '',
    minValue: null,
    maxValue: null,
};

export default DatePickerDisplay;
