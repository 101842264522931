import { TypeModulationHoursAction } from 'types/actionPlan';
import { useTranslation } from 'react-i18next';
import { getHoursInDecimal } from 'utils/number';
import { getShortCC } from 'utils/text';
import Text from '@ingka/text';
import classes from '../ActionTable.module.scss';

const ModulationAction = ({ action }:{ action: TypeModulationHoursAction }) => {
    const { t } = useTranslation();
    const delta = getHoursInDecimal(action.toHours, action.toMinutes) - getHoursInDecimal(action.fromHours, action.fromMinutes);
    const isNegative = delta < 0;

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-modulation-header"
                >
                    {t('MODULATION_HOURS')}
                </Text>
                <Text
                    data-testid="action-plan-modulation-change"
                >
                    <Text
                        tagName="span"
                        className={isNegative ? classes['details-changes__negative'] : classes['details-changes__positive']}
                    >
                        {isNegative ? `${delta} ${t('HOURS_ABBREVIATED')}` : `+${delta} ${t('HOURS_ABBREVIATED')}`}
                    </Text>
                    <b>
                        {`${' '}(CC${getShortCC(action.costCentre)})`}
                    </b>
                </Text>
            </div>
        </div>
    );
};

export default ModulationAction;
