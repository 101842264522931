import SearchBar from 'components/SearchBar';
import Switch from '@ingka/switch';
import TimeFilter from 'components/TimeFilter';
import ScenarioSelector from 'components/ScenarioSelector';
import AddCoworker from 'components/ManageCapacityTable/AddCoworker';
import AddScenario from 'components/AddScenario';
import ManageScenario from 'components/ManageScenario';
import useApp from 'hooks/useApp';
import { useTranslation } from 'react-i18next';
import useData from 'hooks/useData';
import { useLocation } from 'react-router-dom';
import { ANALYTICS_SHOW_COWORKER_DETAILS_MAP, ANALYTICS_SHOW_WEEKENDS_AND_EVENINGS_MAP } from 'types/analytics';
import { ROUTE_CAPACITY_ACTION_PLAN, ROUTE_RECRUITMENT_NEEDS } from 'types/routes';
import CopyScenario from 'components/CopyScenario';
import useAuth from 'hooks/useAuth';
import CreateMainScenario from 'components/ManageScenario/CreateMainScenario';
import { useState } from 'react';
import classes from './PageToolbar.module.scss';
import UploadTemplates from './UploadTemplatesButton';
import ExportPlanButton from './ExportPlanButton';

const PageToolbar = ({ showAllElements }: { showAllElements: boolean }) => {
    const { pathname } = useLocation();
    const [isCreateScenarioOpen, setIsCreateScenarioOpen] = useState(false);
    const { setSearchParam, showAllDetails, setShowAllDetails, showContributionDetails, setShowContributionDetails, gridData } = useApp();
    const { currentUnit } = useData();
    const { t } = useTranslation();
    const { access } = useAuth();
    const isRecruitmentNeeds = pathname.substring(1) === ROUTE_RECRUITMENT_NEEDS;
    const hascapacityFactorView = access?.features?.capacityFactorView ?? false;
    const hasCreateScenarioAccess = access?.api?.createScenario ?? false;
    const hasCopyScenarioAccess = access?.api?.copyScenario ?? false;
    const hasEditScenarioAccess = access?.api?.editScenario ?? false;

    const toggleShowDetails = () => {
        setShowAllDetails(current => !current);
    };
    const toggleShowContribution = () => {
        setShowContributionDetails(current => !current);
    };

    return (
        <>
            <div className={classes['filter-bar']}>
                <div className={classes['manage-scenario']}>
                    <span data-testid="scenario-selector">
                        <ScenarioSelector />
                    </span>

                    {hasEditScenarioAccess && !isRecruitmentNeeds && (
                        <span data-testid="add-scenario">
                            <CreateMainScenario
                                showTooltip
                                isModalOpen={isCreateScenarioOpen}
                                setIsModalOpen={setIsCreateScenarioOpen}
                            />
                        </span>
                    )}

                    {hasCreateScenarioAccess && !hasEditScenarioAccess && !isRecruitmentNeeds && <span data-testid="add-scenario"><AddScenario /></span>}

                    {hasCopyScenarioAccess && !isRecruitmentNeeds && (
                    <span data-testid="copy-scenario">
                        <CopyScenario />
                    </span>
                    )}
                    {!isRecruitmentNeeds && (
                    <span>
                        <ManageScenario />
                    </span>
                    )}
                </div>
                <div>
                    <div>
                        {hascapacityFactorView && !isRecruitmentNeeds && <UploadTemplates />}
                    </div>
                    <div>
                        {!!isRecruitmentNeeds && <ExportPlanButton />}
                    </div>
                    <TimeFilter disabled={pathname.substring(1) === ROUTE_CAPACITY_ACTION_PLAN} />
                </div>
            </div>
            {showAllElements && (/* TODO: Make this component generic , it should not contain elements specific to Manage Capacity */

            <div className={classes['filter-bar']} data-testid="filter-bar">
                <div data-testid="filter-bar-controls">
                    {hasEditScenarioAccess && (<AddCoworker />)}
                    <div className={classes['show-details']}>
                        <Switch
                            data-testid="show-coworker-details-button"
                            id="show-coworker-details-button"
                            label={t('SHOW_DETAILS')}
                            onChange={toggleShowDetails}
                            checked={showAllDetails}
                            subtle
                            value="woot value"
                            disabled={!gridData?.coworkers}
                            data-analytics={ANALYTICS_SHOW_COWORKER_DETAILS_MAP.get(pathname.substring(1))}
                        />
                    </div>
                    <div className={classes['show-details']}>
                        <Switch
                            data-testid="show-contribution-details-button"
                            id="show-contribution-details-button"
                            label={t('SHOW_WEEKENDS_EVENINGS')}
                            onChange={toggleShowContribution}
                            checked={showContributionDetails}
                            subtle
                            value="woot value"
                            disabled={!gridData?.coworkers}
                            data-analytics={ANALYTICS_SHOW_WEEKENDS_AND_EVENINGS_MAP.get(pathname.substring(1))}
                        />
                    </div>
                </div>
                <SearchBar
                    callback={setSearchParam}
                    disabled={!currentUnit}
                    pathName={pathname}
                />
            </div>
            )}
        </>
    );
};
export default PageToolbar;
