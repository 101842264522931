import { useEffect, useState } from 'react';

export const useEvent = () => {
    const [events, setEvents] = useState<{ eventName: string, listener:(event: CustomEvent) => void }[]>([]);
    const subscribe = async <T>(eventName: string, listener: (event: CustomEvent<T>) => void) => {
        setTimeout(() => document.addEventListener(eventName, listener as unknown as EventListener));

        // Add events that needs to be unsubscribed on unmount
        if (!events.some(event => event.eventName === eventName)) {
            setEvents([...events, { eventName, listener }]);
        }
    };

    const unsubscribeByEventListener = async <T>(eventName: string, listener: (event: CustomEvent<T>) => void) => {
        setTimeout(() => document.removeEventListener(eventName, listener as unknown as EventListener));
    };

    const unsubscribeByEventName = async (eventName: string) => {
        const event = events.find(e => e.eventName === eventName);

        if (event) {
            setTimeout(() => document.removeEventListener(event.eventName, event.listener as unknown as EventListener));
        }
    };

    const publish = async <T>(eventName: string, data?: T) => {
        const event = new CustomEvent(eventName, { detail: data });
        setTimeout(() => document.dispatchEvent(event));
    };

    // Unsubscribe local events on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => events.forEach(event => unsubscribeByEventListener(event.eventName, event.listener)), []);

    return { publish, subscribe, unsubscribeByEventListener, unsubscribeByEventName };
};
