import { PageContainer } from 'components';
import { ROUTE_RECRUITMENT_NEEDS } from 'types/routes';
import { RecruitmentNeeds } from 'views';

const RecruitmentNeedsRoute = () => (
    <PageContainer pageAnalyticsId={ROUTE_RECRUITMENT_NEEDS} isFullWidth align="horizontal">
        <RecruitmentNeeds />
    </PageContainer>
);

export default RecruitmentNeedsRoute;
