import { DateHelper } from 'utils/date';
import { ReactElement } from 'react';
import { RangeStatus } from 'types/enums';
import { GreyedOutCell } from './GreyedOutCell';

export const getGreyedOutCell = (
    contractStartDate: string,
    contractEndDate: string,
    timeArray: Array<string>,
    currentIndex: number,
    key: string,
): // When result is -1 do nothing
ReactElement | RangeStatus => {
    const startDateIndex = DateHelper.getDateDifferenceInWeeks(contractStartDate, timeArray[0]);
    const contractStartDateIndexRange = startDateIndex > 0
        // Note: week at endIndex is not greyed out, so no -1 on length.
        // Using Math.min to ensure the greyed out cell has right length for future contracts.
        ? { startIndex: 0, endIndex: Math.min(startDateIndex, timeArray.length) }
        : { startIndex: -1, endIndex: -1 }; // Contract start date is before the dates in the timeArray

    const endDateIndex = DateHelper.getDateDifferenceInWeeks(contractEndDate, timeArray[0]);
    const contractEndDateIndexRange = endDateIndex < timeArray.length - 1 // (-1) because the last week should not be greyed out
        ? {
            // (+1) = Contract end date starts with the next cell
            // Math.max(0, ...) = If the contract end date is before the timeframe, the start index should be 0
            startIndex: Math.max(0, endDateIndex + 1),
            endIndex: timeArray.length, // Last greyed out cell is the last cell in the timeframe
        }
        : { startIndex: -1, endIndex: -1 }; // Contract end date is outside of timeframe

    // If we have no contract start date/end date in the timeframe, do nothing
    if (contractStartDateIndexRange.startIndex === -1 && contractEndDateIndexRange.startIndex === -1) {
        return RangeStatus.OUTSIDE_RANGE;
    }

    if (currentIndex === contractStartDateIndexRange.startIndex) {
        return (
            <GreyedOutCell
                position="start"
                colSpan={contractStartDateIndexRange.endIndex - contractStartDateIndexRange.startIndex}
                text="CONTRACT_START"
                key={key}
            />
        );
    }

    // Check if the current index is overlaping contract start date cell collspan
    if (currentIndex > contractStartDateIndexRange.startIndex && currentIndex < contractStartDateIndexRange.endIndex) {
        return RangeStatus.WITHIN_RANGE;
    }

    if (currentIndex === contractEndDateIndexRange.startIndex) {
        return (
            <GreyedOutCell
                position="end"
                colSpan={contractEndDateIndexRange.endIndex - contractEndDateIndexRange.startIndex}
                text="CONTRACT_END"
                key={key}
            />
        );
    }

    // Check if the current index is overlaping contract end date cell collspan
    if (currentIndex > contractEndDateIndexRange.startIndex && currentIndex < contractEndDateIndexRange.endIndex) {
        return RangeStatus.WITHIN_RANGE;
    }

    // Do nothing
    return RangeStatus.OUTSIDE_RANGE;
};
