import { AuthClient, createMsalConfig, getUserImage } from '@ingka-group-digital/people-auth';
import { AnalyticsData } from 'hooks/useGapAnalytics';
import * as Routes from 'routes/api';
import { URL_AUTHORIZATION_API } from 'routes/api';
import { ConfigurationData, HTTPMethod } from 'types/api';
import { AuthAPI } from 'types/authContext';
import { AuthorizeBU,
    ContractMixData,
    ContractMixInput,
    Contribution,
    CountryOMData,
    CustomContributions,
    FileUploadResponse,
    InputBudget,
    InputRates,
    PAData,
    WorkloadData } from 'types/dataContext';
import { BusinessUnitsList, EnableNewUnit, EnableOrDisableUnits } from 'types/configurationPage';
import { Scenario, ScenarioList } from 'types/scenario';
import { AZURE_APP_ID, REDIRECT_URI } from 'utils/env';
import { generateHttpErrorMessage } from 'utils/log';
import { RecriutmentNeedsData } from 'types/recruitmentNeeds';

const config = createMsalConfig(AZURE_APP_ID, REDIRECT_URI);
const tokenScopes = ['User.Read', 'openid', 'profile', 'Group.Read.All'];
const authClient = AuthClient(config, tokenScopes);
const abortControllers = new Map<string, AbortController>();
const digestToHexString = (buffer: ArrayBuffer): string => {
    const digestArray = Array.from(new Uint8Array(buffer));

    return digestArray.map(b => b.toString(16).padStart(2, '0')).join('');
};
type RequestParams = {
    url: string,
    method?: HTTPMethod,
    optionalHeaders?: HeadersInit,
    mode?: RequestMode,
    cache?: RequestCache,
    body?: RequestInit['body'],
};

const callAPI = async ({
    url,
    method,
    optionalHeaders,
    mode,
    cache,
    body,
}: RequestParams): Promise<Response> => {
    const arrayBuffer = await crypto.subtle.digest('SHA-1', new TextEncoder().encode(url + body));
    const signalKey = digestToHexString(arrayBuffer);
    const appAccessToken = await authClient.getAppAccessToken();
    let abortController = null;

    if (abortControllers.has(signalKey)) {
        await abortControllers.get(signalKey)?.abort();
        abortControllers.delete(signalKey);
    }
    abortController = new AbortController();
    abortControllers.set(signalKey, abortController);

    return fetch(url, {
        method: method ?? HTTPMethod.GET,
        headers: {
            Authorization: `Bearer ${appAccessToken}`,
            ...optionalHeaders
        },
        mode: mode ?? 'cors',
        cache: cache ?? 'no-cache',
        body: body ?? undefined,
        signal: abortController ? abortController.signal : undefined,
    })
        .then(response => {
            if (!response.ok) {
                generateHttpErrorMessage(response);
            }

            return response;
        })
        .finally(() => {
            abortControllers.delete(signalKey);
        });
};
const getJSON = async<T> ({
    url,
    method,
    optionalHeaders,
    mode,
    cache,
    body,
}: RequestParams): Promise<T> => callAPI({
    url,
    method,
    optionalHeaders,
    mode,
    cache,
    body,
})
    .then(async response => {
        if (response.ok) {
            return response.json() as Promise<T>;
        }
        const err = await response.json();

        return Promise.reject(err);
    })
    .catch(err => {
        if (err.name !== 'AbortError') {
            generateHttpErrorMessage(err);
        }

        return Promise.reject(err);
    });

const getBlob = async ({
    url,
    method,
    optionalHeaders,
    mode,
    cache,
    body,
}: RequestParams): Promise<Blob> => callAPI({
    url,
    method,
    optionalHeaders,
    mode,
    cache,
    body,
})
    .then(async response => {
        if (response.ok) {
            return response.blob();
        }

        const err = await response.json();

        return Promise.reject(err);
    })
    .catch(err => {
        if (err.name !== 'AbortError') {
            generateHttpErrorMessage(err);
        }

        return Promise.reject(err);
    });

const API = () => ({
    getAuthorization: async (): Promise<AuthAPI> => getJSON({ url: URL_AUTHORIZATION_API }),
    getOMData: async (countryCode: string): Promise<CountryOMData> => {
        if (!countryCode) {
            return Promise.reject(new Error('Bad input'));
        }

        return getJSON({ url: Routes.buildUrlOmApi(countryCode) });
    },
    getPAData: async (countryCode: string | undefined, businessUnitCode: string | undefined, buType: string | undefined): Promise<PAData> => {
        if (countryCode && businessUnitCode) {
            return getJSON({ url: Routes.buildUrlPaApi(countryCode, businessUnitCode, buType) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getScenarios: async (countryCode: string | undefined, businessUnitCode: string | undefined, buType: string | undefined): Promise<ScenarioList> => {
        if (businessUnitCode && countryCode && buType) {
            return getJSON({ url: Routes.buildUrlGetScenariosApi(countryCode, businessUnitCode, buType) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getScenario: async (
        scenarioId: string,
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        buType: string | undefined
    ): Promise<Scenario> => {
        if (scenarioId && countryCode && businessUnitCode && buType) {
            return getJSON({ url: Routes.buildUrlGetScenarioApi(scenarioId, countryCode, businessUnitCode, buType) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getRates: async (
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
        startDate: string,
        endDate: string
    ): Promise<{ data: InputRates }> => {
        if (countryCode && businessUnitCode && businessUnitType && startDate && endDate) {
            return getJSON({ url: Routes.buildUrlRatesApi(countryCode, businessUnitCode, businessUnitType, startDate, endDate) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getBudget: async (
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
        startDate: string,
        endDate: string,
        scenarioId?: string
    ): Promise<InputBudget> => {
        if (countryCode && businessUnitCode && businessUnitType && startDate && endDate) {
            if (scenarioId) {
                return getJSON({ url: Routes.buildUrlScenarioBudgetApi(countryCode, businessUnitCode, businessUnitType, startDate, endDate, scenarioId) });
            }

            return getJSON({ url: Routes.buildUrlBudgetApi(countryCode, businessUnitCode, businessUnitType, startDate, endDate) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    uploadFile: async (
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
        category: string,
        formData: any,
        scenarioId?: string
    )
    : Promise<FileUploadResponse> => {
        if (countryCode && businessUnitCode && businessUnitType && category && formData) {
            if (scenarioId) {
                return getJSON({
                    url: Routes.buildUrlScenarioUploadFileApi(countryCode, businessUnitCode, businessUnitType, category, scenarioId),
                    method: HTTPMethod.POST,
                    body: formData
                });
            }

            return getJSON({
                url: Routes.buildUrlUploadFileApi(countryCode, businessUnitCode, businessUnitType, category),
                method: HTTPMethod.POST,
                body: formData
            });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getDownloadFile: async (
        category: string,
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
        scenarioId?: string
    ) => {
        if (category && countryCode && businessUnitCode && businessUnitType) {
            if (scenarioId) {
                return getBlob({ url: Routes.buildUrlScenarioDownloadFileApi(category, countryCode, businessUnitCode, businessUnitType, scenarioId) });
            }

            return getBlob({ url: Routes.buildUrlDownloadFileApi(category, countryCode, businessUnitCode, businessUnitType) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    isFileAvailable: async (
        category: string,
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
        scenarioId?: string
    )
    : Promise<Response> => {
        if (category && countryCode && businessUnitCode && businessUnitType) {
            if (scenarioId) {
                return callAPI({
                    url: Routes.buildUrlScenarioFileAvailableApi(category, countryCode, businessUnitCode, businessUnitType, scenarioId),
                    method: HTTPMethod.HEAD,
                });
            }

            return callAPI({
                url: Routes.buildUrlFileAvailableApi(category, countryCode, businessUnitCode, businessUnitType),
                method: HTTPMethod.HEAD,
            });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getContractMix: async (
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
        contractMixInput: ContractMixInput
    )
    : Promise<ContractMixData> => {
        if (countryCode && businessUnitCode && businessUnitType && contractMixInput) {
            return getJSON({
                url: Routes.buildUrlContractMixApi(countryCode, businessUnitCode, businessUnitType),
                method: HTTPMethod.POST,
                optionalHeaders: {
                    'content-type': 'application/json',
                    charset: 'utf8',
                },
                body: JSON.stringify(contractMixInput)
            });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getDefaultContribution: async (
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined
    ): Promise<any> => {
        if (countryCode && businessUnitCode && businessUnitType) {
            return getJSON({ url: Routes.buildUrlDefaultContributionApi(countryCode, businessUnitCode, businessUnitType) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getWorkload: async (
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
        startDate: string | undefined,
        endDate: string | undefined
    )
    : Promise<{ data: { workload: WorkloadData } }> => {
        if (countryCode && businessUnitCode && businessUnitType && startDate && endDate) {
            return getJSON({ url: Routes.buildUrlWorkloadApi(countryCode, businessUnitCode, businessUnitType, startDate, endDate) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    updateCustomContribution: async (
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
        contribution: Contribution
    ):Promise<Response> => {
        if (countryCode && businessUnitCode && businessUnitType && contribution) {
            return getJSON({
                url: Routes.buildUrlCustomContributionApi(countryCode, businessUnitCode, businessUnitType),
                method: HTTPMethod.PUT,
                optionalHeaders: {
                    'content-type': 'application/json',
                    charset: 'utf8',
                },
                body: JSON.stringify(contribution)
            });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getCustomContributions: async (
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined
    ): Promise<CustomContributions> => {
        if (countryCode && businessUnitCode && businessUnitType) {
            return getJSON({ url: Routes.buildUrlGetCustomContributionApi(countryCode, businessUnitCode, businessUnitType) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getMultipleBU: async (employeeEmailID: string | undefined): Promise<AuthorizeBU> => {
        if (employeeEmailID) {
            return getJSON({ url: Routes.buildUrlMultipleBuApi(employeeEmailID) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getUserProfilePic: async (employeeEmailID: string): Promise<string | null> => {
        const msftAccessToken = await authClient.getMsftAccessToken();

        return getUserImage(employeeEmailID, msftAccessToken);
    },
    getConfig: async (currentCountry: string = '', currentUnit: string = '', currentUnitType: string = ''): Promise<ConfigurationData> => {
        if (currentCountry && currentUnit && currentUnitType) {
            const apiURL = Routes.buildUrlConfigApi(currentCountry, currentUnit, currentUnitType);

            return getJSON({ url: apiURL });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getUpdatedFileData: async (
        currentCountry: string,
        currentUnit: string,
        currentUnitType: string,
        category: string,
        startDate: string,
        endDate: string,
        scenarioID?: string
    ): Promise<any> => {
        if (currentCountry && currentUnit && currentUnitType) {
            const apiURL = Routes.buildUrlUpdatedFileData(currentCountry, currentUnit, currentUnitType, category, startDate, endDate, scenarioID);

            return getJSON({ url: apiURL });
        }

        return Promise.reject(new Error('Bad input'));
    },
    enableNewUnit: async (
        countryCode: string | undefined,
        businessUnitCode: number | undefined,
        businessUnitType: string | undefined,
        businessUnitName: string | undefined
    ): Promise<EnableNewUnit> => {
        if (countryCode && businessUnitCode && businessUnitType) {
            return getJSON({
                url: Routes.buildUrlEnableNewUnitApi(countryCode),
                method: HTTPMethod.POST,
                optionalHeaders: {
                    'content-type': 'application/json',
                    charset: 'utf8',
                },
                // BusinessUnit Name is optional param for the API call to Backend.
                body: JSON.stringify({ buCode: businessUnitCode, buType: businessUnitType, buName: businessUnitName ?? '' })
            });
        }

        return Promise.reject(new Error('Bad input'));
    },
    postGapAnalytics: async (countryCode: string, buCode: string, buType: string, payload: AnalyticsData) => callAPI({
        url: Routes.buildUrlGapAnalytics(countryCode, buCode, buType),
        method: HTTPMethod.POST,
        body: JSON.stringify(payload),
        optionalHeaders: { 'Content-Type': 'application/json' }
    }),
    getUnitsList: async (countryCode: string | undefined): Promise<BusinessUnitsList[]> => {
        if (!countryCode) {
            return Promise.reject(new Error('Bad input'));
        }

        return getJSON({ url: Routes.buildUrlGetConfigUnitsList(countryCode) });
    },
    updateUnitsList: async (countryCode: string | undefined, unitsList: BusinessUnitsList[] | undefined): Promise<EnableOrDisableUnits> => {
        if (countryCode && unitsList) {
            return getJSON({
                url: Routes.buildUrlUpdateConfigUnitsList(countryCode),
                method: HTTPMethod.PUT,
                optionalHeaders: {
                    'content-type': 'application/json',
                    charset: 'utf8',
                },
                body: JSON.stringify(unitsList)
            });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getDownloadDefaultFile: async (
        category: string,
        countryCode: string | undefined,
        businessUnitCode: string | undefined,
        businessUnitType: string | undefined,
    ) => {
        if (category && countryCode && businessUnitCode && businessUnitType) {
            return getBlob({ url: Routes.buildUrlDownloadDefaultFileApi(category, countryCode, businessUnitCode, businessUnitType) });
        }

        return Promise.reject(new Error('Bad input'));
    },
    getRecruitmentNeeds: async (
        countryCode: string | undefined,
        buCode: string | undefined,
        buType: string | undefined,
        scenarioId: string,
        startDate: string,
        endDate: string
    ): Promise<RecriutmentNeedsData> => {
        if (countryCode && buCode && buType && scenarioId && startDate && endDate) {
            return getJSON({ url: Routes.buildUrlGetRecruitmentNeedsApi(countryCode, buCode, buType, scenarioId, startDate, endDate) });
        }

        return Promise.reject(new Error('Bad input'));
    }
});
export default API;
