import { useEffect, useMemo, useState } from 'react';
import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import plus from '@ingka/ssr-icon/paths/plus';
import useData from 'hooks/useData';
import Tooltip from '@ingka/tooltip';
import { CREATE_SCENARIO_BUTTON__CLICK } from 'types/analytics';
import Modal, { Sheets, ModalBody, ModalFooter, ModalHeader } from '@ingka/modal';
import { Controller, useForm } from 'react-hook-form';
import { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TFunction } from 'i18next';
import { ScenarioList } from 'types/scenario';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import { useCreateScenarioAPI } from 'hooks/api/useCreateScenarioAPI';
import { useToast } from 'hooks/useToast';
import { useListener } from 'hooks/useListener';
import Checkbox from '@ingka/checkbox';
import { CheckBoxLabel } from 'components/OMFilters/CheckBoxLabel';
import { useDeleteScenarioAPI } from 'hooks/api/useDeleteScenarioAPI';
import { MAIN_SCENARIO_SYMBOL, OTHER_SCENARIO_SYMBOL } from 'utils/constants';
import useAuth from 'hooks/useAuth';
import Text from '@ingka/text';
import classes from '../AddScenario/AddScenario.module.scss';

const getValidationSchema = (
    t: TFunction<'translation', undefined>,
    scenarioList: ScenarioList | undefined,
) => zod.object({
    scenarioName: zod.string()
        .min(1, { message: t('SCENARIO_NAME_MISSING') })
        .refine(
            value => (value.trim().length > 0),
            { message: t('SCENARIO_NAME_MISSING') }
        )
        .refine(
            value => !scenarioList?.scenarios?.map(scenario => scenario.name.toLowerCase()).includes(value.toLowerCase()),
            { message: t('SCENARIO_NAME_EXISTS_SELECT_NEW') }
        )
        .refine(
            value => !value.includes(MAIN_SCENARIO_SYMBOL),
            { message: t('SCENARIO_NAME_FORBIDDEN_CHARACTER') }
        )
        .refine(
            value => !value.includes(OTHER_SCENARIO_SYMBOL),
            { message: t('SCENARIO_NAME_FORBIDDEN_CHARACTER') }
        )
});

const CreateMainScenario = ({
    isModalOpen,
    setIsModalOpen,
    checkedBox,
    removeScenarioID,
    disableCheckBox,
    showTooltip
}: {
    isModalOpen: boolean,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    checkedBox?: boolean,
    disableCheckBox?: boolean,
    showTooltip?:boolean,
    removeScenarioID?:string,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { currentCountry, currentUnit, scenarioList, setScenarioBudget, getScenarios } = useData();

    const [isChecked, setIsChecked] = useState<boolean>((checkedBox ?? false));
    const isDisabled = !(currentCountry && currentUnit);
    const handleClick = () => { setIsModalOpen(true); };
    const validationSchema = useMemo(
        () => getValidationSchema(t, scenarioList),
        [t, scenarioList]
    );
    const { writeData: createScenario } = useCreateScenarioAPI();
    const { deleteScenario } = useDeleteScenarioAPI(removeScenarioID ?? '');
    const { displayToast } = useToast();
    const { access } = useAuth();

    const {
        handleSubmit,
        formState: { errors, isSubmitting, isValidating, isValid, isDirty },
        control,
        reset,
        getValues,
        trigger,
    } = useForm<zod.infer<typeof validationSchema>>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: { scenarioName: '' },
        resolver: zodResolver(validationSchema),
    });

    const handleClose = () => {
        reset();
        setIsModalOpen(false);
        setIsChecked(false);
    };

    useEffect(() => {
        if (scenarioList && scenarioList.scenarios.length === 0) {
            setIsChecked(true);
        }
    }, [scenarioList]);

    const onSubmit = (formState: zod.infer<typeof validationSchema>) => {
        if (errors.scenarioName) {
            trigger();

            return;
        }

        const isMainScenario = isChecked || scenarioList?.scenarios.length === 0;
        if (access?.api?.createScenario) {
            setIsLoading(true);
            createScenario({ name: formState.scenarioName, isMain: isMainScenario }).then(response => {
                if (response.error) {
                    if (isMainScenario) {
                        displayToast({ title: t('ERROR'), message: t('CREATING_MAIN_SCENARIO_FAILED') });
                    } else {
                        displayToast({ title: t('ERROR'), message: t('SCENARIO_SAVE_FAILED') });
                    }

                    return;
                }

                if (isMainScenario) {
                    displayToast({
                        title: t(''),
                        message: t('MAIN_SCENARIO_CREATION_SUCCESSFUL', { scenarioName: formState.scenarioName, unitCode: currentUnit })
                    });
                } else {
                    displayToast({ title: t('SUCCESS'), message: t('SCENARIO_SAVE_SUCCEEDED') });
                }

                if (removeScenarioID) {
                    deleteScenario().then(deleteResponse => {
                        if (deleteResponse.error) {
                            displayToast({ title: t('ERROR'), message: t('REMOVING_MAIN_SCENARIO_FAILED') });
                        }
                        getScenarios();
                    });
                } else {
                    getScenarios();
                }
            }).finally(() => {
                setIsLoading(false);
                setScenarioBudget(false);
                handleClose();
            });
        }
    };

    useListener('keydown', 'Enter', () => {
        if (isValid && isDirty) {
            const formState = getValues();
            handleSubmit(() => onSubmit(formState));
        }
    });

    const createMainCheckBoxProperties = () => ({
        id: 'Main-Scenario-Check-ID',
        value: `${isChecked || scenarioList?.scenarios.length === 0}`,
        checked: isChecked || scenarioList?.scenarios.length === 0,
        disabled: disableCheckBox || scenarioList?.scenarios.length === 0,
        label: (<CheckBoxLabel label={t('ADD_SCENARIO_MAIN_CAUTION_MSG')} subLabel="" />),
        onChange: () => {
            setIsChecked(!isChecked);
        }
    });

    return (
        <span data-testid="add-main-scenario-button-span">
            { showTooltip && (
            <Tooltip tooltipText={isDisabled ? t('SELECT_A_UNIT_FIRST') : t('NEW_SCENARIO')}>
                <Button
                    data-testid="add-scenario-button"
                    type="primary"
                    ssrIcon={plus}
                    onClick={handleClick}
                    size="small"
                    iconOnly
                    disabled={isDisabled}
                    data-analytics={CREATE_SCENARIO_BUTTON__CLICK}
                />
            </Tooltip>
            )}
            <Modal
                visible={isModalOpen}
                handleCloseBtn={handleClose}
                escapable
                onModalClosed={() => reset()}
                focusLockProps={{ locked: false }}
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={<ModalHeader title={t('CREATE_NEW_SCENARIO')} backBtnClick={handleClose} className={classes['add-scenario__header']} />}
                    footer={(
                        <ModalFooter>
                            <Button
                                onClick={handleClose}
                                text={t('CANCEL')}
                                type="secondary"
                                size="small"
                                data-testid="cancel-add-main-scenario-button"
                            />
                            <Button
                                onClick={() => { onSubmit(getValues()); }}
                                text={removeScenarioID ? t('SAVE_AND_REMOVE_TITLE') : t('SAVE')}
                                type="primary"
                                size="small"
                                loading={isValidating || isSubmitting || isLoading}
                                data-testid="submit-add-main-scenario-button"
                            />
                        </ModalFooter>
                    )}
                >

                    <form onSubmit={handleSubmit(onSubmit)} data-testid="add-scenario-form">
                        <ModalBody>
                            <Text data-testid="add-scenario-header-info">
                                {t('SCENARIO_CREATED_FOR', { unitCode: currentUnit })}
                            </Text>
                            <Controller
                                control={control}
                                name="scenarioName"
                                render={({
                                    field: { onChange, onBlur, value }
                                }) => (
                                    <FormField
                                        shouldValidate={isDirty}
                                        valid={!errors.scenarioName}
                                        validation={{
                                            msg: errors.scenarioName?.message,
                                        }}
                                    >
                                        <InputField
                                            label={t('ENTER_SCENARIO_NAME')}
                                            id="add-scenario-name"
                                            type="text"
                                            value={value}
                                            className={classes['add-scenario__input-scenario-name']}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            data-testid="add-scenario-form-input"
                                            autoFocus
                                        />
                                    </FormField>
                                )}
                            />
                            <br />
                            <Checkbox
                                {...createMainCheckBoxProperties()}
                            />
                        </ModalBody>
                    </form>
                </Sheets>
            </Modal>
        </span>
    );
};

export default CreateMainScenario;
