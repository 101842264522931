import React, { useState } from 'react';
import Text from '@ingka/text';
import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import ModalService from 'services/modal';
import ReactDOM from 'react-dom';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import Tooltip from '@ingka/tooltip';
import SSRIcon from '@ingka/ssr-icon';
import classes from './EnableNewUnit.module.scss';
import EnableNewUnit from './EnableNewUnit';

const EnableNewUnitButton = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const enableNewUnitModal = ModalService().open(
        'prompt',
        isModalOpen,
        setIsModalOpen,
        <EnableNewUnit handleIsOpen={setIsModalOpen} />
    );

    const portalDiv = document.getElementById('portal');

    return (
        <div id="config-enable-unit-div">
            <div id="config-enable-unit-toggle-div" className={classes['enable-bu__header']}>
                <Text tagName="h5" headingSize="xs" id="config-enable-unit-title" data-testid="config-enable-unit-title">
                    {t('ONBOARD_NEW_UNIT')}
                </Text>
                <Tooltip tooltipText={t('ONBOARD_NEW_UNIT_TOOLTIP')} position="trailing" className={classes['enable-bu__tooltip']}>
                    <SSRIcon id="config-enable-unit-icon" data-testid="config-enable-unit-icon" paths={informationCircle} />
                </Tooltip>
            </div>

            <Button
                id="config-enable-unit-button"
                data-testid="config-enable-unit-button"
                type="secondary"
                onClick={() => setIsModalOpen(true)}
                size="small"
                text={`${t('ONBOARD_NEW_UNIT')} +`}
            />
            {portalDiv && isModalOpen && ReactDOM.createPortal(enableNewUnitModal, portalDiv)}
        </div>
    );
};

export default EnableNewUnitButton;
