import { OpenEndedRange } from 'types/api';
import { CoworkerGridRow, CoworkerGridRowContract, RowType } from 'types/appContext';
import { ContractRange, CostDistribution } from 'types/coworker';
import { CountryOMData } from 'types/dataContext';
import { GapHoursData, GapHoursDerivedData, HoverData, ContributionsData, ContributionsDerivedData } from 'types/table';
import { getShortCC } from 'utils/text';

/**
 * Gets all cost centres from all departments in unit, and filters out duplicates
 * @returns Array of cost centres. If no data is provided, returns an empty array
 */
export const getAllCostCentresInUnit = (unfilteredCountryOMData: CountryOMData | undefined, currentUnit: string) => unfilteredCountryOMData?.departments
    .filter(dept => dept.businessUnitCode === currentUnit)
    .map(dept => dept.costCentre)
    .filter((cc, i, self) => i === self.findIndex(selfCc => selfCc === cc))
    ?? [];

export const getCostCentrePercentFromDistribution = (costCentreId: string, costDistributions: CostDistribution[]) => (
    costDistributions.find(cd => getShortCC(cd.costCentre) === getShortCC(costCentreId))?.costCentrePercent || 0);

/**
 * Gets the capacity hours from the gap hours data
 * @param gapData
 * @returns
 */
export const getCapacityHours = (
    gapData: GapHoursData
) => gapData.coworkerHours - gapData.vacationHours - gapData.sicknessHours - gapData.otherAbsenceHours;

/**
 * gets the gap hours from the gap hours data
 * @param gapData
 * @returns
 */
export const getGapHours = (
    gapData: GapHoursData
) => gapData.coworkerHours - gapData.vacationHours - gapData.sicknessHours - gapData.otherAbsenceHours - gapData.targetCapacity;

/**
 * get the vacation percentage based on the vacation hours and coworker hours (*100)
 * @param gapData
 * @returns
 */
export const getVacationPercentage = (
    gapData: GapHoursData
) => (gapData.vacationHours / gapData.coworkerHours) * 100;

/**
 * get the sickness percentage based on the sickness hours and coworker hours (*100)
 * @param gapData
 * @returns
 */
export const getSicknessPercentage = (
    gapData: GapHoursData
) => (gapData.sicknessHours / gapData.coworkerHours) * 100;

/**
 * get the other absence percentage based on the other absence hours and coworker hours (*100)
 * @param gapData
 * @returns
 */
export const getOtherAbsencePercentage = (
    gapData: GapHoursData
) => (gapData.otherAbsenceHours / gapData.coworkerHours) * 100;

/**
 * Returns true if the capacity is under the target capacity
 * @param gapData
 * @returns
 */
export const isCapacityUnderTarget = (
    gapData: GapHoursData
) => getGapHours(gapData) < 0;

/**
 * Gets all of the derived states from the gap hours data
 * @param gapData
 * @returns
 */
export const generateDerivedStatesHours = (
    gapData: GapHoursData
): GapHoursDerivedData => ({
    capacityHours: getCapacityHours(gapData),
    gap: getGapHours(gapData),
    vacationPercentage: getVacationPercentage(gapData),
    sicknessPercentage: getSicknessPercentage(gapData),
    otherAbsencePercentage: getOtherAbsencePercentage(gapData),
    isDangerColor: isCapacityUnderTarget(gapData),
});

/**
 * extracts the budget hours array from a hover data array
 * @param hoverData
 * @returns
 */
export const getAllBudgetHours = (hoverData: HoverData[]) => hoverData.map(data => data.gap.targetCapacity);

/**
 * gets the gap vlues for the contributions
 * @param contributionData
 * @returns
 */
export const getGapContributions = (
    contributionData: ContributionsData
) => ({
    total: contributionData.contributions.total - contributionData.workload.total,
    evenings: contributionData.contributions.evenings - contributionData.workload.evenings,
    saturday: contributionData.contributions.saturday - contributionData.workload.saturday,
    sunday: contributionData.contributions.sunday - contributionData.workload.sunday
});

/**
 * returns true if any (evenings, saturdays or sundays) contribution does not meet the workload
 * @param contributionData
 * @returns
 */
export const isContributionsUnderTarget = (
    contributionData: ContributionsData
) => contributionData.workload.evenings > contributionData.contributions.evenings
    || contributionData.workload.saturday > contributionData.contributions.saturday
    || contributionData.workload.sunday > contributionData.contributions.sunday;

/**
 * Gets all of the derived states from the contributions data
 * @param contributionData
 * @returns
 */
export const generateDerivedStatesContributions = (
    contributionData: ContributionsData
): ContributionsDerivedData => ({
    gap: getGapContributions(contributionData),
    isDangerColor: isContributionsUnderTarget(contributionData)
});

/**
 * Checks if a CoworkerGridRow is of type CoworkerGridRowContract - Including type predicate for array filter/find type narrowing
 */
export const rowIsContractHours = (row: CoworkerGridRow): row is CoworkerGridRowContract => row.rowType === RowType.CONTRACT_HOURS;

/**
 * Checks if a range is of type ContractRange - Including type predicate for array filter/find type narrowing
 */
export const configRangeIsContract = (range: ContractRange | OpenEndedRange): range is ContractRange => 'type' in range;

/**
 * Checks if a range is of type OpenEndedRange - Including type predicate for array filter/find type narrowing
 */
export const configRangeIsOpenEnded = (range: ContractRange | OpenEndedRange): range is OpenEndedRange => !('type' in range);
