import { Coworker, CoworkerAvailability } from 'types/coworker';
import { CCBudgetEntity,
    CostCentreWeekRateFlat,
    CustomContributions,
    DefaultContributionData,
    DefaultContributions,
    InputBudget,
    InputRates,
    WorkloadData } from 'types/dataContext';
import { ContractDelta, DummyCoworker, WeeklyDelta } from 'types/scenario';
import { WeeklyContribution } from 'types/table';
import { BEGINNING_OF_TIME, DATE_FORMAT_STANDARD, DateHelper, INDEFINITE_END_DATE } from 'utils/date';
import { roundValue } from 'utils/number';
import { costCentreIsInList, costCentresAreSame } from 'utils/text';
import { zeroHoursContractRange } from 'utils/constants';
import { BaseContractType } from 'types/appContext';
import { sortBy } from 'utils/sorting';
import { isCoworkersId } from 'utils/rules';

export const getRatesForCostCentreAndWeek = (
    inputRates: InputRates | undefined,
    costCentreId: string,
    timeSelectionWeek: string
): CostCentreWeekRateFlat => {
    const costCentreRates = inputRates?.rates?.costCentres?.find(({ id }) => costCentreId.includes(id));
    if (!costCentreRates) {
        return {
            vacationRate: 0,
            otherAbsenceRate: 0,
            turnoverRate: 0,
            sicknessRate: 0,
        };
    }
    const timeSelectionStartDate = DateHelper.getISOWeekStartDate(timeSelectionWeek).format(
        DATE_FORMAT_STANDARD,
    );

    return {
        vacationRate: costCentreRates.vacationRate.find(rateEntity => rateEntity.startDate === timeSelectionStartDate)?.percentage ?? 0,
        otherAbsenceRate: costCentreRates.otherAbsenceRate.find(rateEntity => rateEntity.startDate === timeSelectionStartDate)?.percentage ?? 0,
        turnoverRate: costCentreRates.turnoverRate.find(rateEntity => rateEntity.startDate === timeSelectionStartDate)?.percentage ?? 0,
        sicknessRate: costCentreRates.sicknessRate.find(rateEntity => rateEntity.startDate === timeSelectionStartDate)?.percentage ?? 0,
    };
};

export const getCurrentCostCentreRates = (currUnitRates: InputRates | undefined, selectedCostCentres: string[] = []) => {
    const currentCostCenterRates = currUnitRates?.rates?.costCentres
        ?.filter(({ id }) => selectedCostCentres.includes(`${id}`))
        .map(rate => ({
            vacationRate: rate.vacationRate,
            otherAbsenceRate: rate.otherAbsenceRate,
            turnoverRate: rate.turnoverRate,
            sicknessRate: rate.sicknessRate,
        }));

    return {
        vacationRates:
            currentCostCenterRates
                ?.map(rate => ({ vacationRate: rate.vacationRate }))
                .flatMap(({ vacationRate }) => vacationRate) || [],
        turnoverRates:
            currentCostCenterRates
                ?.map(rate => ({ turnoverRate: rate.turnoverRate }))
                .flatMap(({ turnoverRate }) => turnoverRate) || [],
        otherAbsenceRates:
            currentCostCenterRates
                ?.map(rate => ({ otherAbsenceRate: rate.otherAbsenceRate }))
                .flatMap(({ otherAbsenceRate }) => otherAbsenceRate) || [],
        sicknessRates:
            currentCostCenterRates
                ?.map(rate => ({ sicknessRate: rate.sicknessRate }))
                .flatMap(({ sicknessRate }) => sicknessRate) || [],
    };
};

export const getCurrentCostCentreBudgets = (currUnitBudget: InputBudget | undefined, selectedCostCentres: string[] = []) => currUnitBudget?.budget?.costCentres
    ?.filter(({ id }) => costCentreIsInList(id, selectedCostCentres))
    ?.flatMap(({ id, data }) => data.map(element => ({ costCentre: id, ...element })));

export const getTargetCapacity = (timeSelectionArray: Array<string>, costCentreBudgets: CCBudgetEntity[] | undefined) => timeSelectionArray.map(eachWeek => {
    const weekStartDate = DateHelper.getISOWeekStartDate(eachWeek).format(DATE_FORMAT_STANDARD);

    const targetCap = costCentreBudgets
        ?.filter(({ startDate }) => startDate === weekStartDate)
        .reduce((accHours, budget) => accHours + budget.hours, 0) ?? 0;

    return roundValue(targetCap);
});

export const getWorkload = (workload: WorkloadData, startDate: string): WeeklyContribution => {
    const allEvenings = workload.costCentres
        .map(cc => cc.evening)
        .flatMap(cc => cc)
        .filter(cc => cc.startDate === startDate);
    const saturdays = workload.costCentres
        .map(cc => cc.saturday)
        .flatMap(cc => cc)
        .filter(cc => cc.startDate === startDate);
    const sundays = workload.costCentres
        .map(cc => cc.sunday)
        .flatMap(cc => cc)
        .filter(cc => cc.startDate === startDate);

    const evenings: number = allEvenings.map(entry => entry.headcount).reduce((sum, term) => sum + term, 0);
    const saturday: number = saturdays.map(entry => entry.headcount).reduce((sum, term) => sum + term, 0);
    const sunday: number = sundays.map(entry => entry.headcount).reduce((sum, term) => sum + term, 0);

    return {
        evenings,
        saturday,
        sunday,
        total: evenings + saturday + sunday,
    };
};

/**
 * generates object representing no contributions towards evenings or weekends
 */
export const noContribution = { evenings: 0, saturdays: 0, sundays: 0 };

/**
 * finds the [evening/saturday/sunday] contribution entry that is valid given the selected cost centre, and hours/contractRange.
 */
export const getApplicableContribution = (
    contributions: Array<DefaultContributionData> | undefined,
    costCentre: string,
    hoursPerWeek: number | undefined | null,
    contractRangeType: string | undefined | null,
) => contributions
    ?.find(cont => {
        if (cont.rangeType && contractRangeType) {
            return cont.rangeType === contractRangeType;
        }

        if (hoursPerWeek && cont.max && (cont.min || cont.min === 0)) {
            return cont.min <= hoursPerWeek && cont.max >= hoursPerWeek;
        }

        return false;
    })
    ?.data.find(cont => costCentresAreSame(cont.costCentre, costCentre));

const getCoworkersCustomContributions = (
    customContributions: CustomContributions | undefined,
    coworker: Coworker | DummyCoworker,
    costCentre: string,
) => customContributions
    ?.filter(
        cont => isCoworkersId(coworker, cont.employeeId)
                && costCentresAreSame(cont.contribution.costCentre, costCentre),
    )
    ?.map(contributionEntry => contributionEntry.contribution);

/**
 * Checks if the default contributions object has any rangeType set for the evening contributions.
 */
const isContributionsRange = (contributions: DefaultContributions) => contributions.contributions.evening.some(cont => cont.rangeType !== null);

/**
 * gets the evenings/weekend defaultcontributions for the coworker and cost centre for the period where the contract is active.
 */
export const getContractDefaultContributions = (
    coworker: Coworker | DummyCoworker,
    costCentre: string,
    isActive: boolean,
    defaultContributions: DefaultContributions | undefined,
): BaseContractType['defaultContributions'] => {
    if (!isActive) return noContribution;
    if (coworker.contractRange && coworker.costCentre !== costCentre) {
        return noContribution;
    }
    const costCentreHours = coworker.hoursPerWeek
        * ((coworker.costDistributions.find(cd => cd.costCentre === costCentre)?.costCentrePercent ?? 0) / 100);

    const defEvenings = getApplicableContribution(
        defaultContributions?.contributions.evening,
        costCentre,
        costCentreHours,
        coworker.contractRange?.type,
    );
    const defSaturdays = getApplicableContribution(
        defaultContributions?.contributions.saturday,
        costCentre,
        costCentreHours,
        coworker.contractRange?.type,
    );
    const defSundays = getApplicableContribution(
        defaultContributions?.contributions.sunday,
        costCentre,
        costCentreHours,
        coworker.contractRange?.type,
    );

    return {
        evenings: defEvenings?.value ?? 0,
        saturdays: defSaturdays?.value ?? 0,
        sundays: defSundays?.value ?? 0,
    };
};

/**
 * Gets all contributions which are for the coworker and cost centre combinations, and returns them in the BaseContractType['customContributions'] format,
 * with the startWeeksFromZero & endWeeksFromZero being with respect to the reference date.
 * @param referenceDate Typically week 0 of the timearray.
 */
const getApplicableCustomContributions = (
    coworker: Coworker | DummyCoworker,
    costCentre: string,
    referenceDate: string,
    customContributions: CustomContributions | undefined,
): BaseContractType['customContributions'] => {
    const customContributionEntries = getCoworkersCustomContributions(customContributions, coworker, costCentre);

    return customContributionEntries && customContributionEntries.length > 0
        ? customContributionEntries.map(customContributionEntry => ({
            evenings: customContributionEntry.evening,
            saturdays: customContributionEntry.saturday,
            sundays: customContributionEntry.sunday,
            startWeeksFromZero: DateHelper.getDateDifferenceInWeeks(
                customContributionEntry.duration.startDate || BEGINNING_OF_TIME,
                referenceDate,
            ),
            endWeeksFromZero: DateHelper.getDateDifferenceInWeeks(
                customContributionEntry.duration.endDate || INDEFINITE_END_DATE,
                referenceDate,
            ),
        }))
        : null;
};

/**
 * gets the evenings/weekend custom contributions for the coworker and cost centre for the period where the contract is active.
 */
export const getContractCustomContributions = (
    coworker: Coworker | DummyCoworker,
    costCentre: string,
    referenceDate: string,
    isActive: boolean,
    customContributions: CustomContributions | undefined,
): BaseContractType['customContributions'] => {
    if (!isActive) {
        return null;
    }

    // Cant contribute to evenings/weekends in a cost centre if the coworker has no hours at the cost centre.
    const costCentreHours = coworker.hoursPerWeek
        * ((coworker.costDistributions.find(cd => cd.costCentre === costCentre)?.costCentrePercent ?? 0) / 100);
    if (coworker.contractRange && costCentreHours === 0) {
        return null;
    }

    return getApplicableCustomContributions(coworker, costCentre, referenceDate, customContributions) ?? null;
};

/**
 * Gets the contribution towards evenings and weekends for a coworker which contract changes in the scenario.
 * Only used for contract range countries.
 */
export const getContributionForDeltaRange = (
    contractDelta: ContractDelta,
    costCentre: string,
    coworker: Coworker | DummyCoworker,
    referenceDate: string,
    defaultContributions: DefaultContributions,
    customContributions: CustomContributions | undefined,
): Pick<BaseContractType, 'defaultContributions' | 'customContributions'> | null => {
    const costCentreHours = (contractDelta.hoursPerWeekRange?.range.max ?? contractDelta.hoursPerWeek ?? 0)
        * ((contractDelta.costDistributions.find(cd => cd.costCentre === costCentre)?.costCentrePercent ?? 0) / 100);

    // Can't contribute to evenings/weekends in a cost centre if the coworker has no hours at the cost centre.
    // This may differ from the contract hours, use noContribution to set contributions to 0 instead of just null to overwrite.
    if (contractDelta.hoursPerWeekRange?.type === zeroHoursContractRange.type || costCentreHours === 0) {
        return { defaultContributions: noContribution, customContributions: null };
    }

    const homeCostCentre = contractDelta.costDistributions.some(
        cd => cd.costCentrePercent > 0 && cd.costCentre === coworker.costCentre,
    )
        ? coworker.costCentre
        : contractDelta.costDistributions.sort(sortBy('costCentrePercent', 'DESC'))[0].costCentre;

    const applicableCustomContributions = getApplicableCustomContributions(coworker, costCentre, referenceDate, customContributions);

    if (costCentre !== homeCostCentre && !applicableCustomContributions) return null;
    if (costCentre !== homeCostCentre && applicableCustomContributions) {
        return {
            defaultContributions: noContribution,
            customContributions: applicableCustomContributions,
        };
    }
    const eveningCont = getApplicableContribution(
        defaultContributions?.contributions.evening,
        costCentre,
        undefined,
        contractDelta.hoursPerWeekRange?.type,
    );
    const saturdayCont = getApplicableContribution(
        defaultContributions?.contributions.saturday,
        costCentre,
        undefined,
        contractDelta.hoursPerWeekRange?.type,
    );
    const sundayCont = getApplicableContribution(
        defaultContributions?.contributions.sunday,
        costCentre,
        undefined,
        contractDelta.hoursPerWeekRange?.type,
    );

    return {
        defaultContributions: {
            evenings: eveningCont?.value ?? 0,
            saturdays: saturdayCont?.value ?? 0,
            sundays: sundayCont?.value ?? 0,
        },
        customContributions: applicableCustomContributions
    };
};

/**
 * Gets the contribution towards evenings and weekends for a coworker which contract changes in the scenario.
 * Only used for non-range countries.
 */
export const getContributionForDeltaHours = (
    contractDelta: ContractDelta,
    costCentre: string,
    coworker: Coworker | DummyCoworker,
    referenceDate: string,
    defaultContributions: DefaultContributions,
    customContributions: CustomContributions | undefined,
): Pick<BaseContractType, 'defaultContributions' | 'customContributions'> | null => {
    const contributingHours = (
        contractDelta.hoursPerWeek ?? 0
    ) * ((
        contractDelta.costDistributions.find(cd => cd.costCentre === costCentre)?.costCentrePercent ?? 0
    ) / 100);

    // Can't contribute to evenings/weekends in a cost centre if the coworker has no hours at the cost centre.
    // This may differ from the contract hours, use noContribution to set contributions to 0 instead of just null to overwrite.
    if (contributingHours === 0) return { defaultContributions: noContribution, customContributions: null };

    const applicableCustomContributions = getApplicableCustomContributions(coworker, costCentre, referenceDate, customContributions);

    const eveningCont = getApplicableContribution(
        defaultContributions?.contributions.evening,
        costCentre,
        contributingHours,
        undefined,
    );
    const saturdayCont = getApplicableContribution(
        defaultContributions?.contributions.saturday,
        costCentre,
        contributingHours,
        undefined,
    );
    const sundayCont = getApplicableContribution(
        defaultContributions?.contributions.sunday,
        costCentre,
        contributingHours,
        undefined,
    );

    return {
        defaultContributions: {
            evenings: eveningCont?.value ?? 0,
            saturdays: saturdayCont?.value ?? 0,
            sundays: sundayCont?.value ?? 0,
        },
        customContributions: applicableCustomContributions
    };
};

export const getContributionForDelta = (
    contractDelta: ContractDelta,
    costCentre: string,
    coworker: Coworker | DummyCoworker,
    referenceDate: string,
    defaultContributions: DefaultContributions | undefined,
    customContributions: CustomContributions | undefined,
): Pick<BaseContractType, 'defaultContributions' | 'customContributions'> | null => {
    if (!defaultContributions) {
        // If there is no default contribution, user is not using evenings/weekends.
        // Ignore the edge case where (!defaultContributions && customContributions)
        return null;
    }
    if (defaultContributions && isContributionsRange(defaultContributions)) {
        return getContributionForDeltaRange(contractDelta, costCentre, coworker, referenceDate, defaultContributions, customContributions);
    }

    return getContributionForDeltaHours(contractDelta, costCentre, coworker, referenceDate, defaultContributions, customContributions);
};

/**
 * gets contribution towards evenings and for a weekly delta. For contract range countries weekly deltas should only modify the contributions if
 * the coworker get assigned to 0h/A/H for that week. Else, the contribution should be unchanged by the weekly delta. For countries without contract ranges
 * any hours change should modify the contribution.
 * @param hasCustomContributions - flag to indicate if the cw has custom contributions set for the cost centre.
 * If true, this function returns null unless 0/H/A.
 */
export const getWeeklyDeltaContributions = (
    weeklyDelta: WeeklyDelta,
    costCentre: string,
    defaultContributions: DefaultContributions | undefined,
    hasCustomContributions: boolean,
) => {
    if (
        weeklyDelta.costCentreHours === 0
        || weeklyDelta.coworkerAvailability === CoworkerAvailability.ABSENCE
        || weeklyDelta.coworkerAvailability === CoworkerAvailability.HOLIDAY
    ) {
        return noContribution;
    }

    if (hasCustomContributions || !defaultContributions || isContributionsRange(defaultContributions)) return null;

    const eveningCont = getApplicableContribution(
        defaultContributions?.contributions.evening,
        costCentre,
        weeklyDelta.costCentreHours,
        undefined,
    );
    const saturdayCont = getApplicableContribution(
        defaultContributions?.contributions.saturday,
        costCentre,
        weeklyDelta.costCentreHours,
        undefined,
    );
    const sundayCont = getApplicableContribution(
        defaultContributions?.contributions.sunday,
        costCentre,
        weeklyDelta.costCentreHours,
        undefined,
    );

    return {
        evenings: eveningCont?.value ?? 0,
        saturdays: saturdayCont?.value ?? 0,
        sundays: sundayCont?.value ?? 0,
    };
};

/**
 * Checks if the dates specified in the custom contribution has some overlap with the time range of a delta.
 * @returns true if there is some overlap, false otherwise.
 */
export const isCustomContributionActive = (
    contributionStartDate: string,
    contributionEndDate: string,
    deltaStartDate: string,
    deltaEndDate: string,
) => DateHelper.getOverlapBetweenRanges({ startDate: contributionStartDate, endDate: contributionEndDate }, {
    startDate: deltaStartDate,
    endDate: deltaEndDate,
}) !== null;

/**
 * Selects the evening/weekends contribution that are to be used for the specific week in the time selection array.
 * @param referenceIndex The index of the week in the timeselection array
 * @param potentialDefaultContribution undefined or the default contribution value
 * @param potentialCustomContributions undefined or an array of custom contributions for the coworker/costCentre
 * @returns if there is an active custom contribution for the week, that contribution is returned.
 * If not, the default contribution is returned.
 * Else, no contribution is returned.
 */
export const selectActiveContribution = (
    referenceIndex: number,
    potentialDefaultContribution: BaseContractType['defaultContributions'],
    potentialCustomContributions: BaseContractType['customContributions'],
) => {
    const activeCustomContribution = potentialCustomContributions?.find(
        cc => cc.startWeeksFromZero <= referenceIndex && referenceIndex <= cc.endWeeksFromZero,
    );
    if (activeCustomContribution) {
        return {
            evenings: activeCustomContribution.evenings,
            saturdays: activeCustomContribution.saturdays,
            sundays: activeCustomContribution.sundays,
        };
    }
    if (potentialDefaultContribution) {
        return {
            evenings: potentialDefaultContribution.evenings,
            saturdays: potentialDefaultContribution.saturdays,
            sundays: potentialDefaultContribution.sundays,
        };
    }

    return null;
};
