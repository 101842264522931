import { useState, useEffect, memo } from 'react';
import Toast from '@ingka/toast';
import { useEvent } from 'hooks/useEvent';
import { EVENT_DISPLAY_TOAST } from 'types/events';
import { TOAST_DEFAULT_VALUES } from 'hooks/useToast';

type TypeGlobalToast = { isOpen: boolean, error: boolean, title: string, message: string | undefined };

const GlobalToast = () => {
    const [toast, setToast] = useState<TypeGlobalToast>(TOAST_DEFAULT_VALUES);
    const { subscribe } = useEvent();

    useEffect(() => {
        subscribe<TypeGlobalToast>(EVENT_DISPLAY_TOAST, event => {
            setToast(event.detail ?? {});
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Toast
            data-testid="global-toast"
            text={(
                <>
                    <strong>{toast.title}</strong>
                    {' '}
                    {/* // Keep fallback to avoid error if recieving undefined. Isn't caught by TS */}
                    {toast.message ?? ''}
                </>
            )}
            isOpen={toast.isOpen}
            onCloseRequest={() => setToast(TOAST_DEFAULT_VALUES)}
            ariaLabelCloseBtn="Dismiss notification"
            style={{ zIndex: 2147483647 }} // Maximum possible value for z-index
        />
    );
};

export default memo(GlobalToast);
