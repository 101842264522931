import { CheckBox } from 'types/appContext';

const getSelectedCaption = (filteredData: CheckBox[] | undefined, labelSuffix?: string): string => {
    if (!filteredData) {
        return '';
    }
    const selectedDataLength = filteredData?.filter(({ defaultChecked }) => defaultChecked)?.length ?? 0;

    if (selectedDataLength === 0) return '';
    if (selectedDataLength === 1) {
        return filteredData?.find(({ defaultChecked }) => defaultChecked)?.label ?? '';
    }

    return `(${selectedDataLength} ${labelSuffix})`;
};

const getSelectedCCCaption = (filteredCostCenterOMData: CheckBox[] | undefined, labelSuffix: string): string => {
    if (!filteredCostCenterOMData) {
        return '';
    }
    const selectedCostCentreLength = filteredCostCenterOMData?.filter(({ defaultChecked }) => defaultChecked)?.length ?? 0;

    if (selectedCostCentreLength === 0) return '';
    if (selectedCostCentreLength === 1) {
        const costCentreLabel = filteredCostCenterOMData?.find(({ defaultChecked }) => defaultChecked)?.label ?? '';
        const costCentreSubLabel = filteredCostCenterOMData?.find(({ defaultChecked }) => defaultChecked)?.subLabel ?? '';

        return `${costCentreLabel} ${costCentreSubLabel}`;
    }

    return `(${selectedCostCentreLength} ${labelSuffix})`;
};

export { getSelectedCaption, getSelectedCCCaption };
