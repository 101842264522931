import React, { useState } from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { useTranslation } from 'react-i18next';
import classes from './ConfigTabs.module.scss';
import GeneralSettings from './GeneralSettings';

const ConfigTabs = () => {
    const { t } = useTranslation();
    const [activeTabView, setActiveTabView] = useState<string>('');

    const tabs = [
        <Tab key="general_settings-Tab" text={t('GENERAL_SETTINGS')} tabPanelId="tab_1" />

    ];

    const tabPanels = [
        <TabPanel
            key="tab-1"
            tabPanelId="tab_1"
            className={classes.tabs__panel}
        >
            <GeneralSettings />
        </TabPanel>
    ];

    return (
        <Tabs
            id="config__tabs"
            data-testid="config__tabs"
            className={classes.tabs}
            tabs={tabs}
            tabPanels={tabPanels}
            activeTab={activeTabView}
            defaultActiveTab="tab_1"
            onTabChanged={(tabId: string) => setActiveTabView(tabId)}
        />
    );
};

export default ConfigTabs;
