export const calculateSum = (numbers: Array<number> = []) => numbers.reduce((accumulator, value) => accumulator + value, 0);
export const NumberFormatter = Intl.NumberFormat([navigator.language, 'en-US'], {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
});
export const NumberFormatterNoDigits = Intl.NumberFormat([navigator.language, 'en-US'], {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});
export const calculateNumberDividedToPercentage = (number: number, percentage: number) => Math.round(number * (percentage / 100) * 100) / 100;
export const calculateNumberByPercentage = (
    number: number,
    percentage: number
) => (!number || !percentage ? 0 : Math.round(((number * 100) / percentage) * 100) / 100);
export const isNegative = (number: number) => Math.sign(number) === -1;
export const toUSFormatString = (num: number | string) => num.toLocaleString('en-US');
// export const roundToFixed = (value: number) => Math.round(value);
/**
 * Convert a number to a i18n string for display
 * @param value number to convert
 * @returns string
 */
const roundDisplayValue = (value: number): string => {
    const roundedValue = Math.round(value * 100) / 100;
    if (roundedValue % 1 === 0) {
        return NumberFormatterNoDigits.format(Math.trunc(roundedValue));
    }

    return NumberFormatter.format(roundedValue);
};
/**
 * Convert a number to single digit floating point or integer, depending on the original value
 * @param value number to convert
 * @returns string
 */
export const roundValue = (value: number): number => {
    const roundedValue = Math.round(value * 100) / 100;
    if (roundedValue % 1 === 0) {
        return Math.trunc(roundedValue);
    }

    return roundedValue;
};
/**
 * Returns an i18n formatted value, for the purpose of displaying in components.
 * Will display with two decimals if floating point and will display only one decimal if
 * the floating number ends with zero like 1.60 , 1.50 ..etc , and without decimals if integer.
 * When given a string or undefined value as input, returns the string or 'N/A' to indicate
 * that the value is not available.
 * @param number
 * @returns string
 */
export const roundDisplayValueDoubleDecimal = (value: number | string | undefined) => {
    if (typeof value === 'number' && value !== undefined) {
        return roundDisplayValue(value);
    }
    // eslint-disable-next-line no-restricted-globals
    if (typeof value === 'string' && value !== undefined && !isNaN(Number.parseFloat(value)) && !value.endsWith('.')) {
        const parsedValue = Number.parseFloat(value);

        return roundDisplayValue(parsedValue);
    }

    return value;
};

/**
 * Returns an i18n formatted value, for the purpose of displaying in components.
 * Will display with one or two decimals if floating point, and without decimals if integer.
 * When given a string or undefined value as input, returns the string or 'N/A' to indicate
 * that the value is not available.
 * @param number
 * @returns string
 */
export const roundValueToDoubleDecimal = (value: number | string): number | string => {
    if (typeof value === 'number' && value !== undefined) {
        return roundValue(value);
    }
    // eslint-disable-next-line no-restricted-globals
    if (typeof value === 'string' && value !== undefined && !isNaN(Number.parseFloat(value))) {
        if (value.endsWith('.') || value.endsWith(',')) {
            return value;
        }
        const parsedValue = Number.parseFloat(value);

        return roundValue(parsedValue);
    }

    throw new Error('NEVER GET HERE');
};

export const getNumbersFromString = (text: string | null) => (text ? text.match(/\d+/)?.join() ?? null : null);

export const isNumber = (val: number | string) => !Number.isNaN(parseFloat(String(val)));

export const convertMinutesToHours = (minutes: number) => Math.floor((minutes * 10) / 60) / 10;

export const getHoursInDecimal = (hours: number, minutes: number) => {
    const decimalMinutes = minutes / 60;

    return hours + Number(decimalMinutes.toFixed(2));
};

export const formatToSignedNumber = (number: number) => (number > 0 ? `+${number}` : number);
