import { TypeNewCoworkerAction } from 'types/actionPlan';
import { useTranslation } from 'react-i18next';
import { TypeContract } from 'types/scenario';
import { getShortCC } from 'utils/text';
import Text from '@ingka/text';
import classes from '../ActionTable.module.scss';
import { displayActionHours } from './utils';

const NewCoworkerAction = ({ action, contractType }:{ action: TypeNewCoworkerAction, contractType: TypeContract }) => {
    const { t } = useTranslation();

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-dummy-created-header"
                >
                    {t('COWORKER_CREATED')}
                </Text>
            </div>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-dummy-hours-header"
                >
                    {t('CONTRACT_HOURS')}
                </Text>
                <Text
                    data-testid="action-plan-dummy-hours-change"
                >
                    {displayActionHours(action.hours, t)}
                </Text>
            </div>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-dummy-cc-header"
                >
                    {t('COST_CENTER')}
                </Text>
                <Text
                    data-testid="action-plan-dummy-cc-change"
                >
                    {`CC${getShortCC(action.costCentre)}`}
                </Text>
            </div>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-dummy-contract-type-header"
                >
                    {t('CONTRACT_TYPE')}
                </Text>
                <Text
                    data-testid="action-plan-dummy-contract-type-change"
                >
                    {t(contractType)}
                </Text>
            </div>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-dummy-employment-type-header"
                >
                    {t('EMPLOYMENT_TYPE')}
                </Text>
                <Text
                    data-testid="action-plan-dummy-employment-type-change"
                >
                    {t(action.employmentType)}
                </Text>
            </div>
        </div>
    );
};

export default NewCoworkerAction;
