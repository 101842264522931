import { useNavigate as useRouterNavigate, useSearchParams, To, NavigateOptions } from 'react-router-dom';

const useNavigate = () => {
    const navigate = useRouterNavigate();
    const [searchParams] = useSearchParams();

    return ({ to, options }: { to: To, options?: NavigateOptions }) => {
        if (typeof to === 'string') {
            navigate({ pathname: to, search: searchParams.toString() }, options);
        } else {
            navigate(
                {
                    ...to,
                    search: new URLSearchParams({ ...Object.fromEntries(searchParams), ...Object.fromEntries(new URLSearchParams(to.search)) }).toString()
                },
                options
            );
        }
    };
};

export default useNavigate;
