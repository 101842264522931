import React from 'react';
import { AppContext } from 'context/AppContext';

const useApp = () => {
    const context = React.useContext(AppContext);

    if (context === undefined) {
        throw new Error('AppContext was used outside of its provider');
    }

    return context;
};

export default useApp;
