import { UserAccessFeatures, UserPermission, UserProfile } from 'types/authContext';
import { isProd } from 'utils/environments';

export const noUserFeaturesAccess: UserAccessFeatures = {
    capacityFactorView: false,
    contractMixView: false,
    departmentsView: () => false,
    enableDisableUnits: false,
    onboardingNewUnits: false,
    modulation: false,
    recruitmentNeedsView: false
};

const hasOnboardingNewUnitsAccess = (userProfile: UserProfile | null) => !!userProfile?.rolePermissions.includes(UserPermission.ONBOARD_NEW_UNITS);

const hasEnableDisableUnitsAccess = (userProfile: UserProfile | null) => !!userProfile?.rolePermissions.includes(UserPermission.ENABLE_DISABLE_UNITS);

export const hasDepartmentsViewAccess = (userProfile: UserProfile | null, currentCountry: string | undefined) => {
    if (
        !currentCountry
        || !userProfile
        || !userProfile?.rolePermissions.includes(UserPermission.DEPARTMENTS_VIEW)
    ) {
        return false;
    }
    if (isProd) {
        // TODO - Adjust when this is moved to BE or remove if depreciated
        return !!process.env.REACT_APP_FEATURES_enable_department_markets?.split(',').includes(currentCountry);
    }

    return true;
};

const hasCapacityFactorsAccess = (userProfile: UserProfile | null) => !!userProfile?.rolePermissions.includes(UserPermission.CAPACITY_FACTOR_VIEW);

const hasContractMixAccess = (userProfile: UserProfile | null) => !!userProfile?.rolePermissions.includes(UserPermission.READ_CONTRACT_MIX);

const hasModulationAccess = (userProfile: UserProfile | null) => !!userProfile?.rolePermissions.includes(UserPermission.ENABLE_MODULATION);

const hasRecruitmentNeedsAccess = (userProfile: UserProfile | null) => !!userProfile?.rolePermissions.includes(UserPermission.VIEW_RECRUITMENT_NEEDS);

/**
 * Handles whether the user, given the current environment and role, has access to each feature on the site
 */
export const getAccessFeatures = (userProfile: UserProfile | null): UserAccessFeatures => {
    if (!userProfile) return noUserFeaturesAccess;

    return {
        onboardingNewUnits: hasOnboardingNewUnitsAccess(userProfile),
        enableDisableUnits: hasEnableDisableUnitsAccess(userProfile),
        departmentsView: (currentCountry: string | undefined) => hasDepartmentsViewAccess(userProfile, currentCountry),
        capacityFactorView: hasCapacityFactorsAccess(userProfile),
        contractMixView: hasContractMixAccess(userProfile),
        modulation: hasModulationAccess(userProfile),
        recruitmentNeedsView: hasRecruitmentNeedsAccess(userProfile)
    };
};
