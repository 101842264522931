import React, { useEffect, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import useApp from 'hooks/useApp';
import { ContractDelta,
    ContractType,
    CostCentre,
    CoworkerDetailsEditForm, DummyCoworker } from 'types/scenario';
import { useFieldArray, useForm } from 'react-hook-form';
import Button from '@ingka/button';
import useData from 'hooks/useData';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateScenarioAPI } from 'hooks/api/useUpdateScenarioAPI';
import { useToast } from 'hooks/useToast';
import { Coworker } from 'types/coworker';
import { ScenarioUpdateSource } from 'types/api';
import { isIntegerOrDecimal } from 'utils/validation';
import classes from './CoworkerDetails.module.scss';
import { CoworkerDetailsEditDisplay } from './CoworkerDetailsEditDisplay';
import { convertDeltaToISODates,
    getCoworkerDelta,
    getCoworkerDetailsInitialState,
    areRequiredFieldsTouched,
    updateCoworkerDetailsFormState } from './helpers';

const getValidationSchema = (t: TFunction<'translation', undefined>) => (yup.object({
    startDate: yup.string().nullable().required(t('DATE_SELECTION')),
    type: yup.string(),
    endDate: yup.string().when('type', {
        is: 'TEMPORARY',
        then: yup.string().nullable().required(t('DATE_SELECTION')),
        otherwise: yup.string().nullable(),
    }),
    hoursPerWeekRange: yup.lazy((formValue: CoworkerDetailsEditForm['hoursPerWeekRange']) => {
        if (!(formValue === undefined || formValue === null)) {
            return yup.object().shape({
                type: yup.string(),
                range: yup.object().shape({
                    max: yup.number().typeError(t('VALUE_MUST_BE_A_NUMBER_OR_DECIMAL')),
                    min: yup.number().when(value => {
                        // For countries with a 40hr contract in a range country, min comes as NaN or null
                        if (Number.isNaN(value) || value === null) {
                            return yup.mixed().notRequired();
                        }

                        return yup.number();
                    }),
                }),
                description: yup.string()
            });
        }

        return yup.mixed().notRequired();
    }),
    hoursPerWeek: yup.lazy((formValue: CoworkerDetailsEditForm['hoursPerWeek']) => {
        if (!(formValue === undefined || formValue === null)) {
            return yup.number().when(value => {
                if (!isIntegerOrDecimal(value)) {
                    return yup.number().typeError(t('VALUE_MUST_BE_A_NUMBER_OR_DECIMAL'));
                }

                return yup.number();
            });
        }

        return yup.mixed().notRequired();
    }),
    costDistributions: yup.array().required(t('VALUE_IS_REQUIRED')).min(1).of(yup.object().shape({
        costCentre: yup.string().required(t('OPTION_MUST_BE_SELECTED')),
        costCentrePercent: yup.number().typeError(t('VALUE_MUST_BE_A_NUMBER')).required(t('VALUE_IS_REQUIRED')),
    }))
        .test(
            'PERCENT_100_MANDATORY',
            t('PERCENT_100_MANDATORY'),
            form => form?.reduce(
                (total, cd) => Number(total) + Number(cd.costCentrePercent),
                0
            ) === 100
        ),
    globalError: yup.string().nullable()
})
);

const CoworkerDetailsEdit = ({
    coworker,
    costCentres,
    setIsOpen,
}: {
    coworker: Coworker | DummyCoworker,
    costCentres: CostCentre[],
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const { t } = useTranslation();
    const { filteredUnitLevelCostCenterOMData } = useApp();
    const { currentScenario, selectScenario } = useData();
    const defaultValues = useMemo(() => getCoworkerDetailsInitialState(coworker), [coworker]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validationSchema = useMemo(() => getValidationSchema(t), []);
    const { updateScenario } = useUpdateScenarioAPI();
    const { displayToast } = useToast();

    const {
        handleSubmit,
        getValues,
        setValue,
        formState: { errors, touchedFields, isSubmitting, isValidating, isValid },
        control,
        reset,
        watch,
        trigger,
        setError,
        clearErrors,
    } = useForm<CoworkerDetailsEditForm>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues,
        resolver: yupResolver(validationSchema),

    });
    const { fields: costCentreDistributions, append, remove, replace } = useFieldArray({
        control,
        name: 'costDistributions',
    });

    useEffect(() => {
        updateCoworkerDetailsFormState(coworker, currentScenario, defaultValues, getValues, setValue, replace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('startDate'), watch('endDate')]);

    const handleSaveCoworker = async (formState: ContractDelta) => {
        if (!isValid || !areRequiredFieldsTouched(touchedFields)) {
            trigger();
            setTimeout(() => setError(
                'globalError',
                { type: 'FIELD_INTERACTION_REQUIRED_FOR_SAVE', message: t('FIELD_INTERACTION_REQUIRED_FOR_SAVE') },
            ), 0);

            return;
        }

        const { startDate, endDate } = convertDeltaToISODates(formState);

        updateScenario({
            source: ScenarioUpdateSource.CONTRACT_CHANGES_MODAL,
            personId: coworker.personId,
            coworkerName: coworker.legalFullName,
            startDate,
            endDate,
            division: coworker.division,
            costCentre: coworker.costCentre,
            department: coworker.departmentCode ?? null,
            contractType: coworker.contractType as ContractType,
            dummyCoworker: null,
            contractHours: coworker.hoursPerWeek,
            delta: getCoworkerDelta(touchedFields, formState, defaultValues, coworker),
        }).then(response => {
            if (!response.isResponseOk) {
                displayToast({ title: t('ERROR'), message: t('SAVE_FAILED') });

                return;
            }

            displayToast({ title: t('SUCCESS'), message: t('SAVE_SUCCEEDED') });
            setIsOpen(false);
            setTimeout(() => { reset(); selectScenario(currentScenario?.id ?? ''); }, 0);
        });
    };

    // trigger initial form validation on load
    useEffect(() => {
        trigger();

        // Add form submit on Enter key press
        const listener = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                handleSaveCoworker(getValues());
            }
        };
        document.addEventListener('keydown', listener);

        return () => document.removeEventListener('keydown', listener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const unusedCostCenterOptions = useMemo(() => {
        const usedCostCentres = getValues('costDistributions')?.map(cd => cd.costCentre);

        return filteredUnitLevelCostCenterOMData?.filter(cc => !usedCostCentres?.includes(cc.value)) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues('costDistributions')]);

    const addCostDistribution = () => append({ costCentre: '', costCentrePercent: 0 }, { shouldFocus: true });

    const deleteCostDistribution = (index: number) => remove(index);

    return (
        <form onSubmit={handleSubmit(handleSaveCoworker)} className={classes.form}>
            <CoworkerDetailsEditDisplay
                coworker={coworker}
                costCentres={costCentres}
                costDistributions={costCentreDistributions}
                addCostDistribution={addCostDistribution}
                deleteCostDistribution={deleteCostDistribution}
                costCentreDropdownList={unusedCostCenterOptions}
                setValue={setValue}
                getValues={getValues}
                defaultValues={defaultValues}
                control={control}
                errors={errors}
                trigger={trigger}
                clearErrors={clearErrors}
            />
            <div className={classes['modal-buttons']}>
                <div className={classes['button-row']}>
                    <div>
                        <Button
                            className={classes.button}
                            text={t('CANCEL')}
                            size="small"
                            type="secondary"
                            onClick={() => setIsOpen(false)}
                        />
                    </div>
                    <div>
                        <Button
                            className={classes.button}
                            text={t('SAVE_CHANGES')}
                            size="small"
                            type="primary"
                            onClick={() => handleSaveCoworker(getValues())}
                            loading={isSubmitting || isValidating}
                            disabled={!isValid || !areRequiredFieldsTouched(touchedFields)}
                        />
                    </div>
                </div>
            </div>
        </form>

    );
};

export default CoworkerDetailsEdit;
