import { useState } from 'react';
import { ContractType, ContractLegacy } from 'types/scenario';

const useContractType = () => {
    const defaultContractType = ContractLegacy.permanent;
    const [contractType, setContractType] = useState<ContractType>(defaultContractType);
    const contractOptions: ContractType[] = Object.values(ContractLegacy);

    const handleChangeContractType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // Note: can only select from contractOptions, and has a valid default value.
        // no validation needed.
        setContractType(e.target.value as ContractType);
    };

    const resetContractType = () => {
        setContractType(defaultContractType);
    };

    const isPermanentContract = (ct: ContractType) => ct === ContractLegacy.permanent;

    return { contractType, contractOptions, handleChangeContractType, validContractType: true, resetContractType, isPermanentContract };
};

export default useContractType;
