import React, { Dispatch, SetStateAction } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import CoworkerDetailsEdit from 'components/CoworkerDetails/CoworkerDetailsEdit';
import useData from 'hooks/useData';
import { getCostCentreDescription } from 'hooks/useGrid/gridFunctions';
import { DummyCoworker } from 'types/scenario';
import { getShortCC } from 'utils/text';
import { Coworker } from 'types/coworker';
import { getCoworkerCostCentres } from './helpers';
import classes from './CoworkerDetails.module.scss';

export const CoworkerDetailsEditModal = (
    { coworker, isOpen, setIsOpen } : { coworker:Coworker | DummyCoworker; isOpen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> }
) => {
    const { unfilteredCountryOMData, currentScenario } = useData();
    const costCentres = getCoworkerCostCentres(coworker, unfilteredCountryOMData);
    const coworkerDelta = currentScenario?.scenarioData.find(data => data.personId === coworker.personId);

    if (coworkerDelta) {
        coworkerDelta.contractDeltas.forEach(delta => {
            delta?.costDistributions?.forEach(deltaCd => {
                if (!costCentres.some(originalCc => originalCc.costCentre === deltaCd.costCentre)) {
                    costCentres.push({
                        costCentre: deltaCd.costCentre,
                        displayName: `${getShortCC(deltaCd.costCentre)} ${getCostCentreDescription(deltaCd.costCentre, unfilteredCountryOMData)}`,
                        sourceIsPa: false
                    });
                }
            });
        });
    }

    return (
        <Modal
            visible={isOpen}
            escapable
            scrollLock
            handleCloseBtn={() => setIsOpen(false)}
            className={classes.modal}
        >
            <Sheets
                alignment="right"
                size="large"
                header={coworker?.legalFullName ? (<ModalHeader title={coworker.legalFullName} />) : null}
                footer={(
                    <ModalFooter>
                        <div />
                    </ModalFooter>
                )}
            >
                <ModalBody className={classes.modal}>
                    <CoworkerDetailsEdit
                        coworker={coworker}
                        costCentres={costCentres}
                        setIsOpen={setIsOpen}
                    />
                </ModalBody>
            </Sheets>
        </Modal>
    );
};
