import React, { useRef, useState } from 'react';
import { HoverDetails } from 'components';
import ReactDOM from 'react-dom';
import { ContributionsData, GapHoursData, HoverDataContentType } from 'types/table';
import * as colours from '@ingka/variables/colours-css';
import { roundDisplayValueDoubleDecimal } from 'utils/number';
import { t } from 'i18next';
import { getGapHours, getGapContributions, isCapacityUnderTarget, isContributionsUnderTarget } from 'hooks/useGrid/gridFunctionsShared';
import classes from './HeaderCell.module.scss';

const POPUP_MINIMUM_REQUIRED_SPACE = 350;

const HeaderCell = (
    { hoverData }:
    { hoverData: GapHoursData | ContributionsData }
) => {
    const [showPopUp, setShowPopUp] = useState(false);
    const [hoverDetailsDirection, setHoverDetailsDirection] = useState('left-to-right');
    const parentRef = useRef<HTMLTableCellElement>(null);
    const portalDiv = document.getElementById('portal');
    const gapValue = hoverData.contentType === HoverDataContentType.GAP ? getGapHours(hoverData) : getGapContributions(hoverData).total;
    const isDangerColor = hoverData.contentType === HoverDataContentType.GAP
        ? isCapacityUnderTarget(hoverData)
        : isContributionsUnderTarget(hoverData);

    const updateHoverDetailsDirection = (event: { clientX: any; clientY: any; }) => {
        const availableSpace : number = window.innerWidth - event.clientX;
        if (availableSpace >= POPUP_MINIMUM_REQUIRED_SPACE) {
            setHoverDetailsDirection('left-to-right');
        } else {
            setHoverDetailsDirection('right-to-left');
        }
        setShowPopUp(true);
    };

    return (
        <div
            ref={parentRef}
            onMouseEnter={event => updateHoverDetailsDirection(event)}
            onMouseLeave={() => setShowPopUp(false)}
        >
            {hoverData && parentRef.current && portalDiv && showPopUp && (
                ReactDOM.createPortal(
                    <div
                        className={classes['hover-div']}
                        style={{
                            left: `${parentRef.current.getBoundingClientRect().left}px`,
                            top: `${Math.floor(parentRef.current.getBoundingClientRect().bottom)}px`
                        }}
                    >
                        <HoverDetails
                            hoverData={hoverData}
                            hoverDetailsDirection={hoverDetailsDirection}
                        />
                    </div>,
                    portalDiv
                ))}

            <div className={classes.gap__cell} style={{ color: isDangerColor ? colours.colourSemanticNegative : colours.colourTextAndIcon3 }}>
                {roundDisplayValueDoubleDecimal(gapValue) ?? t('N/A')}
            </div>
        </div>
    );
};

export default HeaderCell;
