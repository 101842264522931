import React, { useState } from 'react';
import moment from 'moment';
import { TimeSelection } from 'types/appContext';
import { useTranslation } from 'react-i18next';
import { generateTimeArray, DATE_FORMAT_STANDARD } from 'utils/date';
import DatePickerDisplay from './DatePickerDisplay';
import { MAXIMUM_DATE_IN_WEEK, getMinCalendarDate } from './helpers';

const DatePicker = (
    {
        type,
        timeSelection,
        setTimeSelection,
        handleValidation,
    }
    : {
        type: 'start' | 'end',
        timeSelection: TimeSelection,
        setTimeSelection: React.Dispatch<React.SetStateAction<TimeSelection>>,
        handleValidation : (startDate : string, endDate : string) => boolean,
    }
) => {
    const { t } = useTranslation();
    const dateToLocalDate = (date:string) => (
        timeSelection.selection === 'week'
            ? `${moment(date).year()}-W${moment(date).format('WW')}`
            : `${moment(date).year()}-${moment(date).format('MM')}`

    );

    const [localDate, setLocalDate] = useState(dateToLocalDate(type === 'start'
        ? timeSelection.startDate
        : timeSelection.endDate));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== '') {
            setLocalDate(e.target.value);

            if (type === 'start') {
                handleValidation(moment(e.target.value).format(DATE_FORMAT_STANDARD), timeSelection.endDate);
            } else {
                handleValidation(timeSelection.startDate, moment(e.target.value).endOf('isoWeek').format(DATE_FORMAT_STANDARD));
            }
        }
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== '') {
            setLocalDate(e.target.value);

            setTimeSelection(prevTime => {
                const isStartDateValid = handleValidation(moment(e.target.value).format(DATE_FORMAT_STANDARD), prevTime.endDate);

                if (type === 'start') {
                    return {
                        ...prevTime,
                        startDate: moment(e.target.value).format(DATE_FORMAT_STANDARD),
                        valid: isStartDateValid,
                        timeArray: generateTimeArray(moment(e.target.value).format(DATE_FORMAT_STANDARD), prevTime.endDate, prevTime.selection)
                    };
                }

                const isEndDateValid = handleValidation(prevTime.startDate, moment(e.target.value).endOf('isoWeek').format(DATE_FORMAT_STANDARD));

                return {
                    ...prevTime,
                    endDate: moment(e.target.value).endOf('isoWeek').format(DATE_FORMAT_STANDARD),
                    valid: isEndDateValid,
                    timeArray: generateTimeArray(prevTime.startDate, moment(e.target.value).endOf('isoWeek').format(DATE_FORMAT_STANDARD), prevTime.selection)
                };
            });
        }
    };

    return (
        <DatePickerDisplay
            header={t(`SELECT_THE_${type === 'start' ? 'START' : 'END'}_${timeSelection.selection === 'week' ? 'WEEK' : 'MONTH'}`)}
            inputType={timeSelection.selection}
            inputValue={localDate}
            handleChange={handleChange}
            handleBlur={handleBlur}
            minValue={getMinCalendarDate(type, timeSelection.startDate)}
            maxValue={MAXIMUM_DATE_IN_WEEK}
        />
    );
};

export default DatePicker;
