export const isProd = process.env.REACT_APP_ENV === 'prod';
export const isDev = process.env.REACT_APP_ENV === 'dev';
export const isLocal = process.env.REACT_APP_ENV === 'local';
export const isDemo = process.env.REACT_APP_ENV === 'demo';
export const isTest = process.env.REACT_APP_ENV === 'test';
export enum Environment {
    PROD = 'prod',
    DEV = 'dev',
    LOCAL = 'local',
    DEMO = 'demo',
    TEST = 'stage'
}
