import React from 'react';
import { CostCentre } from 'types/scenario';
import classes from './CoworkerDetails.module.scss';

const CoworkerCostCentre = ({ costCentres, keyPrefix }: {
    costCentres: CostCentre[];
    keyPrefix: string
}) => (
    costCentres.map(costCentre => (
        <div
            data-testid="coworker-costCentre-value"
            key={`coworker-cost-center-${keyPrefix}-${costCentre.costCentre}`}
            className={costCentre.sourceIsPa ? '' : classes['scenario-details']}
        >
            {costCentre.displayName}
        </div>
    ))
);

export default CoworkerCostCentre;
