import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const validateCoworkerName = (name:string) => {
    if (name === '') {
        return false;
    }

    return true;
};

const useCoworkerName = () => {
    const { t } = useTranslation();
    const newCoworkerName = t('NEW_COWORKER');
    const [coworkerName, setCoworkerName] = useState(newCoworkerName);
    const [validCoworkerName, setValidCoworkerName] = useState(true);

    const handleChangeCoworkerName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoworkerName(e.target.value);
        setValidCoworkerName(validateCoworkerName(e.target.value));
    };

    const resetCoworkerName = () => {
        setCoworkerName(newCoworkerName);
        setValidCoworkerName(validateCoworkerName(newCoworkerName));
    };

    return { coworkerName, handleChangeCoworkerName, validCoworkerName, resetCoworkerName };
};

export default useCoworkerName;
