const isLocalhost = () => window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '10.0.0.1';

type CookieOpts = {
    name: string;
    maxAge?: number; // seconds // omit maxAge for session cookie
    path?: string;
    domain?: string;
};

const DEFAULTS: CookieOpts = {
    name: 'kappla',
    domain: isLocalhost() ? undefined : window.location.hostname.split('.').slice(1).join('.'),
    maxAge: 60 * 60 * 24 * 365,
    path: '/',
};

const persistCookie = (options: CookieOpts, data: Record<string, any>) => {
    const { name = 'opp', maxAge, path, domain } = options;

    let cookie = `${name}=${encodeURIComponent(JSON.stringify(data))}`;

    if (maxAge) {
        cookie += `; max-age=${maxAge}`;
    }

    if (path) {
        cookie += `; path=${path}`;
    }

    if (domain) {
        cookie += `; domain=${domain}`;
    }

    document.cookie = cookie;
};

const CookieJar = (options: CookieOpts) => {
    let data: Record<string, any> = {};
    const combinedOptions = {
        ...DEFAULTS,
        ...options,
    };

    return {
        set: (key: string, value: any) => {
            data[key] = value;
            persistCookie(combinedOptions, data);
        },

        get: (key: string, defaultValue?: any) => data[key] ?? defaultValue,

        removeCookie: (key: string) => {
            document.cookie = `${combinedOptions.name}=; max-age=-1`;
            data = {};
            if (key in data) {
                delete data[key];
                persistCookie(combinedOptions, data);
            }
        },

        removeAll: () => {
            document.cookie = `${combinedOptions.name}=; max-age=-1`;
            data = {};
            persistCookie(combinedOptions, data);
        },
    };
};

export default CookieJar;
