import { CartesianScaleTypeRegistry,
    Chart,
    ChartOptions,
    ChartType,
    ChartTypeRegistry,
    CoreInteractionOptions,
    Plugin,
    PluginOptionsByType,
    ScaleOptionsByType,
    ScriptableTooltipContext,
    TooltipItem } from 'chart.js';
import css from '@ingka/variables';
import moment from 'moment';
import { DAYS_IN_A_WEEK, toIntlMedMonthFullYear } from 'utils/date';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { ChartGenerator,
    ChartGeneratorOptions,
    LayoutOptions,
    PluginOptions,
    ScalesOptions,
    CustomYLabelOptions,
    EmptyGraphOptions,
    PluginTooltipExternal } from './types';

/**
 * Handles the external tooltip. Based on example in https://www.chartjs.org/docs/latest/samples/tooltip/html.html
 */
const externalTooltipHandler = ({
    titleFormatter,
    tooltipDivId,
    showAbovePointer,
    numberFormatter,
}: PluginTooltipExternal) => (
    context: ScriptableTooltipContext<'line'>
) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = chart.canvas.parentNode?.querySelector(`#${tooltipDivId}`) as HTMLDivElement | undefined;

    if (!tooltipEl) {
        return;
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';

        return;
    }

    // Set Text
    if (tooltip?.dataPoints && tooltip.title) {
        const titleLines = tooltip.title || [];
        const dataPoints = tooltip?.dataPoints;
        const sortedDataPoints = dataPoints.sort((a, b) => {
            if ('raw' in b && typeof b.raw === 'number' && 'raw' in a && typeof a.raw === 'number') return b.raw - a.raw;

            return 0;
        });

        const table = document.createElement('table');
        const tableHead = document.createElement('thead');

        titleLines.forEach((title: string) => {
            const tr = document.createElement('tr');
            const th = document.createElement('th');
            th.colSpan = 2;
            const text = document.createTextNode(titleFormatter(title));
            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        sortedDataPoints.forEach(datapoint => {
            const color = (datapoint?.dataset?.backgroundColor ?? '') as string;

            const span = document.createElement('span');

            span.style.background = color;
            span.style.borderColor = color;

            const tr = document.createElement('tr');

            const tdLabel = document.createElement('td');
            const tdValue = document.createElement('td');

            const textLabel = document.createTextNode(`${datapoint.dataset.label}:`);
            const textValue = document.createTextNode(`${numberFormatter(datapoint.raw as number) ?? datapoint.formattedValue}`);

            tdLabel.appendChild(span);
            tdLabel.appendChild(textLabel);
            tdValue.appendChild(textValue);
            tr.appendChild(tdLabel);
            tr.appendChild(tdValue);
            tableBody.appendChild(tr);
        });

        table.appendChild(tableHead);
        table.appendChild(tableBody);

        // Remove old table
        while (tooltipEl?.firstChild) {
            tooltipEl.firstChild.remove();
        }

        // Add new table
        if (tooltipEl) {
            tooltipEl.appendChild(table);
        }
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    if (showAbovePointer) {
        tooltipEl.style.top = `${positionY + tooltip.caretY - 24}px`;
        tooltipEl.style.transform = 'translate(-50%, -100%)';
    } else {
        tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
        tooltipEl.style.transform = 'translate(-50%, 0)';
    }
    // @ts-ignore key exists
    tooltipEl.style['min-width'] = '200px';

    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
    if ((positionX + tooltip.caretX) < tooltipEl.offsetWidth / 2) {
        // left side clips
        tooltipEl.style.left = `${tooltipEl.offsetWidth / 2}px`;
    }
    if ((positionX + tooltip.caretX) + tooltipEl.offsetWidth / 2 > chart.width) {
        // right side clips
        tooltipEl.style.left = `${chart.width - (tooltipEl.offsetWidth / 2)}px`;
    }

    tooltipEl.style.backgroundColor = 'black';
    tooltipEl.style.zIndex = '1000';
    tooltipEl.style.borderRadius = '10px';
    tooltipEl.style.padding = '8px';
    tooltipEl.style.transition = 'all 0.25s ease';
};

export const getPlugins = () : Plugin<ChartType>[] => {
    // Custom plugin to display a message when the graph has no data
    const emptyGraph : Plugin<ChartType> = {
        id: 'emptyGraph', // id must match the options.plugins.[id] to get access to correct options
        afterDraw(chart, _args, options: EmptyGraphOptions) {
            if (chart?.data?.datasets[0]?.data?.length < 1) {
                const { ctx, chartArea: { left, top, right, bottom } } = chart;
                const text = options.text ?? '';

                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = `${css.fontSize1000} Noto IKEA`;
                const centerX = (left + right) / 2;
                const centerY = (top + bottom) / 2;
                ctx.fillText(text, centerX, centerY);
                ctx.restore();
            }
        },
    };

    // Custom plugin to display a custom Y label
    const customYLabel: Plugin<ChartType> = {
        id: 'customYLabel', // id must match the options.plugins.[id] to get access to correct options
        afterDraw: (chart, _args, options: CustomYLabelOptions) => {
            const {
                ctx,
                chartArea: {
                    top,
                }
            } = chart;

            if (options.y.display) {
                ctx.fillStyle = options.y.color;
                if (options.y.font) {
                    ctx.font = `normal ${options.y.font.weight} ${options.y.font.size}px ${options.y.font.family}`;
                }

                const minDistance = 20; // px
                ctx.textAlign = 'start';
                ctx.fillText(options.y.text, options?.y?.offsetX ?? 0, (top + (-1 * (options?.y?.offsetY ?? minDistance))));
            }
        }
    };

    return [emptyGraph, customYLabel];
};

/**
 * Helper object for generateGraphOptions. A collection of primitive option values. Can affect a variety of graph properties.
 */
const optionsPrimitives: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
};

/**
 * Helper function for generateGraphOptions. Creates the options.layout object used by chart js.
 * Controls some basic layout options for the graph
 */
const optionsLayout = ({ padleft }: LayoutOptions) => ({
    padding: {
        top: 40, // px
        left: padleft, // px
    },
});

/**
 * Helper function for generateGraphOptions. Creates the options.interaction object used by chart js.
 * Customizes potential interaction behaviours (hover, click, etc) of the graph
 */
const optionsInteraction = (): _DeepPartialObject<CoreInteractionOptions> => ({
    intersect: false,
    mode: 'index',
});

/**
 * Helper function for generateGraphOptions. Creates the options.scales object used by chart js.
 * Used to customize the look and behaviour of the y and x axes in the graph, as well as the grid lines.
 */
const optionsScales = (
    {
        tickColor,
        weeksThreshold,
        labels,
        mirrorYAxis,
        hasNegativeValues,
        formatterYTicks,
    }: ScalesOptions,
): _DeepPartialObject<{
    [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry>;
}> => ({
    x: {
        stacked: true,
        grid: {
            drawOnChartArea: false,
            display: true,
            drawTicks: true,
            tickLength: 5, // px
            tickWidth: 1, // px
            offset: false,
        },
        ticks: {
            display: true,
            callback(_value, index) {
                if (labels.length <= weeksThreshold) {
                    return labels[index];
                }
                const date : moment.Moment = moment(labels[index]);
                if (date.date() <= DAYS_IN_A_WEEK) {
                    return toIntlMedMonthFullYear(date.toDate());
                }

                return null;
            },
            minRotation: 0, // degrees
            maxRotation: 0, // degrees
            autoSkip: labels.length <= weeksThreshold,
            color: tickColor,
        },
    },
    y: {
        grid: hasNegativeValues ? {
            // Adds color to 0-line if there are negative values
            color(context) {
                if (context.tick.value > 0) {
                    return undefined;
                } if (context.tick.value < 0) {
                    return undefined;
                }

                return '#000000';
            }
        } : { display: false },
        beginAtZero: true,
        title: {
            display: false,
        },
        ticks: formatterYTicks ? {
            callback(value) {
                if (typeof formatterYTicks === 'function') {
                    return `${formatterYTicks(value)}`;
                }

                return value;
            }
        } : undefined,
    },
    // Mirrors the Y axis on the right hand side of the graph. Potential use with horizontal zoom.
    y1: mirrorYAxis ? {
        grid: {
            display: false,
        },
        beginAtZero: true,
        title: {
            display: false,
        },
        position: 'right',
        afterBuildTicks: axis => {
            // eslint-disable-next-line no-param-reassign
            axis.ticks = [...axis.chart.scales.y.ticks];
            // eslint-disable-next-line no-param-reassign
            axis.min = axis.chart.scales.y.min;
            // eslint-disable-next-line no-param-reassign
            axis.max = axis.chart.scales.y.max;
        },
    } : {
        display: false,
    },
});

/**
 * Helper function for optionsPlugins. Creates the plugins.customYLabel object used by by the plugin customYLabel
 * Used to customize the look and placement of the Y label
 */
const optionsPluginsCustomYLabel = ({
    text,
    color,
    offsetX,
    offsetY,
}: PluginOptions['customYLabel']): CustomYLabelOptions => ({
    y: {
        display: true,
        text,
        offsetX, // px
        offsetY, // px
        color,
        font: {
            weight: '700',
            family: 'Noto IKEA',
            size: 12, // px
            lineHeight: 0.5 // fraction
        },
    },
});

/**
 * Helper function for optionsPlugins. Creates the plugins.emptyGraph object used by by the plugin emptyGraph
 * Used to set the text displayed when the graph has no data
 */
const optionsPluginsEmptyGraph = (text: string): EmptyGraphOptions => ({
    text,
});

/**
 * Helper function for optionsPlugins. Creates the plugins.legend object
 * for the chart js config options. Turned off by default, as we use a custom legend.
 */
const optionsPluginsLegend = () => ({
    display: false, // hide built in legend
});

/**
 * Helper function for optionsPlugins. Creates the plugins.tooltip object
 * for the chart js config options. Used to cusomize the look and behaviour of the tooltip.
 */
const optionsPluginTooltip = (
    {
        internal,
        external
    }: PluginOptions['tooltip']
) => {
    if (external) {
        return {
            enabled: false,
            position: 'nearest' as const,
            external: externalTooltipHandler(external)
        };
    }
    if (internal) {
        const { backgroundColor, differenceOptions, titleFormatter, numberFormatter } = internal;

        return {
            usePointStyle: true,
            backgroundColor,
            padding: {
                top: 8, // px
                right: 16, // px
                bottom: 8, // px
                left: 16 // px
            },
            callbacks: {
                title: (tooltipItems: TooltipItem<keyof ChartTypeRegistry>[]) => {
                    const currentTitle = tooltipItems[0].label;

                    return titleFormatter(currentTitle);
                },
                afterBody: (context: Array<unknown>) => {
                    if (!differenceOptions) {
                        return '';
                    }

                    // Extract the value and corresponding label for each dataset in the graph
                    const contextValues = context?.map(d => {
                        if (
                            typeof d !== 'object'
                            || d == null
                            || !('dataset' in d)
                            || typeof d.dataset !== 'object'
                            || d.dataset == null
                            || !('label' in d.dataset)
                            || typeof d.dataset.label !== 'string'
                            || !('raw' in d)
                            || typeof d.raw !== 'number'
                        ) {
                            return null;
                        }

                        return { label: d.dataset.label, value: d.raw };
                    })?.filter((d): d is { label: string, value: number } => d !== null);

                    // Get the value of the minuend and subtrahend using their labels
                    const minuend = contextValues?.find(val => val.label === differenceOptions.labelMinuend)?.value;
                    const subtrahend = contextValues?.find(val => val.label === differenceOptions.labelSubtrahend)?.value;

                    // If one of the values is undefined, do not show anything
                    if (minuend === undefined || subtrahend === undefined) return '';

                    return differenceOptions.differenceFormatter(minuend - subtrahend);
                },
                label: (
                    context: TooltipItem<keyof ChartTypeRegistry>
                ) => `${context.dataset.label}: ${numberFormatter(context.raw as number) ?? context.formattedValue}`

            },
            boxPadding: 8, // px
            cornerRadius: 10, // px
            position: 'nearest' as const,
            intersect: true,
            caretPadding: 10, // px
        };
    }

    return undefined;
};

/**
 * Helper function for generateGraphOptions. Creates the plugins object
 * for the chart js config options
 */
const optionsPlugins = (
    {
        customYLabel,
        emptyGraph,
        tooltip
    }: PluginOptions
): _DeepPartialObject<PluginOptionsByType<keyof ChartTypeRegistry>> => ({
    customYLabel: optionsPluginsCustomYLabel(customYLabel),
    emptyGraph: optionsPluginsEmptyGraph(emptyGraph.text),
    legend: optionsPluginsLegend(),
    tooltip: optionsPluginTooltip(tooltip),
}
);

/**
 * Generates a complete options object used by chart js graph in the creation of a new chart instance
 */
export const generateGraphOptions = (options: ChartGeneratorOptions) => ({
    ...optionsPrimitives,
    layout: optionsLayout(options.layout),
    interaction: optionsInteraction(),
    scales: optionsScales(options.scales),
    plugins: optionsPlugins(options.plugins),
} satisfies ChartOptions);

/**
 * Creates a new chart js chart instance in the node instance,
 * with the given data, plugins and options
 */
export const generateNewChart = (
    {
        node,
        data,
        plugins,
        options,
    }: ChartGenerator
) => new Chart(node, {
    type: 'bar',
    data,
    plugins,
    options,
});
