import Button from '@ingka/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import printer from '@ingka/ssr-icon/paths/printer';

const ExportPlanButton = () => {
    const { t } = useTranslation();

    return (
        <Button type="tertiary" ssrIcon={printer} size="small" text={t('Export Plan')} disabled />
    );
};

export default ExportPlanButton;
