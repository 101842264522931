import { buildUrlGetRecruitmentNeedsApi } from 'routes/api';
import useApp from 'hooks/useApp';
import { RecriutmentNeedsData } from 'types/recruitmentNeeds';
import useAuth from 'hooks/useAuth';
import { useReader } from '../useReader';
import useData from '../useData';

export const useRecruitmentNeeds = () => {
    const { access } = useAuth();
    const { timeSelection } = useApp();
    const { currentScenario, currentCountry, currentUnit, currentUnitType } = useData();

    return useReader<RecriutmentNeedsData>(
        {
            url: buildUrlGetRecruitmentNeedsApi(
                currentCountry ?? '',
                currentUnit ?? '',
                currentUnitType ?? '',
                currentScenario?.id ?? '',
                timeSelection?.startDate ?? '',
                timeSelection?.endDate ?? ''
            ),
            isSuspended: !currentScenario || !access?.api?.readRecruitmentNeeds,
        }
    );
};
