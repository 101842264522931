import { createContext, useState, useMemo, useEffect } from 'react';
import { AZURE_APP_ID, REDIRECT_URI } from 'utils/env';
import { AuthContextValues, UserProfile } from 'types/authContext';
import { createMsalConfig, AuthClient } from '@ingka-group-digital/people-auth';
import API from 'services/api';
import { useToast } from 'hooks/useToast';
import { generateHttpErrorMessage } from 'utils/log';
import { sortBy } from 'utils/sorting';
import { getAccessRoutes } from './accessRoutes';
import { getAccessFeatures } from './accessFeatures';
import { getAPIPermissions } from './apiPermissions';

const AuthContext = createContext<AuthContextValues>({} as AuthContextValues);
const tokenScopes = ['User.Read', 'openid', 'profile', 'Group.Read.All'];
const config = createMsalConfig(AZURE_APP_ID, REDIRECT_URI);
const authClient = AuthClient(config, tokenScopes);

const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [countries, setCountries] = useState<any>(null);
    const { displayToast } = useToast();

    useEffect(() => {
        /**
         * In essence, the authentication flow is as follows:
         * 1. Login with msal client
         * 2. The MSAL client sets up a user session
         * 3. Now we can use the MSAL client to get the access token
         * 4. Use the access token to call the API (in this case, our authorization API)
         * All API calls will be authenticated using the access token obtained from the MSAL client
         * see callApi function in src/services/api.ts
         */
        authClient.login()
            .then(async () => {
                API().getAuthorization()
                    .then(auth => {
                        setUserProfile(auth.userProfile);
                        setCountries(auth.countries.sort(sortBy('name', 'ASC')));
                    })
                    .catch(err => {
                        setError(err);
                        generateHttpErrorMessage(err);
                        displayToast({ title: 'ERROR', message: 'AUTHORIZATION_FAILED' });
                    })
                    .finally(() => setLoading(false));
            })
            .catch(err => {
                displayToast({ title: 'ERROR', message: 'AUTHENTICATION_FAILED' });
                generateHttpErrorMessage(err);
                setError(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contextValues: AuthContextValues = useMemo(
        () => ({
            authClient,
            user: userProfile,
            access: {
                api: getAPIPermissions(userProfile),
                routes: getAccessRoutes(userProfile),
                features: getAccessFeatures(userProfile),
            },
            isLoading,
            error,
            countriesList: countries,
        }),
        [userProfile, isLoading, error, countries],
    );

    return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
