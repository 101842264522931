import { EditableContractHoursCellProps } from 'types/appContext';
import { DummyCoworker } from 'types/scenario';
import { formatToSignedNumber, roundDisplayValueDoubleDecimal } from 'utils/number';
import useData from 'hooks/useData';
import useApp from 'hooks/useApp';
import { getContractHoursRange } from 'components/CoworkerDetails/helpers';
import { getGreyedOutCell } from 'components/GreyedOutCell/helpers';
import { useMemo } from 'react';
import { ModulationRange } from 'types/modulation';
import { RangeStatus } from 'types/enums';
import { Coworker } from 'types/coworker';
import CoworkerNameCell from './CoworkerNameCell';
import classes from './TotalHoursRow.module.scss';
import { ContractDetails } from './ContractDetails';

const TotalHoursRow = ({
    coworker,
    showAllDetails,
    showIndividualDetails,
    cellProps,
    averageHrs,
    costCentre,
    handleClickDetails,
    handleClickModulation,
    isPrimaryRow,
    isActive,
    totalHours,
    contractStartDate,
    contractEndDate,
    modulationRange,
}: {
    coworker: Coworker | DummyCoworker,
    showAllDetails: boolean,
    showIndividualDetails: boolean,
    cellProps: Array<EditableContractHoursCellProps>,
    averageHrs: number,
    totalHours: {
        contractHours: number,
        modulationHoursBalance?: number,
    },
    costCentre: string,
    handleClickDetails: () => void,
    handleClickModulation: () => void,
    isPrimaryRow: boolean,
    isActive: boolean,
    contractStartDate: string,
    contractEndDate: string,
    modulationRange: ModulationRange | undefined,
}) => {
    const { currentScenario } = useData();
    const { timeSelection } = useApp();

    const cells = useMemo(
        () => cellProps.map((cProps, index) => {
            const { currentValue, key } = cProps;
            const contractAbsentCell = getGreyedOutCell(
                contractStartDate,
                contractEndDate,
                timeSelection.timeArray,
                index,
                key,
            );

            if (contractAbsentCell !== 'outsideRange') {
                return contractAbsentCell;
            }

            return (
                <td className={classes['table-row__cell']} key={key}>
                    <span data-testid="editable-cell-span">
                        {currentValue}
                    </span>
                </td>

            );
        }).filter(el => el.toString() !== RangeStatus.OUTSIDE_RANGE && el.toString() !== RangeStatus.WITHIN_RANGE),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cellProps]
    );

    return (
        <tr className={(coworker.employmentStatus === 'active' || currentScenario) ? '' : classes.inactive}>
            {isPrimaryRow ? (
                <td>
                    <div className={classes['complex-row-cell']}>
                        <CoworkerNameCell
                            coworker={coworker}
                            handleClickCoworker={handleClickDetails}
                            handleClickModulation={handleClickModulation}
                            key={coworker.personId}
                            isActive={isActive}
                            showModulationIcon={!!modulationRange}
                        />
                        <div data-id="contract-hours">
                            {
                                !coworker.contractRange
                                    ? roundDisplayValueDoubleDecimal(coworker.hoursPerWeek ?? 0)
                                    : getContractHoursRange(coworker as Coworker)
                            }
                        </div>
                    </div>
                </td>

            ) : (<td className={coworker.isDummy && isPrimaryRow ? classes['coworker-name-dummy'] : ''} />)}
            {showAllDetails && (!showIndividualDetails
                ? (
                    <ContractDetails
                        divisionName={coworker.divisionName}
                        costCentre={costCentre}
                    />
                )
                : (
                    <>
                        <td className={classes['table-row__empty-cell']} />
                        <td className={classes['table-row__empty-cell']} />
                        <td className={classes['table-row__empty-cell']} />
                        <td />
                    </>
                )
            )}
            {cells}
            <td>
                <div className={classes['table-row__footer']}>
                    {(totalHours.modulationHoursBalance || totalHours.modulationHoursBalance === 0) && currentScenario
                        && (
                            <div className={`${classes['table-row__footer__cell']} 
                            ${totalHours.modulationHoursBalance !== 0 ? classes['table-row__error'] : ''}`}
                            >
                                {formatToSignedNumber(Number(roundDisplayValueDoubleDecimal(totalHours.modulationHoursBalance)))}
                            </div>
                        )}
                    <div className={classes['table-row__footer__cell']}>{roundDisplayValueDoubleDecimal(averageHrs)}</div>
                    <div className={classes['table-row__footer__cell']}>{roundDisplayValueDoubleDecimal(totalHours.contractHours)}</div>
                </div>
            </td>
        </tr>
    );
};

export default TotalHoursRow;
