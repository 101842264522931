import { UserAccessRoutes, UserPermission, UserProfile } from 'types/authContext';

export const noUserRoutesAccess: UserAccessRoutes = {
    capacityOverview: false,
    manageCapacity: false,
    capacityActionPlan: false,
    recruitmentNeeds: false,
    configuration: false,
};

const hasCapacityOverviewAccess = (userProfile: UserProfile | null) => !!userProfile?.rolePermissions.includes(UserPermission.VIEW_CAPACITY_OVERVIEW);

const hasManageCapacityAccess = (userProfile: UserProfile | null) => (!!userProfile?.rolePermissions.includes(UserPermission.VIEW_MANAGE_CAPACITY));

const hasActionPlanAccess = (userProfile: UserProfile | null) => (!!userProfile?.rolePermissions.includes(UserPermission.VIEW_ACTION_PLAN));

const hasRecruitmentNeedsAccess = (userProfile: UserProfile | null) => (!!userProfile?.rolePermissions.includes(UserPermission.VIEW_RECRUITMENT_NEEDS));

const hasConfigurationPageAccess = (userProfile: UserProfile | null) => (!!userProfile?.rolePermissions.includes(UserPermission.VIEW_CONFIGURATION));

/**
 * Handles whether the user, given the current environment and role, has access to each route on the site
 */
export const getAccessRoutes = (userProfile: UserProfile | null): UserAccessRoutes => {
    if (!userProfile) return noUserRoutesAccess;

    return {
        capacityOverview: hasCapacityOverviewAccess(userProfile),
        manageCapacity: hasManageCapacityAccess(userProfile),
        capacityActionPlan: hasActionPlanAccess(userProfile),
        recruitmentNeeds: hasRecruitmentNeedsAccess(userProfile),
        configuration: hasConfigurationPageAccess(userProfile),
    };
};
