import React, { memo, useMemo, useState } from 'react';
import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import plus from '@ingka/ssr-icon/paths/plus';
import useData from 'hooks/useData';
import Tooltip from '@ingka/tooltip';
import { CREATE_SCENARIO_BUTTON__CLICK } from 'types/analytics';
import Modal, { Sheets, ModalBody, ModalFooter, ModalHeader } from '@ingka/modal';
import RadioButtonGroup from '@ingka/radio-button-group';
import { Controller, useForm } from 'react-hook-form';
import { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TFunction } from 'i18next';
import { ScenarioList } from 'types/scenario';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import useAuth from 'hooks/useAuth';
import { UserPermission } from 'types/authContext';
import { useCreateScenarioAPI } from 'hooks/api/useCreateScenarioAPI';
import { useToast } from 'hooks/useToast';
import { useListener } from 'hooks/useListener';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import Text from '@ingka/text';
import classes from './AddScenario.module.scss';

const getValidationSchema = (
    t: TFunction<'translation', undefined>,
    scenarioList: ScenarioList | undefined,
    hasCreateMasterScenarioAccess: boolean
) => zod.object({
    scenarioTypes: zod.object({
        genericScenario: zod.boolean(),
        masterScenario: zod.boolean(),
    }).refine(
        value => {
            if (!hasCreateMasterScenarioAccess) {
                return true;
            }

            return value.genericScenario || value.masterScenario;
        },
        { message: t('SELECT_SCENARIO_TYPE') }
    ),
    scenarioName: zod.string().min(1, { message: t('SCENARIO_NAME_MISSING') })
        .refine(
            value => !scenarioList?.scenarios?.map(scenario => scenario.name.toLowerCase()).includes(value.toLowerCase()),
            { message: t('SCENARIO_NAME_EXISTS_SELECT_NEW') }
        )
});

const AddScenario = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<1 | 2>(1);
    const { t } = useTranslation();
    const { currentCountry, currentUnit, scenarioList, setScenarioBudget } = useData();
    const isDisabled = !(currentCountry && currentUnit);
    const handleClick = () => { setIsModalOpen(true); };
    const { user, access } = useAuth();
    const hasCreateMasterScenarioAccess = user?.rolePermissions.includes(UserPermission.CREATE_MASTER_SCENARIO) ?? false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validationSchema = useMemo(() => getValidationSchema(t, scenarioList, hasCreateMasterScenarioAccess), [scenarioList, hasCreateMasterScenarioAccess]);
    const { writeData: createScenario } = useCreateScenarioAPI();
    const { displayToast } = useToast();

    const {
        handleSubmit,
        formState: { errors, isSubmitting, isValidating, isValid, isDirty, touchedFields },
        control,
        reset,
        setValue,
        getValues,
        trigger,
    } = useForm<zod.infer<typeof validationSchema>>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            scenarioTypes: {
                genericScenario: true,
                masterScenario: false,
            },
            scenarioName: ''
        },
        resolver: zodResolver(validationSchema),
    });

    const onSubmit = async (formState: zod.infer<typeof validationSchema>) => {
        if (errors.scenarioName || errors.scenarioTypes) {
            trigger();

            return;
        }

        if (activeStep === 1 && formState.scenarioTypes.masterScenario) {
            setActiveStep(2);

            return;
        }
        const hasScenarioAPIAccess = formState.scenarioTypes.masterScenario ? access?.api?.createMasterScenario : access?.api.createScenario;
        if (hasScenarioAPIAccess) {
            createScenario({ name: formState.scenarioName, isMain: false }).then(response => {
                if (!response.isResponseOk) {
                    displayToast({ title: t('ERROR'), message: t('SCENARIO_SAVE_FAILED') });

                    return;
                }

                displayToast({ title: t('SUCCESS'), message: t('SCENARIO_SAVE_SUCCEEDED') });
                setScenarioBudget(false);
                setIsModalOpen(false);
                reset();
            });
        }
        setActiveStep(1);
    };

    const handleClose = () => {
        setIsModalOpen(false);
        reset();
    };

    // Handle enter key event
    useListener('keydown', 'Enter', () => {
        if (isValid && isDirty) {
            const formState = getValues();
            if (activeStep === 1 && formState.scenarioTypes.masterScenario) {
                setActiveStep(2);

                return;
            }
            handleSubmit(() => onSubmit(formState));
        }
    });

    return (
        <span data-testid="add-scenario-button-span">
            <Tooltip tooltipText={isDisabled ? t('SELECT_A_UNIT_FIRST') : t('NEW_SCENARIO')}>
                <Button
                    data-testid="add-scenario-button"
                    type="primary"
                    ssrIcon={plus}
                    onClick={handleClick}
                    size="small"
                    iconOnly
                    disabled={isDisabled}
                    data-analytics={CREATE_SCENARIO_BUTTON__CLICK}
                />
            </Tooltip>

            <Modal
                visible={isModalOpen}
                handleCloseBtn={handleClose}
                escapable
                onModalClosed={() => reset()}
                focusLockProps={{ locked: false }}
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={<ModalHeader title={t('CREATE_NEW_SCENARIO')} backBtnClick={handleClose} className={classes['add-scenario__header']} />}
                    footer={(
                        <ModalFooter>
                            <Button
                                onClick={handleClose}
                                text={t('CANCEL')}
                                type="secondary"
                                size="small"
                            />
                            <Button
                                onClick={() => { onSubmit(getValues()); }}
                                text={t('SAVE')}
                                type="primary"
                                size="small"
                                loading={isValidating || isSubmitting}
                            />

                        </ModalFooter>
                    )}
                >
                    <form onSubmit={handleSubmit(onSubmit)} data-testid="add-scenario-form">
                        <ModalBody>
                            <Text data-testid="add-scenario-header-info">
                                {t('SCENARIO_CREATED_FOR', { unitCode: currentUnit })}
                            </Text>
                            {hasCreateMasterScenarioAccess
                            && (
                                <Controller
                                    control={control}
                                    name="scenarioTypes"
                                    render={({
                                        field: { value, name }
                                    }) => (
                                        <FormField
                                            shouldValidate={touchedFields.scenarioTypes?.genericScenario || touchedFields.scenarioTypes?.masterScenario}
                                            valid={!errors.scenarioTypes?.message}
                                            validation={{
                                                msg: errors.scenarioTypes?.message,
                                            }}
                                        >
                                            <RadioButtonGroup
                                                name={t('SELECT_SCENARIO_TYPE')}
                                                list={[
                                                    {
                                                        id: t('GENERIC_SCENARIO'),
                                                        name,
                                                        label: t('GENERIC_SCENARIO'),
                                                        disabled: false,
                                                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setValue(
                                                                'scenarioTypes.genericScenario',
                                                                event.target.checked,
                                                                {
                                                                    shouldValidate: true,
                                                                    shouldTouch: true,
                                                                    shouldDirty: true
                                                                }
                                                            );

                                                            setValue(
                                                                'scenarioTypes.masterScenario',
                                                                !event.target.checked,
                                                                {
                                                                    shouldValidate: true,
                                                                    shouldTouch: true,
                                                                    shouldDirty: true
                                                                }
                                                            );
                                                        },
                                                        value: value.genericScenario.toString(),
                                                        defaultChecked: true,
                                                    },
                                                    {
                                                        id: t('MASTER_SCENARIO'),
                                                        name,
                                                        label: t('MASTER_SCENARIO'),
                                                        disabled: false,
                                                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setValue(
                                                                'scenarioTypes.masterScenario',
                                                                event.target.checked,
                                                                {
                                                                    shouldValidate: true,
                                                                    shouldTouch: true,
                                                                    shouldDirty: true
                                                                }
                                                            );

                                                            setValue(
                                                                'scenarioTypes.genericScenario',
                                                                !event.target.checked,
                                                                {
                                                                    shouldValidate: true,
                                                                    shouldTouch: true,
                                                                    shouldDirty: true
                                                                }
                                                            );
                                                        },
                                                        value: value.masterScenario.toString(),
                                                    },
                                                ]}
                                                className={classes['add-scenario__select-scenario-type']}
                                            />
                                        </FormField>
                                    )}
                                />
                            )}
                            <Controller
                                control={control}
                                name="scenarioName"
                                render={({
                                    field: { onChange, onBlur }
                                }) => (
                                    <FormField
                                        shouldValidate={touchedFields.scenarioName}
                                        valid={!errors.scenarioName}
                                        validation={{
                                            msg: errors.scenarioName?.message,
                                        }}
                                    >
                                        <InputField
                                            label={t('ENTER_SCENARIO_NAME')}
                                            id="scenarioNameField"
                                            type="text"
                                            className={classes['add-scenario__input-scenario-name']}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            data-testid="add-scenario-form-input"
                                            autoFocus
                                        />
                                    </FormField>
                                )}
                            />
                        </ModalBody>
                    </form>
                </Sheets>
            </Modal>
            <ConfirmationModal
                isOpen={activeStep === 2}
                title={t('CREATE_MASTER_SCENARIO')}
                cancelButtonText={t('CANCEL')}
                confirmButtonText={t('CREATE')}
                content={t('CREATE_MASTER_SCENARIO_DESCRIPTION')}
                handleCancel={() => { setActiveStep(1); }}
                handleConfirm={() => { onSubmit(getValues()); }}
            />
        </span>

    );
};

export default memo(AddScenario);
