import React, { useState } from 'react';
import { EmploymentType } from 'types/scenario';

const useEmploymentType = () => {
    const defaultEmploymentType = EmploymentType.COWORKER;

    const [employmentType, setEmploymentType] = useState<EmploymentType>(defaultEmploymentType);

    const handleChangeEmploymentType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // Note: can only select from employmentTypeOptions, and has a valid default value.
        // no validation needed.
        setEmploymentType(e.target.value as EmploymentType);
    };

    return { employmentType, handleChangeEmploymentType };
};

export default useEmploymentType;
