import { useCallback, useEffect, useRef, useState } from 'react';

// Allows to use refs in useEffect dependency array
const useRefWithCallback = <T>() => {
    const [htmlElement, setHtmlElement] = useState<T | null>(null);

    useEffect(() => (() => setHtmlElement(null)));

    const ref = useRef<T | null>(null);
    const setRef = useCallback((node: T | null) => {
        if (node) {
            setHtmlElement(node);
        }
        ref.current = node;

        return ref;
    }, []);

    return { ref: htmlElement, setRef };
};

export default useRefWithCallback;
