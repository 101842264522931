import { TypeAdditionalHours } from 'types/actionPlan';
import { useTranslation } from 'react-i18next';
import Text from '@ingka/text';
import classes from '../ActionTable.module.scss';

const AdditionalHoursAction = ({ action }:{ action: TypeAdditionalHours }) => {
    const { t } = useTranslation();
    const delta = Number(action.from) - Number(action.to);

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-additional-hours-header"
                >
                    {t('ADDITIONAL_HOURS')}
                </Text>
                <Text
                    data-testid="action-plan-additional-hours-change"
                >
                    {delta < 0 ? `+${delta} ${t('HOURS_ABBREVIATED')}` : `-${delta} ${t('HOURS_ABBREVIATED')}`}
                </Text>
            </div>
        </div>
    );
};

export default AdditionalHoursAction;
