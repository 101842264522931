import { CSSProperties, ReactElement, memo } from 'react';
import Text from '@ingka/text';
import classes from './SectionHeader.module.scss';

export const SectionHeader = memo((
    {
        title,
        description,
        style,
        children
    } : {
        title: string,
        description: string,
        style?: CSSProperties,
        children?: ReactElement
    }
) => (
    <div className={classes['top-header']} style={style || undefined}>
        <div>
            <Text tagName="h2" headingSize="m" className={classes['top-header-title']}>{title}</Text>
            <Text tagName="span" bodySize="s" className={classes['top-header-description']}>{description}</Text>
        </div>

        {children}
    </div>
));
