import { useTranslation } from 'react-i18next';
import { SectionHeader } from 'components/SectionHeader/SectionHeader';
import Text from '@ingka/text';
import css from '@ingka/variables';
import { ActionPlanFilterBar } from 'components/ActionPlanFilterBar/ActionPlanFilterBar';
import useActionPlanContext from 'hooks/useActionPlanContext';
import CapacityActionTable from 'components/CapacityActionPlan/CapacityActionTable';
import { ContractChangeType } from 'types/actionPlan';
import { TFunction } from 'i18next';
import useData from 'hooks/useData';
import useApp from 'hooks/useApp';
import { CheckBox } from 'types/appContext';
import classes from './CapacityActionPlan.module.scss';

export const getSectionTitleAndSubtitle = (activeFilter: ContractChangeType | null, t: TFunction<'translation', undefined, 'translation'>) => {
    switch (activeFilter) {
    case ContractChangeType.CONTRACT_HOURS:
        return { title: t('CONTRACT_HOURS'), subtitle: t('CONTRACT_HOURS_SUBTITLE') };
    case ContractChangeType.ADDITIONAL_HOURS:
        return { title: t('ADDITIONAL_HOURS'), subtitle: t('ADDITIONAL_HOURS_SUBTITLE') };
    case ContractChangeType.MODULATION:
        return { title: t('MODULATION'), subtitle: t('MODULATION_SUBTITLE') };
    default:
        return { title: t('ALL_CHANGES'), subtitle: t('ALL_CHANGES_SUBTITLE') };
    }
};
export const CapacityActionPlanContent = () => {
    const { t } = useTranslation();
    const { activeFilter } = useActionPlanContext();
    const { title, subtitle } = getSectionTitleAndSubtitle(activeFilter, t);
    const { currentScenario, currentCountry, currentUnit } = useData();
    const { filteredDivisionOMData, filteredCostCenterOMData } = useApp();
    const getSelectedElements = (data: Array<CheckBox> | undefined, mapFn: (item: CheckBox) => string): string[] | '' => (
        data?.some(item => item.defaultChecked)
            ? data?.filter(item => item.defaultChecked).map(mapFn)
            : data?.map(mapFn) || '');
    const selectedDivisions = getSelectedElements(filteredDivisionOMData, (item: any) => item.label);

    const selectedCostCenters = getSelectedElements(filteredCostCenterOMData, costCenter => `${costCenter.subLabel ?? ''} ${costCenter.label ?? ''}`);

    return (
        <div data-testid="capacity-action-plan-page" id="capacity-action-plan-page" className={classes['table-wrapper']}>
            <ActionPlanFilterBar />
            <SectionHeader
                title={title}
                description={subtitle}
                style={{ paddingBottom: css.space100 }}
            />
            {currentCountry && currentUnit && currentScenario && (
            <div className={classes['print-only']}>
                <Text tagName="p" headingSize="xs">{`${t('COUNTRY')}: ${currentCountry}`}</Text>
                <Text tagName="p" headingSize="xs">{`${t('UNIT')}:  ${currentUnit}`}</Text>
                <Text tagName="p" headingSize="xs">
                    {`${t('BUSINESS_FUNCTION')}:  ${selectedDivisions}`}
                </Text>
                <Text tagName="p" headingSize="xs">
                    {`${t('COST_CENTERS')}:  ${selectedCostCenters}`}
                </Text>
                <Text tagName="p" headingSize="xs">{`${t('SCENARIO_NAME')}:  ${currentScenario?.name || ''}`}</Text>
            </div>
            )}
            <CapacityActionTable />
        </div>
    );
};
