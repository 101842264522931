import { PageContainer } from 'components';
import Configuration from 'components/Configuration/Configuration';
import React from 'react';
import { ROUTE_CONFIGURATION } from 'types/routes';

const ConfigurationRoute = () => (
    <PageContainer pageAnalyticsId={ROUTE_CONFIGURATION} isFullWidth align="horizontal">
        <Configuration />
    </PageContainer>
);

export default ConfigurationRoute;
