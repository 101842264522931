import { ContractType, EmploymentType, ScenarioDeltaType } from './scenario';
import { TypeCoworkerActionPlan } from './actionPlan';
import { ContractRange, EmploymentStatus } from './coworker';
import { SupportedCountry } from './configuration';
import { SharedModulationData } from './modulation';

export enum ScenarioUpdateSource {
    MANAGE_CAPACITY_TABLE = 'MANAGE_CAPACITY_TABLE',
    CONTRACT_CHANGES_MODAL = 'CONTRACT_CHANGES_MODAL',
    NEW_COWORKER_MODAL = 'NEW_COWORKER_MODAL'
}
export type TypeScenarioUpdateRequestAPI = {
    source: ScenarioUpdateSource,
    personId: string,
    coworkerName: string,
    division: string,
    costCentre: string,
    department: string | null,
    contractType: ContractType,
    dummyCoworker: {
        dummyName: string,
        employmentType: EmploymentType,
        employmentStatus: EmploymentStatus,
        buName: string
    } | null,
    startDate: string,
    endDate: string | null,
    contractHours: number | ContractRange,
    delta: ScenarioDeltaType[],
};

export type TypeCreateScenarioRequestAPI = {
    name: string,
    isMain : boolean,
};

export type TypeUpdateScenarioMainStateRequestAPI = {
    id: string,
    isMain : boolean,
};

export type TypeUpdateScenarioMainStateResponseAPI = {
    id: string,
    name : string,
    updatedBy : string,
    updatedAt : string,
};

export type TypeCopyScenarioRequestAPI = {
    name: string,
    sourceScenarioId: number
};

export type TypeCreateScenarioResponseAPI = {
    id: number,
    isMain : boolean,
};

export type TypeCopyScenarioResponseAPI = {
    id: number
};

export type TypeCoworkerNoteRequest = {
    personId: string,
    note: string,
};

export type TypeActionPlanAPIResponse = TypeCoworkerActionPlan[];

/**
 * Ranges used to sort coworkers into contract mix categories.
 * min === null means down to 0.
 * max === null means up to infinity.
 * Max is non-inclusive, so (min <= x < max).
 */
export type OpenEndedRange = {
    max: number,
    min: null,
} | {
    min: number,
    max: null,
} | {
    min: number,
    max: number,
};

type ContractRangeConfigurations = {
    contractRanges: Array<ContractRange>,
    isContractRangeSupported: true,
};
type ContractHoursConfigurations = {
    contractRanges: Array<OpenEndedRange>,
    isContractRangeSupported: false,
};

export type ConfigurationData = (ContractRangeConfigurations | ContractHoursConfigurations) & {
    country: keyof typeof SupportedCountry,
    isModulationSupported: boolean,
    isAdditionalHoursSupported: boolean,
    businessUnitCode: string,
    modulation: {
        unit: SharedModulationData & {
            costCentres: Array<SharedModulationData> | never[],
            departments: Array<SharedModulationData> | never[],
            divisions: Array<SharedModulationData> | never[]
            eligibility: {
                all: boolean
            }
        },
    }
};
export enum HTTPHeader {
    AUTHORIZATION = 'Authorization',
    CONTENT_TYPE = 'Content-Type'
}
export enum HTTPMethod { GET = 'GET', POST = 'POST', PUT = 'PUT', DELETE = 'DELETE', PATCH = 'PATCH', HEAD = 'HEAD', OPTIONS = 'OPTIONS'}
export enum HTTPStatusCode {
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    NO_CONTENT = 204,
    MOVED_PERMANENTLY = 301,
    FOUND = 302,
    NOT_MODIFIED = 304,
    TEMPORARY_REDIRECT = 307,
    PERMANENT_REDIRECT = 308,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    NOT_ACCEPTABLE = 406,
    REQUEST_TIMEOUT = 408,
    CONFLICT = 409,
    GONE = 410,
    INTERNAL_SERVER_ERROR = 500,
    NOT_IMPLEMENTED = 501,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    GATEWAY_TIMEOUT = 504,
    HTTP_VERSION_NOT_SUPPORTED = 505
}
