import { buildUrlUpdateScenarioApi } from 'routes/api';
import { TypeScenarioNameRequestAPI } from 'types/scenario';
import { useWriter } from '../useWriter';
import useData from '../useData';

export const useUpdateScenarioNameAPI = (scenarioId?: string) => {
    const { currentScenario, currentCountry, currentUnit, currentUnitType } = useData();

    const { writeData, ...response } = useWriter<TypeScenarioNameRequestAPI, null>({
        url: buildUrlUpdateScenarioApi(scenarioId ?? currentScenario?.id ?? '', currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
    });

    return {
        ...response,
        updateScenarioName: writeData
    };
};
