import React from 'react';
import { useTranslation } from 'react-i18next';
import { GapHoursData } from 'types/table';
import { roundDisplayValueDoubleDecimal } from 'utils/number';
import { getCapacityHours,
    getOtherAbsencePercentage,
    getSicknessPercentage,
    getVacationPercentage } from 'hooks/useGrid/gridFunctionsShared';
import Detail from './Detail';
import classes from './HoverDetails.module.scss';

const ContentGap = ({ hoverData }: { hoverData: GapHoursData }) => {
    const { t } = useTranslation();

    return (
        <>
            <Detail property={t('TARGET_CAPACITY')} value={hoverData.targetCapacity} />
            <Detail property={t('CAPACITY_HOURS')} value={getCapacityHours(hoverData)} />
            <div className={classes.tablehover__grid__gap}>
                <span className={classes.tablehover__grid__property}>{t('COWORKER_HOURS')}</span>
                <span className={classes.tablehover__grid__percentage} />
                <span data-testid="total-gap-hover-coworkerHours" className={classes.tablehover__grid__value}>
                    {roundDisplayValueDoubleDecimal(hoverData.coworkerHours)}
                </span>

                <span className={classes.tablehover__grid__property}>{t('VACATION')}</span>
                <span data-testid="total-gap-hover-vacationPercentage" className={classes.tablehover__grid__percentage}>
                    {`${roundDisplayValueDoubleDecimal((getVacationPercentage(hoverData)))}%`}
                </span>
                <span data-testid="total-gap-hover-vacationHours" className={classes.tablehover__grid__value}>
                    {hoverData.vacationHours ? '-' : ''}
                    {roundDisplayValueDoubleDecimal(hoverData.vacationHours)}
                </span>

                <span className={classes.tablehover__grid__property}>{t('SICKNESS')}</span>
                <span data-testid="total-gap-hover-sicknessPercentage" className={classes.tablehover__grid__percentage}>
                    {`${roundDisplayValueDoubleDecimal((getSicknessPercentage(hoverData)))}%`}
                </span>
                <span data-testid="total-gap-hover-sicknessHours" className={classes.tablehover__grid__value}>
                    {hoverData.sicknessHours ? '-' : ''}
                    {roundDisplayValueDoubleDecimal(hoverData.sicknessHours)}
                </span>

                <span className={classes.tablehover__grid__property}>{t('OTHER_ABSENCE')}</span>
                <span data-testid="total-gap-hover-otherAbsencePercentage" className={classes.tablehover__grid__percentage}>
                    {`${roundDisplayValueDoubleDecimal(((getOtherAbsencePercentage(hoverData))))}%`}
                </span>
                <span data-testid="total-gap-hover-otherAbsenceHours" className={classes.tablehover__grid__value}>
                    {hoverData.otherAbsenceHours ? '-' : ''}
                    {roundDisplayValueDoubleDecimal(hoverData.otherAbsenceHours)}
                </span>
            </div>
        </>
    );
};

export default ContentGap;
