import React, { useState } from 'react';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { Trans, useTranslation } from 'react-i18next';
import Modal, { Sheets, ModalFooter, ModalHeader, ModalBody } from '@ingka/modal';
import { Scenario, ScenarioList } from 'types/scenario';
import InlineMessage from '@ingka/inline-message';
import useData from 'hooks/useData';
import informativeCircle from '@ingka/ssr-icon/paths/information-circle';
import CreateMainScenario from 'components/ManageScenario/CreateMainScenario';
import RadioButton from '@ingka/radio-button';
import { useDeleteScenarioAPI } from 'hooks/api/useDeleteScenarioAPI';
import { useUpdateScenarioMainStateAPI } from 'hooks/api/useUpdateScenarioMainStateAPI';
import { useToast } from 'hooks/useToast';
import Hyperlink from '@ingka/hyperlink';
import classes from './ManageScenarios.module.scss';

const createRadioButtonProps = (item: Scenario, selectedScenarioID: string, callback : React.Dispatch<React.SetStateAction<string | undefined>>) => ({
    id: item.id,
    key: item.id,
    value: item.id,
    checked: item.id === selectedScenarioID,
    label: (item.name),
    onChange: () => {
        callback(item.id);
    },
});

const RemoveMainScenario = (
    { deletedScenario, modalVisibility, setModalVisibility }:
    { deletedScenario : Scenario, modalVisibility : boolean, setModalVisibility : React.Dispatch<React.SetStateAction<boolean>> }
) => {
    const { currentUnit, scenarioList, currentScenario, setScenarioBudget, selectScenario, setScenarioList } = useData();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [selectedScenarioID, setSelectedScenarioID] = useState<string | undefined>();
    const { t } = useTranslation();
    const { displayToast } = useToast();

    const { deleteScenario } = useDeleteScenarioAPI(deletedScenario?.id ?? '');
    const { updateScenarioMainState } = useUpdateScenarioMainStateAPI();

    const handleClose = () => {
        setModalVisibility(false);
    };

    const handleDeleteMainScenario = (() => {
        if (!scenarioList?.scenarios?.length || !selectedScenarioID) {
            return;
        }

        deleteScenario().then(response => {
            if (response.error) {
                displayToast({ title: t('ERROR'), message: t('OPERATION_FAILED') });

                return;
            }

            if (Number(deletedScenario?.id) === Number(currentScenario?.id)) {
                selectScenario('noScenario');
                setScenarioBudget(false);
            }

            const clonedScenarioList : ScenarioList = JSON.parse(JSON.stringify(scenarioList));
            clonedScenarioList.scenarios = clonedScenarioList.scenarios.filter(scenario => Number(scenario.id) !== Number(deletedScenario?.id));

            if (selectedScenarioID) {
                updateScenarioMainState({
                    id: selectedScenarioID,
                    isMain: true
                }).then(updateResponse => {
                    if (updateResponse.error) {
                        displayToast({ title: t('ERROR'), message: t('OPERATION_FAILED') });
                    }
                    let updateScenarioName = '';
                    clonedScenarioList.scenarios.forEach(item => {
                        if (item.id === selectedScenarioID) {
                            const newMainItem = item;
                            newMainItem.isMain = true;
                            updateScenarioName = newMainItem.name;
                        }

                        return item;
                    });

                    displayToast({
                        title: t(''),
                        message: t('MAIN_SCENARIO_CREATION_SUCCESSFUL', { scenarioName: updateScenarioName, unitCode: currentUnit })
                    });
                }).catch(() => {
                    displayToast({ title: t('ERROR'), message: t('OPERATION_FAILED') });
                }).finally(() => {
                    setScenarioList(
                        {
                            scenarios: [
                                ...clonedScenarioList.scenarios,
                            ]
                        }
                    );
                });
            }
        }).finally(() => {
            handleClose();
        });
    });

    return (
        <div>
            <Modal
                visible={modalVisibility}
                handleCloseBtn={handleClose}
                escapable
                onModalClosed={() => handleClose}
                focusLockProps={{ locked: false }}
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={<ModalHeader title={t('MAIN_SCENARIO_REMOVE_HEADER')} backBtnClick={handleClose} className={classes['add-scenario__header']} />}
                    footer={(
                        <ModalFooter>
                            <Button
                                onClick={handleClose}
                                text={t('CANCEL')}
                                type="secondary"
                                size="small"
                                data-testid="manage-scenario-cancel-main-scenario-button"
                            />
                            <Button
                                onClick={handleDeleteMainScenario}
                                text={t('SAVE_AND_REMOVE_TITLE')}
                                type="primary"
                                size="small"
                                loading={false}
                                disabled={!selectedScenarioID}
                                data-testid="manage-scenario-remove-main-scenario-button"
                            />
                        </ModalFooter>
                    )}
                >
                    <ModalBody>
                        <div className={classes['remove-main-scenario']} data-testid="remove-main-scenario">
                            <div>
                                <InlineMessage
                                    data-testid="pick-another-scenario-inline-message"
                                    ssrIcon={informativeCircle}
                                    title={`${t('DELETING_MAIN_SCENARIO_INFORMATIVE_TITLE_MSG')}`}
                                    body={t('DELETING_MAIN_SCENARIO_MESSAGE', { unitCode: currentUnit })}
                                    dismissable={false}
                                    subtle={false}
                                />
                            </div>
                            <div className={classes['remove-main-scenario__checkboxes-container']}>
                                <div className={classes['remove-main-scenario__checkboxes-header']}>
                                    <Text headingSize="xs">
                                        {t('MAIN_SCENARIO_NEW_SELECT', { unitCode: currentUnit })}
                                    </Text>
                                </div>
                                {scenarioList?.scenarios
                                    ?.filter(item => item.id !== deletedScenario?.id)
                                    .map(item => (
                                        <div
                                            className={classes['remove-main-scenario__checkbox']}
                                            key={`remove-scenario-checkbox-${item.id}`}
                                        >
                                            <RadioButton
                                                {...createRadioButtonProps(
                                                    item,
                                                    selectedScenarioID ?? '',
                                                    setSelectedScenarioID,
                                                )}
                                            />
                                        </div>
                                    ))}
                            </div>
                            <div className={classes['remove-main-scenario__create-link']}>
                                <Trans
                                    i18nKey={t('OR_CREATE_NEW_MAIN_SCENARIO')}
                                    components={[
                                        <Hyperlink
                                            button
                                            key="create-new-main-scenario-link"
                                            onClick={
                                                () => {
                                                    setIsCreateModalOpen(true);
                                                }
                                            }
                                        />
                                    ]}
                                />
                            </div>
                        </div>
                    </ModalBody>
                </Sheets>
            </Modal>
            <CreateMainScenario
                checkedBox
                isModalOpen={isCreateModalOpen}
                setIsModalOpen={setIsCreateModalOpen}
                showTooltip={false}
                disableCheckBox
                removeScenarioID={deletedScenario?.id}
            />
        </div>
    );
};

export default RemoveMainScenario;
