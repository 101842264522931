import Text from '@ingka/text';
import classes from './CapacityFactor.module.scss';

const CapacityFactorGroup = ({
    headerText,
    children,
}: {
    headerText: string,
    children?: React.ReactNode
}) => (
    <div data-testid={`CapacityFactorGroup--${headerText}`}>
        <Text tagName="h3" headingSize="s" className={classes['header-title']}>{headerText}</Text>
        {children}
    </div>
);

export default CapacityFactorGroup;
