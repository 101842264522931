import { ContractRange } from './coworker';
import { ContractType, EmploymentType, TypeContract } from './scenario';

export enum CoworkerAction {
    WEEKLY_HOURS = 'WEEKLY_HOURS',
    AVAILABILITY = 'AVAILABILITY',
    ADDITIONAL_HOURS = 'ADDITIONAL_HOURS',
    MODULATION = 'MODULATION_HOURS',
    CC_DISTRIBUTION = 'CC_DISTRIBUTION',
    CONTRACT_TYPE = 'CONTRACT_TYPE',
    CONTRACT_HOURS = 'CONTRACT_HOURS',
    NEW_COWORKER = 'NEW_COWORKER',
    CONTRACT_DATES = 'CONTRACT_DATES'
}

export type TypeWeeklyHoursAction = {
    type: CoworkerAction.WEEKLY_HOURS,
    from: number,
    to: number,
    costCentre: string,
};

export type TypeAvailabilityAction = {
    type: CoworkerAction.AVAILABILITY,
    from: string,
    to: 'H' | 'A',
    costCentre: string,
};

export type TypeCCDistributionAction = {
    type: CoworkerAction.CC_DISTRIBUTION,
    ccDistributions: {
        from: {
            costCentre: string,
            percentage: number,
            hours: number
        },
        to: {
            costCentre: string,
            percentage: number,
            hours: number
        }
    }[]

};

export type TypeAdditionalHours = {
    type: CoworkerAction.ADDITIONAL_HOURS,
    from: number,
    to: number,
    costCentre: string,
};

export type TypeModulationHoursAction = {
    type: CoworkerAction.MODULATION,
    fromHours: number,
    fromMinutes: number,
    toHours: number,
    toMinutes: number,
    costCentre: string,
};

export type TypeContractTypeAction = {
    type: CoworkerAction.CONTRACT_TYPE,
    from: ContractType,
    to: ContractType,
    hoursPerWeek: number,
};

export type TypeContractDatesAction = {
    type: CoworkerAction.CONTRACT_DATES,
    from: ContractType,
    to: ContractType,
    hoursPerWeek: number,
};

export type TypeContractHoursAction = {
    type: CoworkerAction.CONTRACT_HOURS,
    from: number | ContractRange,
    to: number | ContractRange,
};

export type TypeNewCoworkerAction = {
    type: CoworkerAction.NEW_COWORKER,
    hours: number | ContractRange,
    employmentType: EmploymentType,
    costCentre: string
};

export type TypeAction = (
    TypeWeeklyHoursAction
    | TypeAvailabilityAction
    | TypeCCDistributionAction
    | TypeAdditionalHours
    | TypeModulationHoursAction
    | TypeContractHoursAction
    | TypeNewCoworkerAction
    | TypeContractTypeAction
    | TypeContractDatesAction);

export type TypeActionGroup = {
    startDate: string,
    endDate: string | null,
    changedBy: string,
    changedAt: string,
    actions: TypeAction[]
};

export type TypeCoworkerActionPlan = {
    personId: string,
    name: string,
    note: string,
    isDummy: boolean,
    division: string,
    costCentres: string[],
    department: string,
    contractType: TypeContract,
    lastChanged: string,
    initialContractHours: number,
    actionGroups: TypeActionGroup[]
};

export enum ContractChangeType {
    ALL_CHANGES = 'ALL_CHANGES',
    CONTRACT_HOURS = 'CONTRACT_HOURS',
    ADDITIONAL_HOURS = 'ADDITIONAL_HOURS',
    MODULATION = 'MODULATION'
}
