import { TimeSelection } from 'types/appContext';
import { UserProfile, UserPermission } from 'types/authContext';
import { Scenario } from 'types/scenario';

/**
 * Allows typing dots and commas after numbers, as usual when typing a number
 * @param value Event Target value
 * @returns boolean
 */
export const isIntegerOrDecimal = (value: string): boolean => /^[0-9]*[.,]?[0-9]{0,2}$/.test(value);
export const isIntegerOrDecimalNegativeAllowed = (value: string): boolean => /^-?[0-9]*[.,]?[0-9]{0,2}$/.test(value);

/**
 * The string contains only numbers, nothing else
 * @param value Event Target value
 * @returns boolean
 */
export const isInteger = (value: string) => /^[0-9]*$/.test(value);

/**
 * Compares two TimeSelections to see that they span the same weeks
 * @param timeSelectionA TimeSelection
 * @param timeSelectionB TimeSelection
 * @returns boolean
 */
export const isIdenticalTimeSelection = (timeSelectionA: TimeSelection, timeSelectionB: TimeSelection) => (
    timeSelectionA.timeArray.length === timeSelectionB.timeArray.length
    && timeSelectionA.timeArray[0] === timeSelectionB.timeArray[0]
    && timeSelectionA.timeArray[timeSelectionA.timeArray.length - 1] === timeSelectionB.timeArray[timeSelectionB.timeArray.length - 1]
);

/**
 * Checks if the user has permission to edit the scenario
 * @param scenario Scenario
 * @param user UserProfile
 * @returns boolean
 */
export const userCanEdit = (scenario: Scenario | undefined, user: UserProfile | null) => !!(
    scenario
    && user
    && user.rolePermissions?.some(el => el === UserPermission.EDIT_SCENARIO)
);

/**
 * Checks if the string is a valid hexadecimal color code
 * (allowing #rgb, #rrggbb, #rgba and #rrggbbaa)
 */
export const isHexColor = (color: string): boolean => {
    // #rrggbb
    if (/^#[0-9A-F]{6}$/i.test(color)) return true;
    // #rgb
    if (/^#[0-9A-F]{3}$/i.test(color)) return true;
    // #rrggbbaa
    if (/^#[0-9A-F]{8}$/i.test(color)) return true;
    // #rgba
    if (/^#[0-9A-F]{4}$/i.test(color)) return true;

    return false;
};
