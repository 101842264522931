import { ContractRange } from 'types/coworker';

/**
 * Gets the minimum value of a contract range type safely as a number. The picking order is:
 * 1) use min value 2) use the max value 3) return 0.
 * Note: There shouldn't be any contract ranges with both min and max as null.
 */
export const getContractRangeMin = (cr: ContractRange): number => cr.range.min ?? cr.range.max ?? 0;

/**
 * Gets the maximum value of a contract range type safely as a number. The picking order is:
 * 1) use max value 2) return Number.POSITIVE_INFINITY.
 * Note: There shouldn't be any contract ranges with max as null.
 */
export const getContractRangeMax = (cr: ContractRange): number => cr.range.max ?? Number.POSITIVE_INFINITY;
