import { createPortal } from 'react-dom';
import { ReactElement } from 'react';
import classes from './Tooltip.module.scss';

/**
* @param ref When given a ref will open a react portal, when not will render content locally
*/

export const Tooltip = ({ isOpen, parentElement, prefix, content, className, position, offsetPx = 0, styling = 'default' }:
{
    isOpen: boolean;
    content: string | ReactElement;
    parentElement: HTMLElement | null,
    prefix?: string,
    className?: string,
    position?: 'top' | 'bottom',
    offsetPx?: number,
    styling?: 'default' | 'discreet'
}) => {
    let portalDiv = document.getElementById('portal');
    if (!portalDiv) {
        portalDiv = document.createElement('div');
        portalDiv.id = 'portal';
        document.querySelector('body')?.appendChild(portalDiv);
    }

    const parentTop = Math.ceil(parentElement?.getBoundingClientRect().top ?? 0);
    const parentBottom = Math.floor(parentElement?.getBoundingClientRect().bottom ?? 0);
    const parentLeft = Math.floor(parentElement?.getBoundingClientRect().left ?? 0);
    const parentWidth = parentElement?.getBoundingClientRect().width ?? 0;

    const positioningStyle = position === 'top' ? {
        left: `${parentLeft + (parentWidth / 2)}px`,
        top: `${parentTop - offsetPx}px`,
        transform: 'translateY(-100%) translateX(-50%)',
    } : {
        left: `${parentLeft}px`,
        top: `${parentBottom + offsetPx}px`
    };

    return (parentElement && createPortal(
        <div
            className={`${classes.tooltip} ${isOpen ? classes.tooltip__show : ''} ${className ?? ''} ${classes[`tooltip__${styling}`]}`}
            style={positioningStyle}
            data-testid="tooltip"
        >
            {prefix && (
            <b>
                {prefix}
                :
                {' '}
            </b>
            )}
            <span>{content}</span>
        </div>,
        portalDiv
    ));
};
