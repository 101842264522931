import React from 'react';
import useAuth from 'hooks/useAuth';
import classes from './Configuration.module.scss';
import EnableNewUnitButton from './EnableNewUnitButton';
import DisableBusinessUnit from './DisableBusinessUnit';

const GeneralSettings = () => {
    const { access } = useAuth();

    return (
        <div className={classes['config__general-setting-div']}>
            {access?.features?.onboardingNewUnits && (
                <div
                    id="config-enable-unit"
                    data-testid="config-enable-unit"
                    className={classes['config__general-setting-item']}
                >
                    <EnableNewUnitButton />
                </div>
            )}
            {access?.features?.enableDisableUnits && (
                <div
                    id="config-disable-unit"
                    data-testid="config-disable-unit"
                    className={classes['config__general-setting-item']}
                >
                    <DisableBusinessUnit />
                </div>
            )}
        </div>
    );
};

export default GeneralSettings;
