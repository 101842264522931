import React from 'react';
import { Scenario } from 'types/scenario';
import useData from 'hooks/useData';
import { useToast } from 'hooks/useToast';
import classes from './ManageScenarios.module.scss';
import EditableScenario from './EditableScenario';

const ManageScenarios = () => {
    const { scenarioList } = useData();
    const { displayToast } = useToast();

    return (
        <div className={classes['manage-scenarios']} data-testid="manage-scenarios">
            {scenarioList?.scenarios?.map((scenario: Scenario) => (
                <EditableScenario key={scenario.id} scenario={scenario} displayToast={displayToast} />
            ))}
        </div>
    );
};

export default ManageScenarios;
