import React, { useRef } from 'react';
import Button from '@ingka/button';
import modulationArrow from '@ingka/ssr-icon/paths/arrow-left-arrow-right';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import { DummyCoworker } from 'types/scenario';
import { useTranslation } from 'react-i18next';
import Tooltip from '@ingka/tooltip';
import useData from 'hooks/useData';
import { Coworker } from 'types/coworker';
import Text from '@ingka/text';
import useAuth from 'hooks/useAuth';
import classes from './TotalHoursRow.module.scss';

const CoworkerNameCell = ({
    coworker,
    handleClickCoworker,
    handleClickModulation,
    isActive,
    showModulationIcon,
}: {
    coworker: Coworker | DummyCoworker;
    handleClickCoworker: () => void;
    handleClickModulation: () => void;
    isActive: boolean;
    showModulationIcon: boolean;
}) => {
    const { access } = useAuth();
    const { currentScenario } = useData();
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div ref={ref} className={`${coworker.isDummy ? classes['coworker-name-dummy'] : ''}`}>
            {isActive ? (
                <div className={classes['coworker-detail']} data-testid="user-details-trigger">
                    <div
                        className={classes['table-row__employee']}
                        onClick={handleClickCoworker}
                        onKeyDown={handleClickCoworker}
                        data-testid="user-details-legalFullName"
                    >
                        <Text headingSize="xs">
                            {coworker.legalFullName}
                        </Text>
                        <Text
                            bodySize="m"
                            data-testid="user-details-personID"
                        >
                            {coworker.isDummy ? '' : coworker.personId}
                        </Text>
                    </div>
                    <div
                        className={`${classes['coworker-detail__button']}`}
                        onClick={handleClickModulation}
                        onKeyDown={handleClickModulation}
                        data-testid="user-modulation-trigger"
                    >
                        {showModulationIcon && access?.features?.modulation && (
                        <Tooltip
                            tooltipText={!currentScenario
                                ? `${t('MODULATION_ELIGIBLE_COWORKER')}. ${t('SELECT_A_SCENARIO')}`
                                : t('MODULATION_ELIGIBLE_COWORKER')}
                            position="trailing"
                            data-testid="user-modulation-tooltip-icon"
                        >
                            <Button
                                data-testid="user-modulation-button"
                                iconPosition="trailing"
                                iconOnly
                                size="small"
                                ssrIcon={modulationArrow}
                                type="tertiary"
                                className={`${classes['coworker-detail__button--active']} 
                                    ${currentScenario ? '' : classes.inactive}`}
                            />
                        </Tooltip>
                        )}
                    </div>
                </div>
            ) : (
                <Tooltip tooltipText={`${t('DEACTIVATED_COWORKER_WARNING_SHORT')}.`} position="trailing" data-testid="user-deactivated-tooltip">
                    <div
                        className={classes['coworker-detail--deactivated']}
                        data-testid="user-deactivated-trigger"
                        onClick={handleClickCoworker}
                        onKeyDown={handleClickCoworker}
                    >
                        <div className={classes['coworker-detail--deactivated--button']}>
                            <Button
                                data-testid="user-deactivated-icon"
                                ssrIcon={warningTriangle}
                                iconOnly
                                size="small"
                                iconPosition="trailing"
                                type="tertiary"
                                className={classes['coworker-detail--deactivated--button--warning']}
                            />
                        </div>
                        <div
                            className={`${classes['table-row__employee']} ${currentScenario ? '' : classes['coworker-detail--deactivated']
                            }`}
                        >
                            <Text headingSize="xs" data-testid="user-deactivated-fullName" className={classes['coworker-detail__name--deactivated']}>
                                {coworker.legalFullName}
                            </Text>
                            <Text
                                bodySize="m"
                                className={`${classes['table-row__employee-id']} ${currentScenario ? '' : classes['coworker-detail--deactivated']
                                }`}
                                data-testid="user-deactivated-personID"
                            >
                                {coworker.isDummy ? '' : coworker.personId}
                            </Text>
                        </div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default CoworkerNameCell;
