import { Dispatch, SetStateAction } from 'react';
import useAuth from 'hooks/useAuth';
import UserInfoPanel from 'components/UserInfo/UserInfoPanel';
import Button from '@ingka/button';
import Modal, { Sheets, ModalHeader, ModalFooter, ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';

const UserInfoModal = (
    {
        isOpen,
        setIsOpen
    }: {
        isOpen: boolean,
        setIsOpen: Dispatch<SetStateAction<boolean>>
    }
) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    return (
        <Modal
            visible={isOpen}
            escapable
            handleCloseBtn={() => setIsOpen(false)}
        >
            <Sheets
                alignment="right"
                size="small"
                header={(<ModalHeader backBtnClick={() => setIsOpen(false)} />)}
                footer={(
                    <ModalFooter>
                        <Button
                            data-testid="user-info-modal-cancel-button"
                            size="small"
                            text={t('CLOSE')}
                            type="secondary"
                            onClick={() => { setIsOpen(false); }}
                        />
                    </ModalFooter>
                )}
            >
                <ModalBody>
                    <UserInfoPanel user={user} />
                </ModalBody>
            </Sheets>
        </Modal>
    );
};

export default UserInfoModal;
