import { ReactElement } from 'react';
import classes from './LoadingWrapper.module.scss';
import LoadingIndicator from './LoadingIndicator';
/**
 *
 * @param placeHolder If provided will replace `children` prop
 * @returns
 */
export const LoadingWrapper = ({
    isLoading,
    children,
    className,
    placeHolder,
    isLoadingIndicatorFixed
}:{
    isLoading: boolean,
    children: ReactElement,
    className?: string,
    placeHolder?:ReactElement,
    isLoadingIndicatorFixed? : boolean,
}) => (
    <div className={`${classes['loading-wrapper']} ${className ?? ''}`}>
        {isLoading && (placeHolder || <LoadingIndicator isLoading={isLoading} isFixed={isLoadingIndicatorFixed} />)}
        <span className={`${isLoading ? classes['loading-wrapper__loading--active'] : classes['loading-wrapper__loading--inactive']}`}>
            { children }
        </span>
    </div>
);
