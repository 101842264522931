import React from 'react';
import { useTranslation } from 'react-i18next';
import { roundDisplayValueDoubleDecimal } from 'utils/number';
import { ContributionsData } from 'types/table';
import { getGapContributions } from 'hooks/useGrid/gridFunctionsShared';
import classes from './HoverDetails.module.scss';

const ContentContributions = ({ hoverData }: { hoverData: ContributionsData }) => {
    const { t } = useTranslation();
    const getClass = (value: number) => (value <= 0 ? classes.tablehover__grid__value__warning : classes.tablehover__grid__value);
    const contributionsGap = getGapContributions(hoverData);

    return (
        <div className={classes.tablehover__grid__contrib} data-testid="tableheader-contribution-hover-popup">
            <span className={classes.tablehover__grid__header} />
            <span className={classes.tablehover__grid__header}>{t('CAPACITY')}</span>
            <span className={classes.tablehover__grid__header}>{t('TARGET')}</span>
            <span className={classes.tablehover__grid__header}>{t('GAP')}</span>

            <span className={classes.tablehover__grid__emphasis}>{t('EVENINGS')}</span>
            <span data-testid="tableheader-contribution-hover-eveningContributionHours" className={classes.tablehover__grid__value}>
                {roundDisplayValueDoubleDecimal(hoverData.contributions.evenings)}
            </span>
            <span data-testid="tableheader-contribution-hover-eveningsTotal" className={classes.tablehover__grid__value}>
                {roundDisplayValueDoubleDecimal(hoverData.workload.evenings)}
            </span>
            <span data-testid="tableheader-contribution-hover-gapEvenings" className={getClass(contributionsGap.evenings)}>
                {roundDisplayValueDoubleDecimal(contributionsGap.evenings)}
            </span>

            <span className={classes.tablehover__grid__emphasis}>{t('SATURDAY')}</span>
            <span data-testid="tableheader-contribution-hover-saturdayContributionHours" className={classes.tablehover__grid__value}>
                {roundDisplayValueDoubleDecimal(hoverData.contributions.saturday)}
            </span>
            <span data-testid="tableheader-contribution-hover-saturdaysTotal" className={classes.tablehover__grid__value}>
                {roundDisplayValueDoubleDecimal(hoverData.workload.saturday)}
            </span>
            <span data-testid="tableheader-contribution-hover-gapSaturdays" className={getClass(contributionsGap.saturday)}>
                {roundDisplayValueDoubleDecimal(contributionsGap.saturday)}
            </span>

            <span className={classes.tablehover__grid__emphasis}>{t('SUNDAY')}</span>
            <span data-testid="tableheader-contribution-hover-sundayContributionHours" className={classes.tablehover__grid__value}>
                {roundDisplayValueDoubleDecimal(hoverData.contributions.sunday)}
            </span>
            <span data-testid="tableheader-contribution-hover-sundaysTotal" className={classes.tablehover__grid__value}>
                {roundDisplayValueDoubleDecimal(hoverData.workload.sunday)}
            </span>
            <span data-testid="tableheader-contribution-hover-gapSundays" className={getClass(contributionsGap.sunday)}>
                {roundDisplayValueDoubleDecimal(contributionsGap.sunday)}
            </span>
        </div>
    );
};

export default ContentContributions;
