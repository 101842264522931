import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useScrollSlice from 'hooks/useScrollSlice';
import { CoworkerGridData, DummyGridData, GridData } from 'types/appContext';
import useApp from 'hooks/useApp';
import { useTranslation } from 'react-i18next';
import useLoading from 'hooks/useLoading';
import InlineMessage from '@ingka/inline-message';
import information from '@ingka/ssr-icon/paths/information';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import { useSearchParams } from 'react-router-dom';
import { useConfigApi } from 'hooks/api/useConfigApi';
import { getModulationForCoworker } from 'utils/rules';
import { sortCoworkersByName, sortCoworkersByContractHours, sortCoworkersByModulationHours } from 'hooks/useGrid/gridFunctionsSorting';
import { userCanEdit } from 'utils/validation';
import useAuth from 'hooks/useAuth';
import CoworkerRows from './CoworkerRows';
import classes from './Table.module.scss';
import WeekHeaderRow from './WeekHeaderRow';
import HeaderRow from './HeaderRow';
import headerRowClasses from './HeaderRow.module.scss';

export const MANAGE_CAPACITY_TABLE_CONTAINER_ID = 'manage-capacity-table-div';

const getCurrentCoworkerGridData = ({ coworkedId, gridData }: { coworkedId: string; gridData: GridData }) => {
    const coworker = gridData?.coworkers.find(c => c.coworker.personId === coworkedId);

    return {
        ...gridData,
        coworkers: coworker ? [coworker] : []
    };
};

const Table = ({ timeArray, gridData, showAllDetails }: { timeArray: Array<string>, gridData: GridData | undefined, showAllDetails: boolean }) => {
    const { t } = useTranslation();
    const { slice } = useScrollSlice(30, gridData?.coworkers?.length, MANAGE_CAPACITY_TABLE_CONTAINER_ID);
    const { isLoading } = useLoading();
    const { currentCoworkerId, showContributionDetails, searchParam, currentScenario } = useApp();
    const { user } = useAuth();
    const canEdit = userCanEdit(currentScenario, user);
    const [data, setData] = useState<GridData | undefined | null>(null);
    const [queryParams] = useSearchParams();
    const { data: configApi, isLoading: isConfigApiLoading } = useConfigApi();
    const modulationRanges = useMemo(
        () => data?.coworkers.map(cw => getModulationForCoworker(cw.coworker, configApi)?.modulationRange ?? null),
        [data, configApi]
    );

    const filterBySearchParam = useCallback(
        (cw: DummyGridData | CoworkerGridData) => cw.coworker.legalFullName.toLocaleLowerCase().includes(searchParam.trim().toLocaleLowerCase())
        || cw.coworker.personId.includes(searchParam.trim()),
        [searchParam]
    );
    const handleSort = useCallback((isAsc: boolean, sortBy: string) => {
        if (!gridData) {
            return;
        }
        const tempData = searchParam && !searchParam.trim().toLowerCase() ? gridData.coworkers.filter(filterBySearchParam) : gridData.coworkers;
        let sortedCoworkers;
        switch (sortBy) {
        case 'name':
            sortedCoworkers = sortCoworkersByName(tempData, isAsc);
            break;
        case 'hours':
            sortedCoworkers = sortCoworkersByContractHours(tempData, isAsc);
            break;
        case 'modulation':
            sortedCoworkers = sortCoworkersByModulationHours(tempData, modulationRanges ?? [], isAsc);
            break;
        default:
            sortedCoworkers = tempData;
        }
        setData({
            ...gridData,
            coworkers: sortedCoworkers,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridData, searchParam]);

    const handleSortByHours = (isAsc: boolean) => {
        handleSort(isAsc, 'hours');
    };

    const handleSortByName = (isAsc: boolean) => {
        handleSort(isAsc, 'name');
    };

    const handleSortByModulationHours = (isAsc: boolean) => {
        handleSort(isAsc, 'name');
    };

    useEffect(() => {
        if (!gridData) {
            setData(null);

            return;
        }

        if (currentCoworkerId) {
            setData(getCurrentCoworkerGridData({ coworkedId: currentCoworkerId, gridData }));

            return;
        }

        if (queryParams.get('sortedBy') === 'hours') {
            handleSortByHours(queryParams.get('sortOrder') === 'asc');
        } else if (queryParams.get('sortedBy') === 'name') {
            handleSortByName(queryParams.get('sortOrder') === 'asc');
        } else if (queryParams.get('sortedBy') === 'modulation') {
            handleSortByModulationHours(queryParams.get('sortOrder') === 'asc');
        }

        if (!queryParams.get('sortedBy') || !queryParams.get('sortOrder')) {
            // const filteredCoworkers = searchParam?.trim().toLocaleLowerCase()
            //     ? gridData?.coworkers?.filter(cw => cw.coworker.legalFullName.toLocaleLowerCase().includes(searchParam.trim().toLocaleLowerCase()))
            //     : gridData?.coworkers;
            setData({
                ...gridData,
                // coworkers: filteredCoworkers
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCoworkerId, gridData?.coworkers, queryParams, searchParam]);

    return (
        <LoadingWrapper isLoading={isLoading || isConfigApiLoading} isLoadingIndicatorFixed>
            {!data?.coworkers.length ? (
                <InlineMessage
                    ssrIcon={information}
                    title={t('NO_PERSONNEL_DATA')}
                    body={t('SELECT_DATA_INFO')}
                    className={classes['pa-table__empty-message']}
                />
            ) : (
                <table
                    border={1}
                    className={classes['pa-table']}
                >
                    <thead>
                        <WeekHeaderRow
                            weeks={timeArray}
                            showDetails={showAllDetails}
                            onSortByName={handleSortByName}
                            onSortByHours={handleSortByHours}
                            onSortByModulation={handleSortByModulationHours}
                        />
                        <HeaderRow showDetails={showAllDetails} title={t('GAP_HOURS')} data={data.hoverData.map(hover => hover.gap)} testid="gap-row" />
                        {showContributionDetails && (
                        <HeaderRow
                            testid="gap-evenings-weekends-row"
                            showDetails={showAllDetails}
                            title={t('GAP_EVENINGS_WEEKENDS')}
                            data={data.hoverData.map(hover => hover.contributions)}
                            className={headerRowClasses['gap-row__weekends-and-evenings']}
                        />
                        )}
                    </thead>
                    <tbody>
                        {data.coworkers
                            .filter(filterBySearchParam)
                            .slice(slice.start, slice.end)
                            .map((coworkerData, index) => (
                                <CoworkerRows
                                    canEdit={canEdit}
                                    key={`coworker-rows-${coworkerData.coworker.personId}`}
                                    coworkerData={coworkerData}
                                    showAllDetails={showAllDetails}
                                    modulationRange={modulationRanges?.[index] ?? undefined}
                                />
                            ))}
                    </tbody>
                </table>
            )}
        </LoadingWrapper>
    );
};

export default React.memo(Table);
