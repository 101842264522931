import { useState } from 'react';
import { ContractRange } from 'types/coworker';
import { isIntegerOrDecimal } from 'utils/validation';
import useData from 'hooks/useData';
import { validateContractHours } from '../validators';

export const defaultContractHours = undefined;
export const defaultContractRange = undefined;

const useContractHours = () => {
    const [contractHours, setContractHours] = useState<number | string | undefined>(defaultContractHours);
    const [contractRange, setContractRange] = useState<ContractRange | undefined>(defaultContractRange);

    const [validContractHours, setValidContractHours] = useState(validateContractHours(defaultContractHours));
    const { config } = useData();
    const handleChangeContractHours = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (config?.isContractRangeSupported) {
            const rangeValue = config?.contractRanges.find((range: ContractRange) => range.type === e.target.value);
            setContractRange(rangeValue);
            setValidContractHours(!!rangeValue);
        } else {
            if (!isIntegerOrDecimal(e.target.value)) {
                return;
            }
            setContractHours(e.target.value);
            setValidContractHours(validateContractHours(e.target.value));
        }
    };

    const resetContractHours = () => {
        setContractHours(defaultContractHours);
        setContractRange(defaultContractRange);
        setValidContractHours(validateContractHours(defaultContractHours));
    };

    return { contractHours, contractRange, handleChangeContractHours, validContractHours, resetContractHours };
};

export default useContractHours;
