import React, { memo } from 'react';
import { PageContainer } from 'components';
import PageToolbar from 'components/PageToolbar/PageToolbar';
import ContractMix from 'components/ContractMix/ContractMix';
import ContractMixHeader from 'components/ContractMix/ContractMixHeader';
import { CapacityOverviewGraph } from 'components/Graph/CapacityOverviewGraph';
import { GapDistributionGraph } from 'components/Graph/GapDistributionGraph';
import { ContractMixGraph } from 'components/Graph/ContractMixGraph';
import useAuth from 'hooks/useAuth';
import classes from './CapacityOverview.module.scss';

const CapacityOverview = memo(() => {
    const { access } = useAuth();

    return (
        <PageContainer testid="capacity-overview-page" className={classes['capacity-overview__container']}>
            <>
                <PageToolbar showAllElements={false} />
                <CapacityOverviewGraph />
                <GapDistributionGraph />
                {access?.features?.contractMixView && (
                    <>
                        <ContractMixHeader />
                        <ContractMix />
                        <ContractMixGraph />
                    </>
                )}
            </>
        </PageContainer>
    );
});

export default CapacityOverview;
