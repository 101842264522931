import { useCallback, useState } from 'react';
import Search from '@ingka/search';
import useApp from 'hooks/useApp';
import { useTranslation } from 'react-i18next';
import { ANALYTICS_SEARCH_BAR_MAP } from 'types/analytics';
import _ from 'lodash';
import { DEFAULT_DEBOUNCE_TIME } from 'types/enums';
import classes from './SearchBar.module.scss';

const SearchBar = ({ callback, disabled, pathName }: {
    callback: Function,
    disabled: boolean,
    pathName: string
}) => {
    const { searchParam } = useApp();
    const { t } = useTranslation();
    const [currentSearchParam, setCurrentSearchParam] = useState(searchParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(_.debounce((value: string) => {
        if (value.length >= 2) {
            callback(value);
        } else if (!value) {
            callback('');
        }
    }, DEFAULT_DEBOUNCE_TIME, { trailing: true }), []);

    return (
        <Search
            className={classes.search}
            id="searchbar"
            data-testid="searchbar"
            autoComplete="off"
            onClear={() => {
                debouncedSearch('');
                setCurrentSearchParam('');
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, state: { scope: string | null, value: string | undefined }) => {
                const value = state.value || '';
                debouncedSearch(value);
                setCurrentSearchParam(value);
            }}
            onSearch={(
                e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>,
                data: { scope: string | null, value: string | undefined }
            ) => {
                const value = data.value || '';
                debouncedSearch(value);
                setCurrentSearchParam(value);
            }}
            placeholder={t('SEARCH_COWORKER_PLACEHOLDER')}
            disabled={disabled}
            data-analytics={ANALYTICS_SEARCH_BAR_MAP.get(pathName.substring(1))}
            value={currentSearchParam}
        />
    );
};

export default SearchBar;
