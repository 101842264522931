import moment from 'moment';
import { useEffect, useState } from 'react';
import { ContractType, ContractLegacy } from 'types/scenario';
import { isoWeekFromDateString } from 'utils/date';

export const validateDates = (start: string | null, end: string | null, contractType: ContractType) => {
    if (contractType === ContractLegacy.temporary && end === null) {
        return false;
    }

    if (start === null) return false;

    const startDate = start === null ? moment('1000-01-01') : moment(start, 'YYYY-[W]WW', true);
    const endDate = end === null ? moment('3000-01-01') : moment(end, 'YYYY-[W]WW', true);

    if (startDate.isValid() && endDate.isValid() && startDate.isSameOrBefore(endDate)) {
        return true;
    }

    return false;
};

const getMinimumStartWeek = () => {
    const globalMinimum = '2022-W01';

    return isoWeekFromDateString(globalMinimum);
};

export const defaultNewCoworkerStartDate = null;
export const minimumNewCoworkerStartWeek = getMinimumStartWeek();
export const defaultNewCoworkerEndDate = null;

const useContractDates = (contractType: ContractType) => {
    const [startDate, setStartDate] = useState<string | null>(defaultNewCoworkerStartDate);
    const [endDate, setEndDate] = useState<string | null>(defaultNewCoworkerEndDate);
    const [validDates, setValidDates] = useState(true);

    const handleChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value);
    };
    const handleChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(e.target.value);
    };

    const resetDates = () => {
        setStartDate(defaultNewCoworkerStartDate);
        setEndDate(defaultNewCoworkerEndDate);
    };

    useEffect(() => {
        if (contractType === ContractLegacy.permanent && endDate !== null) {
            setEndDate(null);
        }
        setValidDates(validateDates(startDate, contractType === ContractLegacy.permanent ? null : endDate, contractType));
    }, [contractType, endDate, startDate]);

    return { startDate, handleChangeStartDate, minimumStartDate: minimumNewCoworkerStartWeek, endDate, handleChangeEndDate, validDates, resetDates };
};

export default useContractDates;
