import React from 'react';
import { UserProfile } from 'types/authContext';
import { useTranslation } from 'react-i18next';
import Avatar from '../Avatar/Avatar';
import classes from './UserInfoPanel.module.scss';
import UserInfoName from './UserInfoName';

const UserInfoPanel = ({ user }: { user: UserProfile | null }) => {
    const { t } = useTranslation();

    return (
        <div className={classes['user-info-panel']} data-testid="user-info-panel">
            <Avatar imageSrc={user?.image ?? null} size="large" />
            <UserInfoName userName={user?.name} />
            {user
            && (
                <>
                    <div className={classes.bold}>
                        {t('ROLE')}
                    </div>

                    <div>
                        {t(user.role)}
                    </div>

                    <div className={classes.bold}>
                        {t('COUNTRY')}
                    </div>

                    <div>
                        {t(`${user.country}_FULL`)}
                    </div>

                    <div className={classes.bold}>
                        {t('UNIT')}
                    </div>

                    <div>
                        {user.businessUnit}
                    </div>

                    <div className={classes.bold}>
                        {t('OFFICE_LOCATION')}
                    </div>

                    <div>
                        {user.officeLocation}
                    </div>

                    <a
                        href="https://iweof.sharepoint.com/sites/sup/dp/SitePages/Countrycoworkerdataprivacypolicies.aspx?web=1"
                        target="_blank"
                        rel="noreferrer"
                        key="privacy-policy"
                    >
                        {t('PRIVACY_POLICY')}
                    </a>

                </>
            )}

        </div>
    );
};

export default UserInfoPanel;
