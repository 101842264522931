import React, { createContext, useEffect, useMemo, useState } from 'react';
import useOMFilter from 'hooks/useOMFilter/useOMFilter';
import useAuth from 'hooks/useAuth';
import useData from 'hooks/useData';
import { AppContextValues, TimeSelection } from 'types/appContext';
import { initializeTimeSelection } from 'utils/date';
import useGrid from 'hooks/useGrid/useGrid';
import { useGapAnalytics } from 'hooks/useGapAnalytics';

const AppContext = createContext<AppContextValues>({
    updateOMSelection: () => {},
    filteredCountryOMData: undefined,
    filteredUnitOMData: undefined,
    filteredDivisionOMData: undefined,
    filteredDepartmentOMData: undefined,
    filteredUnitLevelCostCenterOMData: undefined,
    filteredCostCenterOMData: undefined,
    costCentreList: undefined,
    coworkerData: undefined,
    timeSelection: initializeTimeSelection('week'),
    setTimeSelection: () => {},
    gridData: undefined,
    currentCoworkerId: undefined,
    setCurrentCoworkerId: () => {},
    currentWeek: undefined,
    setCurrentWeek: () => {},
    searchParam: '',
    setSearchParam: () => {},
    showAllDetails: false,
    setShowAllDetails: () => {},
    showContributionDetails: true,
    setShowContributionDetails: () => {},
    isCalculating: false,
    currentScenario: undefined,
    setCurrentScenario: () => {},
    handleAnalytics: () => {},
});

const AppProvider = ({ children }: { children: JSX.Element }) => {
    const { countriesList, user } = useAuth();
    const [timeSelection, setTimeSelection] = useState<TimeSelection>(initializeTimeSelection('week'));
    const [currentCoworkerId, setCurrentCoworkerId] = useState<string>();
    const [currentWeek, setCurrentWeek] = useState<string>();
    const [searchParam, setSearchParam] = useState<string>('');
    const [showAllDetails, setShowAllDetails] = useState<boolean>(false);
    const [showContributionDetails, setShowContributionDetails] = useState<boolean>(true);
    const [isCalculating, setIsCalculating] = useState<boolean>(false);
    const { handleAnalytics } = useGapAnalytics();

    const {
        coworkerData,
        unfilteredCountryOMData,
        selectCountry,
        selectUnit,
        getContractMix,
        currentScenario,
        setCurrentScenario,
        setDateInterval,
    } = useData();

    const {
        updateOMSelection,
        filteredCountryOMData,
        filteredUnitOMData,
        filteredDivisionOMData,
        filteredDepartmentOMData,
        filteredUnitLevelCostCenterOMData,
        filteredCostCenterOMData,
        costCentreList,
    } = useOMFilter({
        user,
        unfilteredCountriesList: countriesList,
        unfilteredCountryOMData,
        selectCountry,
        selectUnit,
        getContractMix
    });

    const { gridData } = useGrid(
        {
            coworkers: coworkerData,
            timeSelection,
            currentScenario,
            user,
            costCentreList,
            setIsCalculating,
        }
    );

    const contextValue = useMemo(
        () => ({
            updateOMSelection,
            filteredCountryOMData,
            filteredUnitOMData,
            filteredDivisionOMData,
            filteredDepartmentOMData,
            filteredUnitLevelCostCenterOMData,
            filteredCostCenterOMData,
            costCentreList,
            coworkerData,
            timeSelection,
            setTimeSelection,
            gridData,
            currentCoworkerId,
            setCurrentCoworkerId,
            currentWeek,
            setCurrentWeek,
            searchParam,
            setSearchParam,
            showAllDetails,
            setShowAllDetails,
            showContributionDetails,
            setShowContributionDetails,
            isCalculating,
            currentScenario,
            setCurrentScenario,
            handleAnalytics
        }),
        [
            updateOMSelection,
            filteredCountryOMData,
            filteredUnitOMData,
            filteredDivisionOMData,
            filteredDepartmentOMData,
            filteredUnitLevelCostCenterOMData,
            filteredCostCenterOMData,
            costCentreList,
            coworkerData,
            timeSelection,
            setTimeSelection,
            gridData,
            currentCoworkerId,
            setCurrentCoworkerId,
            currentWeek,
            setCurrentWeek,
            searchParam,
            setSearchParam,
            showAllDetails,
            setShowAllDetails,
            showContributionDetails,
            setShowContributionDetails,
            isCalculating,
            currentScenario,
            setCurrentScenario,
            handleAnalytics
        ],
    );

    useEffect(() => {
        if (timeSelection) {
            setDateInterval({
                startDate: timeSelection.startDate,
                endDate: timeSelection.endDate
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSelection]);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };
