import { buildUrlUpdateScenarioApi } from 'routes/api';
import { useWriter } from '../useWriter';
import useData from '../useData';

export const useDeleteScenarioAPI = (scenarioId: string) => {
    const { currentCountry, currentUnit, currentUnitType } = useData();

    const { writeData, ...response } = useWriter<null, {}>({
        url: buildUrlUpdateScenarioApi(scenarioId ?? '', currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
        method: 'DELETE'
    });

    return {
        ...response,
        deleteScenario: writeData
    };
};
