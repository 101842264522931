import { useCallback } from 'react';
import { useEvent } from './useEvent';

type CustomCopyEvent = CustomEvent<{ targetId?: string, highlight: boolean }>;

/**
 * Used to trigger the highlighting of the editable cell when the user copies a value from one week to neighboring weeks.
 * Highlight true means that the cell should be highlighted, false means that the highlighting should be removed.
 * If targetId is provided, the event is only for that cell. If targetId is not provided, the event is for all editable cells.
 */
export const useHighlightEvent = (elementId:string, setIsHighlighted: React.Dispatch<React.SetStateAction<boolean>>) => {
    const { publish, subscribe } = useEvent();

    const dispatchHighlightEvent = useCallback((highlight: boolean, targetId?: string) => {
        publish('custom:copy-event', { highlight, targetId });
    }, [publish]);

    const highlightCopy = useCallback((event: CustomCopyEvent) => {
        // The event is not for this cell - return
        if ((event.detail.targetId) && event.detail.targetId !== elementId) {
            return;
        }

        if (event.detail.highlight) {
            setIsHighlighted(true);
        } else {
            setIsHighlighted(false);
        }
    }, [elementId, setIsHighlighted]);

    subscribe('custom:copy-event', highlightCopy);

    return { dispatchHighlightEvent };
};
