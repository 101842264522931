import { buildUrlCopyScenarioApi } from 'routes/api';
import { useRef } from 'react';
import { TypeCopyScenarioRequestAPI, TypeCopyScenarioResponseAPI } from 'types/api';
import { toServerDate } from 'utils/date';
import useAuth from 'hooks/useAuth';
import { useWriter } from '../useWriter';
import useData from '../useData';

export const useCopyScenarioAPI = () => {
    const { user } = useAuth();
    const referenceRequestId = useRef<string | null>(null);
    const { currentCountry, currentUnit, selectScenario, scenarioList, setScenarioList, currentUnitType } = useData();

    const response = useWriter<TypeCopyScenarioRequestAPI, TypeCopyScenarioResponseAPI>({
        url: buildUrlCopyScenarioApi(currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
    });

    const { isResponseOk, requestId, requestData, responseData } = response;

    if (
        isResponseOk
        && referenceRequestId.current !== requestId
        && currentUnit
        && currentCountry
        && requestData
        && responseData
    ) {
        const newScenario = {
            id: responseData?.id.toString(),
            name: requestData.name,
            isMain: false,
            createdBy: user?.name ?? 'N/A',
            createdDate: toServerDate(new Date()),
            modifiedBy: user?.name ?? 'N/A',
            modifiedDate: toServerDate(new Date()),
            unitId: currentUnit,
            country: currentCountry,
            scenarioData: [],
            executed: false,
        };
        // Copy scenario component is rendered while saving at the same time React cannot update the DataProvider.
        setTimeout(() => {
            setScenarioList({ scenarios: [...(scenarioList?.scenarios ?? []), newScenario] });
            selectScenario(responseData?.id.toString() ?? '');
        }, 0);
    }

    referenceRequestId.current = requestId;

    return response;
};
