import { buildUrlGetActionPlanApi } from 'routes/api';
import { TypeActionPlanAPIResponse } from 'types/api';
import { useReader } from '../useReader';
import useData from '../useData';

export const useActionPlan = () => {
    const { currentScenario, currentCountry, currentUnit, currentUnitType } = useData();

    return useReader<TypeActionPlanAPIResponse>(
        {
            url: buildUrlGetActionPlanApi(currentScenario?.id ?? '', currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
            isSuspended: !currentScenario,
        }
    );
};
