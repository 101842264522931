import { PageContainer } from 'components';
import PageToolbar from 'components/PageToolbar/PageToolbar';
import { ActionPlanProvider } from 'context/ActionPlanContext';
import classes from './CapacityActionPlan.module.scss';
import { CapacityActionPlanContent } from './CapacityActionPlanContent';

const CapacityActionPlan = () => (
    <PageContainer testid="capacity-action-plan" className={classes['action-plan__container']}>
        <>
            <PageToolbar showAllElements={false} />
            <ActionPlanProvider>
                <CapacityActionPlanContent />
            </ActionPlanProvider>
        </>
    </PageContainer>
);
export default CapacityActionPlan;
