import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import ListBoxWithMultipleSelect from 'components/MultipleSelect';
import { API } from 'services';
import useData from 'hooks/useData';
import { ListBoxWithMultiSelect } from 'types/appContext';
import { BusinessUnitsList } from 'types/configurationPage';
import { useToast } from 'hooks/useToast';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import Tooltip from '@ingka/tooltip';
import useAuth from 'hooks/useAuth';
import SSRIcon from '@ingka/ssr-icon';
import classes from './DisableBusinessUnit.module.scss';

const hasCheckedItems = (unitsData: ListBoxWithMultiSelect[]): boolean => unitsData?.some(({ isChecked }) => isChecked);

const createListBoxData = (list: BusinessUnitsList[]): ListBoxWithMultiSelect[] => list?.map((unit: BusinessUnitsList) => ({
    id: unit.buCode,
    type: unit.buType,
    label: unit.buName,
    value: unit.buCode,
    status: unit.status,
    isChecked: unit.status,
}));

const createUnitsReqData = (list: ListBoxWithMultiSelect[]): BusinessUnitsList[] => list?.map((unit: ListBoxWithMultiSelect) => ({
    buCode: unit.id,
    buType: unit.type,
    status: unit.isChecked,
    buName: unit.label
}));

const DisableBusinessUnit = () => {
    const { t } = useTranslation();
    const { access } = useAuth();
    const { displayToast } = useToast();
    const { currentCountry } = useData();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [unitsList, setUnitsList] = useState<ListBoxWithMultiSelect[] | undefined>(undefined);

    const handleSelectUnits = (e: React.FormEvent<Element>) => setUnitsList(oldData => oldData
        ?.map((unit: ListBoxWithMultiSelect) => ((e.target as HTMLInputElement).value === unit.value
            ? { ...unit, isChecked: !unit.isChecked }
            : unit)));

    const totalUnits = unitsList?.length ?? 0;
    const showDisabledUnits = hasCheckedItems(unitsList ?? []);
    const totalEnabledUnits = unitsList?.filter(({ isChecked }) => isChecked)?.length ?? 0;

    useEffect(() => {
        if (currentCountry) {
            API().getUnitsList(currentCountry)
                .then((list: BusinessUnitsList[]) => {
                    if (list) {
                        setUnitsList(createListBoxData(list));
                    }
                })
                .catch(() => {
                    displayToast({ title: t('ERROR'), message: t('OPERATION_FAILED') });
                    setUnitsList(undefined);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCountry]);

    const onSaveUnits = () => {
        const getModifiedData = unitsList?.filter(({ status, isChecked }) => isChecked !== status) ?? [];
        const modifiedUnits = createUnitsReqData(getModifiedData);
        if (modifiedUnits.length > 0 && access?.api?.enableDisableUnits) {
            API().updateUnitsList(currentCountry, modifiedUnits)
                .then(response => {
                    if (response.message) {
                        displayToast({ title: t('SUCCESS'), message: t('BUSINESS_UNITS_UPDATED') });
                    }
                }).catch(() => {
                    displayToast({ title: t('ERROR'), message: t('BUSINESS_UNITS_UPDATION_FAILED') });
                });
        }
    };

    return (
        <>
            <div id="config-disable-unit-toggle-div" className={classes['disable-bu__header']}>
                <Text tagName="h5" headingSize="xs" id="config-disable-unit-title" data-testid="config-disable-unit-title">
                    {t('ENABLE_DISABLE_BUSINESS_UNITS')}
                </Text>
                <Tooltip tooltipText={t('ENABLE_UNITS_TOOLTIP')} position="trailing" className={classes['disable-bu__tooltip']}>
                    <SSRIcon id="config-disable-unit-icon" data-testid="config-disable-unit-icon" paths={informationCircle} />
                </Tooltip>
            </div>

            <div className={classes['disable-bu__container']}>
                <div className={classes['disable-bu__dropdown']}>
                    <div>{`${t('ENABLE')}/${t('DISABLE')} ${t('BUSINESS_UNITS')}`}</div>
                    <ListBoxWithMultipleSelect
                        showCount
                        placeHolder={`${t('BUSINESS_UNIT')}`}
                        data={unitsList}
                        isOpen={isOpen}
                        handleSetIsOpen={setIsOpen}
                        handleSelect={handleSelectUnits}
                        handleSave={onSaveUnits}
                    />
                    {showDisabledUnits ? (
                        <div id="disabled-units-note" data-testid="disabled-units-note" className={classes['disable-bu__note']}>
                            <Text
                                tagName="span"
                                bodySize="l"
                                className={classes['disable-bu__note-text']}
                            >
                                {`${t('NOTE')}: 
                                    ${totalEnabledUnits} ${t('OUT_OF').toLocaleLowerCase()} 
                                    ${totalUnits} ${t('BUSINESS_UNITS').toLocaleLowerCase()} ${t('ENABLED').toLocaleLowerCase()}`}
                            </Text>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default DisableBusinessUnit;
