import { Header as CWDSHeader } from '@ingka-group-digital/cwds-react-header';
import { getLinks } from '@ingka-group-digital/opp-components';
import { isProd, isDemo, Environment, isTest } from 'utils/environments';
import { UserProfile } from 'types/authContext';
import { convertBlobToImageSrc } from 'utils/blob';
import React from 'react';
import classes from './NavigationBar.module.scss';

export const getWaterMarkClass = () => {
    if (isProd) return '';
    if (isDemo) return classes[`app-bar__watermark--${Environment.DEMO}`];
    if (isTest) return classes[`app-bar__watermark--${Environment.TEST}`];

    return classes[`app-bar__watermark--${Environment.DEV}`];
};

export const availableApps = () => {
    if (isProd) return getLinks(Environment.PROD);
    if (isDemo) return getLinks(Environment.DEMO);
    if (isTest) return getLinks(Environment.TEST);

    return getLinks(Environment.DEV);
};

export const getCWDSUserProfile = (user: UserProfile | null): React.ComponentProps<typeof CWDSHeader>['user'] => {
    if (!user) return undefined;

    return {
        picture: user?.image ? convertBlobToImageSrc(user.image, 'jpeg') : undefined,
        /*
            If no picture is available, the first letters of given_name and family_name is used.
            If not provided, it shows 'undefined undefined'...
            Since we only have name, use the first letter of the name and override the family_name with a space.
            If user is not yet loaded, show a question mark.
        */
        // eslint-disable-next-line camelcase
        given_name: user?.name ?? '?',
        // eslint-disable-next-line camelcase
        family_name: ' ',
    };
};
