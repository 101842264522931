export interface ContributionConfig {
    evening: number;
    saturday: number;
    sunday: number;
}
export interface CostCentreContribution extends ContributionConfig {
    costCentre: string;
    duration?: {
        startDate: string;
        endDate: string;
    };
}
export type CostDistribution = {
    costCentre: string;
    costCentrePercent: number;
    deleted?: boolean;
};
export type ContractRange = {
    type: string;
    range: {
        max: number;
        min: number | null; // Min = null means min = max.
    };
    description: string;
};
export enum EmploymentStatus { ACTIVE = 'active', DEACTIVATED = 'deactivated'}
export enum CoworkerAvailability {
    ABSENCE = 'A',
    HOLIDAY = 'H',
    CONTRACT_START_OR_END = 'contract_start_or_end',
    HOURS = 'hours'
}
export type Coworker = {
    annualWorkingHours: number;
    businessUnitNo: string;
    businessUnitType: string;
    companyCode: string;
    companyName: string;
    contractType: string;
    costCentre: string;
    costDistributions: Array<CostDistribution>;
    contractEndDate: string | null;
    contractStartDate: string | null;
    contributions: Array<CostCentreContribution>;
    country: string;
    createdBy: string;
    createdOn: string;
    dailyWorkingHours: number;
    departmentCode: string;
    departmentName: string;
    email: string;
    employeeGroup: string;
    employeeId: string;
    employeeSubGroup: string;
    employmentPercentage: number;
    employmentStatus: EmploymentStatus;
    employmentType: string;
    gvCompanyCode: string;
    hireDate: string;
    hoursPerWeek: number;
    isDummy?: boolean;
    job: string;
    jobTitle: string;
    legalFirstName: string;
    legalFullName: string;
    legalLastName: string;
    legalMiddleName: string | null;
    loginId: string;
    managerFlag: boolean;
    managerId: string;
    monthlyHours: number;
    organizationalKey: string | null;
    partTimeIndicator: boolean;
    personId: string;
    personalAreaName: string;
    division: string;
    divisionName: string;
    positionId: string;
    positionName: string;
    terminationDate: string | null;
    updatedBy: string;
    updatedOn: string;
    weeklyWorkDays: number;
    contractRange: ContractRange | null | undefined;
    isModulated: boolean;
};
