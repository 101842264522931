import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ListView, { ListViewItem } from '@ingka/list-view';
import download from '@ingka/ssr-icon/paths/arrow-down-to-line';
import Hyperlink from '@ingka/hyperlink';
import { APIPath } from 'components/CapacityFactor/FileUpload/types';
import { API } from 'services';
import useData from 'hooks/useData';
import { FileFormat } from 'types/fileFormat';
import useAuth from 'hooks/useAuth';
import classes from './DefaultTemplates.module.scss';

const templates = [
    { id: 'capacity-factors', label: 'CAPACITY_FACTORS', apiType: APIPath.CAPACITY_FACTORS },
    { id: 'budget-hours', label: 'BUGDET_HOURS', apiType: APIPath.BUDGET_HOURS },
    { id: 'default-contract-contributions', label: 'DEFAULT_CONTRACT_CONTRIBUTION', apiType: APIPath.DEFAULT_CONFIG },
    { id: 'coworker-expectations', label: 'COWORKER_EXCEPTIONS', apiType: APIPath.CUSTOM_CONTRIBUTIONS },
    { id: 'headcount-demand', label: 'HEADCOUNT_DEMAND', apiType: APIPath.WORKLOAD },
    { id: 'coworker-turnover', label: 'COWORKER_TURN_OVER', apiType: APIPath.COWORKER_TURN_OVER }
];
const DefaultTemplates = () => {
    const { t } = useTranslation();
    const { access } = useAuth();
    const { currentCountry, currentUnit, currentUnitType } = useData();

    const downloadTemplate = (category: string) => {
        if (access?.api?.readInputs) {
            API().getDownloadDefaultFile(category, currentCountry, currentUnit, currentUnitType).then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response], { type: FileFormat.xlsx }));
                link.download = `${category}_${currentCountry}_${currentUnit}`; // Setting the FileName as budget_DK_121
                link.click();
                setTimeout(() => {
                    URL.revokeObjectURL(link.href);
                }, 1000);
            });
        }
    };

    return (
        <>
            <div className={classes['default-templates__header']}>
                <span id="default-templates-header" data-testid="default-templates-header">
                    {t('DEFAULT_TEMPLATES_DESCRIPTION')}
                </span>
            </div>
            <div>
                <Trans
                    i18nKey={t('DEFAULT_TEMPLATES_INSTRUCTIONS')}
                    components={[
                        <Hyperlink
                            key="default-templates-instructions-link"
                            url={process.env.REACT_APP_URLS_default_templates_url}
                            newWindow
                            target="_blank"
                        />
                    ]}
                />
            </div>
            <div>
                <ListView id="default-template-content" data-testid="default-template-list-view">
                    {templates.map(({ id, label, apiType }) => (
                        <ListViewItem
                            key={`default-template-${id}`}
                            data-testid={`default-template-${id}`}
                            className={classes['default-templates__listbox-item']}
                            inset
                            emphasised
                            description={t(label)}
                            leadingIcon={download}
                            size="large"
                            control="navigational"
                            controlIcon={null}
                            onClick={() => downloadTemplate(apiType)}
                        />
                    ))}
                </ListView>
            </div>
        </>

    );
};

export default DefaultTemplates;
