import { noop } from 'lodash';
import { createContext, useMemo, useState } from 'react';
import { ContractChangeType } from 'types/actionPlan';

type ActionPlanContextValues = {
    activeFilter: ContractChangeType | null;
    setActiveFilter: (activeFilter: ContractChangeType | null) => void,
};

const ActionPlanContext = createContext<ActionPlanContextValues>({
    activeFilter: null,
    setActiveFilter: noop,
});

const ActionPlanProvider = ({ children }: { children: JSX.Element }) => {
    const [activeFilter, setActiveFilter] = useState<ContractChangeType | null>(null);

    const contextValue = useMemo(() => ({
        activeFilter,
        setActiveFilter,

    }), [
        activeFilter,
        setActiveFilter,
    ]);

    return (
        <ActionPlanContext.Provider value={contextValue}>
            {children}
        </ActionPlanContext.Provider>
    );
};

export { ActionPlanContext, ActionPlanProvider };
