import { CountryOMData,
    CustomContributions,
    DefaultContributions,
    InputRates } from 'types/dataContext';
import { CellGenContractData, CellGenContractDeltaData, CellGenCoworkerDelta, CellGenWeeklyDeltaData, CoworkerGridData,
    CoworkerGridDataRef,
    CoworkerGridRow,
    DummyGridData,
    EditableContractHoursCellProps,
    EditableModulationCellProps,
    GenerateEditableCells,
    GridDataCoworkers,
    RowType,
    TimeSelection } from 'types/appContext';
import { Scenario,
    ScenarioDelta,
    DummyCoworker,
    ContractDelta,
    WeeklyDelta } from 'types/scenario';
import { CoworkerAvailability, Coworker, EmploymentStatus } from 'types/coworker';
import { DateHelper, INDEFINITE_END_DATE, BEGINNING_OF_TIME, isoWeekFromDateString } from 'utils/date';
import { calculateSum, getHoursInDecimal, roundValue } from 'utils/number';
import { costCentreIsInList, costCentresAreSame, getShortCC } from 'utils/text';
import _ from 'lodash';
import { UserProfile } from 'types/authContext';
import { isIdenticalTimeSelection } from 'utils/validation';
import { isCoworkersId } from 'utils/rules';
import { getHeaderContribution, getModulationHeaderContribution } from './gridFunctionsHeaders';
import { getAllCostCentresInUnit, rowIsContractHours } from './gridFunctionsShared';
import { getContractCustomContributions,
    getContractDefaultContributions,
    getContributionForDelta,
    getWeeklyDeltaContributions,
    isCustomContributionActive,
    noContribution,
    selectActiveContribution } from './gridFunctionsInputFiles';

const getCostCentreDescription = (
    cc: string,
    OMData: CountryOMData | undefined
) => OMData?.departments?.find(dept => dept.costCentre.includes(cc))?.costCentreDesc ?? '';

/**
 * extracts the contributions in terms of hours and evenings/weekends that a coworker provides for the given cost centre.
 */
const getContractData = (
    coworker: Coworker | DummyCoworker,
    costCentre: string,
    referenceDate: string,
    defaultContributions: DefaultContributions | undefined,
    customContributions: CustomContributions | undefined,
): CellGenContractData => {
    const isActive = coworker?.employmentStatus === EmploymentStatus.ACTIVE;
    const contractHours = isActive ? coworker?.contractRange?.range.max ?? coworker?.hoursPerWeek ?? 0 : 0;
    const contractDistribution = (coworker?.costDistributions.find(cd => costCentresAreSame(cd.costCentre, costCentre))?.costCentrePercent ?? 0) / 100;
    const defContributions = getContractDefaultContributions(
        coworker,
        costCentre,
        isActive,
        defaultContributions,
    );

    const custContributions = getContractCustomContributions(
        coworker,
        costCentre,
        referenceDate,
        isActive,
        customContributions,
    );

    return {
        startWeeksFromZero: DateHelper.getDateDifferenceInWeeks(coworker?.contractStartDate || BEGINNING_OF_TIME, referenceDate),
        endWeeksFromZero: DateHelper.getDateDifferenceInWeeks(coworker?.contractEndDate || INDEFINITE_END_DATE, referenceDate),
        weeklyHours: contractHours * contractDistribution,
        timeStamp: BEGINNING_OF_TIME,
        availability: CoworkerAvailability.HOURS,
        startDay: DateHelper.getWeekDay(coworker?.contractStartDate || BEGINNING_OF_TIME),
        endDay: DateHelper.getWeekDay(coworker?.contractEndDate || INDEFINITE_END_DATE),
        defaultContributions: defContributions,
        customContributions: custContributions,
    };
};

/**
 * Gets the contract hours for a specific week based on the contract data. If the week is not within the contract period, the hours are set to 0.
 * If the contract starts and/or ends within the week, this returns a fraction of the weekly hours based on how many days within the week
 * the contract is active.
 *
 * @returns The contract hours for the week, rounded to two decimal places.
 */
const getContractHoursForWeek = (contractData: CellGenContractData, weeksFromZero: number) => {
    let hours = contractData.weeklyHours;
    if (contractData.startWeeksFromZero === weeksFromZero && contractData.endWeeksFromZero === weeksFromZero) {
        hours = contractData.weeklyHours * ((contractData.endDay - contractData.startDay + 1) / 7);
    } else if (contractData.startWeeksFromZero === weeksFromZero && contractData.endWeeksFromZero > weeksFromZero) {
        hours = contractData.weeklyHours * ((7 - contractData.startDay + 1) / 7);
    } else if (contractData.startWeeksFromZero < weeksFromZero && contractData.endWeeksFromZero === weeksFromZero) {
        hours = contractData.weeklyHours * (contractData.endDay / 7);
    } else if (contractData.startWeeksFromZero > weeksFromZero || contractData.endWeeksFromZero < weeksFromZero) {
        hours = 0;
    }

    return Math.round(hours * 100) / 100;
};

const getCoworkerDeltaCellgen = (
    coworkerDelta: Partial<Coworker>,
    coworkerOrDummy: Coworker | DummyCoworker,
    referenceDate:string,
    costCentre: string,
    defaultContributions: DefaultContributions | undefined,
    customContributions: CustomContributions | undefined,
): CellGenCoworkerDelta => {
    const updatedCoworker = { ...coworkerOrDummy, ...coworkerDelta } as Coworker | DummyCoworker;
    const { startWeeksFromZero, endWeeksFromZero, weeklyHours, defaultContributions: defContributions, customContributions: custCont } = getContractData(
        updatedCoworker,
        costCentre,
        referenceDate,
        defaultContributions,
        customContributions,
    );

    return ({
        startWeeksFromZero,
        endWeeksFromZero,
        weeklyHours,
        timeStamp: BEGINNING_OF_TIME,
        availability: CoworkerAvailability.HOURS,
        delta: coworkerDelta,
        defaultContributions: defContributions,
        customContributions: custCont
    });
};

const mapContractDeltasCellgen = (
    contractDeltas: ContractDelta[],
    outputArray: CellGenContractDeltaData,
    costCentre: string,
    coworker: Coworker | DummyCoworker,
    referenceDate: string,
    defaultContributions: DefaultContributions | undefined,
    customContributions: CustomContributions | undefined,
) => {
    contractDeltas.filter(contDelt => contDelt.costDistributions.some(costDist => costCentresAreSame(costDist.costCentre, costCentre)))
        .forEach(contractDelta => {
            const totalHours = contractDelta.hoursPerWeekRange ? contractDelta.hoursPerWeekRange.range.max : contractDelta.hoursPerWeek;
            const distributionInCostcentre = (contractDelta.costDistributions
                .find(cd => costCentresAreSame(cd.costCentre, costCentre))?.costCentrePercent ?? 0) / 100;
            const contributions = getContributionForDelta(
                contractDelta,
                costCentre,
                coworker,
                referenceDate,
                defaultContributions,
                customContributions,
            );
            outputArray.push({
                startWeeksFromZero: DateHelper.getDateDifferenceInWeeks(
                    DateHelper.isoWeekFromDate(contractDelta.startDate),
                    referenceDate,
                ),
                endWeeksFromZero: DateHelper.getDateDifferenceInWeeks(
                    DateHelper.isoWeekFromDate(contractDelta.endDate ?? INDEFINITE_END_DATE),
                    referenceDate,
                ),
                weeklyHours: totalHours * distributionInCostcentre,
                timeStamp: contractDelta.timeStamp,
                availability: CoworkerAvailability.HOURS,
                delta: contractDelta,
                defaultContributions: contributions?.defaultContributions ?? noContribution,
                customContributions: contributions?.customContributions ?? null,
            });
        });
};

const mapWeeklyDeltasCellgen = (
    weeklyDeltas: WeeklyDelta[],
    outputArray: CellGenWeeklyDeltaData,
    costCentre: string,
    referenceDate: string,
    coworker: Coworker | DummyCoworker,
    defaultContributions: DefaultContributions | undefined,
    customContributions: CustomContributions | undefined,
) => {
    weeklyDeltas
        .filter(wd => costCentresAreSame(wd.costCentre, costCentre))
        .forEach(weeklyDelta => {
            const hasCustomContribution = customContributions?.some(contribution => (
                isCoworkersId(coworker, contribution.employeeId)
                && getShortCC(contribution.contribution.costCentre) === getShortCC(costCentre)
                && isCustomContributionActive(
                    contribution.contribution.duration.startDate,
                    contribution.contribution.duration.endDate,
                    weeklyDelta.startDate,
                    weeklyDelta.endDate
                )
            )) ?? false;

            outputArray.push({
                startWeeksFromZero: DateHelper.getDateDifferenceInWeeks(
                    DateHelper.isoWeekFromDate(weeklyDelta.startDate),
                    referenceDate,
                ),
                endWeeksFromZero: DateHelper.getDateDifferenceInWeeks(
                    DateHelper.isoWeekFromDate(weeklyDelta.endDate),
                    referenceDate,
                ),
                weeklyHours: weeklyDelta.costCentreHours,
                timeStamp: weeklyDelta.timeStamp,
                availability: weeklyDelta.coworkerAvailability,
                delta: weeklyDelta,
                defaultContributions: getWeeklyDeltaContributions(
                    weeklyDelta,
                    costCentre,
                    defaultContributions,
                    hasCustomContribution, // If there is a custom contribution, it will be handled in the contract deltas unless weekly delta sets hours to 0
                ),
                customContributions: null, // If there is a custom contribution, it will be handled in the contract deltas
            });
        });
};

const generateContractHoursEditableCellsProps = ({
    coworker,
    delta,
    timeArray,
    costCentre,
    defaultContributions,
    customContributions,
}: GenerateEditableCells) => {
    const coworkerOrDummy = coworker ?? delta?.dummyCoworker;
    if (timeArray.length === 0 || !coworkerOrDummy) return [];

    const editableCellRow: Array<EditableContractHoursCellProps> = [];

    const contractData: CellGenContractData = getContractData(coworkerOrDummy, costCentre, timeArray[0], defaultContributions, customContributions);
    timeArray.forEach((week, index) => {
        const hours = getContractHoursForWeek(contractData, index);
        const contributions = selectActiveContribution(index, contractData.defaultContributions, contractData.customContributions);
        editableCellRow.push({
            elementId: `${coworkerOrDummy.personId}:${costCentre}:${week}`,
            originalValue: coworker ? hours : 0, // Always 0 for dummy coworkers
            currentValue: hours,
            key: `${coworkerOrDummy.personId}:${costCentre}:${week}-cell`,
            coworkerAvailability: CoworkerAvailability.HOURS,
            contributions: contributions ?? noContribution,
        });
    });

    if (delta) {
        const contractDeltas: CellGenContractDeltaData = [];
        const weeklyDeltas: CellGenWeeklyDeltaData = [];
        let coworkerDelta: CellGenCoworkerDelta;
        if (delta.coworkerDelta) {
            coworkerDelta = getCoworkerDeltaCellgen(delta.coworkerDelta, coworkerOrDummy, timeArray[0], costCentre, defaultContributions, customContributions);
        }
        if (delta.contractDeltas?.length) {
            mapContractDeltasCellgen(
                delta.contractDeltas,
                contractDeltas,
                costCentre,
                coworkerOrDummy,
                timeArray[0],
                defaultContributions,
                customContributions,
            );
        }
        if (delta.weeklyDeltas?.length) {
            mapWeeklyDeltasCellgen(
                delta.weeklyDeltas,
                weeklyDeltas,
                costCentre,
                timeArray[0],
                coworkerOrDummy,
                defaultContributions,
                customContributions,
            );
        }
        const sortedWeeklyDeltas = weeklyDeltas.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());
        const sortedContractDeltas = contractDeltas.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());

        timeArray.forEach((_week, index) => {
            // Note: Changes are always weekly.
            const latestWeeklyDelta = sortedWeeklyDeltas.find(
                sortedDelta => sortedDelta.startWeeksFromZero <= index && index <= sortedDelta.endWeeksFromZero,
            );
            const latestContractDelta = sortedContractDeltas.find(
                sortedDelta => sortedDelta.startWeeksFromZero <= index && index <= sortedDelta.endWeeksFromZero,
            );
            const latestCoworkerDelta = coworkerDelta && coworkerDelta.startWeeksFromZero <= index && index <= coworkerDelta.endWeeksFromZero
                ? coworkerDelta
                : undefined;

            const allDeltas = [latestContractDelta, latestWeeklyDelta, latestCoworkerDelta].filter(Boolean);

            const sortedDeltas = allDeltas.length
                ? allDeltas.sort(
                    (a, b) => new Date(b?.timeStamp ?? BEGINNING_OF_TIME).getTime()
                          - new Date(a?.timeStamp ?? BEGINNING_OF_TIME).getTime(),
                )
                : null;
            const activeDelta = sortedDeltas?.[0];
            const activeContribution = sortedDeltas?.map(
                del => selectActiveContribution(index, del?.defaultContributions ?? null, del?.customContributions ?? null),
            )?.find(contribution => contribution !== null) ?? null;
            if (activeDelta) {
                editableCellRow[index].currentValue = activeDelta.weeklyHours;
                editableCellRow[index].coworkerAvailability = activeDelta.availability;
                editableCellRow[index].scenarioDeltas = {
                    latestContractDelta: latestContractDelta?.delta,
                    latestWeeklyDelta: latestWeeklyDelta?.delta,
                    latestCoworkerDelta: coworkerDelta?.delta,
                };
            }
            if (activeContribution) {
                editableCellRow[index].contributions = activeContribution;
            }
        });
    }

    return editableCellRow;
};

const generateModulationEditableCellsProps = (
    coworker: Coworker | undefined,
    delta: ScenarioDelta | null,
    timeArray: Array<string>,
    costCentreId: string,
) => {
    const row: Array<EditableModulationCellProps> = [];
    const modulationHoursDeltaForCC = delta?.modulationDeltas?.filter(cc => costCentresAreSame(cc.costCentre, costCentreId));

    timeArray.forEach((week: string) => {
        const modulationHours = modulationHoursDeltaForCC?.find(el => isoWeekFromDateString(el.startDate) === week);
        row.push({
            elementId: `${coworker?.personId}:${costCentreId}:${week}`,
            originalHours: 0,
            originalMinutes: 0,
            currentHours: modulationHours ? modulationHours.hours : 0,
            currentMinutes: modulationHours ? modulationHours.minutes : 0,
            key: `${coworker?.personId}:${costCentreId}:${week}-cell`,
        });
    });

    return row;
};

export const generateTotalHoursCellProps = (
    costCentre: string,
    personId: string,
    gridRows: Array<CoworkerGridRow>,
    timeArray: Array<string>,
) => {
    const row: Array<EditableContractHoursCellProps> = [];

    timeArray.forEach((time, index) => {
        const elementId = `total-hours-${personId}:${costCentre}:${time}`;
        const currentValue = calculateSum(gridRows.map(el => {
            if (rowIsContractHours(el)) {
                return Number(el.editableCells[index].currentValue);
            }

            if (el.rowType === RowType.MODULATION) {
                const castedModulationEditableCell = (el.editableCells as Array<EditableModulationCellProps>)[index];

                return getHoursInDecimal(castedModulationEditableCell.currentHours, castedModulationEditableCell.currentMinutes);
            }

            return 0;
        }));

        row.push({
            elementId,
            originalValue: currentValue,
            currentValue,
            key: elementId,
            coworkerAvailability: CoworkerAvailability.HOURS,
            contributions: noContribution,
        });
    });

    return row;
};

const getContractDelta = (personId: string, scenario: Scenario | undefined): ScenarioDelta | null => {
    if (!scenario) {
        return null;
    }

    const coworkerScenarioRaw = scenario.scenarioData.find(delta => delta.personId === personId);

    return coworkerScenarioRaw ?? null;
};

const getAllCostCenters = (coworker: Coworker | DummyCoworker | undefined, cwScenarioDelta: ScenarioDelta | null) => {
    const ccList = coworker?.costDistributions.map(cd => cd.costCentre) || [];

    cwScenarioDelta?.dummyCoworker?.costDistributions.forEach(cd => {
        if (!ccList.includes(cd.costCentre) && cd.costCentre) {
            ccList.push(cd.costCentre);
        }
    });

    cwScenarioDelta?.contractDeltas.forEach(delta => {
        delta?.costDistributions?.forEach(cd => {
            if (!ccList.includes(cd.costCentre) && cd.costCentre) {
                ccList.push(cd.costCentre);
            }
        });
    });

    return ccList;
};

/**
 * Function that generates the data used in the manage capacity row tables
 * for all the coworkers in the grid and any dummy coworkers in the scenario.
 * @param coworkers Array of coworkers
 * @param scenario Current scenario
 * @param timeSelection TimeSelection
 * @param costCentreList List of cost centres
 * @param hasModulationAccess If the user has access to modulation
 * @param currentUnitRates Unit rates/ Capacity factors
 * @param defaultContributions Contributions from the default contributions
 * @param customContributions Any custom contributions
 * @returns Array<CoworkerGridData | DummyGridData>
 */
const generateGridRows = (
    {
        coworkers,
        scenario,
        timeSelection,
        costCentreList,
        hasModulationAccess,
        currentUnitRates,
        defaultContributions,
        customContributions
    } : {
        coworkers: Array<Coworker> | undefined,
        scenario: Scenario | undefined,
        timeSelection: TimeSelection,
        costCentreList: Array<string>,
        hasModulationAccess: boolean,
        currentUnitRates: InputRates | undefined,
        defaultContributions: DefaultContributions | undefined,
        customContributions: CustomContributions | undefined
    }
) => {
    const gridData: Array<CoworkerGridData | DummyGridData> = [];

    // When coworker IS dummy coworker
    scenario?.scenarioData
        .forEach(scenDelta => {
            if (!scenDelta.dummyCoworker) return;
            const confirmedDummyCoworker = scenDelta.dummyCoworker;

            const costCenters = getAllCostCenters(confirmedDummyCoworker, scenDelta);
            const gridRows = [] as Array<CoworkerGridRow>;
            costCenters.forEach(ccId => {
                const editableCells = generateContractHoursEditableCellsProps({
                    coworker: undefined,
                    delta: scenDelta,
                    timeArray: timeSelection.timeArray,
                    costCentre: ccId,
                    defaultContributions,
                    customContributions
                });
                const rowSum = editableCells
                    .map(p => (typeof p.currentValue === 'number' ? p.currentValue : 0))
                    .reduce((total, term) => total + term, 0);
                const rowAverage = roundValue(rowSum / editableCells.length) || 0;

                const headerContributions = editableCells.map(
                    (editableCell, index) => getHeaderContribution(
                        confirmedDummyCoworker,
                        editableCell,
                        ccId,
                        timeSelection.timeArray[index],
                        currentUnitRates,
                    )
                );

                gridRows.push({
                    costCentre: ccId,
                    editableCells,
                    rowSum: costCentreList.includes(ccId) ? roundValue(rowSum) : 0,
                    rowAverage,
                    rowType: RowType.CONTRACT_HOURS,
                    headerContributions,
                });
            });

            gridData.push({
                coworker: scenDelta.dummyCoworker,
                gridRows,
            });
        });

    // When coworker IS NOT dummy coworker
    coworkers?.forEach(coworker => {
        const cwScenarioDelta = getContractDelta(coworker.personId, scenario);

        const costCentres = getAllCostCenters(coworker, cwScenarioDelta);
        const gridRows: Array<CoworkerGridRow> = [];
        costCentres.forEach(ccId => {
            const editableCells = generateContractHoursEditableCellsProps({
                coworker,
                delta: cwScenarioDelta,
                timeArray: timeSelection.timeArray,
                costCentre: ccId,
                defaultContributions,
                customContributions
            });
            const rowSum = editableCells
                .map(p => (typeof p.currentValue === 'number' ? p.currentValue : 0))
                .reduce((total, term) => total + term, 0);
            const rowAverage = roundValue(rowSum / editableCells.length) || 0;

            const headerContributions = editableCells.map(
                (editableCell, index) => getHeaderContribution(
                    coworker,
                    editableCell,
                    ccId,
                    timeSelection.timeArray[index],
                    currentUnitRates,
                )
            );

            gridRows.push({
                costCentre: ccId,
                editableCells,
                rowSum: costCentreList.some(ccItem => ccItem.includes(ccId)) ? roundValue(rowSum) : 0,
                rowAverage,
                rowType: RowType.CONTRACT_HOURS,
                headerContributions,
            });

            // Add modulation for the current cost center
            if (hasModulationAccess) {
                const modulationEditableCells = generateModulationEditableCellsProps(
                    coworker,
                    cwScenarioDelta,
                    timeSelection.timeArray,
                    ccId,
                );
                const modulationRowSum = modulationEditableCells
                    .map(value => value.currentHours + value.currentMinutes / 60)
                    .reduce((total, term) => total + term, 0);
                const modulationRowAverage = roundValue(modulationRowSum / editableCells.length) || 0;

                const modulationHeaderContributions = modulationEditableCells.map(
                    (editableCell, index) => getModulationHeaderContribution(
                        editableCell,
                        ccId,
                        timeSelection.timeArray[index],
                        currentUnitRates,
                    )
                );
                gridRows.push({
                    costCentre: ccId,
                    editableCells: modulationEditableCells,
                    rowSum: costCentreList.includes(ccId) ? roundValue(modulationRowSum) : 0,
                    rowAverage: modulationRowAverage,
                    rowType: RowType.MODULATION,
                    headerContributions: modulationHeaderContributions,
                });
            }
        });

        gridData.push({
            coworker,
            gridRows,
        });
    });

    return gridData;
};

/**
 * Filters the gridRows by removing any coworkers that do not belong to any cost centre
 * in the costCentreList.
 * @param gridRowsData gridRows generated by generateGridRows
 * @param costCentreList List of cost centres
 * @returns \{coworkers: Array<(DummyGridData | CoworkerGridData)>}
 */
const filterGridDataByCostCentre = (gridRowsData: GridDataCoworkers | undefined, costCentreList: string[] | undefined) => {
    if (!gridRowsData) {
        return undefined;
    }

    return {
        coworkers: gridRowsData.coworkers
            .filter(coworker => !costCentreList || costCentreList.length === 0 || coworker.gridRows
                .some(row => costCentreIsInList(row.costCentre, costCentreList))) ?? [],
    };
};

/**
 * A function which merges two gridRows objects. The scenarioData object always takes priority over
 * the coworkerData object. So if a coworker is present in both objects, the coworker in scenarioData
 * overwrites the coworker in coworkerData when producing the final output.
 * @param coworkerData gridRows generated by generateGridRows without the scenario data. Contains all the coworkers in the unit
 * @param scenarioData gridRows generated by generateGridRows with only coworkers/dummys affected by the scenario data
 * @returns A merged object where coworkers in scenarioData replace corresponding coworkers in coworkerData
 */
const mergeRowsData = (
    coworkerData: GridDataCoworkers | undefined,
    scenarioData: GridDataCoworkers | undefined
): GridDataCoworkers | undefined => {
    if (!coworkerData && !scenarioData) {
        return undefined;
    }
    if (!coworkerData?.coworkers) {
        return _.cloneDeep(scenarioData);
    }
    if (!scenarioData?.coworkers) {
        return { coworkers: _.cloneDeep(coworkerData.coworkers) };
    }

    const mergedList = _.cloneDeep(coworkerData.coworkers);
    const clonedScenarioData = _.cloneDeep(scenarioData);
    clonedScenarioData.coworkers.forEach(scenarioCoworker => {
        if (scenarioCoworker.coworker.isDummy) {
            mergedList.unshift(scenarioCoworker);
        } else {
            mergedList[mergedList.findIndex(coworker => coworker.coworker.personId === scenarioCoworker.coworker.personId)] = scenarioCoworker;
        }
    });

    return {
        coworkers: mergedList
    };
};

/**
 * A helper function which generates gridrows for all coworkers/dummy coworkers which are in the scenario.
 * and have a cost centre in the costCentreList.
 * @param param0
 * @returns gridRows for all coworkers/dummy coworkers which are in the scenario.
 */
const getScenarioGridRows = (
    {
        costCentreList,
        unfilteredCountryOMData,
        currentUnit,
        coworkers,
        currentScenario,
        timeSelection,
        hasModulationAccess,
        currentUnitRates,
        defaultContributions,
        customContributions,
    } : {
        costCentreList: Array<string> | undefined,
        unfilteredCountryOMData: CountryOMData | undefined,
        currentUnit: string,
        coworkers: Array<Coworker> | undefined,
        currentScenario: Scenario | undefined,
        timeSelection: TimeSelection,
        hasModulationAccess: boolean,
        currentUnitRates: InputRates | undefined,
        defaultContributions: DefaultContributions | undefined,
        customContributions: CustomContributions | undefined,
    }
) => {
    const costCentres = costCentreList ?? getAllCostCentresInUnit(unfilteredCountryOMData, currentUnit);
    const coworkerIdsInScenario = currentScenario?.scenarioData
        .filter(data => data.coworkerSource === 'PA')
        .map(data => data.personId) ?? [];
    const coworkersInScenario = coworkers?.filter(coworker => coworkerIdsInScenario.includes(coworker.personId)) ?? [];

    return {
        coworkers: generateGridRows(
            {
                coworkers: coworkersInScenario,
                scenario: currentScenario,
                timeSelection,
                costCentreList: costCentres,
                hasModulationAccess,
                currentUnitRates,
                defaultContributions,
                customContributions,
            }
        )
    };
};

/**
 * A helper function which primarily keeps track of whether or not the
 * gridDataRef needs to be updated. If it does, it uses generateGridRows
 * on all the coworkers in all the cost centres and stores the data for future use.
 * @param param0
 * @returns CoworkerGridDataRef (which includes gridrows and trackers
 * for whether or not the data needs to be updated)
 */
const updateCoworkerGridDataRef = (
    {
        coworkerGrid,
        coworkers,
        timeSelection,
        user,
        currentUnit,
        unfilteredCountryOMData,
        currentUnitRates,
        defaultContributions,
        customContributions,
        hasModulationAccess,
    }:
    {
        coworkerGrid: CoworkerGridDataRef | undefined,
        coworkers: Array<Coworker> | undefined,
        timeSelection: TimeSelection,
        user: UserProfile | null,
        currentUnit: string | undefined,
        unfilteredCountryOMData: CountryOMData | undefined,
        currentUnitRates: InputRates | undefined,
        defaultContributions: DefaultContributions | undefined,
        customContributions: CustomContributions | undefined,
        hasModulationAccess: boolean,
    }
) => {
    if (
        coworkerGrid
                && coworkerGrid.coworkers.length > 0
                && isIdenticalTimeSelection(coworkerGrid.timeSelection, timeSelection)
                && currentUnit === coworkerGrid.currentUnit
                && unfilteredCountryOMData?.country === coworkerGrid.currentCountry
                && (coworkerGrid?.defaultContributionsIdentifier
                    && defaultContributions && JSON.stringify(defaultContributions) === coworkerGrid.defaultContributionsIdentifier)
                && (coworkerGrid?.customContributionsIdentifier
                    && customContributions && JSON.stringify(customContributions) === coworkerGrid.customContributionsIdentifier)
                && (coworkerGrid?.rateIdentifier
                    && currentUnitRates && JSON.stringify(currentUnitRates) === coworkerGrid.rateIdentifier)
    ) {
        /* This prevents recalculations of the coworkerGridDataRef if nothing has changed.
        If we get here, it means that the coworkerGridDataRef is already up to date. */

        return coworkerGrid;
    }
    if (!user
                || !timeSelection
                || !timeSelection.valid
                || !currentUnit
                || !coworkers) {
        /* These are the required values to calculate the gridData.
            If any of them are missing, we return undefined */

        return undefined;
    }

    return {
        /*
            TODO: Figure out a way to get more efficient identifiers && other ways to reduce redundant calculations when moving timeselection
            Now it calculates once when timeselection updates, then again when rates api call is resolved, and this is the heaviest calculation.
            We want to suspend calculation pending the rates api call, and make sure we dont recalculate if nothing has changed.
            Add a recieved at timestamp in the front end and use as an identifier to replace stringify()?
            Note that different timeselections could in theory yield identical rates (if they span beyond end of last year), so making a validating function
            to prevent unneeded calculations can be difficult (i.e. if (!validateRatesUsingTimeSelection(timeSelection, currentUnitRates)) return undefined)).
        */
        rateIdentifier: currentUnitRates ? JSON.stringify(currentUnitRates) : 'no data',
        defaultContributionsIdentifier: defaultContributions ? JSON.stringify(defaultContributions) : 'no data',
        customContributionsIdentifier: customContributions ? JSON.stringify(customContributions) : 'no data',
        timeSelection,
        currentUnit,
        currentCountry: unfilteredCountryOMData?.country ?? '',
        coworkers: generateGridRows(
            {
                coworkers,
                scenario: undefined,
                timeSelection,
                costCentreList: getAllCostCentresInUnit(unfilteredCountryOMData, currentUnit),
                hasModulationAccess,
                currentUnitRates,
                defaultContributions,
                customContributions,
            }
        ),
    };
};

export {
    mergeRowsData,
    getScenarioGridRows,
    updateCoworkerGridDataRef,
    generateContractHoursEditableCellsProps,
    generateGridRows,
    getContractDelta,
    filterGridDataByCostCentre,
    roundValue,
    getCostCentreDescription
};
