import moment from 'moment';
import { DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR, DATE_FORMAT_STANDARD, INDEFINITE_END_DATE, getActionWeekDate } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import Text from '@ingka/text';
import { TypeActionGroup } from 'types/actionPlan';
import classes from '../ActionTable.module.scss';

const ActionGroup = ({ actionGroup, totalHours, children }:{ actionGroup: TypeActionGroup, totalHours: number, children: ReactNode }) => {
    const { t } = useTranslation();
    const { startDate, endDate, changedBy, changedAt } = actionGroup;

    return (
        <tr>
            <td className={`${classes['details-changes']} ${classes['details-date']}`}>
                <div>
                    <Text
                        tagName="span"
                        headingSize="s"
                        data-testid="action-plan-date-header"

                    >
                        {getActionWeekDate(startDate, endDate, t('WEEK'))}
                    </Text>
                </div>
                <div className={classes['details-table-description']}>
                    <Text
                        data-testid="action-plan-date-change"
                    >
                        {`${moment(startDate).format(DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR)}`}
                        {` - ${(!endDate || moment(endDate).format(DATE_FORMAT_STANDARD) === INDEFINITE_END_DATE)
                            ? t('INDEFINITE')
                            : moment(endDate).format(DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR)}`}
                    </Text>
                </div>
            </td>
            <td className={classes['details-changes']}>
                {children}
            </td>
            <td className={`${classes['details-changes']} ${classes['details-changes--on-single-line']}`}>
                <Text
                    bodySize="l"
                    data-testid="action-plan-new-total-hours"
                >
                    {`${totalHours} ${t('HOURS_ABBREVIATED')}`}
                </Text>
            </td>
            <td className={`${classes['details-changes']} ${classes['details-changes__changed-by-cell']}`}>
                <Text
                    bodySize="l"
                    data-testid="action-plan-changed-by"
                >
                    {changedBy}
                    <b>
                        {` ${t('AT')} `}
                    </b>
                    {moment(changedAt).format(DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR)}
                </Text>
            </td>
        </tr>
    );
};

export default ActionGroup;
