export const zeroHoursContractRange = {
    range: {
        min: 0,
        max: 0,
    },
    description: 'ZERO_HOURS',
    type: 'ZERO_HOURS',
};

export const MAIN_SCENARIO_SYMBOL = '★';
export const OTHER_SCENARIO_SYMBOL = '☆';
