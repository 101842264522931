import { UserProfile } from 'types/authContext';
import { useEffect, useRef } from 'react';
import { REACT_APP_TRACKER_API_KEY, REACT_APP_TRACKER_URL } from 'utils/env';
import { START_TRACKING__ON_LOAD } from 'types/analytics';

const useAnalytics = (user: UserProfile | null) => {
    const email = user?.email;
    const isAnalyticsLoaded = useRef<boolean | null>(null);

    useEffect(() => {
        if (!user || !REACT_APP_TRACKER_API_KEY || !REACT_APP_TRACKER_URL) {
            isAnalyticsLoaded.current = false;

            return;
        }

        const script = document.createElement('script');
        script.src = `${REACT_APP_TRACKER_URL}/t.js`;
        script.async = true;

        const event = new CustomEvent(START_TRACKING__ON_LOAD, {
            detail: {
                user,
                apiKey: REACT_APP_TRACKER_API_KEY,
                trackerUrl: REACT_APP_TRACKER_URL,
            },
        });

        const eventListener = async () => window.dispatchEvent(event);
        script.onload = eventListener;
        document.body.appendChild(script);
        isAnalyticsLoaded.current = true;

        // Cleanup custom event
        // eslint-disable-next-line consistent-return
        return () => {
            isAnalyticsLoaded.current = false;
            document.removeEventListener(START_TRACKING__ON_LOAD, eventListener);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    return { isAnalyticsLoaded: isAnalyticsLoaded.current };
};

export default useAnalytics;
