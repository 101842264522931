import React from 'react';

export const CheckBoxLabel = ({ label, subLabel }: { label: string, subLabel: string }) => (
    <>
        <div>
            {label}
        </div>
        <span>{subLabel}</span>
    </>

);
