import { isHexColor } from './validation';

const colorGroupBlue = ['#0058A3', '#004f93', '#004682', '#003e72', '#003562', '#002c52', '#002341', '#1a69ac', '#3379b5',
    '#4d8abf', '#669bc8', '#80acd1', '#99bcda'];
const colorGroupRed = ['#CC0008', '#b80007', '#a30006', '#8f0006', '#7a0005', '#660004', '#d11a21', '#d63339',
    '#db4d52', '#e0666b', '#e68084', '#eb999c'];
const colorGroupGreen = ['#00853E', '#007838', '#006a32', '#005d2b', '#005025', '#00431f', '#003519', '#1a9151', '#339d65',
    '#4daa78', '#66b68b', '#80c29f', '#99ceb2'];
const colorGroupOrange = ['#F26A2F', '#da5f2a', '#c25526', '#a94a21', '#91401c', '#793518', '#612a13', '#f37944', '#f58859',
    '#f6976d', '#f7a682', '#f9b597', '#fac3ac'];
const colorGroupLightBlue = ['#007CC1', '#0070ae', '#00639a', '#005787', '#004a74', '#003e61', '#00324d', '#00253a', '#1a89c7', '#3396cd',
    '#4da3d4', '#66b0da', '#80bee0', '#99cbe6'];
const colorGroupPink = ['#E52F7E', '#ce2a71', '#b72665', '#a02158', '#891c4c', '#73183f', '#5c1332', '#e8448b', '#ea5998',
    '#ed6da5', '#ef82b2', '#f297bf', '#f5accb'];
const defaultColorGroups = [colorGroupBlue, colorGroupRed, colorGroupGreen, colorGroupOrange, colorGroupLightBlue, colorGroupPink];

/**
 * Generates a random hexadecimal color code of #rrggbb format
 * @returns string
 */
const getRandomColor = (): string => {
    const potentialLetters = '0123456789ABCDEF';

    const color = '#XXXXXX'.replace(/X/g, () => potentialLetters[Math.floor(Math.random() * 16)]);
    if (isHexColor(color)) {
        return color;
    }

    // Should never happen
    return '#000000';
};

/**
 * Picks a color from an array of hexadecimal color codes. If the data set is too small, a random color is generated
 * A default set of colors is available (blue shades), but custom colors can be provided
 * @param index
 * @param colors string[], where the string is validated as a hexadecimal color code
 * @returns returns a provided color or a #rrggbb string
 */
export const getColorShade = (index: number, colors: string[] = colorGroupBlue) => {
    if (index >= colors.length || !isHexColor(colors[index])) {
        return getRandomColor();
    }

    return colors[index];
};

/**
 * Picks a color from an array of arrays containing different color shades in an alternating pattern. If the data set is too small, a random color is returned
 * A default set of color shades is available, but custom ones can be provided
 * @param index
 * @param colors string[][], where the string is validated as a hexadecimal color code
 * @returns returns a provided color or a #rrggbb string
 */
export const getAlternatingColor = (
    index: number,
    colors: string[][] = defaultColorGroups
) => {
    const colorIndex = Math.floor(index / colors.length);
    const groupIndex = index % colors.length;

    return getColorShade(colorIndex, colors[groupIndex]);
};
