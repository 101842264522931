import { Dispatch, SetStateAction, useCallback } from 'react';
import { AppSwitcherDrawer, AppSwitcherDrawerProps } from '@ingka-group-digital/cwds-react-app-switcher';
import { useTranslation } from 'react-i18next';
import { availableApps } from './NavigationBar.utils';

const AppSwitcherModal = ({
    isAppSwitcherOpen,
    setIsAppSwitcherOpen
}: {
    isAppSwitcherOpen: boolean;
    setIsAppSwitcherOpen: Dispatch<SetStateAction<boolean>>
}) => {
    const { t } = useTranslation();
    const handleHomeClick = useCallback<NonNullable<AppSwitcherDrawerProps['onHomeClick']>>(
        async event => {
            event.preventDefault();
            const oppHomeLink = process.env.REACT_APP_URLS_opp_home_url;
            if (oppHomeLink) {
                window.location.href = oppHomeLink;
            }
        },
        []
    );

    return (
        <AppSwitcherDrawer
            visible={isAppSwitcherOpen}
            accessibleApps={availableApps()}
            isSeeAllAppsShown={false}
            onHomeClick={handleHomeClick}
            onAppSwitcherClose={() => setIsAppSwitcherOpen(false)}
            appSwitcherLabels={{ title: t('PEOPLE_PLANNING_CAPABILITIES'), goToHome: t('GO_TO_HOME', { home: 'One people planning' }) }}
        />
    );
};

export default AppSwitcherModal;
