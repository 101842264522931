import { buildUrlCreateScenarioApi } from 'routes/api';
import { useEffect, useRef } from 'react';
import { toServerDate } from 'utils/date';
import { TypeCreateScenarioRequestAPI, TypeCreateScenarioResponseAPI } from 'types/api';
import useApp from 'hooks/useApp';
import { useWriter } from '../useWriter';
import useData from '../useData';
import useAuth from '../useAuth';

export const useCreateScenarioAPI = () => {
    const { currentCountry, currentUnit, setCurrentScenario, scenarioList, setScenarioList, currentUnitType } = useData();
    const referenceRequestId = useRef<string | null>(null);
    const { user } = useAuth();
    const { handleAnalytics } = useApp();

    const response = useWriter<TypeCreateScenarioRequestAPI, TypeCreateScenarioResponseAPI>({
        url: buildUrlCreateScenarioApi(currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
    });

    useEffect(() => {
        if (
            response.isResponseOk
            && referenceRequestId.current !== response.requestId
            && currentUnit
            && currentCountry
            && response.requestData
            && response.responseData
        ) {
            const newScenario = {
                id: String(response.responseData.id),
                name: response.requestData.name,
                createdBy: user?.name || 'N/A',
                isMain: response.requestData.isMain,
                createdDate: toServerDate(new Date()),
                modifiedBy: user?.name || 'N/A',
                modifiedDate: toServerDate(new Date()),
                unitId: currentUnit,
                country: currentCountry,
                scenarioData: [],
                executed: false,
            };
            setScenarioList({ scenarios: [...(scenarioList?.scenarios ?? []), newScenario] });
            setCurrentScenario(newScenario);
        }

        referenceRequestId.current = response.requestId;
    }, [response, currentUnit, currentCountry, scenarioList, setCurrentScenario, setScenarioList, user]);

    useEffect(() => {
        if (response.isResponseOk && response.requestData?.isMain && handleAnalytics) {
            handleAnalytics();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response.requestId]); // if request id changes, a request has completed

    return {
        ...response,
    };
};
