import moment from 'moment';
import { DATE_FORMAT_FULL_YEAR_ISO_WEEK } from 'utils/date';

export const MINIMUM_DATE_IN_WEEK = '2022-W01';
export const MAXIMUM_DATE_IN_WEEK = '2050-W52';

export const isDateAllowed = (date: string) => !(moment(date).isBefore(moment(MINIMUM_DATE_IN_WEEK))
    || moment(date).isAfter(moment(MAXIMUM_DATE_IN_WEEK))
);

export const getMinCalendarDate = (type: 'start' | 'end', startDate: string) => {
    if (type === 'start') {
        return MINIMUM_DATE_IN_WEEK;
    }
    if (isDateAllowed(startDate)) {
        return moment(startDate).format(DATE_FORMAT_FULL_YEAR_ISO_WEEK);
    }

    return MINIMUM_DATE_IN_WEEK;
};
