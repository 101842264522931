import React from 'react';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import classes from './ContractMix.module.scss';

const ContractMixHeader = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Text data-testid="contract-mix-header-title" tagName="h2" headingSize="m" className={classes['contract-mix-header']}>
                {t('CONTRACT_MIX_TITLE')}
            </Text>
            <Text data-testid="contract-mix-header-subtitle" tagName="span" bodySize="s" className={classes['contract-mix-description']}>
                {t('CONTRACT_MIX_SUBTITLE')}
            </Text>
        </div>
    );
};

export default ContractMixHeader;
