import React, { useState, useEffect, FocusEvent, useRef } from 'react';
import useData from 'hooks/useData';
import { isIntegerOrDecimal } from 'utils/validation';
import { isNumber, roundDisplayValueDoubleDecimal } from 'utils/number';
import { useWriter } from 'hooks/useWriter';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useToast } from 'hooks/useToast';
import { DATE_FORMAT_FULL_YEAR_ISO_WEEK, DATE_FORMAT_STANDARD, DateHelper } from 'utils/date';
import { CoworkerAvailability, Coworker } from 'types/coworker';
import { DummyCoworker, ContractType, ChangeType } from 'types/scenario';
import { ScenarioUpdateSource, TypeScenarioUpdateRequestAPI } from 'types/api';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useHighlightEvent } from 'hooks/useHighlightEvent';
import classes from './EditableCell.module.scss';

const getClassNameByCoworkerAvailability = (coworkerAvailability: CoworkerAvailability) => {
    if (coworkerAvailability === CoworkerAvailability.ABSENCE) {
        return classes['td-cell__absence'];
    }

    if (coworkerAvailability === CoworkerAvailability.HOLIDAY) {
        return classes['td-cell__holiday'];
    }

    return '';
};

const getCurrentValue = (currentValue: string | number, coworkerAvailability: CoworkerAvailability) => {
    if (coworkerAvailability === CoworkerAvailability.ABSENCE) {
        return 'A';
    }

    if (coworkerAvailability === CoworkerAvailability.HOLIDAY) {
        return 'H';
    }

    return roundDisplayValueDoubleDecimal(currentValue) ?? '0';
};

const shouldSave = (
    { value, currentValue, coworkerAvailability, weeksToCopy }:
    { value: string, currentValue:number, coworkerAvailability: CoworkerAvailability, weeksToCopy: number }
) => {
    if (!isNumber(value) && value !== CoworkerAvailability.HOLIDAY && value !== CoworkerAvailability.ABSENCE) {
        return false;
    }

    if (weeksToCopy !== 0) {
        // Skipping this validation here, since else we need separate validations for each affected week.
        return true;
    }
    if (value === coworkerAvailability) {
        // Was A/H, is still A/H

        return false;
    }
    if (value !== currentValue?.toString()) {
        // Not the same hours

        return true;
    }
    if (value === '0' && coworkerAvailability !== CoworkerAvailability.HOURS) {
        // special case, can be A => 0 transition, since A/H has 0 as currentValue

        return true;
    }

    return false;
};

/**
 * Gets the start and end date for the weekly delta, limiting changes to the current contract of the coworker and the timeArray.
 * (No changes allowed outside of the contract dates and no changes allowed outside of the timeArray dates)
 */
const getDeltaDates = (weekDate: string, weeksToCopy: number, contractStartDate: string, contractEndDate:string, timeArray: string[]) => {
    if (weeksToCopy === 0) {
        return {
            startDate: DateHelper.getISOWeekStartDate(weekDate).format(DATE_FORMAT_STANDARD),
            endDate: DateHelper.getISOWeekEndDate(weekDate).format(DATE_FORMAT_STANDARD)
        };
    }
    const proposedStartDate = weeksToCopy > 0
        ? DateHelper.getISOWeekStartDate(weekDate).format(DATE_FORMAT_STANDARD)
        : DateHelper.getISOWeekStartDate(weekDate).add(weeksToCopy, 'weeks').format(DATE_FORMAT_STANDARD);
    const proposedLastDate = weeksToCopy > 0
        ? DateHelper.getISOWeekEndDate(weekDate).add(weeksToCopy, 'weeks').format(DATE_FORMAT_STANDARD)
        : DateHelper.getISOWeekEndDate(weekDate).format(DATE_FORMAT_STANDARD);

    // The latest day of proposedStartDate, contractStartDate and the first day of timeArray[0] is the startDate
    const startDate = DateHelper.getLatestDate([proposedStartDate, contractStartDate, timeArray[0]], 'startOf');
    // The earliest day of proposedLastDate, contractEndDate and the last day of timeArray is the endDate
    const endDate = DateHelper.getEarliestDate([proposedLastDate, contractEndDate, timeArray[timeArray.length - 1]], 'endOf');

    return { startDate, endDate };
};

/**
 * Checks to see if the target neighbour cell is available to be copied to.
 * The neighbour cell week needs to be within the contract dates and be inside the timeArray.
 * @param cellWeek 'YYYY-[W]-WW'
 * @param weeksToShift + for added weeks, - for subtracted weeks
 */
const isNeighbourAvailable = (cellWeek:string, weeksToShift: number, timeArray: string[], contractStartDate:string, contractEndDate:string) => {
    const proposedWeekStartDate = DateHelper.getISOWeekStartDate(cellWeek).add(weeksToShift, 'weeks').format(DATE_FORMAT_STANDARD);
    if (proposedWeekStartDate !== DateHelper.getLatestDate([proposedWeekStartDate, contractStartDate, timeArray[0]], 'startOf')) {
        return false;
    }

    const proposedWeekEndDate = DateHelper.getISOWeekEndDate(cellWeek).add(weeksToShift, 'weeks').format(DATE_FORMAT_STANDARD);
    if (proposedWeekEndDate !== DateHelper.getEarliestDate([proposedWeekEndDate, contractEndDate, timeArray[timeArray.length - 1]], 'endOf')) {
        return false;
    }

    return true;
};

const EditableCell = React.memo(({
    elementId,
    originalValue,
    currentValue,
    canBeEdited,
    coworkerAvailability,
    isDisabled,
    coworker,
    updateScenario,
    tooltipMessage,
    contractStartDate,
    contractEndDate,
    timeArray
}: {
    elementId: string,
    originalValue: number,
    currentValue: number,
    canBeEdited: boolean,
    coworkerAvailability: CoworkerAvailability,
    isDisabled?: boolean,
    coworker: Coworker | DummyCoworker,
    updateScenario: ReturnType<typeof useWriter<TypeScenarioUpdateRequestAPI, {}>>['writeData'],
    tooltipMessage?: string,
    contractStartDate: string,
    contractEndDate: string,
    timeArray: string[]
}) => {
    const [value, setValue] = useState<string>(getCurrentValue(currentValue, coworkerAvailability));
    const [coworkerAvailabilityInternal, setCoworkerAvailabilityInternal] = useState(coworkerAvailability);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const ref = useRef<HTMLTableCellElement>(null);
    const tooltipTimer = useRef<number | null>(null);
    const [isCopying, setIsCopying] = useState(false);
    const [weeksToCopy, setWeeksToCopy] = useState(0);
    const { currentScenario, selectScenario } = useData();
    const { t } = useTranslation();
    const { displayToast } = useToast();
    const isCellChanged = value.replace(',', '.') !== originalValue.toString();
    const { dispatchHighlightEvent } = useHighlightEvent(elementId, setIsCopying);

    const saveToScenario = async (val: string) => {
        if (!shouldSave({ value: val, currentValue: currentValue as number, coworkerAvailability, weeksToCopy })) {
            return;
        }
        const [, costCentre, weekDate] = elementId.split(':', 3);
        const { startDate, endDate } = getDeltaDates(weekDate, weeksToCopy, contractStartDate, contractEndDate, timeArray);

        updateScenario({
            source: ScenarioUpdateSource.MANAGE_CAPACITY_TABLE,
            personId: coworker.personId,
            coworkerName: coworker.legalFullName,
            startDate,
            endDate,
            division: coworker.division,
            costCentre,
            department: coworker.departmentCode ?? null,
            contractType: coworker.contractType as ContractType,
            dummyCoworker: null,
            contractHours: coworker.hoursPerWeek,
            delta: [
                isNumber(val) && isNumber(originalValue)
                    ? {
                        type: ChangeType.WEEKLY_HOURS,
                        from: originalValue,
                        to: Number(val),
                        costCentre,
                    }
                    : {
                        type: ChangeType.AVAILABILITY,
                        from: originalValue,
                        to: val as CoworkerAvailability.ABSENCE | CoworkerAvailability.HOLIDAY,
                        costCentre
                    }
            ],
        }).then(response => {
            if (!response.isResponseOk) {
                displayToast({ title: t('ERROR'), message: t('SAVE_FAILED') });

                return;
            }

            displayToast({ title: t('SUCCESS'), message: t('SAVE_SUCCEEDED') });
        }).finally(() => {
            selectScenario(currentScenario?.id ?? '');
            setWeeksToCopy(0);
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isIntegerOrDecimal(event.target.value)) {
            setValue(event.target.value);
            setCoworkerAvailabilityInternal(CoworkerAvailability.HOURS);

            return;
        }

        if (event.target.value.toUpperCase() === 'H') {
            setValue(event.target.value.toUpperCase());
            setCoworkerAvailabilityInternal(CoworkerAvailability.HOLIDAY);

            return;
        }

        if (event.target.value.toUpperCase() === 'A') {
            setValue(event.target.value.toUpperCase());
            setCoworkerAvailabilityInternal(CoworkerAvailability.ABSENCE);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const [id, cc, thisWeek] = elementId.split(':', 3);
        if (event.key === 'Control') {
            setIsCopying(true);
        }
        if (event.ctrlKey && event.key === 'ArrowRight') {
            if (!isNeighbourAvailable(thisWeek, weeksToCopy + 1, timeArray, contractStartDate, contractEndDate)) {
                return;
            }
            setWeeksToCopy(prev => {
                if (prev >= 0) {
                    const targetId = `${id}:${cc}:${moment(thisWeek).add(prev + 1, 'week').format(DATE_FORMAT_FULL_YEAR_ISO_WEEK)}`;
                    dispatchHighlightEvent(true, targetId);
                } else {
                    const targetId = `${id}:${cc}:${moment(thisWeek).add(prev, 'week').format(DATE_FORMAT_FULL_YEAR_ISO_WEEK)}`;
                    dispatchHighlightEvent(false, targetId);
                }

                return prev + 1;
            });
        } else if (event.ctrlKey && event.key === 'ArrowLeft') {
            if (!isNeighbourAvailable(thisWeek, weeksToCopy - 1, timeArray, contractStartDate, contractEndDate)) {
                return;
            }
            setWeeksToCopy(prev => {
                if (prev <= 0) {
                    const targetId = `${id}:${cc}:${moment(thisWeek).add(prev - 1, 'week').format(DATE_FORMAT_FULL_YEAR_ISO_WEEK)}`;
                    dispatchHighlightEvent(true, targetId);
                } else {
                    const targetId = `${id}:${cc}:${moment(thisWeek).add(prev, 'week').format(DATE_FORMAT_FULL_YEAR_ISO_WEEK)}`;
                    dispatchHighlightEvent(false, targetId);
                }

                return prev - 1;
            });
        }
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Control' && weeksToCopy !== 0) {
            // Trigger blur to save if many weeks are selected
            event.currentTarget.blur();
        } else if (event.key === 'Control') {
            setIsCopying(false);
        }
    };

    const handleBlur = () => {
        setIsFocused(false);
        saveToScenario(value.replace(',', '.'));
        dispatchHighlightEvent(false);
        // Format to a localized display format
        setValue(getCurrentValue(value.replace(',', '.'), coworkerAvailabilityInternal));
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        if (coworkerAvailability === CoworkerAvailability.HOURS && value === getCurrentValue(currentValue, coworkerAvailability)) {
            // Revert localized display format to a valid input format
            setValue(currentValue.toString());
        }
        // Using setTimeout to allow the select to happen after the setValue
        setTimeout(() => event.target.select());
    };

    const handleMouseEnter = () => {
        if (tooltipMessage) {
            tooltipTimer.current = window.setTimeout(() => {
                setShowTooltip(true);
                tooltipTimer.current = null;
            }, 500);
        }
    };

    const handleMouseLeave = () => {
        if (tooltipMessage) {
            if (tooltipTimer.current) {
                clearTimeout(tooltipTimer.current);
            }
            setShowTooltip(false);
        }
    };

    useEffect(() => {
        setValue(getCurrentValue(currentValue, coworkerAvailability));
        setCoworkerAvailabilityInternal(coworkerAvailability);
    }, [currentValue, coworkerAvailability]);

    return (
        <td
            ref={ref}
            key={elementId}
            data-testid="editable-cell-td"
            className={`
            ${classes['td-cell']} 
            ${isCellChanged ? classes['td-cell-edited'] : ''} 
            ${(isFocused && !isDisabled) ? classes['td-cell-focused'] : ''}
            ${currentScenario ? classes['td-select-cell'] : ''}
            ${isDisabled ? classes.disabled : ''}
            ${getClassNameByCoworkerAvailability(coworkerAvailabilityInternal)}
            ${isCopying ? ` ${classes['td-cell-copy']}` : ''}
            `}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {tooltipMessage && (
            <Tooltip
                isOpen={showTooltip && !isFocused}
                content={tooltipMessage}
                parentElement={ref.current}
                position="top"
                offsetPx={5}
                styling="discreet"
            />
            )}
            {canBeEdited && !isDisabled ? (
                <input
                    data-testid="editable-cell"
                    className={`${classes['input-cell']} ${isCellChanged ? classes['input-cell-edited'] : ''}`}
                    id={elementId}
                    type="text"
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoComplete="off"
                />
            ) : (
                <span data-testid="editable-cell-span">
                    {value}
                </span>
            )}
        </td>
    );
});

export default EditableCell;
