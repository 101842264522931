import { useUpdateScenarioAPI } from 'hooks/api/useUpdateScenarioAPI';
import useApp from 'hooks/useApp';
import useData from 'hooks/useData';
import { useTranslation } from 'react-i18next';
import { EditableModulationCellProps } from 'types/appContext';
import { DummyCoworker } from 'types/scenario';
import { getGreyedOutCell } from 'components/GreyedOutCell/helpers';
import { useMemo } from 'react';
import { ModulationRange } from 'types/modulation';
import { RangeStatus } from 'types/enums';
import { Coworker } from 'types/coworker';
import { costCentreIsInList } from 'utils/text';
import classes from './ModulationSubRow.module.scss';
import ModulationCell from './ModulationCell';

export const ModulationSubRow = ({
    coworker,
    cellProps,
    canEdit,
    isActive,
    contractStartDate,
    contractEndDate,
    modulationRange,
    showAllDetails,
    costCentre,
}: {
    coworker: Coworker | DummyCoworker,
    cellProps: Array<EditableModulationCellProps>,
    canEdit: boolean,
    isActive: boolean,
    contractStartDate:string,
    contractEndDate:string,
    modulationRange: ModulationRange,
    showAllDetails: boolean,
    costCentre: string,
}) => {
    const { t } = useTranslation();
    const { currentScenario } = useData();
    const { timeSelection, costCentreList } = useApp();
    const { updateScenario } = useUpdateScenarioAPI();

    const cells = useMemo(
        () => cellProps.map((cProps, index) => {
            const { elementId, key, currentHours, currentMinutes } = cProps;
            const contractAbsentCell = getGreyedOutCell(
                contractStartDate,
                contractEndDate,
                timeSelection.timeArray,
                index,
                key,
            );

            if (contractAbsentCell !== RangeStatus.OUTSIDE_RANGE) {
                return contractAbsentCell;
            }

            return (
                <ModulationCell
                    elementId={elementId}
                    currentHours={currentHours}
                    currentMinutes={currentMinutes}
                    canBeEdited={canEdit}
                    key={key}
                    isActive={isActive && costCentreIsInList(costCentre, costCentreList ?? [])}
                    coworker={coworker}
                    updateScenario={updateScenario}
                    modulationRange={modulationRange}
                />
            );
        }).filter(el => el.toString() !== RangeStatus.OUTSIDE_RANGE && el.toString() !== RangeStatus.WITHIN_RANGE),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cellProps, currentScenario, coworker]
    );

    return (
        <tr className={(coworker.employmentStatus === 'active' || currentScenario) ? '' : classes.inactive}>
            <td className={classes['modulation-row__title-cell']}>
                <b className={classes['modulation-row__title-cell--title']}>{t('MODULATION_HOURS')}</b>
                <span className={classes['modulation-row__title-cell--subtitle']}>
                    {`${modulationRange.minLimit.hours}${t('HOURS_ABBREVIATED')}${
                        modulationRange.minLimit.minutes
                    }${t('MINUTES_ABBREVIATED')} - ${modulationRange.maxLimit.hours}${t(
                        'HOURS_ABBREVIATED',
                    )}${modulationRange.maxLimit.minutes}${t('MINUTES_ABBREVIATED')}`}
                </span>
            </td>
            {showAllDetails && (
                <>
                    <td className={classes['modulation-row__empty-cell']} />
                    <td className={classes['modulation-row__empty-cell']} />
                    <td className={classes['modulation-row__empty-cell']} />
                    <td />
                </>
            )}
            {cells}
            <td />
        </tr>
    );
};
