import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRefWithCallback from 'hooks/useRefWithCallback';
import chevronDownSmallIcon from '@ingka/ssr-icon/paths/chevron-down-small';
import chevronUpSmallIcon from '@ingka/ssr-icon/paths/chevron-up-small';
import chevronUpDown from '@ingka/ssr-icon/paths/chevron-up-chevron-down-small';
import { DAYS_IN_A_WEEK, toIntlMonthShort } from 'utils/date';
import moment from 'moment';
import SSRIcon from '@ingka/ssr-icon';
import useApp from 'hooks/useApp';
import { useConfigApi } from 'hooks/api/useConfigApi';
import { useSearchParams } from 'react-router-dom';
import { updateSearchParams } from 'components/Router/searchParams';
import useAuth from 'hooks/useAuth';
import classes from './WeekHeaderRow.module.scss';
import EmptyHeaders from './EmptyHeaders';

const WeekHeaderRow = memo(({
    weeks,
    showDetails,
    onSortByName,
    onSortByHours,
    onSortByModulation,
}: {
    weeks: string[],
    showDetails: boolean,
    onSortByName: (isAsc: boolean) => void,
    onSortByHours: (isAsc: boolean) => void,
    onSortByModulation: (isAsc: boolean) => void,
}) => {
    const { t } = useTranslation();
    const { currentWeek, currentScenario } = useApp();
    const { access } = useAuth();
    const { data: config } = useConfigApi();
    const { ref, setRef } = useRefWithCallback<HTMLElement>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortOrder, setSortOrder] = useState<string>(searchParams.get('sortOrder') ?? '');
    const [sortedBy, setSortedBy] = useState<string>(searchParams.get('sortedBy') ?? '');

    useEffect(() => {
        if (ref && currentWeek) {
            ref?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'end' });
        }
        if (sortOrder && sortedBy) {
            updateSearchParams([{ sortOrder }, { sortedBy }], searchParams, setSearchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, sortOrder, sortedBy]);

    useEffect(() => {
        if (searchParams.size === 0) {
            setSortOrder('');
            setSortedBy('');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
    const handleSortCoworkerName = () => {
        setSortedBy('name');
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        onSortByName(!(sortOrder === 'asc'));
    };

    const handleSortCoworkerHours = () => {
        setSortedBy('hours');
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        onSortByHours(!(sortOrder === 'asc'));
    };

    const handleSortCoworkerModulationHours = () => {
        setSortedBy('modulation');
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        onSortByModulation(!(sortOrder === 'asc'));
    };

    return (
        <tr className={classes['week-row']}>
            <th>
                <div className={classes['week-row__cell']}>
                    <div className={classes['week-row__cell__sort-button']} onClick={handleSortCoworkerName}>
                        <div>
                            {t('COWORKER')}
                            {sortedBy === 'name'
                                ? <SSRIcon paths={sortOrder === 'asc' ? chevronDownSmallIcon : chevronUpSmallIcon} />
                                : <SSRIcon paths={chevronUpDown} />}
                        </div>
                    </div>
                    {access?.features?.modulation && (
                    <div className={classes['week-row__cell__sort-button']} onClick={handleSortCoworkerModulationHours}>
                        <div>
                            {t('MODULATION')}
                            {sortedBy === 'modulation'
                                ? <SSRIcon paths={sortOrder === 'asc' ? chevronDownSmallIcon : chevronUpSmallIcon} />
                                : <SSRIcon paths={chevronUpDown} />}
                        </div>
                    </div>
                    ) }
                    <div className={classes['week-row__cell__sort-button']} onClick={handleSortCoworkerHours}>
                        <div>
                            {t('CONTRACT_HOURS')}
                            {sortedBy === 'hours'
                                ? <SSRIcon paths={sortOrder === 'asc' ? chevronDownSmallIcon : chevronUpSmallIcon} />
                                : <SSRIcon paths={chevronUpDown} />}
                        </div>
                    </div>
                </div>
            </th>
            {showDetails && (<EmptyHeaders showTitles={showDetails} />)}
            {weeks.map((date, index) => {
                const isHighlightedCell = currentWeek === date;
                const currentDate = moment(date).endOf('isoWeek');
                const isFirstWeekOfTheMonth = currentDate.date() <= DAYS_IN_A_WEEK;
                const week = date ? `W${parseInt(date.slice(6), 10)}` : t('N/A');

                return (
                    <th
                        // eslint-disable-next-line react/no-array-index-key
                        key={`gapCells-${index}`}
                        className={isHighlightedCell ? classes['week-row__highlighted-week'] : ''}
                        ref={isHighlightedCell ? setRef : undefined}
                    >
                        {isFirstWeekOfTheMonth
                            ? (
                                <div>
                                    <div>
                                        <div className={classes['week-row__month']}>
                                            {toIntlMonthShort(currentDate.add(1, 'days'))}
                                        </div>
                                        <div>
                                            {week}
                                        </div>
                                    </div>
                                </div>
                            )
                            : <div className={classes['container-week']}>{week}</div>}
                    </th>
                );
            })}

            <th>
                <div className={classes['week-row__footer-cell']}>
                    {access?.features?.modulation && config?.isModulationSupported && currentScenario
                        && (
                            <div>
                                {t('MODULATION_BALANCE')}
                            </div>
                        )}
                    <div>
                        {t('WEEKLY_AVG')}
                    </div>
                    <div>
                        {t('TOTAL_HOURS')}
                    </div>
                </div>
            </th>
        </tr>
    );
});

export default WeekHeaderRow;
