import Button from '@ingka/button';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import { useListener } from 'hooks/useListener';
import React from 'react';
import classes from './ConfirmationModal.module.scss';

type TypeConfirmationModal = {
    isOpen: boolean,
    cancelButtonText: string,
    confirmButtonText: string,
    title: string,
    content: string | React.ReactElement,
    className?: string,
    handleCancel: () => void,
    handleConfirm: () => void,
};

export const ConfirmationModal = ({
    isOpen,
    cancelButtonText,
    confirmButtonText,
    title,
    content,
    className,
    handleCancel,
    handleConfirm
}:TypeConfirmationModal) => {
    // Handle enter key event
    useListener('keydown', 'Enter', () => {
        if (isOpen) {
            handleConfirm();
        }
    });

    return (
        <Modal
            visible={isOpen}
            handleCloseBtn={handleCancel}
            escapable

        >
            <Prompt
                header={<ModalHeader />}
                footer={(
                    <ModalFooter>
                        <Button
                            onClick={handleCancel}
                            text={cancelButtonText}
                            type="secondary"
                            size="small"
                            data-testid="confirmation-modal-cancel-button"
                        />
                        <Button
                            onClick={handleConfirm}
                            text={confirmButtonText}
                            type="primary"
                            size="small"
                            autoFocus
                            data-testid="confirmation-modal-confirm-button"
                        />
                    </ModalFooter>
                )}
                title={title}
                titleId={title}
                className={`${classes['confirmation-modal__container']} ${className ?? ''}`}
            >
                {content}
            </Prompt>
        </Modal>
    );
};
