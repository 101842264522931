import React from 'react';
import useAuth from 'hooks/useAuth';
import { ErrorBoundary as SentryErrorBoundary, ReportDialogOptions } from '@sentry/react';
import initSentry from './sentry';

initSentry();

const ErrorBoundary = ({ children }: { children: JSX.Element }) => {
    const { user } = useAuth();

    const dialogOptions: ReportDialogOptions = {
        user: {
            email: user?.email || 'N/A',
            name: user?.name || 'N/A',
        },
    };

    return (
        <SentryErrorBoundary showDialog dialogOptions={dialogOptions}>
            {children}
        </SentryErrorBoundary>
    );
};

export default ErrorBoundary;
