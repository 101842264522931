import { Scenario } from 'types/scenario';

import useData from 'hooks/useData';

import RemoveLastMainScenario from './RemoveLastMainScenario';
import RemoveMainScenario from './RemoveMainScenario';

const RemoveMainScenarioNavigationPanel = (
    { deletedScenario, modalVisibility, setModalVisibility }:
    { deletedScenario : Scenario, modalVisibility : boolean, setModalVisibility : React.Dispatch<React.SetStateAction<boolean>> }
) => {
    const { scenarioList } = useData();

    if (deletedScenario?.isMain && scenarioList?.scenarios.length === 1) {
        return (
            <RemoveLastMainScenario deletedScenario={deletedScenario} modalVisibility={modalVisibility} setModalVisibility={setModalVisibility} />
        );
    }

    return (
        <RemoveMainScenario deletedScenario={deletedScenario} modalVisibility={modalVisibility} setModalVisibility={setModalVisibility} />
    );
};

export default RemoveMainScenarioNavigationPanel;
