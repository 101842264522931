import { useMemo, useState } from 'react';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Select, { Option } from '@ingka/select';
import DatePickerDisplay from 'components/TimeFilter/DatePickerDisplay';
import { useTranslation } from 'react-i18next';
import { ContractType, EmploymentType } from 'types/scenario';
import { noop } from 'lodash';
import Toggle from '@ingka/toggle';
import { displayContractRangeWithDescription, getShortCC } from 'utils/text';
import useData from 'hooks/useData';
import { ContractRange } from 'types/coworker';
import { sortContractRangeDesc } from 'utils/sorting';
import classes from './AddCoworker.module.scss';

type TypeAddCoworkerPanel = {
    costCentreProps: {
        costCentre: string | undefined,
        costCentreOptions: {
            costcentre: string;
            costcentreDescription: string;
        }[];
        handleChangeCostCentre: (e: React.ChangeEvent<HTMLSelectElement>) => void,
        validCostCentre: boolean,
    };
    contractHoursProps: {
        contractRange: ContractRange | undefined,
        contractHours: string | number | undefined,
        handleChangeContractHours: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
        validContractHours: boolean,
    };
    contractDatesProps: {
        startDate: string | null,
        endDate: string | null,
        handleChangeEndDate: (e: React.ChangeEvent<HTMLInputElement>) => void,
        handleChangeStartDate: (e: React.ChangeEvent<HTMLInputElement>) => void,
        minimumStartDate: string
        validDates: boolean,
    };
    contractTypeProps: {
        contractType: ContractType,
        contractOptions: string[],
        handleChangeContractType: (e: React.ChangeEvent<HTMLSelectElement>) => void,
        isPermanentContract: (ct: ContractType) => boolean,
    };
    employmentTypeProps: {
        employmentType: string | undefined,
        handleChangeEmploymentType: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    };
    coworkerNameProps: {
        coworkerName: string | undefined,
        handleChangeCoworkerName: (e: React.ChangeEvent<HTMLInputElement>) => void,
        validCoworkerName: boolean
    };
    unitName: string
};

const AddCoworkerPanel = ({
    costCentreProps,
    contractHoursProps,
    contractDatesProps,
    contractTypeProps,
    coworkerNameProps,
    employmentTypeProps,
    unitName
}:TypeAddCoworkerPanel) => {
    const { t } = useTranslation();
    const { contractHours, contractRange, handleChangeContractHours, validContractHours } = contractHoursProps;
    const { costCentre, costCentreOptions, handleChangeCostCentre, validCostCentre } = costCentreProps;
    const { coworkerName, handleChangeCoworkerName, validCoworkerName } = coworkerNameProps;
    const { contractType, contractOptions, handleChangeContractType, isPermanentContract } = contractTypeProps;
    const { startDate, endDate, handleChangeEndDate, handleChangeStartDate, minimumStartDate, validDates } = contractDatesProps;
    const { employmentType, handleChangeEmploymentType } = employmentTypeProps;
    const [toggleIndex, setToggleIndex] = useState<number | null>(null);
    const { config } = useData();

    const ccDropDownList = useMemo(() => {
        if (toggleIndex || null) {
            return costCentreOptions
                ?.sort((current, next) => Number(getShortCC(current.costcentre)) - Number(getShortCC(next.costcentre)));
        }

        return costCentreOptions
            ?.sort((current, next) => current.costcentreDescription
                .localeCompare(next.costcentreDescription));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleIndex]);

    return (
        <div className={classes['new-coworker-container']} data-testid="add-coworker-modal-container">
            <div className={classes['heading-row']}>{t('NEW_COWORKER')}</div>
            <div className={classes['input-field']}>
                <FormField
                    valid={validCoworkerName}
                    shouldValidate={!validCoworkerName}
                    validation={{
                        msg: t('ENTER_COWORKER_NAME')
                    }}
                >
                    <InputField
                        data-testid="new-coworker-name-input"
                        label={t('NAME')}
                        value={coworkerName}
                        id="new-coworker-name-input"
                        type="text"
                        onChange={handleChangeCoworkerName}
                        autoFocus
                    />
                </FormField>
            </div>
            <div className={classes['select-dropdown']}>
                <Select
                    data-testid="new-coworker-contract-type"
                    id="new-coworker-contract-type"
                    label={t('CONTRACT_TYPE')}
                    value={contractType}
                    hintText=""
                    onChange={handleChangeContractType}
                >
                    {contractOptions.map(val => (
                        <Option key={`contract-type-${val}`} value={val} name={t(val)} />
                    ))}
                </Select>
            </div>
            { !config?.isContractRangeSupported && (
            <div className={classes['input-field']}>
                <FormField
                    valid={validContractHours}
                    shouldValidate={!validContractHours}
                    validation={{
                        msg: t('ENTER_CONTRACT_HOURS')
                    }}
                >
                    <InputField
                        label={t('CONTRACT_HOURS')}
                        data-testid="new-coworker-contract-hours"
                        id="new-coworker-contract-hours"
                        type="number"
                        autoComplete="off"
                        value={contractHours ?? ''}
                        onChange={handleChangeContractHours}
                    />
                </FormField>
            </div>
            )}
            { config?.isContractRangeSupported && (
                <div className={classes['select-dropdown']}>
                    <FormField
                        valid={validContractHours}
                        shouldValidate={!validContractHours}
                        validation={{
                            msg: t('CONTRACT_RANGE_HINT')
                        }}
                    >
                        <Select
                            data-testid="new-coworker-contract-ranges"
                            id="new-coworker-contract-ranges"
                            label={t('CONTRACT_HOURS')}
                            value={contractRange?.type}
                            hintText={t('CONTRACT_RANGE_HINT')}
                            onChange={handleChangeContractHours}
                        >
                            {config.contractRanges
                                .sort(sortContractRangeDesc)
                                .map(cr => (
                                    <Option
                                        key={`contract-range-${cr.type}`}
                                        value={cr.type}
                                        name={displayContractRangeWithDescription(cr)}
                                    />
                                ))}
                        </Select>
                    </FormField>
                </div>

            )}
            <div className={classes['date-field']}>
                <DatePickerDisplay
                    data-testId="new-coworker-start-time"
                    header={t('SELECT_THE_START_WEEK')}
                    inputType="week"
                    inputValue={startDate || ''}
                    handleChange={handleChangeStartDate}
                    handleBlur={noop}
                    minValue={minimumStartDate || null}
                    maxValue={endDate || null}
                    invalid={!validDates}
                />
            </div>
            {!isPermanentContract(contractType)
                        && (
                            <div className={classes['date-field']}>
                                <DatePickerDisplay
                                    data-testId="new-coworker-end-time"
                                    header={t('SELECT_THE_END_WEEK')}
                                    inputType="week"
                                    inputValue={endDate || ''}
                                    handleChange={handleChangeEndDate}
                                    handleBlur={noop}
                                    minValue={startDate || null}
                                    invalid={!validDates}
                                />
                            </div>
                        )}
            <div className={classes['select-dropdown']}>
                <Select
                    id="new-coworker-employment-type"
                    data-testid="new-coworker-employment-type"
                    label={t('EMPLOYMENT_TYPE')}
                    value={employmentType}
                    hintText=""
                    onChange={handleChangeEmploymentType}
                >
                    {Object.keys(EmploymentType)
                        .filter(key => Number.isNaN(Number(key)))
                        .map(key => (
                            <Option key={`employment-type-${key}`} value={key} name={t(key)} />
                        ))}
                </Select>
            </div>
            <div className={classes['select-dropdown-toggle']}>
                <Toggle
                    className={classes['om-filters-toggle-button']}
                    buttons={[{ text: t('A_Z') }, { text: '0-9' }]}
                    fluid={false}
                    activeIndex={toggleIndex ?? 0}
                    onClick={(event, index) => {
                        setToggleIndex(index);
                    }}
                />
            </div>
            {ccDropDownList
                        && (
                            <div className={classes['select-dropdown']}>
                                <FormField
                                    valid={validCostCentre}
                                    shouldValidate={!validCostCentre}
                                    validation={{
                                        msg: t('SELECT_COST_CENTRE')
                                    }}
                                >
                                    <Select
                                        id="new-coworker-cost-center"
                                        data-testid="new-coworker-cost-center"
                                        label={t('COST_CENTRE')}
                                        hintText={t('SELECT_COST_CENTRE')}
                                        value={costCentre}
                                        onChange={handleChangeCostCentre}
                                    >
                                        {ccDropDownList?.map(({ costcentre, costcentreDescription }) => (
                                            <Option
                                                key={costcentre}
                                                value={costcentre}
                                                name={`${getShortCC(costcentre)} - ${costcentreDescription}`}
                                            />
                                        ))}
                                    </Select>
                                </FormField>
                            </div>
                        )}
            <div className={classes['select-dropdown']}>
                <Select
                    id="new-coworker-unit"
                    data-testid="new-coworker-unit"
                    label={t('UNIT')}
                    hintText=""
                    disabled
                >
                    <Option
                        value={unitName}
                        name={unitName}
                    />
                </Select>
            </div>
        </div>

    );
};

export default AddCoworkerPanel;
