import { useState } from 'react';
import UserInfoModal from 'components/UserInfo/UserInfoModal';
import NavigationBar from 'components/NavigationBar';
import AppSwitcherModal from 'components/NavigationBar/AppSwitcherModal';
import '@ingka-group-digital/opp-components/dist/style.css';
import { useLocation } from 'react-router-dom';
import classes from './AppBar.module.scss';

const AppBar = () => {
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [isAppSwitcherOpen, setIsAppSwitcherOpen] = useState(false);
    const location = useLocation();

    return (
        <div data-testid="appbar" className={classes.appbar} data-page={location.pathname.substring(1)}>
            <NavigationBar setUserModalOpen={setUserModalOpen} setIsAppSwitcherOpen={setIsAppSwitcherOpen} />
            <AppSwitcherModal isAppSwitcherOpen={isAppSwitcherOpen} setIsAppSwitcherOpen={setIsAppSwitcherOpen} />
            <UserInfoModal isOpen={userModalOpen} setIsOpen={setUserModalOpen} />
        </div>
    );
};

export default AppBar;
