import Switch from '@ingka/switch';
import useData from 'hooks/useData';
import { useTranslation } from 'react-i18next';
import { CAPACITY_FACTORS_SCENARIO_BUDGET__CLICK } from 'types/analytics';

const ScenarioFileUpload = ({ enableFileUpload } : { enableFileUpload: boolean }) => {
    const { t } = useTranslation();
    const { scenarioBudget, setScenarioBudget, isFetching } = useData();

    return (
        <Switch
            data-testid="enable-scenario-budget"
            id="enable-scenario-budget"
            label={(
                <div data-testid="scenario-budget-title">
                    <div>
                        <b>{t('SCENARIO_BASED_BUDGET')}</b>
                    </div>
                    <div data-testid="scenario-budget-subTitle">
                        {enableFileUpload ? t('DISABLE_SCENARIO_SUBTITLE') : t('ENABLE_SCENARIO_SUBTITLE')}
                    </div>
                </div>
            )}
            onChange={() => { setScenarioBudget(prevScenarioBudget => !prevScenarioBudget); }}
            subtle
            value={`${scenarioBudget}`}
            checked={scenarioBudget}
            disabled={isFetching}
            data-analytics={CAPACITY_FACTORS_SCENARIO_BUDGET__CLICK}
        />
    );
};

export default ScenarioFileUpload;
