export interface CoworkerDetailsValues {
    coworkerId: string;
    unit: string;
    department: string;
    fixedRange: string;
    contractualType: string;
    contractualHours: string;
    costCentre: string;
    vacationHours: string;
    qualifications: string[];
}

export type WeeklyContribution = {
    total: number,
    evenings: number,
    saturday: number,
    sunday: number
};

export type HoverData = {
    gap: GapHoursData,
    minRangeGap: GapHoursData | undefined,
    contributions: ContributionsData
};

export enum HoverDataContentType {
    GAP = 'gap',
    CONTRIBUTIONS = 'contributions'
}

/**
 * Contains the primary data for evenings, saturdays and sundays.
 * Contributions are the number of available coworkers, and
 * workload is the number of coworkers needed.
 */
export type ContributionsData = {
    weekDateIso: string;
    contentType: HoverDataContentType.CONTRIBUTIONS;
    contributions: WeeklyContribution;
    workload: WeeklyContribution;
};

/**
 * Contains the derived data for evenings, saturdays and sundays.
 * This is the gap between contributions and workload
 * (positive numbers mean overstaffed, negative numbers understaffed),
 * as well as a flag for if either of the evenings, saturdays or sundays are understaffed.
 */
export type ContributionsDerivedData = {
    gap: WeeklyContribution;
    isDangerColor: boolean,
};

/**
 * Contains the primary data needed to calculate the capacity gap for a specific week.
 * This is the target capacity (or budgeted hours), coworker hours (sum of all coworkers' weekly working hours)
 * and the various absence hours (vacation, sickness, other).
 */
export type GapHoursData = {
    weekDateIso: string;
    contentType: HoverDataContentType.GAP;
    targetCapacity: number;
    coworkerHours: number;
    vacationHours: number;
    sicknessHours: number;
    otherAbsenceHours: number;
};

/**
 * Contains the derived data that can be calculated from GapHoursData.
 * This is the capacity hours (coworker hours - absence hours), the gap (capacity hours - target capacity),
 * as well as the various absence percentages (absence hours/coworker hours).
 * Gap is positive if the capacity is over the target capacity, and negative if it is under.
 * isDangerColor is true if the unit is understaffed that week
 */
export type GapHoursDerivedData = {
    vacationPercentage: number;
    sicknessPercentage: number;
    otherAbsencePercentage: number;
    capacityHours: number;
    gap: number;
    isDangerColor: boolean,
};

export interface CoworkerElement {
    elementId: string;
    displayValue: string;
    coworkerDetails: CoworkerDetailsValues;
}
