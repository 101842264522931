import { TypeAvailabilityAction } from 'types/actionPlan';
import { useTranslation } from 'react-i18next';
import Text from '@ingka/text';
import classes from '../ActionTable.module.scss';

const AvailabilityAction = ({ action }:{ action: TypeAvailabilityAction }) => {
    const { t } = useTranslation();
    const isAbsence = action.to === 'A';
    const displayValue = isAbsence ? t('ABSENCE') : t('HOLIDAY');

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-availability-header"
                >
                    {displayValue}
                </Text>
                <Text
                    data-testid="action-plan-availability-change"
                >
                    {Number.isNaN(action.from) ? action.from : `${action.from} ${t('HOURS_ABBREVIATED')}`}
                    <b>
                        {' ➔ '}
                    </b>
                    <Text
                        tagName="span"
                        className={classes[`${isAbsence ? 'details-changes__absence' : 'details-changes__holiday'}`]}
                        data-testid="action-plan-availability-new-value"
                    >
                        {displayValue}
                    </Text>
                </Text>
            </div>
        </div>
    );
};

export default AvailabilityAction;
