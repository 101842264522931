import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './CoworkerDetails.module.scss';

const CoworkerData = ({ title, data }: { title: string; data: number | string | null | undefined; }) => {
    const { t } = useTranslation();

    return (
        <div data-testid="coworker-details-title">
            <div className={classes.title}>
                <b>{title}</b>
            </div>
            <div data-testid="coworker-details-value" className={classes.value}>
                {data || t('N/A')}
            </div>
        </div>
    );
};

export default CoworkerData;
