import { getCostCentreDescription } from 'hooks/useGrid/gridFunctions';
import { CheckBox } from 'types/appContext';
import { UserProfile } from 'types/authContext';
import { OMDepartment, CountriesList, OMUnit, OMDivision, CountryOMData } from 'types/dataContext';
import { filterDepartmentByCostCentreId } from 'utils/filters';
import { getShortCC, getShortDepartmentID } from 'utils/text';
import { OMFilter as OMFilterCategory } from 'types/enums';

/**
 * Gets the division IDs of all checked divisions. If none is checked, all division IDs are returned.
 */
export const getSelectedDivisionIds = (filteredDivisionOMData: Array<CheckBox>) => {
    const filteredList = filteredDivisionOMData.filter(({ defaultChecked }) => defaultChecked)?.map(({ id }) => id);

    if (filteredList.length === 0) {
        return filteredDivisionOMData.map(({ id }) => id);
    }

    return filteredList;
};

export const filterUniqueDepartments = (departments: OMDepartment[]) => departments?.filter(
    (department, index, self) => index
            === self.findIndex(department2 => (department2.costCentre?.length > 4
                ? department2.costCentre === department.costCentre
                : getShortCC(department2.costCentre) === getShortCC(department.costCentre))),
);

export const countryToProps = (countryList: CountriesList, userObject: UserProfile) => {
    // Convert Country Checkbox Select if list has only 1 country
    const countryCheckboxList: CheckBox[] = [];
    countryList?.forEach(country => {
        countryCheckboxList.push({
            autocomplete: false,
            id: country.code,
            type: OMFilterCategory.COUNTRY,
            label: country.name,
            value: country.code,
            defaultChecked: userObject.country === country.name, // Auto Check to the user homeCountry
            ccDesc: '',
        });
    });

    return countryCheckboxList;
};

export const unitsToProps = (units: OMUnit[], userObject: UserProfile) => {
    // Convert to Unit Checkbox Type
    const unitsCheckboxList: CheckBox[] = [];
    units?.forEach((unit: OMUnit) => {
        unitsCheckboxList.push({
            autocomplete: false,
            id: unit.unitId,
            type: OMFilterCategory.UNIT,
            label: unit.name,
            defaultChecked: userObject.businessUnit.split('.')[1] === unit.unitId, // Auto Check to the user unit by default
            value: unit.unitId,
            ccDesc: '',
        });
    });

    return unitsCheckboxList;
};

export const divisionsToProps = (divisions: OMDivision[]) => {
    // Convert to Divisions Checkbox Type
    const bFCheckboxList: CheckBox[] = [];
    divisions?.forEach((division: OMDivision) => {
        bFCheckboxList.push({
            autocomplete: false,
            id: division.id,
            type: OMFilterCategory.DIVISION,
            label: division.name,
            disabled: divisions.length === 1,
            defaultChecked: divisions.length === 1,
            value: division.id,
            ccDesc: '',
        });
    });

    return bFCheckboxList;
};

export const getCheckBoxProps = (
    type: OMFilterCategory,
    departments: OMDepartment[],
    unfilteredCountryOMData?: CountryOMData,
) => {
    // Convert to CheckBox Type
    const checkboxList: CheckBox[] = [];
    if (type === OMFilterCategory.COST_CENTER) {
        departments?.forEach((department: OMDepartment) => {
            checkboxList.push({
                autocomplete: false,
                id: department.costCentre,
                type: OMFilterCategory.COST_CENTER,
                label: getCostCentreDescription(department.costCentre, unfilteredCountryOMData) ?? '',
                subLabel: getShortCC(department.costCentre),
                disabled: departments.length === 1,
                defaultChecked: departments.length === 1,
                value: department.costCentre,
                ccDesc: '',
            });
        });
    }
    if (type === OMFilterCategory.DEPARTMENT) {
        const uniqueDepartments: Map<string, string> = new Map<string, string>();
        departments?.forEach((department: OMDepartment) => {
            uniqueDepartments.set(department.name, department.organizationId);
        });
        uniqueDepartments?.forEach((organizationID, departmentName) => {
            checkboxList.push({
                autocomplete: false,
                id: organizationID,
                type: OMFilterCategory.DEPARTMENT,
                label: departmentName,
                subLabel: getShortDepartmentID(organizationID) ?? '',
                value: organizationID,
                disabled: departments.length === 1,
                defaultChecked: departments.length === 1,
                ccDesc: '',
            });
        });
    }

    return checkboxList;
};

export const getallDepartmentsUnderDivisions = (
    divisionIds: string[],
    unfilteredCountryOMData: CountryOMData,
    filteredUnitOMData: CheckBox[],
): OMDepartment[] => {
    if (!unfilteredCountryOMData || !divisionIds) {
        return [];
    }
    const selectedUnitId = filteredUnitOMData.find(({ defaultChecked }) => defaultChecked)?.id;
    const unitType = unfilteredCountryOMData?.units?.find(({ unitId }) => unitId === selectedUnitId)?.unitType ?? '';

    const departments = unfilteredCountryOMData.departments?.filter(
        ({ division, businessUnitCode, businessUnitType }) => divisionIds.includes(division || '')
            && businessUnitCode === selectedUnitId
            && businessUnitType === unitType,
    );

    // Filter out duplicate departments pending further discussion and decisions due to some units having bad data
    const filterDepartmentsUnitId = filterDepartmentByCostCentreId(departments, selectedUnitId ?? '');

    return filterDepartmentsUnitId;
};

export const SELECT_ALL_ID = 'select-all';

export const hasCheckedItems = (checkBoxArray: CheckBox[]): boolean => {
    for (let i = 0; i < checkBoxArray.length; i += 1) {
        if (checkBoxArray[i].defaultChecked === true) {
            return true;
        }
    }

    return false;
};

export const filterIdsBasedOnSelections = (checkBoxArray: CheckBox[]) => (
    hasCheckedItems(checkBoxArray)
        ? checkBoxArray.filter(item => item.defaultChecked === true).map(item => item.id)
        : checkBoxArray.map(item => item.id));
