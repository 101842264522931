import { useEffect, useState } from 'react';
import { HTTPStatusCode, HTTPHeader } from 'types/api';
import { generateHttpErrorMessage, generateRuntimeErrorMessage } from 'utils/log';
import useAuth from './useAuth';

type TypeUseReaderRequest<TResponseData> = {
    url: string;
    isSuspended?: boolean;
    initialValue?: TResponseData | null,
    deps?: Array<unknown>,
    options?: {
        headers?:[HTTPHeader, string][],
        fetchOptions?: Partial<Omit<RequestInit, 'body'>>
    }
};

export type TypeUseReaderResponse<TResponseData> = {
    data: TResponseData | null,
    isLoading: boolean,
    error: HTTPStatusCode | null,
    readData:(newUrl?: string) => Promise<void>
};

export const useReader = <TResponseData>({
    url,
    isSuspended,
    initialValue,
    deps,
    options,
}: TypeUseReaderRequest<TResponseData>): TypeUseReaderResponse<TResponseData> => {
    const [data, setData] = useState<TResponseData | null>(initialValue ?? null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<HTTPStatusCode | null >(null);
    const { authClient } = useAuth();

    async function readData(newUrl?: string) {
        setIsLoading(true);
        const currentUrl = newUrl ?? url;
        let appAccessToken: string | null = null;
        const shoudUseBuiltInAuth = !options?.headers?.some(el => el[0] === HTTPHeader.AUTHORIZATION);

        if (shoudUseBuiltInAuth) {
            appAccessToken = await authClient.getAppAccessToken();
        }

        fetch(
            currentUrl,
            {
                headers: shoudUseBuiltInAuth
                    ? [[HTTPHeader.AUTHORIZATION, `Bearer ${appAccessToken}`], ...(options?.headers ?? [])]
                    : [...(options?.headers ?? [])],
                mode: 'cors',
                cache: 'default',
                ...(options?.fetchOptions ?? [])
            }
        )
            .then(response => {
                if (!response.ok) {
                    // Response not ok - payload is expected to be an error message
                    setError(response.status);
                    generateHttpErrorMessage(response);
                    setData(null);

                    return Promise.resolve();
                }

                // Response is ok - expecting a response with the correct data type
                return response.json().then((responseData: TResponseData) => {
                    setData(responseData);
                    setError(null);
                });
            })

            .catch((err: Error) => {
                generateRuntimeErrorMessage(err);
                setError(HTTPStatusCode.INTERNAL_SERVER_ERROR);
                setData(null);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (!isSuspended) {
            readData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuspended, url, ...(deps ?? [])]);

    return {
        data,
        isLoading,
        error,
        readData
    };
};
