import React, { useState } from 'react';
import Button from '@ingka/button';
import useData from 'hooks/useData';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ANALYTICS_CAPACITY_FACTORS_MAP } from 'types/analytics';
import documentUpload from '@ingka/ssr-icon/paths/document-upload';
import Modal, { Sheets, ModalHeader, ModalFooter, ModalBody } from '@ingka/modal';
import CapacityFactorPanel from 'components/CapacityFactor/CapacityFactorPanel';
import Tooltip from '@ingka/tooltip';
import DefaultTemplatesIcon from './DefaultTemplatesIcon';
import classes from './UploadTemplatesButton.module.scss';

const UploadTemplatesButton = () => {
    const { t } = useTranslation();
    const { currentUnit } = useData();
    const { pathname } = useLocation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                id="upload-templates-button"
                data-testid="upload-templates-button"
                ssrIcon={documentUpload}
                type="tertiary"
                text={t('UPLOAD_TEMPLATES')}
                size="small"
                onClick={() => setModalOpen(true)}
                disabled={!currentUnit}
                data-analytics={ANALYTICS_CAPACITY_FACTORS_MAP.get(pathname.substring(1))}
            />
            <Modal
                id="upload-templates-modal"
                data-testid="upload-templates-modal"
                visible={modalOpen}
                handleCloseBtn={() => setModalOpen(false)}
                escapable
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={(
                        <ModalHeader
                            title={t('UPLOAD_TEMPLATES')}
                            backBtnClick={() => setModalOpen(false)}
                            className={classes['upload-templates__modal-header']}
                        >
                            <Tooltip position="bottom" tooltipText={t('DEFAULT_TEMPLATE_ICON_TOOLTIP')}>
                                <DefaultTemplatesIcon />
                            </Tooltip>
                        </ModalHeader>
                    )}
                    footer={(
                        <ModalFooter>
                            <Button
                                data-testid="upload-templates-close-button"
                                text={t('CLOSE')}
                                onClick={() => setModalOpen(false)}
                                type="secondary"
                                size="small"
                            />
                        </ModalFooter>
                    )}
                >
                    <ModalBody>
                        <CapacityFactorPanel />
                    </ModalBody>
                </Sheets>
            </Modal>
        </>
    );
};

export default UploadTemplatesButton;
