import { useCallback, useEffect, useState } from 'react';
import { CheckBox, CoworkersOMFiltersCheckBox } from 'types/appContext';
import { UserProfile } from 'types/authContext';
import { ContractMixInput, CountriesList, CountryOMData, OMDivision, OMDepartment } from 'types/dataContext';
import { OMFilter as OMFilterCategory } from 'types/enums';
import { getallDepartmentsUnderDivisions,
    filterUniqueDepartments,
    getCheckBoxProps,
    SELECT_ALL_ID,
    countryToProps,
    unitsToProps,
    divisionsToProps,
    filterIdsBasedOnSelections } from './useOMFilter.utils';

const useOMFilter = ({
    user,
    unfilteredCountriesList,
    unfilteredCountryOMData,
    selectCountry,
    selectUnit,
    getContractMix,
}: {
    user: UserProfile | null,
    unfilteredCountriesList: CountriesList | undefined,
    unfilteredCountryOMData: CountryOMData | undefined,
    selectCountry: (s: string) => void,
    selectUnit: (unitId: string) => void,
    getContractMix: (unit: string | undefined, contractMixInput: ContractMixInput) => void,
}) => {
    /*
        This hook is used to handle the logic for the OM filter
    */
    const [filteredCountryOMData, setFilteredCountryOMData] = useState<CheckBox[] | undefined>(undefined);
    const [filteredUnitOMData, setFilteredUnitOMData] = useState<CheckBox[] | undefined>(undefined);
    const [filteredDivisionOMData, setFilteredDivisionOMData] = useState<CheckBox[] | undefined>(undefined);
    const [filteredDepartmentOMData, setFilteredDepartmentOMData] = useState<CheckBox[] | undefined>(undefined);
    const [bfDepartments, setBFDepartments] = useState<OMDepartment[] | undefined>(undefined);
    const [filteredCostCenterOMData, setFilteredCostCenterOMData] = useState<CheckBox[] | undefined>(undefined);
    const [filteredUnitLevelCostCenterOMData, setFilteredUnitLevelCostCenterOMData] = useState<CheckBox[] | undefined>(undefined);
    const [costCentreList, setCostCentreList] = useState<string[] | undefined>(undefined);
    const [selectedUnitId, setSelectedUnitId] = useState<string | undefined>(undefined);
    const [checkedOMFiltersData, setCheckedOMFiltersData] = useState<CoworkersOMFiltersCheckBox | undefined>(undefined);

    const getSelectedUnitId = useCallback((unitList: CheckBox[]) => {
        for (let i = 0; i < unitList.length; i += 1) {
            if (unitList[i].defaultChecked === true) {
                setSelectedUnitId(unitList[i].id);

                return unitList[i].id;
            }
        }

        return '';
    }, []);

    const resetOMFilters = (category: OMFilterCategory): void => {
        switch (category) {
        case OMFilterCategory.COUNTRY:
            setFilteredUnitOMData(undefined);
            setFilteredDivisionOMData(undefined);
            setFilteredCostCenterOMData(undefined);
            setFilteredDepartmentOMData(undefined);
            break;

        case OMFilterCategory.UNIT:
            setFilteredDivisionOMData(undefined);
            setFilteredCostCenterOMData(undefined);
            setFilteredDepartmentOMData(undefined);
            break;

        case OMFilterCategory.DIVISION:
            setFilteredCostCenterOMData(undefined);
            break;

        default:
            setFilteredCountryOMData(undefined);
            setFilteredUnitOMData(undefined);
            setFilteredDivisionOMData(undefined);
            setFilteredCostCenterOMData(undefined);
            setFilteredDepartmentOMData(undefined);
        }
    };

    /**
     * handles all onChange triggers and updates the various states of the OM Filter
     * @param category
     * @param key
     * @returns
     */
    const updateOMSelection = (category: OMFilterCategory, key: string, isSelectAll?: boolean): void => {
        if (category === OMFilterCategory.COUNTRY) {
            setFilteredCountryOMData(oldState => oldState?.map(
                country => ({ ...country, defaultChecked: key === country.id })
            ));
            selectCountry(key);
            resetOMFilters(OMFilterCategory.COUNTRY);
        }
        if (category === OMFilterCategory.UNIT) {
            setFilteredUnitOMData(oldState => oldState?.map(
                unit => ({ ...unit, defaultChecked: unit.id === key })
            ));
            selectUnit(key);
            setSelectedUnitId(key);
            resetOMFilters(OMFilterCategory.UNIT);
        }
        if (category === OMFilterCategory.DIVISION) {
            switch (key) {
            case SELECT_ALL_ID:
                if (isSelectAll) {
                    setFilteredDivisionOMData(oldState => oldState?.map(
                        division => ({ ...division, defaultChecked: true })
                    ));
                } else {
                    setFilteredDivisionOMData(oldState => oldState?.map(
                        division => ({ ...division, defaultChecked: false })
                    ));
                }
                break;

            default:
                setFilteredDivisionOMData(oldState => oldState?.map(division => ({
                    ...division,
                    defaultChecked: key === (division.id)
                        || (isSelectAll)
                        ? !division.defaultChecked
                        : division.defaultChecked
                })));
                resetOMFilters(OMFilterCategory.DIVISION);
            }
        }
        if (category === OMFilterCategory.COST_CENTER) {
            switch (key) {
            case SELECT_ALL_ID:
                if (isSelectAll) {
                    setFilteredCostCenterOMData(oldState => oldState?.map(
                        costcenter => ({ ...costcenter, defaultChecked: true })
                    ));
                } else {
                    setFilteredCostCenterOMData(oldState => oldState?.map(
                        costcenter => ({ ...costcenter, defaultChecked: false })
                    ));
                }

                break;
            default:
                setFilteredCostCenterOMData(oldState => oldState?.map(costcenter => ({
                    ...costcenter,
                    defaultChecked: key === (costcenter.id)
                        || (isSelectAll)
                        ? !costcenter.defaultChecked
                        : costcenter.defaultChecked
                })));

                break;
            }
        }
        if (category === OMFilterCategory.DEPARTMENT) {
            if (filteredDivisionOMData
                && filteredDepartmentOMData
                && unfilteredCountryOMData
                && filteredUnitOMData) {
                switch (key) {
                case SELECT_ALL_ID:
                    if (isSelectAll) {
                        setFilteredDepartmentOMData(oldState => oldState?.map(
                            department => ({ ...department, defaultChecked: true })
                        ));
                    } else {
                        setFilteredDepartmentOMData(oldState => oldState?.map(
                            department => ({ ...department, defaultChecked: false })
                        ));
                    }
                    break;

                default:
                    setFilteredDepartmentOMData(oldState => oldState?.map(department => ({
                        ...department,
                        defaultChecked: key === (department.id)
                            || (isSelectAll)
                            ? !department.defaultChecked
                            : department.defaultChecked
                    })));
                }
            }
        }
    };

    useEffect(() => { // Initializes the countries list array
        if (!unfilteredCountriesList || !user) { return; }
        const countriesPropsList: CheckBox[] = countryToProps(unfilteredCountriesList, user);
        setFilteredCountryOMData(countriesPropsList);
        updateOMSelection(OMFilterCategory.COUNTRY, user.country); // Auto Select to the user homeCountry
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unfilteredCountriesList, user]);

    useEffect(() => { // Initializes the units array
        // This triggers when new country data is loaded
        // Should create a fresh filter with home unit autoselected

        if (!unfilteredCountryOMData || !user) {
            setFilteredUnitOMData(undefined);

            return;
        }

        if (filteredUnitOMData) {
            // Make sure that a new country has been selected to not trigger unnecessary renders
            for (let i = 0; i < unfilteredCountryOMData?.units.length; i += 1) {
                if (filteredUnitOMData[0].id === unfilteredCountryOMData?.units[i].unitId) return;
            }
        }
        const userProfileUnitId = user.businessUnit?.split('.')[1];

        const unitsPropsList: CheckBox[] = unitsToProps(unfilteredCountryOMData.units, user);
        setFilteredUnitOMData(unitsPropsList);
        if (userProfileUnitId && unitsPropsList?.filter(({ id }) => id === userProfileUnitId).length === 1) {
            updateOMSelection(OMFilterCategory.UNIT, userProfileUnitId, false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unfilteredCountryOMData, user]);

    useEffect(() => { // Initializes the business functions array upon selecting a new unit.
        if (!filteredUnitOMData || !unfilteredCountryOMData) {
            setFilteredDivisionOMData(undefined);

            return;
        }
        const getAllDivisionsUnderUnit = (): OMDivision[] => {
            if (!unfilteredCountryOMData || !filteredUnitOMData) { return []; }

            const selectedUnitsId = getSelectedUnitId(filteredUnitOMData);
            const unitType = unfilteredCountryOMData?.units?.find(({ unitId }) => unitId === selectedUnitsId)?.unitType ?? '';

            return unfilteredCountryOMData?.divisions
                .filter(({ parentUnitId, parentUnitType }) => parentUnitId === selectedUnitsId && parentUnitType === unitType);
        };

        const divisionsUnitLevel = getAllDivisionsUnderUnit();
        const divisionsPropsList = divisionsToProps(divisionsUnitLevel);
        if (divisionsPropsList.length === 0) {
            // Unit didn't have any children
            setFilteredDivisionOMData(undefined);

            return;
        }
        setFilteredDivisionOMData(divisionsPropsList);
        // Initializes the Cost Centers under Unit Level
        // Get all costCentres under the Unit Level to show in coworkerDetailsEdit.tsx in CC DropdownList

        const getAllCostCentersUnderDivision = (divisionList: CheckBox[]) => {
            if (!divisionList || !unfilteredCountryOMData || !filteredUnitOMData) {
                return [];
            }

            const selectedDivisionIds = filterIdsBasedOnSelections(divisionList);
            const departments = getallDepartmentsUnderDivisions(selectedDivisionIds, unfilteredCountryOMData, filteredUnitOMData);
            if (!departments?.length) { // No departments found for the Business Functions
                setFilteredCostCenterOMData(undefined);

                return [];
            }

            const uniqueDepartments = filterUniqueDepartments(departments);
            const costCentres = getCheckBoxProps(OMFilterCategory.COST_CENTER, uniqueDepartments, unfilteredCountryOMData);
            setBFDepartments(departments);

            return costCentres;
        };
        const uniqueCostCentres = getAllCostCentersUnderDivision(divisionsPropsList);
        uniqueCostCentres?.sort((current, next) => current.label?.localeCompare(next.label));
        setFilteredUnitLevelCostCenterOMData(uniqueCostCentres);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredUnitOMData, unfilteredCountryOMData]);

    useEffect(() => { // Update Cost Center List based on Divisions Selection
        if (!unfilteredCountryOMData || !filteredDivisionOMData || !filteredUnitOMData) { return; }
        const selectedDivisionIds = filterIdsBasedOnSelections(filteredDivisionOMData);
        const departments = getallDepartmentsUnderDivisions(selectedDivisionIds, unfilteredCountryOMData, filteredUnitOMData);
        if (!departments) {
            return;
        }
        setBFDepartments(departments);
        const uniqueDepartments = filterUniqueDepartments(departments);
        const costCentres = getCheckBoxProps(OMFilterCategory.COST_CENTER, uniqueDepartments, unfilteredCountryOMData);
        setFilteredCostCenterOMData(costCentres?.sort((current, next) => current.label?.localeCompare(next.label)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredDivisionOMData]);

    useEffect(() => { // Initializes the Departments array
        if (!filteredDivisionOMData || !filteredUnitOMData || !unfilteredCountryOMData || !filteredCostCenterOMData) { return; }

        const checkBoxTypeCostCentres: CheckBox[] = filteredCostCenterOMData;
        const selectedCostCentres = filterIdsBasedOnSelections(checkBoxTypeCostCentres);
        setCostCentreList(selectedCostCentres);
        const departments = bfDepartments?.filter(cc => selectedCostCentres.includes(cc.costCentre)) ?? [];
        const unitLevelDepartments = getCheckBoxProps(OMFilterCategory.DEPARTMENT, departments);
        setFilteredDepartmentOMData(unitLevelDepartments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredCostCenterOMData]);

    useEffect(() => {
        if (!filteredUnitOMData || !unfilteredCountryOMData || !filteredDivisionOMData || !filteredCostCenterOMData) { return; }
        const contractMixInput: ContractMixInput = {
            filters: {
                departments: filteredDepartmentOMData?.filter(({ defaultChecked }) => defaultChecked)?.map(i => i.id) ?? [],
                divisions: filteredDivisionOMData?.filter(({ defaultChecked }) => defaultChecked)?.map(i => i.id) ?? [],
                costCentres: filteredCostCenterOMData?.filter(({ defaultChecked }) => defaultChecked)?.map(i => i.id) ?? []
            }
        };
        getContractMix(selectedUnitId, contractMixInput);

        if (!filteredDivisionOMData && !filteredCostCenterOMData && !filteredDepartmentOMData) { return; }
        const checkedBFs = filteredDivisionOMData?.filter(({ defaultChecked }) => defaultChecked)?.map(i => i.label) ?? [];
        const checkedCostCenters = filteredCostCenterOMData?.filter(({ defaultChecked }) => defaultChecked)?.map(i => i.id) ?? [];
        const checkedDepartments = filteredDepartmentOMData?.filter(({ defaultChecked }) => defaultChecked)
            ?.map(({ id, label }) => ({ departmentCode: id, departmentName: label })) ?? [];
        setCheckedOMFiltersData({ divisionNames: checkedBFs, costCentres: checkedCostCenters, departments: checkedDepartments });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredDepartmentOMData]);

    return {
        updateOMSelection,
        filteredCountryOMData,
        filteredUnitOMData,
        filteredDivisionOMData,
        filteredDepartmentOMData,
        filteredUnitLevelCostCenterOMData,
        filteredCostCenterOMData,
        costCentreList,
        checkedOMFiltersData
    };
};

export default useOMFilter;
