import { PageContainer } from 'components';
import React from 'react';

const BlankPage = () => (
    <PageContainer isFullWidth align="horizontal">
        <PageContainer testid="capacity-action-plan">
            <div data-testid="waiting-authorization" />
        </PageContainer>
    </PageContainer>
);

export default BlankPage;
