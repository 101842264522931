/* eslint-disable react/require-default-props */
import React from 'react';
import classes from './PageContainer.module.scss';

interface PageContainerProps {
    pageAnalyticsId?: string,
    testid?: string,
    children?: JSX.Element,
    className?: string,
    isFullWidth?: boolean,
    align?: 'horizontal' | 'vertical'
}

/**
 * This component is supposed to be used for views, to get consistent behavior
 * @param children
 */
const PageContainer = ({ pageAnalyticsId, testid = '', children, className, isFullWidth, align = 'vertical' }: PageContainerProps) => (
    <div
        data-page={pageAnalyticsId}
        data-testid={testid}
        className={`
    ${classes.container} 
    ${className ?? ''} 
    ${isFullWidth ? classes['container__full-width'] : ''}
    ${align === 'vertical' ? classes['container__align-vertical'] : classes['container__align-horizontal']}`}
    >
        {children}
    </div>
);
export default PageContainer;
