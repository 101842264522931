import React from 'react';
import { useTranslation } from 'react-i18next';
import { roundDisplayValueDoubleDecimal } from 'utils/number';
import classes from './HoverDetails.module.scss';

const Detail = ({
    property,
    value,
    percentage,
    isChild,
    totalGapValueColor
}: {
    property: string;
    value: number | string
    percentage?: number,
    isChild?: boolean,
    totalGapValueColor?: string
}) => {
    const { t } = useTranslation();

    return (
        <div className={isChild ? classes.tablehover__details__child : classes.tablehover__details__row} data-testid="tableheader-hover-popup">
            <div className={classes['tablehover__details__item-name']}>
                {property}
            </div>
            {Boolean(percentage) /* TODO: handle position percentage like in a grid */
            && (
            <div className={classes.tablehover__details__percentage}>
                {`${percentage}%`}
            </div>
            ) }
            <div data-testid="tableheader-hover-totalgap-value" style={{ color: totalGapValueColor }}>
                {roundDisplayValueDoubleDecimal(value) ?? t('N/A')}
            </div>
        </div>
    );
};

export default Detail;
