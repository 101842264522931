/* eslint-disable @typescript-eslint/naming-convention */
import { OMFilter } from './enums';
import { ROUTE_CAPACITY_ACTION_PLAN, ROUTE_CAPACITY_OVERVIEW, ROUTE_MANAGE_CAPACITY } from './routes';

// Analytics hook
export const START_TRACKING__ON_LOAD = 'start-tracking';
// Navigation links
export const CAPACITY_OVERVIEW_LINK__CLICK = 'CAPACITY_OVERVIEW_LINK__CLICK';
export const MANAGE_CAPACITY_LINK__CLICK = 'MANAGE_CAPACITY_LINK__CLICK';
export const ACTION_PLAN_LINK__CLICK = 'ACTION_PLAN_LINK__CLICK';
export const RECRUITMENT_NEEDS_LINK__CLICK = 'RECRUITMENT_NEEDS_LINK__CLICK';
export const HOME_LINK__CLICK = 'HOME_LINK__CLICK';
// Add new coworker
export const ADD_NEW_COWORKER_BUTTON__CLICK = 'ADD_NEW_COWORKER_BUTTON__CLICK';
// Manage Scenario
export const DROPDOWN_SCENARIO_BUTTON__CLICK = 'DROPDOWN_SCENARIO_BUTTON__CLICK';
export const CREATE_SCENARIO_BUTTON__CLICK = 'CREATE_SCENARIO_BUTTON__CLICK';
export const EDIT_SCENARIO_BUTTON__CLICK = 'EDIT_SCENARIO_BUTTON__CLICK';
export const COPY_SCENARIO_BUTTON__CLICK = 'COPY_SCENARIO_BUTTON__CLICK';
// Search
export const SEARCH_CAPACITY_OVERVIEW__CLICK = 'SEARCH_CAPACITY_OVERVIEW__CLICK';
export const SEARCH_MANAGE_CAPACITY__CLICK = 'SEARCH_MANAGE_CAPACITY__CLICK';
export const SEARCH_ACTION_PLAN__CLICK = 'SEARCH_ACTION_PLAN__CLICK';
// Show coworker details toggle
export const SHOW_COWORKER_DETAILS_CAPACITY_OVERVIEW__CLICK = 'SHOW_COWORKER_DETAILS_CAPACITY_OVERVIEW__CLICK';
export const SHOW_COWORKER_DETAILS_MANAGE_CAPACITY__CLICK = 'SHOW_COWORKER_DETAILS_MANAGE_CAPACITY__CLICK';
export const SHOW_COWORKER_DETAILS_ACTION_PLAN__CLICK = 'SHOW_COWORKER_DETAILS_ACTION_PLAN__CLICK';
// Weekends and evenings toggle
export const WEEKENDS_AND_EVENINGS_CAPACITY_OVERVIEW__CLICK = 'WEEKENDS_AND_EVENINGS_CAPACITY_OVERVIEW_CLICK';
export const WEEKENDS_AND_EVENINGS_MANAGE_CAPACITY__CLICK = 'WEEKENDS_AND_EVENINGS_MANAGE_CAPACITY_CLICK';
export const WEEKENDS_AND_EVENINGS_ACTION_PLAN__CLICK = 'WEEKENDS_AND_EVENINGS_ACTION_PLAN_CLICK';
// Capacity factors
export const CAPACITY_FACTORS_CAPACITY_OVERVIEW__CLICK = 'CAPACITY_FACTORS_CAPACITY_OVERVIEW__CLICK';
export const CAPACITY_FACTORS_MANAGE_CAPACITY__CLICK = 'CAPACITY_FACTORS_MANAGE_CAPACITY__CLICK';
export const CAPACITY_FACTORS_ACTION_PLAN__CLICK = 'CAPACITY_FACTORS_ACTION_PLAN__CLICK';
export const CAPACITY_FACTORS_SCENARIO_BUDGET__CLICK = 'CAPACITY_FACTORS_SCENARIO_BUDGET__CLICK';
// User info
export const USER_INFO_BUTTON__CLICK = 'USER_INFO_BUTTON__CLICK';
// OM filters
export const OM_FILTERS_COUNTRY__CLICK = 'OM_FILTERS_COUNTRY__CLICK';
export const OM_FILTERS_UNIT__CLICK = 'OM_FILTERS_UNIT__CLICK';
export const OM_FILTERS_BUSINESS_FUNCTION__CLICK = 'OM_FILTERS_BUSINESS_FUNCTION__CLICK';
export const OM_FILTERS_DEPARTMENT__CLICK = 'OM_FILTERS_DEPARTMENT__CLICK';
export const OM_FILTERS_COST_CENTER__CLICK = 'OM_FILTERS_COST_CENTER__CLICK';
export const OM_FILTERS_EXPAND_COLLAPSE_BUTTON__CLICK = 'OM_FILTERS_EXPAND_COLLAPSE_BUTTON__CLICK';
// Capacity Overview graph
export const CAPACITY_OVERVIEW_GRAPH__CLICK = 'CAPACITY_OVERVIEW_GRAPH__CLICK';

// Time filter
export const TIME_FILTER_PREVIOUS_WEEK_BUTTON__CLICK = 'TIME_FILTER_PREVIOUS_WEEK_BUTTON__CLICK';
export const TIME_FILTER_NEXT_WEEK_BUTTON__CLICK = 'TIME_FILTER_NEXT_WEEK_BUTTON__CLICK';
export const TIME_FILTER_CALENDAR_BUTTON__CLICK = 'TIME_FILTER_CALENDAR_BUTTON__CLICK';
// Manage capacity > Coworker Details
export const COWORKER_CHANGE_CONTRIBUTION_BUTTON__CLICK = 'COWORKER_CHANGE_CONTRIBUTION_BUTTON__CLICK';
export const COWORKER_CHANGE_CONTRACT_BUTTON__CLICK = 'COWORKER_CHANGE_CONTRACT_BUTTON__CLICK';
export const COWORKER_RESET_BUTTON__CLICK = 'COWORKER_RESET_BUTTON__CLICK';
// Action Plan table
export const MANAGE_CAPACITY_REDIRECT_BUTTON__CLICK = 'MANAGE_CAPACITY_REDIRECT_BUTTON__CLICK';
//

export const ANALYTICS_SEARCH_BAR_MAP = new Map([
    [ROUTE_CAPACITY_OVERVIEW, SEARCH_CAPACITY_OVERVIEW__CLICK],
    [ROUTE_MANAGE_CAPACITY, SEARCH_MANAGE_CAPACITY__CLICK],
    [ROUTE_CAPACITY_ACTION_PLAN, SEARCH_ACTION_PLAN__CLICK]
]);

export const ANALYTICS_SHOW_COWORKER_DETAILS_MAP = new Map([
    [ROUTE_CAPACITY_OVERVIEW, SHOW_COWORKER_DETAILS_MANAGE_CAPACITY__CLICK],
    [ROUTE_MANAGE_CAPACITY, SHOW_COWORKER_DETAILS_MANAGE_CAPACITY__CLICK],
    [ROUTE_CAPACITY_ACTION_PLAN, SHOW_COWORKER_DETAILS_ACTION_PLAN__CLICK]
]);

export const ANALYTICS_SHOW_WEEKENDS_AND_EVENINGS_MAP = new Map([
    [ROUTE_CAPACITY_OVERVIEW, WEEKENDS_AND_EVENINGS_CAPACITY_OVERVIEW__CLICK],
    [ROUTE_MANAGE_CAPACITY, WEEKENDS_AND_EVENINGS_MANAGE_CAPACITY__CLICK],
    [ROUTE_CAPACITY_ACTION_PLAN, WEEKENDS_AND_EVENINGS_ACTION_PLAN__CLICK]
]);

export const ANALYTICS_CAPACITY_FACTORS_MAP = new Map([
    [ROUTE_CAPACITY_OVERVIEW, CAPACITY_FACTORS_CAPACITY_OVERVIEW__CLICK],
    [ROUTE_MANAGE_CAPACITY, CAPACITY_FACTORS_MANAGE_CAPACITY__CLICK],
    [ROUTE_CAPACITY_ACTION_PLAN, CAPACITY_FACTORS_ACTION_PLAN__CLICK]
]);

export const ANALYTICS_OM_FILTERS_MAP = new Map([
    [OMFilter.COUNTRY, OM_FILTERS_COUNTRY__CLICK],
    [OMFilter.UNIT, OM_FILTERS_UNIT__CLICK],
    [OMFilter.DIVISION, OM_FILTERS_BUSINESS_FUNCTION__CLICK],
    [OMFilter.DEPARTMENT, OM_FILTERS_DEPARTMENT__CLICK],
    [OMFilter.COST_CENTER, OM_FILTERS_COST_CENTER__CLICK],
]);
