import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useIsOpp = () => {
    const [searchParams] = useSearchParams();
    const [isOpp, setIsOpp] = useState(false);

    useEffect(() => {
        setIsOpp(searchParams.get('opp') === 'true');
    }, [searchParams]);

    return { isOpp };
};

export default useIsOpp;
