import useApp from 'hooks/useApp';
import useData from 'hooks/useData';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertColour } from 'utils/canvas';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import ingkaColours from '@ingka/variables/colours-css';
import { getAllBudgetHours, getCapacityHours } from 'hooks/useGrid/gridFunctionsShared';
import * as colours from '@ingka/variables/colours-css';
import { toIntlLongMonthFullYear } from 'utils/date';
import moment from 'moment';
import { calculateSum, isNegative, roundDisplayValueDoubleDecimal } from 'utils/number';
import { TFunction } from 'i18next';
import { ConfigurationData } from 'types/api';
import Graph from './Graph';
import GraphHeader from './GraphHeader';
import { ChartDataWithLabel, ChartGeneratorOptions, GraphData } from './types';

const generateGraphOptions = (
    t: TFunction<'translation', undefined, 'translation'>,
    graphData: GraphData,
    config: ConfigurationData | undefined,
    labelBudget: string,
    labelMaxCapacity: string
): ChartGeneratorOptions => (
    {
        layout: {
            padleft: graphData.datasets.length && graphData.datasets[0].data.length ? 0 : 20,
        },
        scales: {
            tickColor: convertColour(colours.colourStaticBlack),
            weeksThreshold: 16,
            labels: graphData.labels,
            hasNegativeValues: graphData.datasets.some(ds => ds.data.some(d => d < 0)),
            formatterYTicks: roundDisplayValueDoubleDecimal
        },
        plugins: {
            customYLabel: {
                text: t('HOURS'),
                color: convertColour(colours.colourStaticBlack),
                offsetX: graphData.datasets.length && graphData.datasets[0].data.length ? 0 : 20,
            },
            emptyGraph: {
                text: t('GRAPH_NO_DATA_MESSAGE')
            },
            tooltip: {
                internal: {
                    titleFormatter: (currentTitle: string) => (
                        `${t('WEEK')} ${currentTitle.slice(-2)} (${toIntlLongMonthFullYear(moment(currentTitle).toDate())})`
                    ),
                    backgroundColor: convertColour(colours.colourNeutral7),
                    numberFormatter: roundDisplayValueDoubleDecimal,
                    differenceOptions: config?.isContractRangeSupported ? {
                        differenceFormatter: (gap: number) => (
                            `${t('GAP')}: ${isNegative(gap)
                                ? roundDisplayValueDoubleDecimal(gap)
                                : `+${roundDisplayValueDoubleDecimal(gap)}`}`
                        ),
                        labelMinuend: labelMaxCapacity,
                        labelSubtrahend: labelBudget,
                    } : undefined,
                }
            }
        }

    }
);

const emptyDataset = (t: TFunction<'translation', undefined, 'translation'>) => [{
    label: t('TARGET_CAPACITY'),
    data: [],
}, {
    label: t('CAPACITY_HOURS'),
    data: [],
}];

export const CapacityOverviewGraph = memo(() => {
    const { gridData, timeSelection } = useApp();
    const { config, currentScenario, isFetching } = useData();
    const { t } = useTranslation();
    const [graphData, setGraphData] = useState<GraphData>({
        datasets: emptyDataset(t),
        labels: timeSelection.timeArray,
    });

    const labelBudget = useMemo(() => t('TARGET_CAPACITY'), [t]);
    const labelMaxCapacity = useMemo(
        () => (currentScenario
            ? t(`SCENARIO_CAPACITY${config?.isContractRangeSupported ? '_MAX_SHORT' : ''}`)
            : t(`CAPACITY_HOURS${config?.isContractRangeSupported ? '_MAX_SHORT' : ''}`)),
        [config?.isContractRangeSupported, currentScenario, t]
    );

    useEffect(() => {
        if (!gridData || !config) {
            setGraphData({
                datasets: emptyDataset(t),
                labels: timeSelection.timeArray,
            });

            return;
        }
        const capacityHours = gridData.hoverData.map(d => getCapacityHours(d.gap));
        const budgetHours = getAllBudgetHours(gridData.hoverData);

        const newDataSets: ChartDataWithLabel[] = [{
            type: 'line',
            label: labelBudget,
            data: budgetHours,
            backgroundColor: '#0061B4',
            spanGaps: true,
            segment: {
                borderColor: '#0061B4',
                backgroundColor: '#0061B4',
                borderWidth: 2, // px
            },
            pointRadius: 0, // px
            borderColor: '#0061B4',
        }];

        if (config.isContractRangeSupported) {
            const scenarioHoursMin = gridData.hoverData.map(d => getCapacityHours(d.minRangeGap ?? d.gap));
            newDataSets.push(
                {
                    type: 'bar',
                    label: currentScenario ? t('SCENARIO_CAPACITY_MIN_SHORT') : t('CAPACITY_HOURS_MIN_SHORT'),
                    data: scenarioHoursMin,
                    backgroundColor: convertColour(ingkaColours.colourNeutral5),
                    barThickness: 8, // px
                    hoverBackgroundColor: convertColour(ingkaColours.colourNeutral7),
                    borderColor: convertColour(ingkaColours.colourNeutral5),
                }
            );
        }

        newDataSets.push(
            {
                type: 'bar',
                label: labelMaxCapacity,
                data: capacityHours,
                backgroundColor: convertColour(ingkaColours.colourNeutral4),
                barThickness: 8, // px
                hoverBackgroundColor: convertColour(ingkaColours.colourNeutral5),
            },
        );

        setGraphData({
            datasets: newDataSets,
            labels: timeSelection.timeArray
        });
    }, [gridData, config, currentScenario, t, timeSelection.timeArray, labelBudget, labelMaxCapacity]);

    return (
        <LoadingWrapper isLoading={isFetching}>
            <>
                <GraphHeader
                    headerTitle={t('TARGET_AND_SCENARIO_TITLE')}
                    headerSubTitle={t('TARGET_AND_SCENARIO_SUBTITLE')}
                    legends={graphData.datasets.map(el => ({
                        label: el.label,
                        value: calculateSum(el.data),
                        color: el.backgroundColor as string
                    }))}
                />
                <Graph
                    graphData={graphData}
                    graphOptions={generateGraphOptions(t, graphData, config, labelBudget, labelMaxCapacity)}
                    customOptions={{ height: '170px' }}
                />
            </>
        </LoadingWrapper>
    );
});
