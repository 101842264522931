import React from 'react';
import classes from './UserInfoName.module.scss';

const UserInfoName = (
    { userName, handleClick, small }:
    { userName:string | undefined, handleClick?: () => void, small?: boolean }
) => (userName ? (
    <span
        data-testid="user-info-name"
        className={classes[`user-info-name${small ? '-small' : '-large'}`]}
        onClick={handleClick}
        role="button"
    >
        {userName}

    </span>
) : (
    <div
        data-testid="user-info-name-undefined"
        className={classes[`user-info-name${small ? '-small' : '-large'}-undefined`]}
    />
));

UserInfoName.defaultProps = {
    small: false,
    handleClick: () => {},
};

export default UserInfoName;
