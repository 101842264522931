import React, { memo } from 'react';
import { PageContainer } from 'components';
import Table from 'components/ManageCapacityTable/Table';
import useApp from 'hooks/useApp';
import PageToolbar from 'components/PageToolbar/PageToolbar';
import classes from './ManageCapacity.module.scss';

const ManageCapacity = memo(() => {
    const { timeSelection, gridData, showAllDetails } = useApp();

    return (
        <PageContainer testid="manage-capacity-page" className={classes['manage-capacity__container']}>
            <>
                <PageToolbar showAllElements />

                <div id="manage-capacity-table-div" className={classes.table}>
                    <Table
                        timeArray={timeSelection.timeArray}
                        gridData={gridData}
                        showAllDetails={showAllDetails}
                    />
                </div>

            </>
        </PageContainer>
    );
});

export default ManageCapacity;
