import React, { memo, useMemo, useState } from 'react';
import CoworkerDetails from 'components/CoworkerDetails/CoworkerDetails';
import { CoworkerGridData, CoworkerGridRow, CoworkerGridRowTotalHours, DummyGridData, RowType } from 'types/appContext';
import useApp from 'hooks/useApp';
import { BEGINNING_OF_TIME, INDEFINITE_END_DATE } from 'utils/date';
import { ModulationRange } from 'types/modulation';
import { calculateSum } from 'utils/number';
import { generateTotalHoursCellProps } from 'hooks/useGrid/gridFunctions';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Tooltip from '@ingka/tooltip';
import Button from '@ingka/button';
import { COWORKER_CHANGE_CONTRACT_BUTTON__CLICK, COWORKER_RESET_BUTTON__CLICK } from 'types/analytics';
import reset from '@ingka/ssr-icon/paths/arrow-u-turn-backwards';
import { useTranslation } from 'react-i18next';
import pencil from '@ingka/ssr-icon/paths/pencil';
import { useDeleteScenarioCoworkerAPI } from 'hooks/api/useDeleteScenarioCoworkerAPI';
import ModalService from 'services/modal';
import { useToast } from 'hooks/useToast';
import ReactDOM from 'react-dom';
import useData from 'hooks/useData';
import { Scenario } from 'types/scenario';
import useAuth from 'hooks/useAuth';
import { UserPermission } from 'types/authContext';
import { rowIsContractHours } from 'hooks/useGrid/gridFunctionsShared';
import { ContractHoursRow } from './ContractHoursRow';
import { getCoworkerDelta } from './shared';
import { ModulationSubRow } from './Modulation/ModulationSubRow';
import TotalHoursRow from './TotalHoursRow';
import ContractHoursSubRow from './ContractHoursSubRow';
import classes from '../CoworkerDetails/CoworkerDetails.module.scss';

const isPrimaryRow = (index: number) => index === 0;
const getModulationBalance = (gridRows: Array<CoworkerGridRow>, costCentre: string, calculateTotal?: boolean) => {
    if (calculateTotal) {
        return gridRows.find(row => row.rowType === RowType.MODULATION && row.costCentre === costCentre)?.rowSum ?? 0;
    }

    return calculateSum(gridRows.filter(row => row.rowType === RowType.MODULATION).map(row => row.rowSum));
};

const CoworkerRows = memo(
    ({
        coworkerData,
        canEdit,
        showAllDetails,
        modulationRange,
    }
    : {
        coworkerData: CoworkerGridData | DummyGridData;
        canEdit: boolean,
        showAllDetails: boolean,
        modulationRange: ModulationRange | undefined,
    }) => {
        const { t } = useTranslation();
        const { coworker, gridRows } = coworkerData;
        const { currentCoworkerId, currentScenario, timeSelection } = useApp();
        const [showIndividualDetails, setShowIndividualDetails] = useState(!!currentCoworkerId || false);
        const [showModulation, setShowModulation] = useState(false);
        const { selectScenario } = useData();
        const [deleteCoworkerModal, setDeleteCoworkerModal] = useState<boolean>(false);
        const [openEditCoworkerModal, setOpenEditCoworkerModal] = useState<boolean>(false);
        const { deleteCoworker } = useDeleteScenarioCoworkerAPI(coworkerData.coworker.personId);
        const { displayToast } = useToast();
        const { user, access } = useAuth();
        const hasEditScenarioAccess = user?.rolePermissions.includes(UserPermission.EDIT_SCENARIO) ?? false;

        const handleClickDetails = () => {
            setShowIndividualDetails(show => !show);
        };
        const handleClickModulation = () => {
            setShowModulation(show => !show);
        };

        const { newContractStartDate, newContractEndDate } = useMemo(() => getCoworkerDelta(
            currentScenario?.scenarioData?.find(el => el.personId === coworker.personId)?.coworkerDelta,
            currentScenario?.scenarioData?.find(el => el.personId === coworker.personId)?.contractDeltas,
            coworker.contractStartDate ?? BEGINNING_OF_TIME,
            coworker.contractEndDate ?? INDEFINITE_END_DATE,
        ), [coworker, currentScenario]);

        // TODO: Move to Backend getContractDatesColSpan
        const isActive = coworker.employmentStatus === 'active' || !coworker.employmentStatus;
        const hasModulationAccess = access?.features?.modulation ?? false;
        const showModulationSubrow = hasModulationAccess && showModulation && modulationRange && currentScenario;
        const totalHoursGridRowProps: Array<CoworkerGridRowTotalHours> = [];
        const showTotalHours = hasModulationAccess && modulationRange && currentScenario;

        if (showTotalHours) {
            if (!showIndividualDetails) {
                const costCentres = gridRows.filter(rowIsContractHours)?.map(el => el.costCentre);

                costCentres.forEach(costCentre => {
                    const filteredGridRows = gridRows.filter(el => el.costCentre === costCentre);
                    const rowSum = calculateSum(filteredGridRows.map(el => el.rowSum));
                    totalHoursGridRowProps.push({
                        costCentre,
                        editableCells: generateTotalHoursCellProps(
                            coworker.personId,
                            costCentre,
                            filteredGridRows,
                            timeSelection.timeArray,
                        ),
                        rowSum,
                        rowAverage: rowSum / timeSelection.timeArray.length,
                        rowType: RowType.TOTAL_HOURS,
                    });
                });
            } else {
                const rowSum = calculateSum(gridRows.map(el => el.rowSum));

                totalHoursGridRowProps.push({
                    costCentre: coworker.costCentre,
                    editableCells: generateTotalHoursCellProps(coworker.personId, coworker.costCentre, gridRows, timeSelection.timeArray),
                    rowSum,
                    rowAverage: rowSum / timeSelection.timeArray.length,
                    rowType: RowType.TOTAL_HOURS,
                });
            }
        }
        const isResetButtonEnabled = currentScenario?.scenarioData.some(({ personId }) => personId === coworkerData.coworker.personId);
        const getResetButtonText = (scenario: Scenario | undefined) => {
            if (!scenario) {
                return t('SELECT_A_SCENARIO');
            }

            return isResetButtonEnabled ? t('RESET_CHANGES') : t('NO_CHANGES_CO_WORKER');
        };

        const portalDiv = document.getElementById('portal');
        const confirmDeleteModal = ModalService().open(
            'prompt',
            deleteCoworkerModal,
            setDeleteCoworkerModal,
            <div>{coworkerData.coworker.isDummy ? t('DELETE_CO_WORKER_PROMPT') : t('RESET_CO_WORKER_PROMPT')}</div>,
            <div className={classes['delete-prompt-btn']}>
                <Button
                    text={coworkerData.coworker.isDummy ? t('DELETE_CO_WORKER_CONFIRM') : t('RESET_CO_WORKER_CONFIRM')}
                    size="small"
                    type="danger"
                    onClick={() => {
                        setDeleteCoworkerModal(false);
                        deleteCoworker().then(response => {
                            if (!response.isResponseOk) {
                                displayToast({ title: t('ERROR'), message: t('OPERATION_FAILED') });
                            } else {
                                displayToast({ title: t('SUCCESS'), message: t('SCENARIO_UPDATED') });
                                selectScenario(currentScenario?.id ?? '');
                            }
                        });
                    }}
                    autoFocus
                />
                <Button
                    text={t('CANCEL')}
                    size="small"
                    type="secondary"
                    onClick={() => setDeleteCoworkerModal(false)}
                />
            </div>,
            coworkerData.coworker.isDummy ? t('DELETE_CO_WORKER') : t('RESET_CHANGES'),
        );
        const coworkerDetailsModal = (
            <Modal
                visible={showIndividualDetails}
                escapable
                handleCloseBtn={() => setShowIndividualDetails(false)}
                focusLockProps={{ locked: false }}
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={(<ModalHeader />)}
                    footer={(
                        <ModalFooter>
                            {hasEditScenarioAccess && (!currentScenario ? null
                                : (
                                    <>
                                        <Tooltip tooltipText={getResetButtonText(currentScenario)} position="trailing">
                                            <Button
                                                data-testid="coworker-reset-button"
                                                ssrIcon={reset}
                                                onClick={() => setDeleteCoworkerModal(true)}
                                                size="small"
                                                disabled={!isResetButtonEnabled}
                                                type="secondary"
                                                text={coworkerData.coworker.isDummy ? t('DELETE_CO_WORKER') : t('RESET_CHANGES')}
                                                data-analytics={COWORKER_RESET_BUTTON__CLICK}
                                            />
                                        </Tooltip>
                                        <Tooltip tooltipText={t('CHANGE_SCENARIO_DATA')} position="top">
                                            <Button
                                                data-testid="coworker-edit-button"
                                                ssrIcon={pencil}
                                                onClick={() => setOpenEditCoworkerModal(true)}
                                                size="small"
                                                disabled={!currentScenario}
                                                type="primary"
                                                text={t('CHANGE_SCENARIO_DATA')}
                                                data-analytics={COWORKER_CHANGE_CONTRACT_BUTTON__CLICK}
                                                autoFocus
                                            />
                                        </Tooltip>
                                    </>
                                ))}
                        </ModalFooter>
                    )}
                >
                    <ModalBody>
                        <CoworkerDetails
                            coworker={coworkerData.coworker}
                            isActive={isActive}
                            modulationRange={modulationRange}
                            editCoworkerSheet={openEditCoworkerModal}
                            handleEditCoworkerSheet={setOpenEditCoworkerModal}
                        />
                    </ModalBody>
                </Sheets>
            </Modal>
        );

        return (
            <>
                {totalHoursGridRowProps.map((totalHoursRow, index) => (
                    <TotalHoursRow
                        key={`${coworker.personId}-${totalHoursRow.costCentre}-total-row`}
                        coworker={coworker}
                        showAllDetails={showAllDetails}
                        showIndividualDetails={showIndividualDetails}
                        cellProps={totalHoursRow.editableCells}
                        averageHrs={totalHoursRow.rowAverage}
                        totalHours={
                            {
                                contractHours: totalHoursRow.rowSum,
                                modulationHoursBalance: hasModulationAccess && modulationRange
                                    ? getModulationBalance(gridRows, totalHoursRow.costCentre, !showIndividualDetails)
                                    : undefined
                            }
                        }
                        costCentre={totalHoursRow.costCentre}
                        handleClickDetails={handleClickDetails}
                        handleClickModulation={handleClickModulation}
                        isPrimaryRow={isPrimaryRow(index)}
                        isActive={isActive}
                        contractStartDate={newContractStartDate}
                        contractEndDate={newContractEndDate ?? INDEFINITE_END_DATE}
                        modulationRange={modulationRange}
                    />
                ))}

                {gridRows.map((gridRow, index) => (
                    <React.Fragment
                        key={`${coworker.employeeId}-${gridRow.rowType}-${gridRow.costCentre}`}
                    >
                        {showModulationSubrow
                            ? (
                                <>
                                    {rowIsContractHours(gridRow)
                                        && (
                                            <ContractHoursSubRow
                                                key={`${coworker.personId}-${gridRow.costCentre}-contract-sub-row`}
                                                coworker={coworker}
                                                cellProps={gridRow.editableCells}
                                                canEdit={canEdit}
                                                isActive={isActive}
                                                contractStartDate={newContractStartDate}
                                                contractEndDate={newContractEndDate ?? INDEFINITE_END_DATE}
                                                showAllDetails={showAllDetails}
                                                showIndividualDetails={showIndividualDetails}
                                                costCentre={gridRow.costCentre}
                                            />

                                        )}
                                    {showModulationSubrow && gridRow.rowType === 'MODULATION' && (
                                        <ModulationSubRow
                                            key={`${coworker.personId}-${gridRow.costCentre}-modulation-sub-row`}
                                            coworker={coworker}
                                            cellProps={gridRow.editableCells}
                                            canEdit={canEdit}
                                            isActive={isActive}
                                            contractStartDate={newContractStartDate}
                                            contractEndDate={newContractEndDate ?? INDEFINITE_END_DATE}
                                            modulationRange={modulationRange}
                                            showAllDetails={showAllDetails}
                                            costCentre={gridRow.costCentre}
                                        />
                                    )}
                                </>
                            )
                            : (
                                !totalHoursGridRowProps?.length && rowIsContractHours(gridRow)
                                && (
                                    <ContractHoursRow
                                        key={`${coworker.personId}-${gridRow.costCentre}-contract-row`}
                                        coworker={coworker}
                                        showAllDetails={showAllDetails}
                                        cellProps={gridRow.editableCells}
                                        canEdit={canEdit}
                                        averageHrs={gridRow.rowAverage}
                                        totalHours={
                                            {
                                                contractHours: gridRow.rowSum,
                                                modulationHoursBalance: hasModulationAccess && modulationRange
                                                    ? getModulationBalance(gridRows, gridRow.costCentre, !showIndividualDetails)
                                                    : undefined
                                            }
                                        }
                                        costCentre={gridRow.costCentre}
                                        handleClickDetails={handleClickDetails}
                                        handleClickModulation={handleClickModulation}
                                        isPrimaryRow={isPrimaryRow(index)}
                                        isActive={isActive}
                                        contractStartDate={newContractStartDate}
                                        contractEndDate={newContractEndDate ?? INDEFINITE_END_DATE}
                                        modulationRange={modulationRange}
                                    />
                                )

                            )}

                    </React.Fragment>
                ))}
                {portalDiv && deleteCoworkerModal && ReactDOM.createPortal(confirmDeleteModal, portalDiv)}
                {portalDiv && ReactDOM.createPortal(coworkerDetailsModal, portalDiv)}
            </>
        );
    },
);

export default CoworkerRows;
