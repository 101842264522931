import { buildUrlDeleteCoworkerApi } from 'routes/api';
import { useWriter } from '../useWriter';
import useData from '../useData';

export const useDeleteScenarioCoworkerAPI = (personId: string) => {
    const { currentScenario, currentCountry, currentUnit, currentUnitType } = useData();
    const { writeData, ...response } = useWriter<null, {}>({
        url: buildUrlDeleteCoworkerApi(
            currentScenario?.id ?? '',
            personId,
            currentCountry ?? '',
            currentUnit ?? '',
            currentUnitType ?? ''
        ),
        method: 'DELETE'
    });

    return {
        ...response,
        deleteCoworker: writeData
    };
};
