import contractTypes from 'providers/staticContractTypes';
import { Coworker } from 'types/coworker';
import { DummyCoworker } from 'types/scenario';

/**
 * Function that estimates if a coworker is permanent or not, based on the contract type and the map between contract types and contract type descriptions.
 * TODO: Refactor this once we know how to accurately and globally determine if a coworker is permanent or not.
 */
export const isPermanent = (coworker: Pick<Coworker | DummyCoworker, 'contractType' | 'contractEndDate'>): boolean => {
    // First check for the two most common contract types.
    if (coworker.contractType === '01') return true;
    if (coworker.contractType === '02') return false;

    // See if the contract type description contains the words 'permanent', 'fixed term' or 'temporary'.
    const contractTypeDescription = contractTypes.get(coworker.contractType);
    if (contractTypeDescription?.toLocaleLowerCase().includes('permanent')) return true;
    if (contractTypeDescription?.toLocaleLowerCase().includes('fixed term')) return false;
    if (contractTypeDescription?.toLocaleLowerCase().includes('temporary')) return false;

    // Last resort: if the contract end date is set, assume that the coworker is temporary.
    return !coworker.contractEndDate;
};
