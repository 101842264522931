import { TypeWeeklyHoursAction } from 'types/actionPlan';
import { useTranslation } from 'react-i18next';
import { getShortCC } from 'utils/text';
import Text from '@ingka/text';
import classes from '../ActionTable.module.scss';

const WeeklyHoursAction = ({ action }:{ action: TypeWeeklyHoursAction }) => {
    const { t } = useTranslation();

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-weekly-hours-header"
                >
                    {t('WEEKLY_HOURS')}
                </Text>
                <Text
                    data-testid="action-plan-weekly-hours-change"
                >
                    {`${action.from} ${t('HOURS_ABBREVIATED')}`}
                    <b>
                        {' ➔ '}
                    </b>
                    {`${action.to} ${t('HOURS_ABBREVIATED')}`}
                    {' '}
                    <b>
                        {`(CC${getShortCC(action.costCentre)})`}
                    </b>
                </Text>
            </div>
        </div>
    );
};

export default WeeklyHoursAction;
