import { INDEFINITE_END_DATE } from 'utils/date';
import { ContractDelta } from 'types/scenario';
import moment from 'moment';
import { Coworker } from 'types/coworker';

export const getCoworkerDelta = (
    coworkerDelta : Partial<Coworker> | undefined,
    contractDeltas: ContractDelta[] | undefined,
    contractStartDate: string,
    contractEndDate: string,
) => {
    let deltaContractStartDate :string = contractStartDate;
    let deltaContractEndDate :string = contractEndDate;

    if (contractDeltas?.length) {
        // eslint-disable-next-line prefer-destructuring
        deltaContractStartDate = contractDeltas
            .map(el => el.startDate)
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0];
        const latestContractDelta = contractDeltas.sort(
            (a, b) => new Date(a.endDate ?? INDEFINITE_END_DATE).getTime() - new Date(b.endDate ?? INDEFINITE_END_DATE).getTime(),
        )[contractDeltas.length - 1];
        deltaContractEndDate = latestContractDelta?.endDate ?? INDEFINITE_END_DATE;
    }

    let coworkerDeltaStartDate = contractStartDate;
    let coworkerDeltaEndDate = contractEndDate;
    if (coworkerDelta) {
        coworkerDeltaStartDate = coworkerDelta?.contractStartDate ?? contractStartDate;
        coworkerDeltaStartDate = moment(contractStartDate).isBefore(coworkerDeltaStartDate)
            ? contractStartDate
            : coworkerDeltaStartDate;

        coworkerDeltaEndDate = coworkerDelta?.contractEndDate ?? contractEndDate;
        coworkerDeltaEndDate = moment(contractEndDate).isAfter(coworkerDeltaEndDate)
            ? contractEndDate
            : coworkerDeltaEndDate;
    }

    return {
        newContractStartDate: moment(coworkerDeltaStartDate).isAfter(deltaContractStartDate)
            ? deltaContractStartDate
            : contractStartDate,
        newContractEndDate: moment(coworkerDeltaEndDate ?? INDEFINITE_END_DATE).isBefore(
            deltaContractEndDate ?? INDEFINITE_END_DATE,
        )
            ? deltaContractEndDate
            : coworkerDeltaEndDate,
    };
};
