import { DummyGridData, CoworkerGridData, ContractMixGraphData } from 'types/appContext';
import { ModulationRange } from 'types/modulation';
import { Coworker } from 'types/coworker';
import { sortContractRangeDesc, sortOpenEndedRangeDesc } from 'utils/sorting';
import { configRangeIsContract, configRangeIsOpenEnded } from './gridFunctionsShared';

export const sortCoworkersByModulationHours = (
    coworkers: Array<(DummyGridData | CoworkerGridData)>,
    modulationRanges: Array<ModulationRange | null>,
    isAscending: boolean,
) => {
    const modulatedCoworkers = coworkers.filter((_cw, index) => !!modulationRanges[index]);
    const nonModulatedCoworkers = coworkers.filter((_cw, index) => !modulationRanges[index]);

    if (isAscending) {
        return modulatedCoworkers.concat(nonModulatedCoworkers);
    }

    return nonModulatedCoworkers.concat(modulatedCoworkers);
};

export const sortCoworkersByContractHours = (
    coworkers: Array<(DummyGridData | CoworkerGridData)>,
    isAscending: boolean,
) => {
    if (!coworkers) return [];
    const coworkersWithRanges = coworkers
        .filter(element => !!(element.coworker as Coworker).contractRange?.range.min && !!(element.coworker as Coworker).contractRange?.range.max)
        .sort((current: DummyGridData | CoworkerGridData, next: DummyGridData | CoworkerGridData) => {
            if (((current.coworker as Coworker).contractRange?.range.max ?? 0) === ((next.coworker as Coworker).contractRange?.range.max ?? 0)) {
                if (((current.coworker as Coworker).contractRange?.range.min ?? 0) === ((next.coworker as Coworker).contractRange?.range.min ?? 0)) {
                    return 0;
                }

                return ((current.coworker as Coworker).contractRange?.range.min ?? 0) > ((next.coworker as Coworker).contractRange?.range.min ?? 0) ? 1 : -1;
            }

            return ((current.coworker as Coworker).contractRange?.range.max ?? 0) > ((next.coworker as Coworker).contractRange?.range.max ?? 0) ? 1 : -1;
        });

    const coworkersWithoutRanges = coworkers
        // some coworkers with a range of min=null and max=40 are to be counted as not with ranges
        .filter(element => !(element.coworker as Coworker).contractRange || !(element.coworker as Coworker).contractRange?.range.min)
        .sort((current: DummyGridData | CoworkerGridData, next: DummyGridData | CoworkerGridData) => {
            if ((current.coworker.hoursPerWeek ?? 0) === (next.coworker.hoursPerWeek ?? 0)) {
                return 0;
            }

            return ((current.coworker.hoursPerWeek ?? 0) > (next.coworker.hoursPerWeek ?? 0)) ? 1 : -1;
        });

    if (!isAscending) {
        coworkersWithRanges.reverse();
        coworkersWithoutRanges.reverse();
    }

    return [
        ...coworkersWithRanges,
        ...coworkersWithoutRanges,
    ];
};

export const sortCoworkersByName = (
    coworkers: Array<(DummyGridData | CoworkerGridData)>,
    isAsc: boolean
) => coworkers?.sort((current: DummyGridData | CoworkerGridData, next: DummyGridData | CoworkerGridData) => {
    if (isAsc) {
        return current.coworker?.legalFullName?.localeCompare(next.coworker?.legalFullName);
    }

    return next.coworker?.legalFullName?.localeCompare(current.coworker.legalFullName);
}).slice();

export const sortContractMixData = ((a: ContractMixGraphData[number], b: ContractMixGraphData[number]) => {
    if (configRangeIsContract(a.rangeData) && configRangeIsContract(b.rangeData)) {
        return sortContractRangeDesc(a.rangeData, b.rangeData);
    }

    if (configRangeIsOpenEnded(a.rangeData) && configRangeIsOpenEnded(b.rangeData)) {
        return sortOpenEndedRangeDesc(a.rangeData, b.rangeData);
    }

    return 0;
});
