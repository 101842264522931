import { buildUrlUpdateCoworkerNoteApi } from 'routes/api';
import { TypeCoworkerNoteRequest } from 'types/api';
import { useWriter } from '../useWriter';
import useData from '../useData';

export const useUpdateActionPlanCoworkerNote = () => {
    const { currentScenario, currentCountry, currentUnit, currentUnitType } = useData();

    const { writeData, ...response } = useWriter<TypeCoworkerNoteRequest, null>(
        {
            url: buildUrlUpdateCoworkerNoteApi(currentScenario?.id ?? '', currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
            method: 'PUT',
        }
    );

    return {
        ...response,
        updateActionPlanCoworkerNote: writeData
    };
};
