import { AuthClient } from '@ingka-group-digital/people-auth';
import { KapplaRoute, ROUTE_UNAUTHORIZED } from './routes';

export enum UserPermission {

    /**
     * Access to the modulation feature
     */
    ENABLE_MODULATION = 'ENABLE_MODULATION',

    /**
     * Access to view OM filter on departments level
     */
    DEPARTMENTS_VIEW = 'DEPARTMENTS_VIEW',

    /**
     * Ability to create a master scenario (note: not main, but master as per discussions around France)
     */
    CREATE_MASTER_SCENARIO = 'CREATE_MASTER_SCENARIO',

    // API GET related permissions

    /**
     * Access to get PA data
     */
    READ_PA_DATA = 'READ_PA_DATA',

    /**
     * Access to get OM data
     */
    READ_OM_DATA = 'READ_OM_DATA',

    /**
     * *CLARIFY* - Access to config api?
     */
    READ_CONFIG = 'READ_CONFIG',

    /**
     * Access to get data for each scenario
    */
    READ_SCENARIOS = 'READ_SCENARIOS',

    /**
     * Access to fetch and view list of scenarios
    */
    READ_SCENARIOS_LIST = 'READ_SCENARIOS_LIST',

    /**
     * Access to get input file data & download input files
    */
    READ_INPUTS = 'READ_INPUTS',

    // App-wide permissions

    /**
     * *CLARIFY* - Access to upload all input files. Difference between these two? Remove 1?
     */
    EDIT_INPUTS = 'EDIT_INPUTS',

    /**
     * *CLARIFY* - Access to upload all input files. Difference between these two? Remove 1?
     */
    UPLOAD_INPUTS = 'UPLOAD_INPUTS',

    /**
     * Access to the upload/download modal (hide/show button) (currently feature flag)
     */
    CAPACITY_FACTOR_VIEW = 'CAPACITY_FACTOR_VIEW',

    /**
     * Access to copy a scenario.
     */
    COPY_SCENARIO = 'COPY_SCENARIO',

    /**
     * Access to create a scenario.
     */
    CREATE_SCENARIO = 'CREATE_SCENARIO',

    /**
     * Allows user to delete a scenario.
     */
    DELETE_SCENARIO = 'DELETE_SCENARIO',

    /**
     * Allows user to edit scenario data - renaming the scenario, switching which scenario is main,
     * make weekly changes, contract changes, add dummy CW.
     */
    EDIT_SCENARIO = 'EDIT_SCENARIO',

    // Route/page permissions

    /**
     * Access to the capacity overview page
     */
    VIEW_CAPACITY_OVERVIEW = 'VIEW_CAPACITY_OVERVIEW',

    /**
     * Access to the manage capacity page
     */
    VIEW_MANAGE_CAPACITY = 'VIEW_MANAGE_CAPACITY',

    /**
     * Access to the action plan page (currently feature flag)
     */
    VIEW_ACTION_PLAN = 'VIEW_ACTION_PLAN',

    /**
     * Access to the recruitment needs page (currently feature flag)
     */
    VIEW_RECRUITMENT_NEEDS = 'VIEW_RECRUITMENT_NEEDS',

    /**
     * Access to the configuration page (currently feature flag)
     */
    VIEW_CONFIGURATION = 'VIEW_CONFIGURATION',

    // Page permissions - Capacity Overview
    /**
     * Used to show/hide the contract mix cards and graph (currently also feature flag)
     */
    READ_CONTRACT_MIX = 'READ_CONTRACT_MIX',

    // Page permissions - Manage Capacity

    // Page permissions - Action Plan

    /**
     * Access to fetch and view action plan data
    */
    READ_ACTION_PLAN = 'READ_ACTION_PLAN',

    /**
     * Access to edit action plan notes.
     */
    EDIT_ACTION_PLAN = 'EDIT_ACTION_PLAN',

    // Page permissions - Configuration

    /**
     * Access to the onboarding new units in the config page
     */
    ONBOARD_NEW_UNITS = 'ONBOARD_NEW_UNITS',

    /**
     * Access to enable/disable units in the config page
     */
    ENABLE_DISABLE_UNITS = 'ENABLE_DISABLE_UNITS',

    /**
     * Access to the config setting to onboard a new country
     */
    ONBOARD_COUNTRY = 'ONBOARD_COUNTRY',

    // Page permissions - Recruitment Needs
    /**
    * Access to read recruitment needs data
    */
    READ_RECRUITMENT_DASHBOARD = 'READ_RECRUITMENT_DASHBOARD',
    /**
    * Access to upload / download coworker turnover rates for excel file
    */
    UPLOAD_RECRUITMENT_NEEDS = 'UPLOAD_RECRUITMENT_NEEDS',
}

export type Country = {
    code: string,
    name: string,
    isDefault: boolean,
};

export type Group = {
    id: string;
    displayName: string;
};

export type UserProfile = {
    businessUnit: string,
    country: string,
    email: string,
    name: string,
    officeLocation: string,
    role: string,
    image: string | null,
    rolePermissions: UserPermission[],
};

export type AuthAPI = {
    countries: Country[],
    userProfile: UserProfile,
};

/**
 * Map over all routes in Kappla, omitting unauthorized route
 */
export type UserAccessRoutes = {
    [TKey in KapplaRoute as TKey extends typeof ROUTE_UNAUTHORIZED ? never : TKey]: boolean;
};

/**
 * All features that can be conditionally enabled/disabled in the app.
 * Feature accesses based on user alone have boolean values.
 * Feature accesses which depend on user as well as some state of the app have values which are functions that return a boolean.
 */
export type UserAccessFeatures = {
    capacityFactorView: boolean;
    contractMixView: boolean;
    /**
     * Department view can be country specific, so it requires the current country to be passed in.
     */
    departmentsView: (currentCountry: string | undefined) => boolean;
    enableDisableUnits: boolean;
    onboardingNewUnits: boolean;
    modulation: boolean;
    recruitmentNeedsView: boolean
};

export type AuthAccess = {
    api: UserAPIPermissions;
    features: UserAccessFeatures | undefined;
    routes: UserAccessRoutes | undefined;
};

export type AuthContextValues = {
    authClient: ReturnType<typeof AuthClient>;
    user: UserProfile | null;
    access: AuthAccess | undefined;
    isLoading: boolean;
    error: any;
    countriesList: Country[] | undefined
};

export type UserAPIPermissions = {
    editScenario: boolean,
    deleteScenario: boolean,
    readInputs: boolean,
    uploadInputs: boolean,
    readPAData: boolean,
    readOMData: boolean,
    createMasterScenario: boolean,
    readContractMix: boolean,
    createScenario: boolean,
    readActionPlan: boolean,
    editActionPlan: boolean,
    readScenariosList: boolean,
    readScenarios: boolean,
    readConfig: boolean,
    copyScenario: boolean,
    onBoardNewUnits: boolean,
    enableDisableUnits: boolean,
    onBoardCountry: boolean,
    readRecruitmentNeeds: boolean,
    uploadRecruitmentNeeds: boolean,
};
