import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import useData from 'hooks/useData';
import { formatDate } from 'utils/date';
import { DummyCoworker } from 'types/scenario';
import InlineMessage from '@ingka/inline-message';
import ListView, { ListViewItem } from '@ingka/list-view';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import Avatar from 'components/Avatar';
import { getCostCentreDescription } from 'hooks/useGrid/gridFunctions';
import { t } from 'i18next';
import { getShortCC } from 'utils/text';
import contractTypes from 'providers/staticContractTypes';
import { API } from 'services';
import { ModulationRange } from 'types/modulation';
import { Coworker } from 'types/coworker';
import useAuth from 'hooks/useAuth';
import CoworkerContribution from './CoworkerContribution';
import CoworkerCostCentre from './CoworkerCostCentre';
import { getContractHoursRange, getCoworkerCostCentres, getCoworkerModulation } from './helpers';
import classes from './CoworkerDetails.module.scss';
import { CoworkerDetailsEditModal } from './CoworkerDetailsEditModal';
import CoworkerData from './CoworkerData';

export const DeactivatedCoworker = () => (
    <InlineMessage
        data-testid="coworker-details-deactivated"
        className={classes['deactivated-coworker']}
        ssrIcon={warningTriangle}
        body={t('DEACTIVATED_COWORKER_WARNING')}
        variant="cautionary"
        subtle
    />
);

const CoworkerDetails = ({
    coworker,
    isActive,
    modulationRange,
    editCoworkerSheet,
    handleEditCoworkerSheet
}: {
    coworker: Coworker | DummyCoworker;
    isActive: boolean;
    modulationRange: ModulationRange | undefined;
    editCoworkerSheet: boolean,
    handleEditCoworkerSheet: Dispatch<SetStateAction<boolean>>
}) => {
    const [imgSrc, setImgSrc] = useState<string | null>();
    const { access } = useAuth();
    const { currentCountry, currentUnit, currentScenario, unfilteredCountryOMData } = useData();
    const costCentres = getCoworkerCostCentres(coworker, unfilteredCountryOMData);

    useEffect(() => {
        if (currentCountry && currentUnit) {
            API().getUserProfilePic((coworker as Coworker).email)
                .then(objectUrl => {
                    setImgSrc(objectUrl);
                })
                .catch(() => {
                    setImgSrc(null);
                });
        }

        // clean up
        return () => {
            if (imgSrc) { URL.revokeObjectURL(imgSrc); }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coworker]);

    const coworkerDelta = currentScenario?.scenarioData.find(({ personId }) => personId === coworker.personId);
    if (coworkerDelta !== undefined) {
        coworkerDelta.contractDeltas.forEach(delta => {
            delta.costDistributions?.forEach(deltaCd => {
                if (!costCentres.some(originalCc => originalCc.costCentre === deltaCd.costCentre)) {
                    costCentres.push({
                        costCentre: deltaCd.costCentre,
                        displayName: `${getShortCC(deltaCd.costCentre)} ${getCostCentreDescription(
                            deltaCd.costCentre,
                            unfilteredCountryOMData,
                        )}`,
                        sourceIsPa: false,
                    });
                }
            });
        });
    }

    const portalDiv = document.getElementById('portal');

    return (
        <div className={classes['coworker-details-container']} data-testid="coworker-details">
            <div className={classes['profile-container-bg']} data-testid="coworker-details-profile">
                <div className={classes['profile-picture']} data-testid="coworker-details-profile-pic">
                    <Avatar imageSrc={imgSrc ?? null} size="medium" coworker />
                </div>
                <div className={classes.role}>
                    <div className={classes.bold} data-testid="coworker-details-legal-full-name">
                        {coworker?.legalFullName}
                    </div>
                    <div data-testid="coworker-details-job-title">
                        {coworker?.jobTitle ?? `${t('NO')} ${t('JOB_TITLE')}`}
                    </div>
                    <div className={classes['office-location']} data-testid="coworker-details-office-location">
                        {coworker?.personalAreaName}
                    </div>
                </div>
            </div>
            <div className="coworker-details-content" data-testid="coworker-details-content">
                <ListView id="coworker-details-content" data-testid="coworker-details-list-view">
                    {!isActive && (
                    <ListViewItem
                        data-testid="coworker-details-deactivated"
                        inset
                        emphasised
                        description={<DeactivatedCoworker />}
                        size="large"
                    />
                    )}
                    <ListViewItem
                        data-testid="coworker-details-id-number"
                        inset
                        emphasised
                        description={(
                            <CoworkerData
                                title={t('COWORKER_ID_NUMBER')}
                                data={coworker.isDummy ? `${t('NO')} ${t('COWORKER_ID_NUMBER')}` : coworker.personId}
                            />
                        )}
                        size="large"
                    />
                    <ListViewItem
                        data-testid="coworker-details-business-function"
                        inset
                        emphasised
                        description={<CoworkerData title={t('BUSINESS_FUNCTION')} data={coworker.divisionName ?? `${t('NO')} ${t('BUSINESS_FUNCTION')}`} />}
                        size="large"
                    />
                    <ListViewItem
                        data-testid="coworker-details-department"
                        inset
                        emphasised
                        description={<CoworkerData title={t('DEPARTMENT')} data={coworker.departmentName ?? `${t('NO')} ${t('DEPARTMENT')}`} />}
                        size="large"
                    />
                    <ListViewItem
                        data-testid="coworker-details-cost-centre"
                        inset
                        emphasised
                        title={t('COST_CENTRE')}
                        description={(<CoworkerCostCentre keyPrefix={coworker.personId} costCentres={costCentres} />)}
                        size="large"
                    />
                    <ListViewItem
                        data-testid="coworker-details-contract-details"
                        inset
                        emphasised
                        title={t('CONTRACT_DETAILS')}
                        description={`
                            ${contractTypes.get(coworker.contractType) || t('N/A')}, ${t(coworker.employmentType)},
                            ${coworker.contractRange ? getContractHoursRange(coworker as Coworker) : coworker.hoursPerWeek} ${t('HRS_PER_WEEK')}`}
                        size="large"
                    />
                    <ListViewItem
                        data-testid="coworker-details-contract-period"
                        inset
                        emphasised
                        title={t('CONTRACT_PERIOD')}
                        description={`
                            ${formatDate(coworker.contractStartDate) ?? t('N/A')} - ${formatDate(coworker.contractEndDate) ?? t('N/A')}`}
                        size="large"
                    />
                    {coworker.contributions.length > 0
                    && (
                    <ListViewItem
                        data-testid="coworker-details-contributions"
                        inset
                        emphasised
                        description={<CoworkerContribution title={t('CONTRIBUTIONS')} contributions={coworker.contributions} />}
                        size="large"
                    />
                    )}
                    {access?.features?.modulation && (
                    <ListViewItem
                        data-testid="coworker-details-modulation"
                        inset
                        emphasised
                        description={<CoworkerData title={t('MODULATION')} data={getCoworkerModulation(modulationRange)} />}
                        size="large"
                    />
                    )}
                </ListView>
            </div>
            {portalDiv
                && ReactDOM.createPortal(
                    <CoworkerDetailsEditModal
                        coworker={coworker}
                        isOpen={editCoworkerSheet}
                        setIsOpen={handleEditCoworkerSheet}
                    />,
                    portalDiv,
                )}
        </div>
    );
};

export default CoworkerDetails;
