import { OpenEndedRange } from 'types/api';
import { ContractRange } from 'types/coworker';
import { getContractRangeMax, getContractRangeMin } from './contractRange';

export const sortContractRangeDesc = (a:ContractRange, b: ContractRange) => {
    if (getContractRangeMax(a) === getContractRangeMax(b)) {
        return getContractRangeMin(a) - getContractRangeMin(b);
    }

    return getContractRangeMax(a) - getContractRangeMax(b);
};

export const sortOpenEndedRangeDesc = (a:OpenEndedRange, b: OpenEndedRange) => {
    if (a.max === b.max) {
        return ((b.min ?? 0) - (a.min ?? 0));
    }
    const totalHoursPerWeek = 7 * 24;

    return ((b.max ?? totalHoursPerWeek) - (a.max ?? totalHoursPerWeek));
};

/**
 * Helper function for sorting an array of objects based on a numeric value in the object
 * @param key A key of the objects that are sorted, where Object[key] is a number or a string
 * @param dir ASC for ascending, DESC for descending
 * @returns Sorting function
 * @example [{str: 'a', num: 1}, {str: 'b', num: 2}].sort(sortBy('num', 'ASC')) => [{str: 'a', num: 1}, {str: 'b', num: 2}]
 */
export const sortBy = <TObj extends Record<string, unknown>, TKey extends keyof TObj>(
    key: TObj[TKey] extends string | number ? TKey : never,
    dir: 'ASC' | 'DESC',
) => (a: TObj, b: TObj) => {
    const aValue = a[key];
    const bValue = b[key];
    if (typeof aValue === 'string' && typeof bValue === 'string') {
        if (dir === 'ASC') {
            return aValue.localeCompare(bValue);
        }

        return bValue.localeCompare(aValue);
    }
    if (typeof aValue === 'number' && typeof bValue === 'number') {
        if (dir === 'ASC') {
            return aValue - bValue;
        }

        return bValue - aValue;
    }

    return 0;
};
