import React, { memo } from 'react';
import { ContributionsData, GapHoursData } from 'types/table';
import HeaderCell from 'components/HeaderCell';
import Text from '@ingka/text';
import classes from './HeaderRow.module.scss';
import EmptyHeaders from './EmptyHeaders';

const HeaderRow = memo(({ data, title, showDetails, className, testid }: {
    data: Array<GapHoursData | ContributionsData>,
    title: string,
    showDetails: boolean,
    className?: string,
    testid?: string
}) => (
    <tr className={`${classes['gap-row']} ${className}`} data-testid={testid || ''}>
        <th className={classes['gap-row__display-cell']}>
            <Text headingSize="xs">
                {title}
            </Text>
        </th>
        {showDetails && (<EmptyHeaders showTitles={false} />)}
        {data.map((el, index) => (
            <th
                key={`gapCells-${index + 0}`}
            >
                <HeaderCell
                    key={`gap-hover-${index + 0}`}
                    hoverData={el}
                />
            </th>
        ))}
        <th>&nbsp;</th>

    </tr>
));

export default HeaderRow;
