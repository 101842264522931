import React from 'react';
import Modal, { Sheets, ModalFooter, ModalBody, ModalHeader, Prompt, Theatre } from '@ingka/modal';

const ModalService = () => ({
    /**
     *
     * @param type can be 'sheets' | 'prompt' | 'theatre'
     * @param modalOpen boolean for if the modal should be visible
     * @param setModalOpen setter for modalOpen, used for the close btn
     * @param bodyElement pass the element which will render as the body in the modal
     * @param footerElement pass the modal footer. This must contain a button
     * @param title optional header string
     * @param side can be 'left' | 'right' for  the modal to open from which side
     */
    open: (
        type: 'sheets' | 'prompt' | 'theatre',
        modalOpen: boolean,
        setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
        bodyElement: JSX.Element,
        footerElement?: JSX.Element,
        title?: string,
        side? : 'left' | 'right',
        size?: 'small' | 'medium' | 'large'
    ) => {
        if (type === 'sheets') {
            return (
                <Modal
                    visible={modalOpen}
                    escapable
                    scrollLock
                    handleCloseBtn={() => setModalOpen(false)}
                    focusLockProps={{ locked: false }}
                >
                    <Sheets
                        alignment={side}
                        size={size}
                        header={title ? (<ModalHeader title={title} />) : null}
                        footer={(
                            <ModalFooter>
                                {footerElement}
                            </ModalFooter>
                        )}
                    >
                        <ModalBody>
                            {bodyElement}
                        </ModalBody>
                    </Sheets>
                </Modal>
            );
        } if (type === 'theatre') {
            return (
                <Modal
                    visible={modalOpen}
                    escapable
                    scrollLock
                    handleCloseBtn={() => setModalOpen(false)}
                    focusLockProps={{ locked: false }}
                >
                    <Theatre
                        header={title ? (<ModalHeader title={title} />) : null}
                    >
                        {bodyElement}
                        {footerElement}
                    </Theatre>
                </Modal>
            );
        } if (type === 'prompt') {
            return (
                <Modal
                    visible={modalOpen}
                    escapable
                    handleCloseBtn={() => setModalOpen(false)}
                    focusLockProps={{ locked: false }}
                >
                    <Prompt
                        header={null}
                        footer={(
                            <ModalFooter>
                                {footerElement}
                            </ModalFooter>
                        )}
                        title={title || ''}
                        titleId={title || ''}
                    >
                        {bodyElement}
                    </Prompt>
                </Modal>
            );
        }

        return null;
    },
});

export default ModalService;
