import React from 'react';
import { convertBlobToImageSrc } from 'utils/blob';
import personLogo from '@ingka/ssr-icon/paths/person';
import SSRIcon from '@ingka/ssr-icon';
import classes from './Avatar.module.scss';

const Avatar = ({ imageSrc, size, coworker }: { imageSrc:string | null, size: 'small' | 'medium' | 'large', coworker?: boolean }) => {
    const avatarSizeClass = `avatar-${size}`;

    return (
        <div className={classes[avatarSizeClass]} data-testid="avatar">
            {imageSrc
                ? (
                    <img
                        data-testid="user-avatar-image"
                        src={coworker ? imageSrc : convertBlobToImageSrc(imageSrc, 'jpeg')}
                        alt="profile"
                    />
                )
                : (
                    <div className={classes['empty-circle']}>
                        <SSRIcon
                            color="#000000"
                            className="kappla-svg-icon"
                            paths={personLogo}
                            data-testid="user-avatar-no-image"
                        />
                    </div>
                )}
        </div>
    );
};

export default Avatar;
