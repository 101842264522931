import { ContributionsData, GapHoursData, HoverDataContentType } from 'types/table';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR, DateHelper } from 'utils/date';
import * as colours from '@ingka/variables/colours-css';
import { getGapContributions,
    isContributionsUnderTarget,
    getGapHours,
    isCapacityUnderTarget } from 'hooks/useGrid/gridFunctionsShared';
import classes from './HoverDetails.module.scss';
import Detail from './Detail';
import ContentGap from './ContentGap';
import ContentContributions from './ContentContributions';

const HoverDetails = ({
    hoverData,
    hoverDetailsDirection
}:
{
    hoverData: GapHoursData | ContributionsData,
    hoverDetailsDirection : string
}) => {
    const { t } = useTranslation();
    const totalGap = hoverData.contentType === HoverDataContentType.GAP ? getGapHours(hoverData) : getGapContributions(hoverData).total;
    const isDangerColor = hoverData.contentType === HoverDataContentType.GAP
        ? isCapacityUnderTarget(hoverData)
        : isContributionsUnderTarget(hoverData);

    return (
        <div data-testid="total-gap__hover" className={classes[`tablehover-show-${hoverDetailsDirection}`]}>
            <div className={classes.tablehover__week}>
                <span className={classes.tablehover__week__name}>{`${t('WEEK')} ${DateHelper.getISOWeek(hoverData.weekDateIso)}`}</span>
                <span className={classes.tablehover__week__range}>
                    {`${DateHelper.getISOWeekStartDate(hoverData.weekDateIso).format(DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR)}
                    ${' '}-${' '}${DateHelper.getISOWeekStartDate(hoverData.weekDateIso).endOf('isoWeek').format(DATE_FORMAT_FULL_DAY_SHORT_MONTH_FULL_YEAR)}`}
                </span>
            </div>
            <div className={classes.tablehover__divider} />
            { hoverData.contentType === HoverDataContentType.CONTRIBUTIONS
                ? <ContentContributions hoverData={hoverData} />
                : <ContentGap hoverData={hoverData} />}
            <div className={classes.tablehover__divider} />
            <Detail
                property={t('TOTAL_GAP')}
                value={totalGap}
                totalGapValueColor={isDangerColor ? colours.colourSemanticNegative : colours.colourTextAndIcon3}
            />
        </div>
    );
};

export default HoverDetails;
