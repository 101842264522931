import Loading, { LoadingBall } from '@ingka/loading';
import classes from './LoadingIndicator.module.scss';

const LoadingIndicator = ({ isLoading, isFixed }:{ isLoading: boolean, isFixed?: boolean }) => (
    <div
        data-testid="loading-indicator"
        className={`
            ${classes['loading-indicator']}
            ${isLoading ? classes['loading-indicator__loading--active']
            : classes['loading-indicator__loading--inactive']}
            ${isFixed ? classes['loading-indicator__fixed'] : ''}        
        `}
    >
        <Loading text="Loading ..." labelTransitions>
            <LoadingBall size="large" />
        </Loading>
    </div>
);

export default LoadingIndicator;
