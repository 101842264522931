import React, { useState } from 'react';
import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import information from '@ingka/ssr-icon/paths/information-circle';
import Modal, { Sheets, ModalHeader, ModalFooter, ModalBody } from '@ingka/modal';
import DefaultTemplates from './DefaultTemplates';

const DefaultTemplatesIcon = () => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <>
            <div>
                <Button
                    id="default-templates-icon"
                    data-testid="default-templates-icon"
                    ssrIcon={information}
                    type="tertiary"
                    iconOnly
                    size="small"
                    onClick={() => setModalOpen(true)}
                />
            </div>
            <Modal
                id="default-templates-modal"
                data-testid="default-templates-modal"
                visible={modalOpen}
                handleCloseBtn={() => setModalOpen(false)}
                escapable
            >
                <Sheets
                    alignment="right"
                    size="small"
                    header={(
                        <ModalHeader backBtnClick={() => setModalOpen(false)} title={t('TEMPLATES')} />
                    )}
                    footer={(
                        <ModalFooter>
                            <Button
                                id="deafault-template-close-button"
                                data-testid="default-template-close-button"
                                text={t('CLOSE')}
                                onClick={() => setModalOpen(false)}
                            />
                        </ModalFooter>
                    )}
                >
                    <ModalBody>
                        <DefaultTemplates />
                    </ModalBody>
                </Sheets>
            </Modal>
        </>
    );
};

export default DefaultTemplatesIcon;
