import i18next from 'services/i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import { ErrorBoundary, LoadingIndicator } from 'components';
import { I18nextProvider } from 'react-i18next';
import { AppProvider } from 'context/AppContext';
import App from 'App';
import { LoadingProvider } from 'context/LoadingContext';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, DataProvider } from './context';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(
    <ErrorBoundary>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <AuthProvider>
                    <DataProvider>
                        <AppProvider>
                            <LoadingProvider>
                                <React.Suspense fallback={<LoadingIndicator isLoading />}>
                                    <App />
                                </React.Suspense>
                            </LoadingProvider>
                        </AppProvider>
                    </DataProvider>
                </AuthProvider>
            </I18nextProvider>
        </BrowserRouter>
    </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
