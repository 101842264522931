import React, { useState } from 'react';
import Tooltip from '@ingka/tooltip';
import useData from 'hooks/useData';
import { useTranslation } from 'react-i18next';
import AddCoworkerButton from './AddCoworkerButton';
import AddCoworkerModal from './AddCoworkerModal';

const AddCoworker = () => {
    const { t } = useTranslation();
    const { currentScenario } = useData();
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            {!currentScenario
                ? (
                    <Tooltip tooltipText={t('SELECT_A_SCENARIO')} position="trailing">
                        <AddCoworkerButton setModalOpen={setModalOpen} />
                    </Tooltip>
                )

                : <AddCoworkerButton setModalOpen={setModalOpen} />}
            <AddCoworkerModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </>
    );
};

export default AddCoworker;
