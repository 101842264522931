import { UserPermission, UserProfile, UserAPIPermissions } from 'types/authContext';

export const noAPIAccess = {
    editScenario: false,
    deleteScenario: false,
    readInputs: false,
    uploadInputs: false,
    readPAData: false,
    readOMData: false,
    createMasterScenario: false,
    readContractMix: false,
    createScenario: false,
    readActionPlan: false,
    editActionPlan: false,
    readScenariosList: false,
    readScenarios: false,
    readConfig: false,
    copyScenario: false,
    onBoardNewUnits: false,
    enableDisableUnits: false,
    onBoardCountry: false,
    readRecruitmentNeeds: false,
    uploadRecruitmentNeeds: false,
};

export const getAPIPermissions = (user: UserProfile | null): UserAPIPermissions => ({
    editScenario: user?.rolePermissions?.includes(UserPermission.EDIT_SCENARIO) ?? noAPIAccess.editScenario,
    deleteScenario: user?.rolePermissions?.includes(UserPermission.DELETE_SCENARIO) ?? noAPIAccess.deleteScenario,
    readInputs: user?.rolePermissions?.includes(UserPermission.READ_INPUTS) ?? noAPIAccess.readInputs,
    uploadInputs: user?.rolePermissions?.includes(UserPermission.UPLOAD_INPUTS) ?? noAPIAccess.uploadInputs,
    readPAData: user?.rolePermissions?.includes(UserPermission.READ_PA_DATA) ?? noAPIAccess.readPAData,
    readOMData: user?.rolePermissions?.includes(UserPermission.READ_OM_DATA) ?? noAPIAccess.readOMData,
    createMasterScenario: user?.rolePermissions?.includes(UserPermission.CREATE_MASTER_SCENARIO) ?? noAPIAccess.createMasterScenario,
    readContractMix: user?.rolePermissions?.includes(UserPermission.READ_CONTRACT_MIX) ?? noAPIAccess.readContractMix,
    createScenario: user?.rolePermissions?.includes(UserPermission.CREATE_SCENARIO) ?? noAPIAccess.createScenario,
    readActionPlan: user?.rolePermissions?.includes(UserPermission.READ_ACTION_PLAN) ?? noAPIAccess.readActionPlan,
    editActionPlan: user?.rolePermissions?.includes(UserPermission.EDIT_ACTION_PLAN) ?? noAPIAccess.editActionPlan,
    readScenariosList: user?.rolePermissions?.includes(UserPermission.READ_SCENARIOS_LIST) ?? noAPIAccess.readScenariosList,
    readScenarios: user?.rolePermissions?.includes(UserPermission.READ_SCENARIOS) ?? noAPIAccess.readScenarios,
    readConfig: user?.rolePermissions?.includes(UserPermission.READ_CONFIG) ?? noAPIAccess.readConfig,
    copyScenario: user?.rolePermissions?.includes(UserPermission.COPY_SCENARIO) ?? noAPIAccess.copyScenario,
    onBoardNewUnits: user?.rolePermissions?.includes(UserPermission.ONBOARD_NEW_UNITS) ?? noAPIAccess.onBoardNewUnits,
    enableDisableUnits: user?.rolePermissions?.includes(UserPermission.ENABLE_DISABLE_UNITS) ?? noAPIAccess.enableDisableUnits,
    onBoardCountry: user?.rolePermissions?.includes(UserPermission.ONBOARD_COUNTRY) ?? noAPIAccess.onBoardCountry,
    readRecruitmentNeeds: user?.rolePermissions?.includes(UserPermission.READ_RECRUITMENT_DASHBOARD) ?? noAPIAccess.readRecruitmentNeeds,
    uploadRecruitmentNeeds: user?.rolePermissions?.includes(UserPermission.UPLOAD_RECRUITMENT_NEEDS) ?? noAPIAccess.uploadRecruitmentNeeds,
});
