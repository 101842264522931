import Table, { TableBody, TableHeader } from '@ingka/table';
import useScrollSlice from 'hooks/useScrollSlice';
import { useTranslation } from 'react-i18next';
import { memo, useMemo } from 'react';
import useActionPlanContext from 'hooks/useActionPlanContext';
import { ContractChangeType, CoworkerAction, TypeCCDistributionAction, TypeCoworkerActionPlan } from 'types/actionPlan';
import InlineMessage from '@ingka/inline-message';
import { useActionPlan } from 'hooks/api/useActionPlan';
import useData from 'hooks/useData';
import information from '@ingka/ssr-icon/paths/information';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import useApp from 'hooks/useApp';
import { CheckBox } from 'types/appContext';
import { TypeActionPlanAPIResponse } from 'types/api';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import { CapacityActionTableRow } from './CapacityActionTableRow';
import classes from './ActionTable.module.scss';

const getIsCoworkerSelectedInOMFilters = (
    coworker: TypeCoworkerActionPlan,
    filteredDivisionOMData: CheckBox[] | undefined,
    filteredCostCenterOMData: CheckBox[] | undefined,
) => {
    const { isDummy } = coworker;

    const selectedCCLength = filteredCostCenterOMData?.filter(({ defaultChecked }) => defaultChecked)?.length ?? 0;
    const selectedDivisionsLength = filteredDivisionOMData?.filter(({ defaultChecked }) => defaultChecked)?.length ?? 0;

    const selectedCCs = selectedCCLength
        ? filteredCostCenterOMData?.filter(({ defaultChecked }) => defaultChecked).map(({ id }) => id) : filteredCostCenterOMData?.map(({ id }) => id);
    const selectedDivisions = selectedDivisionsLength
        ? filteredDivisionOMData?.filter(({ defaultChecked }) => defaultChecked).map(({ id }) => id) : filteredDivisionOMData?.map(({ id }) => id);

    if (isDummy) {
        return coworker.costCentres.some(cc => selectedCCs?.includes(cc));
    }

    const areDivisionsSelected = filteredDivisionOMData?.some(({ defaultChecked }) => defaultChecked);
    const areCostCentresSelected = filteredCostCenterOMData?.some(({ defaultChecked }) => defaultChecked);

    return (areDivisionsSelected ? selectedDivisions?.includes(coworker.division) : true)
        && (areCostCentresSelected ? coworker.costCentres.some(cc => selectedCCs?.includes(cc)) : true);
};

const getCoworkerFilteredActionPlan = (coworkerActionPlan: TypeCoworkerActionPlan, activeFilter: ContractChangeType | null) => {
    switch (activeFilter) {
    case ContractChangeType.CONTRACT_HOURS:
        // eslint-disable-next-line no-case-declarations
        const contractHoursActionGroups = coworkerActionPlan.actionGroups.map(actionGroup => ({
            ...actionGroup,
            actions: actionGroup.actions
                .filter(action => action.type !== CoworkerAction.MODULATION)
        })).filter(g => g.actions.length);

        if (!contractHoursActionGroups.length) {
            return null;
        }

        return {
            ...coworkerActionPlan,
            actionGroups: contractHoursActionGroups
        };
    case ContractChangeType.MODULATION:
        // eslint-disable-next-line no-case-declarations
        const modulationActionGroups = coworkerActionPlan.actionGroups.map(actionGroup => ({
            ...actionGroup,
            actions: actionGroup.actions
                .filter(action => action.type === CoworkerAction.MODULATION)
        })).filter(g => g.actions.length);

        if (!modulationActionGroups.length) {
            return null;
        }

        return {
            ...coworkerActionPlan,
            actionGroups: modulationActionGroups
        };
    default:
        return coworkerActionPlan;
    }
};

const CapacityActionTable = memo(() => {
    const { t } = useTranslation();
    const { searchParam } = useApp();
    const { activeFilter } = useActionPlanContext();
    const { currentScenario } = useData();
    const { data, error, isLoading } = useActionPlan();
    const { filteredDivisionOMData, filteredCostCenterOMData, filteredDepartmentOMData } = useApp();
    const { slice } = useScrollSlice(30, data?.length, 'capacity-action-plan-page');

    const coworkersFilteredActionPlan = useMemo(
        () => {
            if (!data || !currentScenario || error) {
                return null;
            }

            const coworkers = data?.map(coworker => {
                const ccDistributionAction: TypeCCDistributionAction[] = [];
                coworker.actionGroups.forEach(({ actions }) => {
                    actions.forEach(action => {
                        if (action.type === CoworkerAction.CC_DISTRIBUTION) {
                            ccDistributionAction.push(action);
                        }
                    });
                });

                ccDistributionAction.forEach(({ ccDistributions }) => {
                    ccDistributions.forEach(({ from, to }) => {
                        if (!coworker.costCentres.includes(from.costCentre)) {
                            coworker.costCentres.push(from.costCentre);
                        }
                        if (!coworker.costCentres.includes(to.costCentre)) {
                            coworker.costCentres.push(to.costCentre);
                        }
                    });
                });

                if (searchParam
                    && !coworker.name.toLocaleLowerCase().includes(searchParam.trim().toLocaleLowerCase())
                    && !coworker.personId.includes(searchParam.trim().toLocaleLowerCase())) {
                    return null;
                }

                if (!getIsCoworkerSelectedInOMFilters(coworker, filteredDivisionOMData, filteredCostCenterOMData)) {
                    return null;
                }

                return getCoworkerFilteredActionPlan(coworker, activeFilter);
            }).filter(el => !!el);

            return coworkers;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, activeFilter, searchParam, filteredDivisionOMData, filteredCostCenterOMData, filteredDepartmentOMData, currentScenario]
    );

    if (error) {
        return (
            <div>
                <InlineMessage
                    ssrIcon={warningTriangle}
                    title={t('GENERIC_ERROR')}
                />
            </div>
        );
    }

    if (!isLoading && !coworkersFilteredActionPlan?.length) {
        return (
            <div>
                <InlineMessage
                    ssrIcon={information}
                    title={!currentScenario ? t('COWORKERS_SELECT_SCENARIO') : t('NO_PERSONNEL_DATA')}
                />
            </div>
        );
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <Table id="capacity-action-table" data-testid="capacity-action-table" fullWidth className={isLoading ? classes.table__loading : ''}>
                <TableHeader sticky>
                    <tr>
                        <th>{t('COWORKER')}</th>
                        <th>{t('CONTRACT_CHANGES')}</th>
                        <th>{t('LAST_CHANGED')}</th>
                        <th>{t('COST_CENTRE')}</th>
                    </tr>
                </TableHeader>
                <TableBody>
                    {
                        (coworkersFilteredActionPlan as TypeActionPlanAPIResponse)
                            ?.slice(slice.start, slice.end)
                            .map(coworkerActionPlan => (
                                <CapacityActionTableRow
                                    key={`coworker-rows-${coworkerActionPlan?.personId}`}
                                    coworkerActionPlan={coworkerActionPlan}
                                    totalCoworkers={(coworkersFilteredActionPlan as TypeActionPlanAPIResponse).length}
                                />
                            ))
                    }
                </TableBody>
            </Table>
        </LoadingWrapper>
    );
});

export default CapacityActionTable;
