export enum OMFilter {
    COUNTRY = 'country',
    UNIT = 'unit',
    DIVISION = 'division',
    DEPARTMENT = 'department',
    COST_CENTER = 'costCentre'
}

export const DEFAULT_DEBOUNCE_TIME = 500;
export const DEBOUNCE_TIME_LONG = 1000;

export enum RangeStatus {
    /**
     * Within range means this cell will be replaced by a greyed out cell (filtered out, replaced by grey colspan cell)
     */
    WITHIN_RANGE = 'withinRange',
    /**
     * Outside range means this cell will become and editable cell
     */
    OUTSIDE_RANGE = 'outsideRange'
}
