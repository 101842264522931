import * as Sentry from '@sentry/browser';

const initSentry = () => {
    /*
    Conditionally initialize Sentry based on the environment to only work in prod
    as per https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#enabled
    */
    if (process.env.REACT_APP_ENV === 'prod') {
        Sentry.init({
            dsn: process.env.REACT_APP__SENTRY_KEY,
            environment: process.env.NODE_ENV,
            release: process.env.REACT_APP__VERSION,
            integrations: [Sentry.browserTracingIntegration()], // Ref: https://docs.sentry.io/platforms/javascript/tracing/
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // INGKA's trial for perfomance monitoring has elapsed,
            // until a new deal has been made this needs to be set to 0
            tracesSampleRate: 0,
        });
    }
};
export default initSentry;
