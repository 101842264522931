import React, { createContext, useState, useMemo, useEffect } from 'react';
import useData from 'hooks/useData';
import useApp from 'hooks/useApp';

type LoadingContextValues = {
    isLoading: boolean;
};
const LoadingContext = createContext<LoadingContextValues>({} as LoadingContextValues);

const LoadingProvider = ({ children }: { children: JSX.Element }) => {
    const { isFetching } = useData();
    const { isCalculating } = useApp();
    const [isLoading, setLoading] = useState(isFetching || isCalculating);

    useEffect(() => {
        setLoading(isFetching || isCalculating);
    }, [isFetching, isCalculating]);

    const contextValue: LoadingContextValues = useMemo(
        () => ({
            isLoading,
        }),
        [isLoading],
    );

    return <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>;
};

export { LoadingContext, LoadingProvider };
