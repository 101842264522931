import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ContractHoursRow.module.scss';

const EmptyHeaders = ({ showTitles }: { showTitles?: boolean }) => {
    const { t } = useTranslation();

    return (
        <>
            <th>
                <div className={showTitles ? classes.details : ''}>
                    {showTitles ? t('BUSINESS_FUNCTION') : ''}
                </div>
            </th>
            <th>
                <div className={showTitles ? classes.details : ''}>
                    {showTitles ? t('COST_CENTRE') : ''}
                </div>
            </th>
        </>
    );
};

EmptyHeaders.defaultProps = {
    showTitles: false,
};
export default EmptyHeaders;
