import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextBackend from 'i18next-http-backend';
import CookieJar from 'utils/cookieJar';
import locales from './supportedLanguages.json';

// Get preferred language from One People Planning cookie
const jar = CookieJar({ name: 'opp' });
const preferredLang = jar.get('lang');
const MATCH_LOCALE_CODE = /^[a-zA-Z]{2}-[a-zA-Z]{2}$/;
const localeMap = new Map<string, string>();
/**
 * Create a curated list of language codes that fits the languages we support
 * This is a workaround for Chrome, as not all languages that can be set have full language codes (i.e. <language>-<REGION>)
 */
locales.forEach(locale => {
    if (locale.length > 2) {
        const localeParts = locale.split('-');
        localeMap.set(localeParts[0], locale);
    }
});

export const lookupLocale = (locale: string, localesList: Map<string, string>) => {
    if (locale.length <= 2) {
        const [language] = locale.split('-');

        return localesList.get(language) || 'en-XZ';
    } if (locale.match(MATCH_LOCALE_CODE)) {
        return locale;
    }

    return 'en-XZ';
};
/**
 * Usd in conjunction wit i18n.changeLanguage(languageCode) when only a shortCode is available
 * @param shortCode
 * @returns
 */
export const getLocale = (shortCode: string): string => lookupLocale(shortCode ?? 'en', localeMap);

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18next
    .use(i18nextBackend)
    .use(initReactI18next)
    .init({
        debug: false,
        load: 'currentOnly',
        fallbackLng: 'en-XZ',
        lng: lookupLocale(preferredLang || navigator.language, localeMap),
        interpolation: {
            escapeValue: false,
        },
        react: {
            bindI18n: 'languageChanged',
        },
        returnNull: false,
    });

export default i18next;
