import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { updateSearchParams } from 'components/Router/searchParams';
import Toggle, { ToggleProps } from '@ingka/toggle';
import classes from './OMFilters.module.scss';

interface SortOrderParam {
    [key: string]: string;
}

const OMFilterSort = ({
    toggleLabels,
    param
} : {
    toggleLabels: Array<{ text: string }>,
    param: string
} & Omit<ToggleProps, 'buttons'>) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortOrder, setSortOrder] = useState<string>(searchParams.get(param) ?? '0');

    useEffect(() => {
        if (sortOrder !== '') {
            const sortOrderParam: SortOrderParam = {};
            sortOrderParam[param] = sortOrder;
            updateSearchParams([sortOrderParam], searchParams, setSearchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder, searchParams]);

    return (
        <Toggle
            className={classes['om-filters-toggle-button']}
            buttons={toggleLabels}
            activeIndex={Number(sortOrder) ?? undefined}
            onClick={(_, index) => {
                if (Number(sortOrder) !== index) {
                    setSortOrder(index.toString());
                }
            }}
        />
    );
};

export default OMFilterSort;
