import React, { memo, useMemo } from 'react';
import useData from 'hooks/useData';
import Select, { Option } from '@ingka/select';
import { useTranslation } from 'react-i18next';
import { DROPDOWN_SCENARIO_BUTTON__CLICK } from 'types/analytics';
import { useSearchParams } from 'react-router-dom';
import { removeSearchParams } from 'components/Router/searchParams';
import { Scenario } from 'types/scenario';
import { MAIN_SCENARIO_SYMBOL, OTHER_SCENARIO_SYMBOL } from 'utils/constants';
import classes from './ScenarioSelector.module.scss';

export const displayMainOption = (name: string) => `${MAIN_SCENARIO_SYMBOL} ${name}`;
export const displayOtherOption = (name: string) => `${OTHER_SCENARIO_SYMBOL} ${name}`;
const displayScenarioOption = (scenario: Scenario) => {
    if (scenario.isMain) {
        return displayMainOption(scenario.name);
    }

    return displayOtherOption(scenario.name);
};

const ScenarioSelector = memo(() => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { scenarioList, currentScenario, selectScenario, setScenarioBudget } = useData();
    const { t } = useTranslation();
    const defaultScenarioOption = useMemo(() => ({
        id: 'noScenario',
        name: scenarioList ? t('NO_SCENARIO_SELECTED') : t('NO_SCENARIO_CREATED'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [scenarioList]);

    const handleChange = (e: any) => {
        setScenarioBudget(false);
        selectScenario(e.target.value);
        removeSearchParams(['sortedBy', 'sortOrder'], searchParams, setSearchParams);
    };

    return (
        <Select
            data-testid="scenarios-select"
            className={classes.selector}
            id="scenarios-select"
            label={t('SCENARIO')}
            name={currentScenario ? currentScenario.name : defaultScenarioOption.name}
            value={currentScenario ? currentScenario.id : defaultScenarioOption.id}
            hintText=""
            onChange={handleChange}
            disabled={!scenarioList}
            data-analytics={DROPDOWN_SCENARIO_BUTTON__CLICK}
        >
            <Option key="scenario-option-default" value={defaultScenarioOption.id} name={defaultScenarioOption.name} />
            {(scenarioList?.scenarios ?? []).sort((a, b) => a.name.localeCompare(b.name)).map(scenario => (
                <Option
                    key={`scenario-option-${scenario.id}`}
                    value={scenario.id}
                    name={displayScenarioOption(scenario)}
                />
            ))}
        </Select>
    );
});

export default ScenarioSelector;
