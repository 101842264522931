import { useEffect } from 'react';

export const useListener = (
    eventName: keyof DocumentEventMap,
    key: 'Enter' | 'Escape' | 'Space' | null,
    listener: (event: KeyboardEvent | MouseEvent) => void
) => {
    useEffect(() => {
        const internalListener = (event: KeyboardEvent | MouseEvent) => {
            if ((event as KeyboardEvent).key && key && (event as KeyboardEvent).key !== key) {
                return;
            }

            listener(event);
        };
        document.addEventListener(eventName, internalListener as EventListener);

        return () => { document.removeEventListener('keydown', internalListener); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
