import React from 'react';
import InlineMessage from '@ingka/inline-message';
import { useTranslation } from 'react-i18next';
import { ROUTE_UNAUTHORIZED } from 'types/routes';
import classes from './Unauthorized.module.scss';

const Unauthorized = () => {
    const { t } = useTranslation();

    return (
        <div data-testid="unauthorized" id="unauthorized" className={classes.unauthorized} data-page={ROUTE_UNAUTHORIZED}>
            <InlineMessage title={t('UNAUTHORIZED')} body={t('ACCESS_DENIED')} />
        </div>
    );
};

export default Unauthorized;
