import { ContractRange } from 'types/coworker';

const integerOnlyRegex = /^[0-9]*$/;

/**
 * We only use the last four digits/characters for identifying a cost center
 * @param costCentre
 * @returns
 */
export const getShortCC = (costCentre: string) => (costCentre?.length > 4 ? costCentre.slice(6) : costCentre);

export const getShortDepartmentID = (department: string | undefined) => department?.slice(4) ?? '';

export const costCentresAreSame = (costCentre1: string, costCentre2: string) => {
    if (costCentre1.length === costCentre2.length) {
        return costCentre1 === costCentre2;
    }

    return getShortCC(costCentre1) === getShortCC(costCentre2);
};

export const costCentreIsInList = (costCentre: string, costCentreList: string[]) => costCentreList.some(cc => costCentresAreSame(cc, costCentre));

export const isIntegerNumber = (number: number | string) => integerOnlyRegex.test(String(number));

export const getCostCenterFormattedName = (
    costCentre: string,
    costCentreNames: Map<string, string> | undefined
) => `${costCentreNames?.get(costCentre)} CC${getShortCC(costCentre)}`;

// Note : Below Func is used to convert the accents(áéíóúýčďěňřšťžů) into english equivalents characters.
export const replaceAccentText = (character: string) => character.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const isNullOrEmpty = (value: string | number | null | undefined, options?: { allowZero?: boolean }) => (
    typeof value === 'number' && options?.allowZero ? false : value === 0
|| !value
|| (typeof value === 'number' ? false : value.trim() === ''));

export const toFirstLetterUpperCase = (value: string) => {
    const words = value.split(' ');

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    return words.join(' ');
};

export const displayContractRangeWithDescription = (
    contractRange:ContractRange
) => `${contractRange.description} (${contractRange.range.min ?? contractRange.range.max} - ${contractRange.range.max})`;
