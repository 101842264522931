import React from 'react';
import Button from '@ingka/button';
import plus from '@ingka/ssr-icon/paths/plus';
import useData from 'hooks/useData';
import { useTranslation } from 'react-i18next';
import { ADD_NEW_COWORKER_BUTTON__CLICK } from 'types/analytics';
import classes from './AddCoworker.module.scss';

const AddCoworkerButton = ({ setModalOpen }: { setModalOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { currentScenario } = useData();
    const { t } = useTranslation();

    return (
        <Button
            className={classes['add-coworker-button']}
            data-testid="add-new-coworker-button"
            onClick={() => { setModalOpen(true); }}
            ssrIcon={plus}
            iconPosition="leading"
            type="primary"
            size="small"
            disabled={currentScenario === undefined}
            data-analytics={ADD_NEW_COWORKER_BUTTON__CLICK}
        >
            {t('NEW_COWORKER')}
        </Button>

    );
};

export default AddCoworkerButton;
