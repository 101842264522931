import { TFunction } from 'i18next';
import { ContractRange } from 'types/coworker';

const displayContractRangeHours = (hours: ContractRange, t: TFunction<'translation', undefined, 'translation'>) => {
    if (typeof hours.range.min === 'number' && typeof hours.range.max === 'number' && hours.range.min !== hours.range.max) {
        return `${hours.range.min} - ${hours.range.max} ${t('HOURS_ABBREVIATED')}`;
    }

    return `${hours.range.max} ${t('HOURS_ABBREVIATED')}`;
};

const isContractRange = (
    hours: number | ContractRange
): hours is ContractRange => (
    typeof hours === 'object' && !!hours.description && !!hours.range && !!hours.type
);

export const displayActionHours = (hours: number | ContractRange, t: TFunction<'translation', undefined, 'translation'>) => {
    if (isContractRange(hours)) {
        return displayContractRangeHours(hours, t);
    }

    return `${hours} ${t('HOURS_ABBREVIATED')}`;
};
