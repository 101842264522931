import { APIPath } from 'components/CapacityFactor/FileUpload/types';

export const URL_AUTHORIZATION_API = `${process.env.REACT_APP_API}/authorize/auth/authUser`;

const buildUrlCreateScenarioApi = (
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/scenarios?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlCopyScenarioApi = (
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/scenarios/copy?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlUpdateScenarioApi = (
    scenarioId: string,
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/scenarios/${scenarioId}?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlUpdateScenarioMainStateApi = (
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/scenarios?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlDeleteCoworkerApi = (
    scenarioId: string,
    personId: string,
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/scenarios/${scenarioId}?country=${countryCode}&buCode=${buCode}&buType=${buType}&personId=${personId}`;

const buildUrlGetScenarioApi = (
    scenarioId: string,
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/scenarios/${scenarioId}?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlGetScenariosApi = (
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/scenarios/?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlGetActionPlanApi = (
    scenarioId: string,
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/actionplan/${scenarioId}?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlUpdateCoworkerNoteApi = (
    scenarioId: string,
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/actionplan/${scenarioId}/notes?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlConfigApi = (
    countryCode: string,
    buCode: string,
    buType: string,
) => `${process.env.REACT_APP_API}/1.0/config/data?country=${countryCode}&buCode=${buCode}&buType=${buType}`;

const buildUrlOmApi = (
    countryCode: string
) => `${process.env.REACT_APP_API}/om/organizations?country=${countryCode}`;

const buildUrlPaApi = (
    countryCode: string,
    businessUnitCode: string,
    buType: string | undefined,
) => `${process.env.REACT_APP_API}/pa/data?country=${countryCode}&buCode=${businessUnitCode}&&buType=${buType}`;

const buildUrlRatesApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
    startDate: string,
    endDate: string
// eslint-disable-next-line max-len
) => `${process.env.REACT_APP_API}/input/rates/data?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}&startDate=${startDate}&endDate=${endDate}`;

const buildUrlBudgetApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
    startDate: string,
    endDate: string
    // eslint-disable-next-line max-len
) => `${process.env.REACT_APP_API}/input/budget/data?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}&startDate=${startDate}&endDate=${endDate}`;

const buildUrlScenarioBudgetApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
    startDate: string,
    endDate: string,
    scenarioId: string
    // eslint-disable-next-line max-len
) => `${process.env.REACT_APP_API}/input/budget/data?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}&startDate=${startDate}&endDate=${endDate}&scenarioId=${scenarioId}`;

const buildUrlUploadFileApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
    category: string
) => `${process.env.REACT_APP_API}/input/${category}/upload?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}`;

const buildUrlScenarioUploadFileApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
    category: string,
    scenarioId: string
// eslint-disable-next-line max-len
) => `${process.env.REACT_APP_API}/input/${category}/upload?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}&scenarioId=${scenarioId}`;

const buildUrlDownloadFileApi = (
    category: string,
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string
) => `${process.env.REACT_APP_API}/input/${category}/download?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}`;

const buildUrlScenarioDownloadFileApi = (
    category: string,
    countryCode: string,
    businessUnitCode: string,
    buType: string,
    scenarioId: string
) => `${process.env.REACT_APP_API}/input/${category}/download?country=${countryCode}&buCode=${businessUnitCode}&buType=${buType}&scenarioId=${scenarioId}`;

const buildUrlFileAvailableApi = (
    category: string,
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string
) => `${process.env.REACT_APP_API}/input/${category}/download?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}`;

const buildUrlScenarioFileAvailableApi = (
    category: string,
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
    scenarioId: string
// eslint-disable-next-line max-len
) => `${process.env.REACT_APP_API}/input/${category}/download?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}&scenarioId=${scenarioId}`;

const buildUrlContractMixApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
) => `${process.env.REACT_APP_API}/pa/contract?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}`;

const buildUrlDefaultContributionApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
) => `${process.env.REACT_APP_API}/input/defaultcontribution/data/v1?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}`;

const buildUrlWorkloadApi = (
    countryCode: string | undefined,
    businessUnitCode: string | undefined,
    businessUnitType: string | undefined,
    startDate: string,
    endDate: string
    // eslint-disable-next-line max-len
) => `${process.env.REACT_APP_API}/input/workload/data?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}&startDate=${startDate}&endDate=${endDate}`;

const buildUrlCustomContributionApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
) => `${process.env.REACT_APP_API}/input/customcontribution/coworker?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}`;

const buildUrlGetCustomContributionApi = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
) => `${process.env.REACT_APP_API}/input/customcontribution/data?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}`;

const buildUrlMultipleBuApi = (
    employeeEmailID: string,
) => `${process.env.REACT_APP_API}/authorize/auth/bu?userId=${employeeEmailID}`;

const buildUrlUpdatedFileData = (
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string,
    category: string,
    startDate: string,
    endDate: string,
    scenarioID?: string
) => {
    switch (category) {
    case APIPath.CAPACITY_FACTORS:
        return buildUrlRatesApi(countryCode, businessUnitCode, businessUnitType, startDate, endDate);
    case APIPath.WORKLOAD:
        return buildUrlWorkloadApi(countryCode, businessUnitCode, businessUnitType, startDate, endDate);
    case APIPath.BUDGET_HOURS:
        return scenarioID ? buildUrlScenarioBudgetApi(countryCode, businessUnitCode, businessUnitType, startDate, endDate, scenarioID)
            : buildUrlBudgetApi(countryCode, businessUnitCode, businessUnitType, startDate, endDate);
    case APIPath.CUSTOM_CONTRIBUTIONS:
        return buildUrlGetCustomContributionApi(countryCode, businessUnitCode, businessUnitType);
    default:
        return buildUrlDefaultContributionApi(countryCode, businessUnitCode, businessUnitType);
    }
};

const buildUrlEnableNewUnitApi = (countryCode: string) => `${process.env.REACT_APP_API}/1.0/config/unit?country=${countryCode}`;

const buildUrlGapAnalytics = (
    countryCode: string,
    buCode: string,
    buType: string
) => `${process.env.REACT_APP_API}/input/calculations?country=${countryCode}&buCode=${buCode}&buType=${buType}`;
const buildUrlGetConfigUnitsList = (countryCode: string) => `${process.env.REACT_APP_API}/1.0/config/bu?country=${countryCode}`;

const buildUrlUpdateConfigUnitsList = (countryCode: string) => `${process.env.REACT_APP_API}/1.0/config/bu?country=${countryCode}`;

const buildUrlDownloadDefaultFileApi = (
    category: string,
    countryCode: string,
    businessUnitCode: string,
    businessUnitType: string
) => `${process.env.REACT_APP_API}/input/${category}/default/download?country=${countryCode}&buCode=${businessUnitCode}&buType=${businessUnitType}`;

const buildUrlGetRecruitmentNeedsApi = (
    countryCode: string,
    buCode: string,
    buType: string,
    scenarioId: string,
    startDate: string,
    endDate: string
// eslint-disable-next-line max-len
) => `${process.env.REACT_APP_API}/1.0/recruitment/dashboard?country=${countryCode}&buCode=${buCode}&buType=${buType}&scenarioId=${scenarioId}&startDate=${startDate}&endDate=${endDate}`;

export {
    buildUrlBudgetApi,
    buildUrlScenarioBudgetApi,
    buildUrlConfigApi,
    buildUrlContractMixApi,
    buildUrlCopyScenarioApi,
    buildUrlCreateScenarioApi,
    buildUrlCustomContributionApi,
    buildUrlDefaultContributionApi,
    buildUrlDeleteCoworkerApi,
    buildUrlDownloadFileApi,
    buildUrlScenarioDownloadFileApi,
    buildUrlFileAvailableApi,
    buildUrlScenarioFileAvailableApi,
    buildUrlGetActionPlanApi,
    buildUrlGetCustomContributionApi,
    buildUrlGetScenarioApi,
    buildUrlGetScenariosApi,
    buildUrlMultipleBuApi,
    buildUrlOmApi,
    buildUrlPaApi,
    buildUrlGapAnalytics,
    buildUrlRatesApi,
    buildUrlUpdateCoworkerNoteApi,
    buildUrlUpdateScenarioApi,
    buildUrlUpdateScenarioMainStateApi,
    buildUrlUploadFileApi,
    buildUrlScenarioUploadFileApi,
    buildUrlWorkloadApi,
    buildUrlUpdatedFileData,
    buildUrlEnableNewUnitApi,
    buildUrlGetConfigUnitsList,
    buildUrlUpdateConfigUnitsList,
    buildUrlDownloadDefaultFileApi,
    buildUrlGetRecruitmentNeedsApi
};
