import { useState } from 'react';
import { HTTPStatusCode, HTTPHeader } from 'types/api';
import { generateHttpErrorMessage, generateRuntimeErrorMessage } from 'utils/log';
import useAuth from './useAuth';

type TypeUseWriterRequest = {
    url: string;
    method?: 'POST' | 'PUT' | 'DELETE';
    deps?: Array<unknown>,
    options?: {
        headers?:[string, string][],
        fetchOptions?: Partial<RequestInit>
    }
};

export type TypeUseWriterResponse<TRequestData, TResponseData> = {
    isResponseOk: boolean | null,
    isLoading: boolean,
    error: number | null,
    requestId: string | null, // used to track individual API requests
    requestData: TRequestData | null
    responseData: TResponseData | null
    writeData: (newData?: TRequestData) => Promise<Omit<TypeUseWriterResponse<TRequestData, TResponseData>, 'isLoading' | 'writeData'>>,
};

export const useWriter = <TRequestData, TResponseData>(
    {
        url,
        method = 'POST',
        options
    }: TypeUseWriterRequest) : TypeUseWriterResponse<TRequestData, TResponseData> => {
    const [isResponseOk, setIsResponseOk] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<HTTPStatusCode | null>(null);
    const [requestId, setRequestId] = useState<string | null>(null);
    const [requestData, setRequestData] = useState<TRequestData | null>(null);
    const [responseData, setResponseData] = useState<TResponseData | null>(null);
    const { authClient } = useAuth();

    /**
     * The writeData function is used to send a request to the API.
     * @returns Returns a promise that always resolves. If isResponseOk - then payload is in responseData.
     * If !isResponseOk - the api req failed for some reason.
     */
    async function writeData(newData?: TRequestData) {
        setIsLoading(true);
        setIsResponseOk(null);
        setError(null);
        if (newData) {
            setRequestData(newData);
        }
        let appAccessToken: string | null = null;
        const shoudUseBuiltInAuth = !options?.headers?.some(el => el[0] === HTTPHeader.AUTHORIZATION);

        if (shoudUseBuiltInAuth) {
            appAccessToken = await authClient.getAppAccessToken();
        }

        const uuid = window.crypto.randomUUID();

        return fetch(url, {
            body: JSON.stringify(newData),
            method,
            headers: shoudUseBuiltInAuth
                ? [[HTTPHeader.AUTHORIZATION, `Bearer ${appAccessToken}`], [HTTPHeader.CONTENT_TYPE, 'application/json'], ...(options?.headers ?? [])]
                : [...(options?.headers ?? [])],
            mode: 'cors',
            cache: 'default',
            ...(options?.fetchOptions ?? [])
        })
            .then(response => {
                setIsResponseOk(response.ok);
                if (!response.ok) {
                    // Response is not ok, response.body does not contain the correct payload. Instead it likely contains an error object from BE.
                    setError(response.status);
                    setResponseData(null);
                    generateHttpErrorMessage(response);

                    const returnObject: Omit<TypeUseWriterResponse<TRequestData, TResponseData>, 'isLoading' | 'writeData'> = {
                        isResponseOk: false,
                        error: response.status,
                        requestId: uuid,
                        requestData: newData ?? null,
                        responseData: null,
                    };

                    return returnObject;
                }

                // Response is ok, response.body contains the correct payload.
                return response.json().then((payload: TResponseData) => {
                    setResponseData(payload);
                    setError(null);

                    const returnObject: Omit<TypeUseWriterResponse<TRequestData, TResponseData>, 'isLoading' | 'writeData'> = {
                        isResponseOk: true,
                        error: null,
                        requestId: uuid,
                        requestData: newData ?? null,
                        responseData: payload,
                    };

                    return returnObject;
                });
            })
            .catch(err => {
                // This handles any unexpected errors that may occur (e.g. response.json() fails)
                // Store a generic error, and return a standardised response.
                generateRuntimeErrorMessage(err);
                const unknownErrorCode = HTTPStatusCode.INTERNAL_SERVER_ERROR;
                setIsResponseOk(false);
                setError(unknownErrorCode);
                setResponseData(null);

                return {
                    isResponseOk: false,
                    error: unknownErrorCode,
                    requestId: uuid,
                    requestData: newData ?? null,
                    responseData: null,
                };
            })
            .finally(() => {
                setIsLoading(false);
                setRequestId(uuid);
            });
    }

    return {
        isResponseOk,
        isLoading,
        error,
        requestId, // used to track individual API requests
        requestData,
        responseData,
        writeData,
    };
};
