import React from 'react';
import contractTypes from 'providers/staticContractTypes';
import { useTranslation } from 'react-i18next';
import classes from './CoworkerDetails.module.scss';

const CoworkerContractType = ({ title, data }: { title: string; data: string; }) => {
    const { t } = useTranslation();
    const contractType = contractTypes.get(data) || t('N/A');

    return (
        <div className={classes.value}>
            <div>
                <b>{title}</b>
            </div>
            <div>{t(contractType)}</div>
        </div>
    );
};

export default CoworkerContractType;
