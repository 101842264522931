import { buildUrlUpdateScenarioApi } from 'routes/api';
import { TypeScenarioUpdateRequestAPI } from 'types/api';
import { useEffect } from 'react';
import useApp from 'hooks/useApp';
import { useWriter } from '../useWriter';
import useData from '../useData';

export const useUpdateScenarioAPI = () => {
    const { currentScenario, currentCountry, currentUnit, currentUnitType } = useData();
    const { handleAnalytics } = useApp();

    const { writeData, ...response } = useWriter<TypeScenarioUpdateRequestAPI, null>({
        url: buildUrlUpdateScenarioApi(currentScenario?.id ?? '', currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
        method: 'PUT'
    });

    useEffect(() => {
        if (response.isResponseOk && currentScenario?.isMain && handleAnalytics) {
            handleAnalytics();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response.requestId]); // if request id changes, a request has completed

    return {
        ...response,
        updateScenario: writeData
    };
};
