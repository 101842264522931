import Text from '@ingka/text';
import GraphLegend from './GraphLegend';
import classes from './Graph.module.scss';

const GraphHeader = ({
    headerTitle,
    headerSubTitle,
    legends
}: { headerTitle: string, headerSubTitle: string, legends?: { label: string, value: number, color: string }[] }) => (
    <div className={classes.header}>
        <div>
            <div>
                <Text tagName="h2" headingSize="m" className={classes['header-title']}>{headerTitle}</Text>
                <Text tagName="span" bodySize="s" className={classes['header-description']}>
                    {headerSubTitle}
                    .
                </Text>
            </div>
        </div>
        {legends && (
            <div className={classes['legend-wrapper']}>
                <GraphLegend legends={legends} />
            </div>
        )}
    </div>
);

export default GraphHeader;
