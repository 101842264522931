import { PageContainer } from 'components';
import React from 'react';
import { ROUTE_MANAGE_CAPACITY } from 'types/routes';
import { ManageCapacity } from 'views';

const ManageCapacityRoute = () => (
    <PageContainer pageAnalyticsId={ROUTE_MANAGE_CAPACITY} isFullWidth align="horizontal">
        <ManageCapacity />
    </PageContainer>
);

export default ManageCapacityRoute;
