import React from 'react';
import { CostCentre } from 'types/scenario';
import classes from './CoworkerDetails.module.scss';

const CoworkerCostCentreList = ({ title, costCentres }: { title: string; costCentres: CostCentre[] }) => (
    <div className={classes.value} data-testid="coworker-details-cost-centers">
        <div>
            <b>{title}</b>
        </div>
        {costCentres.map(cc => (
            <div key={cc.costCentre} className={cc.sourceIsPa ? '' : classes['scenario-details']}>
                {cc.displayName}
            </div>
        ))}
    </div>
);

export default CoworkerCostCentreList;
