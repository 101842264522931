import { useTranslation } from 'react-i18next';
import { TypeContractDatesAction, TypeContractTypeAction, TypeContractHoursAction } from 'types/actionPlan';
import Text from '@ingka/text';
import classes from '../ActionTable.module.scss';
import { displayActionHours } from './utils';

const ContractDatesAction = ({ action, type }: { action: TypeContractDatesAction, type: string }) => {
    const { t } = useTranslation();

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-contract-dates-header"
                >
                    {t(type)}
                </Text>
                <Text
                    data-testid="action-plan-contract-dates-change"
                >
                    {t(action.from)}
                    <b>
                        {' ➔ '}
                    </b>
                    {t(action.to)}
                </Text>
            </div>
        </div>
    );
};

const ContractHoursAction = ({ action, type }: { action: TypeContractHoursAction, type: string }) => {
    const { t } = useTranslation();

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-contract-hours-header"
                >
                    {t(type)}
                </Text>
                <Text
                    data-testid="action-plan-contract-hours-change"
                >
                    {displayActionHours(action.from, t)}
                    <b>
                        {' ➔ '}
                    </b>
                    {displayActionHours(action.to, t)}
                </Text>
            </div>
        </div>
    );
};

const ContractTypeAction = ({ action, type }: { action: TypeContractTypeAction, type: string }) => {
    const { t } = useTranslation();

    return (
        <div className={classes['details-changes']}>
            <div className={classes['details-changes-container']}>
                <Text
                    tagName="span"
                    headingSize="s"
                    data-testid="action-plan-contract-type-header"
                >
                    {t(type)}
                </Text>
                <Text
                    data-testid="action-plan-contract-type-change"
                >
                    {t(action.from)}
                    <b>
                        {' ➔ '}
                    </b>
                    {t(action.to)}
                </Text>
            </div>
        </div>
    );
};
const ContractDates = ({ action }: { action: TypeContractDatesAction }) => (<ContractDatesAction action={action} type="CONTRACT_DATES" />);
const ContractHours = ({ action }: { action: TypeContractHoursAction }) => (<ContractHoursAction action={action} type="HOURS" />);
const ContractType = ({ action }: { action: TypeContractTypeAction }) => (<ContractTypeAction action={action} type="CONTRACT_TYPE" />);

export { ContractDates, ContractHours, ContractType };
