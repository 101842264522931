import { PageContainer } from 'components';
import React from 'react';
import { ROUTE_CAPACITY_OVERVIEW } from 'types/routes';
import { CapacityOverview } from 'views/';

const CapacityOverviewRoute = () => (
    <PageContainer pageAnalyticsId={ROUTE_CAPACITY_OVERVIEW} isFullWidth align="horizontal">
        <CapacityOverview />
    </PageContainer>
);

export default CapacityOverviewRoute;
