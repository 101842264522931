import { SetURLSearchParams, createSearchParams } from 'react-router-dom';

const removeSearchParams = (paramsToBeRemoved: Array<string>, currentSearchParams: URLSearchParams, callback: SetURLSearchParams) => {
    paramsToBeRemoved.forEach(param => {
        currentSearchParams.delete(param);
    });
    callback(createSearchParams(currentSearchParams));
};

const updateSearchParams = (paramsToBeUpdated: Array<{ [key: string]: string }>, currentSearchParams: URLSearchParams, callback: SetURLSearchParams) => {
    paramsToBeUpdated.forEach(param => {
        currentSearchParams.set(Object.entries(param).flat()[0], Object.entries(param).flat()[1]);
    });

    callback(createSearchParams(currentSearchParams));
};

export { removeSearchParams, updateSearchParams };
