import React, { useState, FocusEvent, ChangeEvent, useCallback, useEffect, useRef } from 'react';
import useData from 'hooks/useData';
import { useWriter } from 'hooks/useWriter';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useToast } from 'hooks/useToast';
import { DATE_FORMAT_STANDARD } from 'utils/date';
import { debounce } from 'lodash';
import { ModulationRange } from 'types/modulation';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { getHoursInDecimal } from 'utils/number';
import { DummyCoworker, ContractType, ChangeType } from 'types/scenario';
import { Coworker } from 'types/coworker';
import { ScenarioUpdateSource, TypeScenarioUpdateRequestAPI } from 'types/api';
import classes from './EditableModulationCell.module.scss';

const ModulationCellContent = ({
    isCellChanged,
    elementId,
    modulationValue,
    canBeEdited,
    currentValue,
    errorMessage,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
}: {
    isCellChanged: boolean;
    elementId: string;
    modulationValue: number | string;
    canBeEdited: boolean;
    currentValue: number;
    errorMessage: string | null;
    onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
    onFocus: (event: FocusEvent<HTMLInputElement>) => void;
    onBlur: () => void;
    onKeyDown: (event: React.KeyboardEvent) => void;
}) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const { t } = useTranslation();
    const ref = useRef<HTMLInputElement>(null);

    const handleMouseEnter = () => {
        if (errorMessage) {
            setShowTooltip(true);
        }
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return canBeEdited ? (
        <>
            <input
                className={`${classes['input-cell']} ${isCellChanged ? classes['input-cell-edited'] : ''}`}
                id={elementId}
                type="number"
                value={modulationValue}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                autoComplete="off"
                ref={ref}
            />
            <Tooltip isOpen={showTooltip} parentElement={ref.current} prefix={t('WARNING')} content={errorMessage ?? ''} />
        </>
    ) : (
        <span data-testid="editable-cell-span">{currentValue}</span>
    );
};

const ModulationCell = ({
    elementId,
    currentHours,
    currentMinutes,
    canBeEdited,
    isActive,
    coworker,
    updateScenario,
    modulationRange,
}: {
    elementId: string;
    currentHours: number;
    currentMinutes: number;
    canBeEdited: boolean;
    isActive: boolean;
    coworker: Coworker | DummyCoworker;
    updateScenario: ReturnType<typeof useWriter<TypeScenarioUpdateRequestAPI, {}>>['writeData'];
    modulationRange: ModulationRange;
}) => {
    const currentValue = getHoursInDecimal(currentHours, currentMinutes);
    const minimumHours = getHoursInDecimal(modulationRange.minLimit.hours, modulationRange.minLimit.minutes);
    const maximumHours = getHoursInDecimal(modulationRange.maxLimit.hours, modulationRange.maxLimit.minutes);
    const [modulationValue, setModulationValue] = useState<number | string>(currentValue);
    const [isFocused, setIsFocused] = useState(false);
    const { currentScenario, selectScenario } = useData();
    const { t } = useTranslation();
    const { displayToast } = useToast();

    const validateModulationHours = (hours: number) => {
        if ((hours > maximumHours || hours < minimumHours) && hours !== 0) {
            return false;
        }

        return true;
    };

    const [isValid, setIsValid] = useState<boolean>(() => validateModulationHours(Number(currentValue)));
    useEffect(() => {
        setModulationValue(currentValue);
        setIsValid(validateModulationHours(Number(currentValue)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentHours, currentMinutes]);

    const isCellChanged = Number(modulationValue) !== 0;
    const outOfRangeErrorMessage = `${t('VALUE_INVALID_RANGE')} (${t('MINIMUM').toLowerCase()} ${minimumHours} 
${t('AND')} ${t('MAXIMUM').toLowerCase()} ${maximumHours})`;

    const handleSave = async (h: number, min: number) => {
        const [, costCentre, weekDate] = elementId.split(':', 3);
        updateScenario({
            source: ScenarioUpdateSource.MANAGE_CAPACITY_TABLE,
            personId: coworker.personId,
            coworkerName: coworker.legalFullName,
            startDate: moment(weekDate).startOf('isoWeek').format(DATE_FORMAT_STANDARD),
            endDate: moment(weekDate).endOf('isoWeek').format(DATE_FORMAT_STANDARD),
            division: coworker.division,
            costCentre,
            department: coworker.departmentCode ?? '',
            contractType: coworker.contractType as ContractType,
            dummyCoworker: null,
            contractHours: coworker.hoursPerWeek,
            delta: [
                {
                    type: ChangeType.MODULATION_HOURS,
                    costCentre,
                    fromHours: 0,
                    fromMinutes: 0,
                    toHours: h,
                    toMinutes: min,
                },
            ],
        }).then(response => {
            if (!response.isResponseOk) {
                displayToast({ title: t('ERROR'), message: t('SAVE_FAILED') });

                return;
            }

            displayToast({ title: t('SUCCESS'), message: t('SAVE_SUCCEEDED') });
            selectScenario(currentScenario?.id ?? '');
        });
    };

    const handleBlur = () => {
        setIsFocused(false);
        const numberValue = Number(modulationValue);
        if (modulationValue === currentValue) {
            return;
        }

        const hours = Math.trunc(numberValue);
        const minutes = (numberValue % 1) * 60;
        handleSave(hours ?? 0, minutes ?? 0);
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        event.target.select();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedValidate = useCallback(
        debounce(
            (hours: number) => {
                const areHoursValid = validateModulationHours(hours);

                setIsValid(areHoursValid);
            },
            250,
            { trailing: true },
        ),
        [],
    );

    const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const val = value === '' ? value : Number(value);
        debouncedValidate(Number(val));
        setModulationValue(val);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleBlur();
        }
    };

    return (
        <td
            data-testid="modulationCell"
            key={elementId}
            className={`
            ${classes['td-cell']} 
            ${isCellChanged ? classes['td-cell-edited'] : ''} 
            ${isFocused ? classes['td-cell-focused'] : ''}
            ${currentScenario ? classes['td-select-cell'] : ''}
            ${currentScenario || isActive ? '' : classes.disabled}
            ${isValid ? '' : classes['td-cell__error']}
            `}
        >
            <ModulationCellContent
                elementId={elementId}
                canBeEdited={canBeEdited}
                currentValue={currentValue}
                modulationValue={modulationValue}
                errorMessage={isValid ? null : outOfRangeErrorMessage}
                isCellChanged={isCellChanged}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
            />
        </td>
    );
};

export default ModulationCell;
