import { CheckBox, TimeSelection } from 'types/appContext';
import { NewHiresDelta, RecruitmentNeedsDelta } from 'types/recruitmentNeeds';
import { isBetweenTimePeriod, isoWeekFromDateString } from 'utils/date';

const isSameCostCentre = (filteredOMData: CheckBox[] | undefined, cc: string): boolean => filteredOMData?.find(({ id }) => id === cc)?.defaultChecked ?? false;

const isCostCentreSelected = (costCenterOMData: CheckBox[] | undefined): boolean => costCenterOMData?.some(({ defaultChecked }) => defaultChecked) ?? false;

const getCCLabel = (costCenter: string, costCenterData: CheckBox[] | undefined): string => costCenterData?.find(({ id }) => costCenter === id)?.label ?? '';

const getFilteredNewHires = (
    costCenterOMData: CheckBox[] | undefined,
    recruitmentNeedsData: RecruitmentNeedsDelta[] | undefined,
    timeSelection: TimeSelection
) => {
    const allNewHires = recruitmentNeedsData?.flatMap(({ newHires }) => newHires);
    if (isCostCentreSelected(costCenterOMData)) {
        return allNewHires?.filter(({ startDate, costCentre }) => isSameCostCentre(costCenterOMData, costCentre)
            && isBetweenTimePeriod(startDate, timeSelection)) ?? null;
    }

    return allNewHires?.filter(({ startDate }) => isBetweenTimePeriod(startDate, timeSelection)) ?? null;
};

const getWeeklyHires = (allNewHires: NewHiresDelta[]): string[] => {
    const availableWeeks: string[] = [];

    allNewHires?.forEach(({ startDate }) => {
        const isoWeek = isoWeekFromDateString(startDate);
        if (!availableWeeks?.includes(isoWeek)) {
            availableWeeks.push(isoWeek);
        }
    });

    return availableWeeks;
};

export { getFilteredNewHires, getCCLabel, getWeeklyHires };
