import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import ModalService from 'services/modal';
import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import InlineMessage from '@ingka/inline-message';
import useApp from 'hooks/useApp';
import { DATE_FORMAT_STANDARD, generateTimeArray } from 'utils/date';
import { TimeSelection } from 'types/appContext';
import TimePeriodDisplay from './TimePeriodDisplay';
import classes from './TimeFilter.module.scss';
import DatePicker from './DatePicker';
import { isDateAllowed } from './helpers';

const TimeFilter = ({ disabled }: { disabled?: boolean }) => {
    const { timeSelection, setTimeSelection } = useApp();
    const [type, setType] = useState<'start' | 'end' | 'both'>('both');
    const [filteredTimeSelection, setFilteredTimeSelection] = useState<TimeSelection>(timeSelection);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    const resetTimeFilters = () => {
        setFilteredTimeSelection(timeObject => ({
            ...timeObject,
            valid: true,
            errorMessage: null,
            startDate: timeSelection.startDate,
            endDate: timeSelection.endDate,
            timeArray: generateTimeArray(timeSelection.startDate, timeSelection.endDate, timeSelection.selection),
        }));
    };

    useEffect(() => {
        const escapeListener = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                resetTimeFilters();
            }
        };
        document.addEventListener('keydown', escapeListener);

        return () => document.removeEventListener('keydown', escapeListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSelection]);

    const validatePeriod = (startDate: string, endDate:string) => {
        let isValid = moment(endDate).isSameOrAfter(moment(startDate));
        let errorMSG = isValid === true ? null : 'INVALID_FILTER_RANGE_ERROR';

        if (isValid && !(isDateAllowed(startDate) && isDateAllowed(endDate))) {
            isValid = false;
            errorMSG = 'UNSUPPORTED_FILTER_RANGE_ERROR';
        }

        setFilteredTimeSelection(timeObject => ({
            ...timeObject,
            valid: isValid,
            errorMessage: errorMSG,
            timeArray: generateTimeArray(filteredTimeSelection.startDate, filteredTimeSelection.endDate, filteredTimeSelection.selection),
        }));

        return isValid;
    };

    const filterCoworkers = () => {
        setModalOpen(false);

        const isDateFilterChanged = !(moment(filteredTimeSelection.endDate).isSame(moment(timeSelection.endDate))
         && moment(filteredTimeSelection.startDate).isSame(moment(timeSelection.startDate)));

        if (isDateFilterChanged && validatePeriod(moment(filteredTimeSelection.startDate).format(DATE_FORMAT_STANDARD), filteredTimeSelection.endDate)) {
            setTimeSelection(timeObject => ({
                ...timeObject,
                startDate: filteredTimeSelection.startDate,
                endDate: filteredTimeSelection.endDate,
                timeArray: generateTimeArray(filteredTimeSelection.startDate, filteredTimeSelection.endDate, filteredTimeSelection.selection),

            }));
        }
    };

    const handlePeriodShift = (periodShift: 'left' | 'right') => {
        if (periodShift === 'left') {
            setTimeSelection(timeObject => {
                const start = moment(timeObject.startDate).subtract(1, timeSelection.selection).format(DATE_FORMAT_STANDARD);
                const end = moment(timeObject.endDate).subtract(1, timeSelection.selection).format(DATE_FORMAT_STANDARD);

                return {
                    ...timeObject,
                    startDate: start,
                    endDate: end,
                    timeArray: generateTimeArray(start, end, timeObject.selection)
                };
            });
        } else {
            setTimeSelection(timeObject => {
                const start = moment(timeObject.startDate).add(1, timeSelection.selection).format(DATE_FORMAT_STANDARD);
                const end = moment(timeObject.endDate).add(1, timeSelection.selection).format(DATE_FORMAT_STANDARD);

                return {
                    ...timeObject,
                    startDate: start,
                    endDate: end,
                    timeArray: generateTimeArray(start, end, timeObject.selection)
                };
            });
        }
    };

    const modal = ModalService().open(
        'prompt',
        modalOpen,
        setModalOpen,
        (
            <div className={classes['filter-date']}>

                {(['start', 'both'].includes(type)) && (
                    <DatePicker
                        type="start"
                        timeSelection={filteredTimeSelection}
                        setTimeSelection={setFilteredTimeSelection}
                        handleValidation={validatePeriod}
                    />
                )}
                {(['end', 'both'].includes(type)) && (
                    <DatePicker
                        type="end"
                        timeSelection={filteredTimeSelection}
                        setTimeSelection={setFilteredTimeSelection}
                        handleValidation={validatePeriod}
                    />
                )}

                { (filteredTimeSelection.errorMessage === null || filteredTimeSelection.errorMessage === undefined) ? (<div />)
                    : (
                        <div className={classes['error-filter-date']}>
                            <InlineMessage className="error" body={t(filteredTimeSelection.errorMessage)} variant="cautionary" subtle />
                        </div>
                    )}
            </div>
        ),
        <Button
            onClick={filterCoworkers}
            text={t('CLOSE')}
            type="primary"
            size="small"
            disabled={!filteredTimeSelection.valid}
        />,
        t('TIME_SELECTION')
    );

    const openCalendar = (selectedType: 'start' | 'end' | 'both') => {
        setType(selectedType);
        setModalOpen(true);
    };

    const portalDiv = document.getElementById('portal');

    return (
        <div className={classes['time-filter-box']} data-testid="time-filter">
            <TimePeriodDisplay
                timeSelection={timeSelection}
                handlePeriodShift={handlePeriodShift}
                openCalendar={openCalendar}
                disabled={disabled}
            />
            {portalDiv && ReactDOM.createPortal(modal, portalDiv)}
        </div>
    );
};

export default TimeFilter;
