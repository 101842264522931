import { useEffect, useState } from 'react';
import _ from 'lodash';

const useScrollSlice = (initialEndValue: number, finalEndValue: number | undefined, elementId: string) => {
    const [slice, setSlice] = useState({ start: 0, end: initialEndValue });

    useEffect(() => {
        const handleScroll = _.debounce((element: HTMLElement | null) => {
            const scrolledHeight = element?.scrollTop || 0;
            const totalContentHeight = element?.scrollHeight || 0; // including the hidden content
            const visibleContentHeight = element?.offsetHeight || 0;
            const hiddenContentHeight = totalContentHeight - visibleContentHeight;
            if (hiddenContentHeight <= scrolledHeight * 1.1) { // 10% extra for safety
                // Update slice when the end is reached
                setSlice(previousState => ({ start: 0, end: previousState.end + initialEndValue }));
            }
        }, 200);

        const handleResize = _.debounce((element: HTMLElement | null) => {
            const totalContentHeight = element?.scrollHeight;
            const visibleContentHeight = element?.offsetHeight;
            if (
                totalContentHeight && visibleContentHeight && finalEndValue
                    && visibleContentHeight >= totalContentHeight
            ) {
                setSlice(previousState => {
                    if (previousState.end >= finalEndValue) {
                        return previousState;
                    }

                    return ({ ...previousState, end: previousState.end + initialEndValue });
                });
                handleResize(element);
            }
        }, 200);

        const element = document.getElementById(elementId);
        element?.addEventListener('scroll', () => handleScroll(element), { passive: true });
        window.addEventListener('resize', () => handleResize(element), { passive: true });
        handleResize(element);

        return () => {
            window.removeEventListener('resize', () => handleResize(element));
            element?.removeEventListener('scroll', () => handleScroll(element));
        };
    }, [elementId, finalEndValue, initialEndValue]);

    return { slice };
};

export default useScrollSlice;
