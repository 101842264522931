import { AccordionItem } from '@ingka/accordion';
import Checkbox from '@ingka/checkbox';
import RadioButton from '@ingka/radio-button';
import React, { useRef } from 'react';
import { ANALYTICS_OM_FILTERS_MAP } from 'types/analytics';
import { CheckBox } from 'types/appContext';
import { t } from 'i18next';
import { OMFilter as OMFilterCategory } from 'types/enums';
import classes from './OMFilters.module.scss';
import { CheckBoxLabel } from './CheckBoxLabel';

const getIndeterminateState = (checkboxes: CheckBox[] | undefined): boolean => (
    !!checkboxes && checkboxes.some(el => el.defaultChecked) && checkboxes.some(el => !el.defaultChecked)
);

/**
 * @param caption
 * @returns Adds custom caption, if omitted checked checkbox label property is used
 */
const OMFilter = (
    {
        data,
        handleChange,
        type,
        title,
        selectionType,
        autoCollapse,
        caption,
        showIndeterminate,
        sortingToggle,
    }
    : {
        data: CheckBox[] | undefined,
        handleChange: (category: OMFilterCategory, key: string, isSelectAll?: boolean, sortCC?: number) => void,
        type: OMFilterCategory,
        title: string,
        selectionType: 'single' | 'multi',
        autoCollapse?: boolean,
        caption?: string,
        showIndeterminate?: boolean,
        sortingToggle?: JSX.Element
    }
) => {
    const ref = useRef<HTMLLIElement>(null);
    const createProps = (item: CheckBox) => ({
        autoComplete: Boolean(item.autocomplete).toString(),
        id: item.id,
        key: item.id,
        name: item.type,
        value: item.value,
        checked: item.defaultChecked,
        disabled: item.disabled,
        onChange: () => {
            handleChange(type, item.id);
            if (autoCollapse) {
                setTimeout(() => ref.current?.getElementsByTagName('button')[0].click(), 0);
            }
        },
        label: (<CheckBoxLabel label={item.label} subLabel={item.subLabel ?? ''} />)
    });

    const renderSelectionItems = (checkboxItem: CheckBox, showListItems: boolean) => {
        if (selectionType === 'single') {
            return (
                <RadioButton
                    {...createProps(checkboxItem)}
                />
            );
        }

        return (!showListItems
            ? (
                <Checkbox
                    {...createProps(checkboxItem)}
                />
            )
            : (
                <ul>
                    <li>
                        <Checkbox
                            {...createProps(checkboxItem)}
                        />
                    </li>
                </ul>
            )
        );
    };

    return (
        <AccordionItem
            data-testid={`accordion-item-${type}`}
            title={t(title)}
            id={`acc-item-${type}`}
            key={`acc-item-${type}`}
            disabled={!data}
            caption={caption}
            ref={ref}
            data-analytics={ANALYTICS_OM_FILTERS_MAP.get(type)}
        >
            {sortingToggle}
            {showIndeterminate && (
            <Checkbox
                id={`select-all-${type}`}
                label={t('SELECT_ALL')}
                name={`select-all-${type}`}
                checked={data?.every((item: CheckBox) => item.defaultChecked)}
                onChange={event => handleChange(type, 'select-all', event.target.checked)}
                value={t('SELECT_ALL')}
                indeterminate={getIndeterminateState(data)}
            />
            )}
            {data?.map((item: CheckBox) => (
                // FIXME: Temporary fix for duplicate id's in departments
                <div key={type === OMFilterCategory.DEPARTMENT ? window.crypto.randomUUID() : item.id} className={classes['checkbox-container']}>
                    {renderSelectionItems(item, showIndeterminate ?? false)}
                </div>
            ))}

        </AccordionItem>
    );
};

export default OMFilter;
