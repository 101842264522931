import React from 'react';
import { DataContext } from 'context';

const useData = () => {
    const context = React.useContext(DataContext);
    if (!context) {
        throw new Error('No data in DataContext?');
    }

    return context;
};

export default useData;
