import React from 'react';
import { useTranslation } from 'react-i18next';
import { CostCentreContribution } from 'types/coworker';
import { getShortCC } from 'utils/text';
import classes from './CoworkerDetails.module.scss';

function CoworkerContribution({ title, contributions }: { title: string, contributions: CostCentreContribution[] }) {
    const { t } = useTranslation();

    return (
        contributions?.map(({ costCentre, evening, saturday, sunday }) => (
            <div key={costCentre} className={classes['info-item']}>
                <div data-testid="coworker-contribution-CostCentre">
                    <b>{`${title} (${getShortCC(costCentre)})`}</b>
                </div>
                <div data-testid="coworker-contribution-evening">
                    {`${t('EVENINGS')} : ${evening} ${t('PER_WEEK')}`}
                </div>
                <div data-testid="coworker-contribution-saturday">
                    {`${t('SATURDAY')} : ${saturday} ${t('PER_WEEK')}`}
                </div>
                <div data-testid="coworker-contribution-sunday">
                    {`${t('SUNDAY')} : ${sunday} ${t('PER_WEEK')}`}
                </div>
            </div>
        ))
    );
}

export default CoworkerContribution;
