import { buildUrlUpdateScenarioMainStateApi } from 'routes/api';
import { TypeUpdateScenarioMainStateRequestAPI } from 'types/api';
import { useWriter } from '../useWriter';
import useData from '../useData';

export const useUpdateScenarioMainStateAPI = () => {
    const { currentCountry, currentUnit, currentUnitType } = useData();
    const { writeData } = useWriter<TypeUpdateScenarioMainStateRequestAPI, {}>(
        {
            url: buildUrlUpdateScenarioMainStateApi(currentCountry ?? '', currentUnit ?? '', currentUnitType ?? ''),
            method: 'PUT',
        }
    );

    return {
        updateScenarioMainState: writeData
    };
};
