import { ConfigurationData } from 'types/api';
import { ClauseOperator, Rule, RuleClause, RuleClauseRangeValue, RuleOperator } from 'types/modulation';
import { Coworker } from 'types/coworker';
import { DummyCoworker } from 'types/scenario';
import _ from 'lodash';

const compareAttributes = (clause: RuleClause, coworker: Coworker | DummyCoworker) => {
    switch (clause.operator) {
    case ClauseOperator.EQUALS:
        return coworker[clause.key] === clause.value;
    case ClauseOperator.NOT_EQUALS:
        return coworker[clause.key] !== clause.value;
    case ClauseOperator.IN:
        if (clause.value.constructor === Array) {
            return clause.value.includes(coworker[clause.key] as string);
        }

        return false;
    case ClauseOperator.RANGE:
        return _.inRange(
            coworker[clause.key] as number,
            (clause.value as RuleClauseRangeValue).min,
            (clause.value as RuleClauseRangeValue).max
        );
    default:
        return false;
    }
};

const getModulationRangeFromRules = (rules: Array<Rule>, coworker: Coworker | DummyCoworker) => {
    const getAndRules = (rule: Rule) => !rule.clauses
        .map((clauseValue: RuleClause) => compareAttributes(clauseValue, coworker))
        .includes(false);

    const filteredRules = rules
        .filter(rule => {
            if (rule.operator) {
                switch (rule.operator) {
                case RuleOperator.AND:
                    return getAndRules(rule);
                case RuleOperator.OR:
                    return rule.clauses
                        .map((clauseValue: RuleClause) => compareAttributes(clauseValue, coworker))
                        .some(Boolean);
                default:
                    return false;
                }
            } else {
                return getAndRules(rule);
            }
        });

    return filteredRules.find(rule => rule.modulationRange) ?? null;
};

export const getModulationForCoworker = (coworker: Coworker | DummyCoworker, config: ConfigurationData | null) => {
    if (!config?.modulation || !(coworker as Coworker)?.isModulated) return null;

    let rule: Rule | null = null;

    const modulatedBusinessFunction = config.modulation.unit.divisions
        .find(bf => bf.isModulated
        && (bf.id === coworker.division));
    if (modulatedBusinessFunction) {
        rule = getModulationRangeFromRules(modulatedBusinessFunction.rules, coworker);
        if (rule) {
            return rule;
        }
    }

    rule = getModulationRangeFromRules(config.modulation.unit.rules, coworker);
    if (rule) {
        return rule;
    }

    return null;
};

/**
 * This checks if a provided id matches either the personId of the coworker or the employeeId of the coworker
 * @param coworker The (dummy) coworker
 * @param id The id that should be checked vs a coworker
 * @returns boolean
 */
export const isCoworkersId = (coworker: Pick<Coworker | DummyCoworker, 'personId' | 'employeeId'>, id: string) => (
    coworker.personId === id || coworker.employeeId === id
);
